@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@font-face {
    font-family: 'SF Pro Text';
    src: url(/static/media/SFProText-Bold.b47a61d3.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url(/static/media/SFProText-Light.9b0bcae3.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url(/static/media/SFProText-Medium.e04d73af.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url(/static/media/SFProText-Regular.a2038fbd.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url(/static/media/SFProText-RegularItalic.1871b3ea.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url(/static/media/SFProText-Semibold.018656c5.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
/* * { scrollbar-width: thin; scrollbar-color: var(--scroll) var(--scroll);} */
*::-webkit-scrollbar { width: 12px;height: 10px;  }
::-webkit-scrollbar-corner {background: var(--scroll);}
*::-webkit-scrollbar-thumb {background-color: var(--scroll);border-radius: 20px;}
/* .ant-layout, body{background-color: var(--bgDarkGrey);} */
.ant-btn{font-weight: var(--fw500);}
.ant-drawer-close{display: none;}
.d-inlineblock{display: inline-block !important;}


.ant-alert{border-radius: 10px;margin-bottom: 16px;}
.ant-alert-message{font-size: 18px !important;font-weight: 400;color: var(--textDark) !important;line-height: normal;}
.ant-alert-description{font-size: 16px !important;color: var(--textSecondary) !important;}
.grey-search.ant-input-search .ant-input-group input, .grey-search.ant-input-search .ant-input-group .ant-input-group-addon{background-color: var(--bgGrey) !important;}
.ant-input-search .ant-input-group-addon .ant-btn{background-color: transparent; border: none; width: 42px;box-shadow: none;}
.ant-dropdown-arrow{width: 10px; height: 10px; background-color: var(--bgGloom);top: 5px !important;}
.dark-search +.ant-table-wrapper .ant-table-container{background-color: var(--bgGrey) !important;}
/*--Dashboard--*/


.chart-loader{display: flex;justify-content: center;height: 400px; align-items: center;}
#graph-container, #container{width: 100%; height: 100%;}
.markets-panel .ant-list-split .ant-list-item, .portfolio-list .ant-list-split .ant-list-item{border-color: var(--borderGrey) !important; padding: 14px 0;transition: 0.3s ease;}
.portfolio-list h1{padding: 36px 0 16px;}
.portfolio-list .ant-list-split .ant-list-item, .markets-line .ant-list-split .ant-list-item{border-top: 1px solid var(--borderDark) !important;}
.portfolio-list .ant-list-split .ant-list-item:last-child{border-bottom: 1px solid var(--borderDark) !important;}
.portfolio-list .ant-list-split .ant-list-item .ant-list-item-meta-avatar{margin-top: 4px;}
.btn-primary{background-color: var(--btnPrimary) !important;}
.btn-back{background-color: transparent;border: 0;border-radius: 40px;height: 42px;text-transform: uppercase;transition: 0.3s ease;}
.btn-back:hover{background-color: var(--bgDarkYellow);color: var(--bgDark);font-weight: 500;}
.login-user, .side-drawer .side-drawer-header, .side-drawer-full .side-drawer-header, .wire-trans, .crypto-card-bottom{display: flex;justify-content: space-between;align-items: center;}
.header-logo{list-style: none;display: inline-flex;padding-right: 0 !important;align-items: center;margin-bottom: 0;}

.header-logo li:first-child::after{content: '';position: absolute;width: 1px;height: 27px;right: 0;top: 16px;background-color: var(--bgWhiteSpin);}
.tlv-header .list-item.ant-menu-item .ant-menu-title-content{padding:6px 12px;}
.tlv-header .list-item.ant-menu-item-selected .ant-menu-title-content, .tlv-header .list-item.ant-menu-item-active .ant-menu-title-content{
background-image: linear-gradient(99.09deg, var(--btnGrad1) 0%, var(--btnGrad2) 100%); filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));margin: 0px;border-radius: 5px;border:0;
line-height: 16px;min-width:90px;color:var(--textPureWhite);}
.custom-btn{border-radius: 5px;border:0;line-height: 18px;min-width:90px;height: 36px;font-weight: 300;font-size: 16px;filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));transition: 0.5s ease;}
.custom-btn.outline{border-color: var(--bgDarkGrey) !important;}
.custom-btn.sec.network{border: 1px solid #8a8ab8; padding: 8px 10px;line-height: 18px; margin-right: 5px;font-size: 12px;}
.carousel-card{background-color:var(--cardBG) !important;border-radius: 13px;height: 110px;padding: 14px 20px;overflow-y: auto;}
.ant-carousel .slick-dots-bottom{bottom: 10px  !important;}
.ant-carousel .slick-dots li button{background: var(--primary) !important; opacity: 0.5;height: 4px;border-radius: 2px;}
.ant-carousel .slick-dots li.slick-active button{background: var(--primary) !important; opacity: 1;}
.ant-carousel .slick-dots li{width: 8px; }
.ant-carousel .slick-dots li.slick-active{width: 30px;}

.ant-modal.megamenu{top: 0 !important;bottom: 0 !important;width: 100% !important;max-width: 100%;}
.megamenu-backdrop, .megamenu .ant-modal-header{background-color: var(--bgGloom);}
.megamenu .ant-modal-header{border: none;}
.megamenu-title{display: flex; justify-content: space-between; padding-right: 60px;align-items:center ; }
.megamenu .ant-modal-content{box-shadow: none; background-color: var(--bgGloom) !important;min-height: calc(100vh - 70px); max-height: calc(100vh - 70px);}
.megamenu-link a, .megamenu-sublink a,.megamenu-link .menu_Link{font-size: 18px; color: var(--textWhite30); display: block;font-weight: 300;cursor: pointer;margin-bottom: 0;}
.megamenu-sublink a{font-size: 14px; margin-bottom: 24px;}
.megamenu-link a:hover, .megamenu-sublink a:hover,.megamenu-link .menu_Link:hover{color: var(--textAqua) !important;}


.dropdown-flex-top{display: flex;justify-content: space-between;align-items: center;}
.drpdwn-list li:first-child a:first-child{border-top: none}
.dropdown-flex{border-bottom: 1px solid var(--bgDarkGrey);display: flex;justify-content: space-between;align-items: center;}
.drpdwn-list li.no-hover:hover{background-color: transparent !important;}
.drpdwn-list li span.icon{transition: margin 0.3s ease-in; margin-left: auto;}

.icon.xl{width: 32px; height: 32px;}
.icon.md{width: 20px; height: 20px;}
.icon.xxxl{width: 70px; height: 70px;}
.icon.xl.closewhite{background-position: -2px -243px;}
.icon.md.close{background-position: -42px -70px;}
.icon.lg.greencheck{background-position: -68px -65px; position: absolute;top: 37px;right: 19px;transform: scale(0.8);}
.icon.lg.close{background-position: -262px -516px; position: absolute;top: 37px;right: 19px;transform: scale(0.8);}
.icon.lg{width: 26px; height: 26px;}
.icon.lg.drawer-close{background-position: -1px -112px;}
.icon.lg.check-ylw{background-position: -36px -111px;}
.icon.lg.chat{background-position: -429px -1px;}
.icon.md.bell{background-position: -1px 0;}
.icon.md.gear{background-position: -30px -1px;}
.icon.md.hamburger{background-position: -59px -1px;}
.icon.md.lose{background-position: -271px -2px;}
.icon.md.gain{background-position: -302px -2px;}
.icon.md.downarrow{background-position: -59px -2px;}
.icon.md.close-white{background-position: -170px 0;}
.icon.md.date-white{background-position: -480px -37px;}
.icon.md.search-white{background-position: -197px 0;}
.icon.md.load{background-position: -242px 0;float: right; margin-top: 2px;}
.icon.md.info{background-position: -74px -327px;transform: scale(0.8); width: 28px;height: 28px;}
.icon.md.lftarw-white{background-position: -377px 0;}
.icon.md.downarrow-icon{background-position: -350px 0;}
.icon.md.history-white{background-position:-272px -33px;}
.icon.md.card{background-position: -480px -1px;}
.icon.md.phone{background-position: 0px -35px;}
.icon.md.qrcode{background-position: -140px -34px;}
.icon.md.swaparrow{background-position: -236px -35px;margin-bottom: 6px;}
.icon.md.sentarrow{background-position: -307px -35px}
.icon.md.recivearrow{background-position: -338px -35px}
.icon.md.check-arrow{background-position: -366px -36px}
.icon.lg.camera{background-position: -397px -31px;}
.icon.md.eye{background-position: -173px -34px;}
.icon.md.address-book{background-position:-239px -70px}
.icon.md.approve{background-position:-184px -70px}
.icon.md.profit-arw{background-position:-308px -34px}
.icon.md.lose-arw{background-position:-338px -34px}
.icon.md.download{background-position:-627px -38px;}
.icon.md.delete{background-position:-465px -102px;}
.icon.md.delete-icon{background-position:-465px -101.5px;}
.icon.lg.hamburg{background-position: -55px 2px; transform: scale(1.4);}
.icon.md.notifyIcon.deposit{background-position: -375px 0px;transform: rotateZ(235deg);}
.icon.md.notifyIcon.deposits, .icon.md.notifyIcon.withdraw{background-position: -376px -1px;transform: rotateZ( 45deg);}
.icon.md.notifyIcon.documents, .icon.md.notifyIcon.cutomers{background-position: -156px -71px;}
.icon.md.notifyIcon.cases{background-position: -213px -332px;}
.icon.md.notifyIcon.payments{background-position: -481px -3px;}
.icon.sm{width: 12px; height: 12px;}
.icon.sm.downarrow{background-position: -107px -4px;margin-left: 8px;}
.icon.sm.uparrow{background-position: -87px -4px;}
.icon.sm.rightarrow{background-position: -78px -40px;}
.icon.sm.leftarrow{background-position: -92px -40px;}
.icon.sm.downarrow-white{background-position: -148px -5px;transform: scale(1.1);}
.icon.sm.uparrow-white{background-position: -128px -6px;}
.icon.sm.r-arrow-o-white{background-position:-222px -5px;}
.icon.sm.uparw-o-white{background-position:-332px -6px;}
.icon.sm.dwnarw-o-white{background-position:-355px -5px;}
.icon.sm.rightthemearrow{background-position: -149px -4px;}
.icon.sm.add{background-position:-32px -40px;}
.icon.sm.right-angle{background-position:-510px -6px;}
.icon.sm.search-angle{background-position:-561px -8px; margin-top: -3px}
.icon.md.diag-arrow{background-position:-111px -36px;}
.icon.md.leftarrow{background-position:-376px -1px}
.icon.md.rarrow-white{background-position:-217px -1px;}
.icon.md.signal-white{background-position: -68px -112px;width: 25px !important;height: 25px !important;}
.icon.md.h-more{background-position:-53px -37px;}
.icon.md.downangle{background-position: -352px 0;}
.icon.md.upangle{background-position: -352px 0;}
.icon.md.greyCheck{background-position: -97px -71px;}
.icon.md.greenCheck{background-position: -71px -71px;}
.icon.md.attach{background-position: -427px -33px;}
.icon.md.send-icon{background-position:-452px -34px;}
.icon.md.reply{background-position:-602px -4px;}
.icon.md.file{background-position:-156px -70px;}
.icon.md.buy{background-position:-568px -35px;}
.icon.md.sell{background-position:-538px -36px;}
.icon.md.menu-bar{background-position:-321px -134px;}
.icon.md.withdraw{background-position:-510px -34px;}
.icon.md.deposit{background-position:-655px -38px;}
.icon.md.user{background-position:-212px -70px;}
.icon.md.calendar{background-position:-481px -36px;}
.icon.md.status{background-position:-365px -69px}
.icon.md.add-icon{background-position:-397px -70px;}
.icon.md.process-icon{background-position: -680px -37px;}
.icon.md.back{background-position:-377px -1px;}
.icon.md.add-icon-black{background-position:-533px -2px;}
.icon.md.edit-icon{background-position:-336px -70px;}
.icon.lg.edit-icon{background-position:-336px -70px;}
.icon.md.email{background-position:-127px -70px;}
.icon.lg.add-icon{background-position:-397px -70px;}
.icon.lg.app-menu{background-position:-235px -130px;}
.icon.md.status-icon{background-position:-637px -134px;}
.icon.xl.image{background-position:-47px -241px;}
.icon.xl.file{background-position:-87px -241px;}
.icon.xxxl.doc-upload{background-position:1px -398px;}
.icon.lg.beneficiary-icon {background-position: -207px -130px;}
.icon.md.address-book-icon{background-position: -581px -3px;}
.header-right li{padding: 0 !important;margin: 0 6px; font-size: 16px; color: var(--textWhite30) !important;}
.header-dropdown{font-size: 24px; color: var(--textBlack) !important;}
.mid-menu{display: flex; align-items: center;}
.box.dash-info{border-radius: 0 !important;}

.trade-btn{border-radius: 5px;  line-height: normal; height: 20px;padding: 0 4px;}
/* Buy/sell */
/* .side-drawer .ant-drawer-body, .side-drawer .ant-drawer-header, .side-drawer-full .ant-drawer-header, .side-drawer-full .ant-drawer-body, .side-drawer-full .ant-table-cell, .side-drawer-full .ant-table-tbody > tr.ant-table-row:hover > td, .side-drawer-full .ant-table-thead th.ant-table-column-sort, .side-drawer-full .ant-table-thead th.ant-table-column-has-sorters:hover{background-color: var(--bgGrey);} */
.side-drawer .ant-drawer-mask, .side-drawer-full .ant-drawer-mask{background-color: var(--bgDarkGrey-95);}
.side-drawer .ant-drawer-header,.side-drawer-full .ant-drawer-header, .crypto-list-tabs .ant-tabs-nav::before{border: none;}
.side-drawer-header.custom-drawer-header{padding: 30px 46px 30px 0px !important;}
.side-drawer-full .ant-drawer-body{padding-top: 0 !important;}
.side-drawer-full .side-drawer-header{font-size: 22px;font-weight: 400;}
.buysell-toggle{border-radius: 20px; background: var(--bgDarkGrey); padding: 10px;display: inline-block;margin: 0 auto 50px}
.buysell-toggle .ant-radio-button-wrapper{width: auto;border-radius: 15px;border: none;background: transparent;color: var(--textYellow);text-transform: uppercase;font-weight: 600;padding: 20px 40px; line-height: 0;font-size: 14px;text-align: center;}
.buysell-toggle .ant-radio-button{border-radius: 15px;font-size: 12px;}
.buysell-toggle .ant-radio-button-checked{background: var(--bgYellow);border-left: none;}
.buysell-toggle .ant-radio-button-wrapper-checked{color: var(--textDark) !important; z-index: 0;}
.buysell-toggle .ant-radio-button-wrapper::before, .crypto-card .ant-card-body::before, .crypto-card .ant-card-body::after{content: none;}
.crypto-list-tabs .crypto-list{max-height: calc(100vh - 391px); overflow-y: auto; padding-right: 10px;}
.crypto-list-tabs .ant-input-search-button, .ant-input-search-middle .ant-input-search-button{display: none;}
.crypto-list-tabs .ant-input-group-addon .icon, .ant-input-search .ant-input-group-addon .icon{margin-right: 16px !important;}
.sellcrypto-container{max-height: calc(100vh - 285px);}
.crypto-container{max-height: calc(100vh - 270px);}
.suisfiat-container{max-height: calc(100vh - 165px);}
.suisfiat-height{max-height: calc(100vh - 158px);}
.addbook-height{max-height: calc(100vh - 84px);}
.verify-container{max-height: calc(100vh - 140px);}
.dep-withdraw{max-height: calc(100vh - 265px);}
.selectcrypto-container{max-height: calc(100vh - 101px);}
.auto-scroll::-webkit-scrollbar-thumb{background-color: var(--bgDark1);}
.ant-list-split .ant-list-item:last-child .crypto-list-tabs .ant-list-item, .wallet-list .ant-list-item{border-color: var(--borderLight);}
.wallet-list .ant-list-item:hover, .wallet-list .ant-list-item.select, .addCryptoList li.select{background-color: var(--bgBlu);}
.wallet-list li a, .crypto-list li a{display: inline-flex; align-items: center;flex-grow: 1; padding: 0px 5px;}
.wallet-list h4{margin-bottom: 0;}
.crypto-toggle .ant-radio-button-wrapper{padding: 22px;}
.crypto-list-tabs .ant-tabs-tab{font-size: 16px; margin: 0; padding-bottom: 0;}
.crypto-list-tabs .ant-tabs-tab-btn{padding: 3px 33px;color: var(--textYellow);}
.crypto-list-tabs .ant-tabs-tab-btn:focus{color: var(--textYellow);}
.crypto-list-tabs .ant-tabs-tab-active .ant-tabs-tab-btn{color: var(--textDark) !important; background: var(--bgYellow); border-radius: 30px;}
.crypto-list-tabs .ant-tabs-ink-bar{display: none;}
.pop-btn,.confirm-btn{font-size: 14px;}
.confirm-btn{height: 48px;}
.confirm-btn, .confirm-btn:hover, .confirm-btn:focus, .confirm-btn:active {background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2));  border-radius: 40px;text-transform: uppercase;font-weight: 700; transition: 0.5s ease; color: var(--textPureWhite) !important;border: 1px solid linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2)) !important;}
.pop-btn.ant-btn[disabled]{background-color: #3653E4 !important; border-color: #3653E4   !important;color: #FFFFFF; cursor: not-allowed !important; }
.btn-dropdown .ant-btn:hover{background-color: var(--bgDarkYellow); color: #000;}
.pop-cancel{background-color: transparent !important;color: var(--White) !important; border-color: transparent !important; border-radius: 40px;height: 57px;padding: 0;}
.pop-cancel:hover span{color: var(--textYellow) !important;}
.cancel-btn{height: 48px; text-transform: uppercase;font-weight: 700;text-align: center;}
.custon-btngroup{height: 48px; text-transform: uppercase; border:0;font-weight: 700;text-align: center; margin-top: 10px; width:50%}
.pick-btn{ border-radius:0 30px 30px 0; background-color: var(--bgYellow);}
.pick-btn:hover, .pick-btn:focus, .pick-btn:active{background-color:var(--bgDarkYellow);color: var(--textDark);  }
.cancel-btngroup:hover{background-color: var(--bgYellow);color: var(--textDark); }
.cancel-btngroup{ border-radius:30px 0 0 30px;background-color:var(--bgDarkYellow); color:var(--textWhite);}
.crypto-card.normal-card{background-color: var(--bgDark);box-shadow:none;}
.crypto-card.fiatcard{background-image: url(/static/media/fiatcard-bg.ab61283c.png);background-size: cover;border-radius: 1.8rem;}
.crypto-card-top{display: flex;justify-content: space-between;align-items: flex-start;}
.textc-white{color:var(--textPureWhite);}
.enter-val input{background-color: transparent;line-height: normal; font-size: 36px; color: var(--textWhite30);font-weight: var(--fw-300);}
.enter-val input::placeholder{color: var(--textWhite30);}
.billing-address{border: 1px solid var(--borderLight);border-radius: 30px; padding: 28px 40px;}

/* .crypto-address{padding: 8px;border: 1px solid var(--borderLight);border-radius: 12px;position: relative;} */
.recomnd-tag{background-color: var(--bgBlue);border-radius: 3px; padding: 1px 5px;}
/* Sell */
.default-radio{display: flex !important; justify-content: flex-start !important;margin-bottom: 16px;}
.default-radio .ant-radio-button-wrapper{background-color: transparent !important; color: var(--textWhite) !important;border-radius: 30px;min-width: 106px; line-height: 32px;border: none;font-size: 12px;font-weight: 500;text-align: center;}
.icon-delete{width:35px;height: 35px; border: 1px solid var(--borderGrey); border-radius: 50%;}
.icon-delete .anticon-delete{margin-left: 7px; margin-top:7px; font-size: 18px !important;}
/* Footer */

.selct-lang, .selct-lang:hover, .selct-lang:active, .selct-lang:focus{width: 42px; height: 40px;border-radius: 50%;background-color: var(--bgDarkGreen);border: none;color: var(--textWhite);box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);}
.theme-btn{box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25)}
.theme-dark, .theme-dark:hover, .theme-dark:focus{background-color: var(--bgWhite) !important;color: var(--textBlack);border-color: var(--bgWhite);}
/* Coins */
.coin{width: 43px; height: 43px;background: var(--cryptoIcons) no-repeat; display: inline-block; border-radius: 6px;}
.coin.DOT{background-position: -55px -60px}
.coin.BTC{background-position: -108px -1px;}
.coin.XLM{background-position: -162px -1px;}
.coin.usd-d{background-position: -216px -1px;}
.coin.oxbtc{background-position: -108px -1px;}
.coin.act{background-position: -270px -1px;}
.coin.EUR{background-position: -378px -1px;}
.coin.usd{background-position: -216px -1px;}
.coin.eur{background-position: -378px -1px;}
.coin.gbp{background-position: -432px -1px;}
.coin.md{width: 40px; height: 40px; box-shadow: none;}
.coin.lg{width: 52px; height: 52px;box-shadow: none;}
.coin-circle{width: 52px !important;height: 52px !important;}
.select .coin.lg.btc-white{background-position:-155px -769px;}
.coin.lg.BTC{background-position: 5px -767px;}
.coin.lg.USDC{background-position: -753px -768px;}
.coin.lg.DOT{background-position: -155px -769px;}
.coin.lg.ETH{background-position: -47px -767px;}
.coin.lg.USDT{background-position: -287px -768px;}
.coin.lg.ADA{background-position: -218px -768px;}
.coin.lg.BNB{background-position: -358px -768px;}
.coin.lg.XRP{background-position: -423px -768px;}
.coin.lg.BUSD{background-position: -563px -768px;}
.coin.lg.DOGE{background-position: -490px -768px;}
.coin.lg.GBP{background-position: -698px -769px;}
.coin.lg.USD{background-position: -98px -769px;}
.coin.lg.EUR{background-position: -630px -771px;}
.coin.lg.BCH{background-position: -890px -768px;}
.coin.lg.LINK{background-position: -1010px -768px;}
.coin.lg.UNI{background-position: 1px -838px;}
.coin.lg.COMP{background-position: -950px -769px;}
.coin.lg.XLM{background-position: -69px -838px;}
.coin.lg.LTC{background-position: -1076px -769px;}
.coin.lg.MKR{background-position: -1144px -769px;}
.coin.md.BTC{background-position: 5px -404px;}
.coin.md.USDC{background-position: -97px -404px;}
.coin.md.DOT{background-position: -135px -404px;}
.coin.md.ETH{background-position: -47px -404px;}
.coin.md.USDT{background-position: -252px -406px;}
.coin.md.ADA{background-position: -194px -404px;}
.coin.md.BNB{background-position: -314px -404px;}
.coin.md.XRP{background-position: -374px -406px;}
.coin.md.BUSD{background-position: -563px -404px;}
.coin.md.DOGE{background-position:-436px -404px;}
.coin.md.MKR{background-position:-931px -404px;}
.coin.md.LTC{background-position:-879px -404px;}
.coin.md.LINK{background-position:-829px -406px;}
.coin.md.COMP{background-position:-782px -406px;}
.coin.md.XLM{background-position:-1036px -406px;}
.coin.md.BCH{background-position:-735px -406px;}
.coin.md.USDC{background-position:-633px -406px;}
.coin.md.UNI{background-position:-984px -406px;}
.coin.md.usd{background-position: -79px -1112px}
.coin.md.btc-white{background-position: 1px -405px}
.coin.md.eth-white{background-position: -48px -405px}
.coin.md.usdt-white{background-position: -93px -405px}
.coin.md.eur-white{background-position: -14px -1112px;}
.coin.md.visa-white{background-position: -15px -1053px;width: 56px !important; height: 35px !important;}
.coin.md.visa-black{background-position: -150px -1053px;width: 56px !important; height: 35px !important;}
.coin.md.mastercard-white{background-position: -83px -1055px;width: 54px !important;height: 35px !important;}
.coin.credit-white{background-position: -488px -2px;box-shadow: none;}
.coin.deposit-white{background-position: -542px 0;box-shadow: none;}
.expiry-input{width: 200px;}
.expiry-input input{width: 50px; padding: 0 4px;height: 50px;}
.input-label{color: var(--textWhite30); font-size: 14px;font-weight: 600;margin-left: 12px;margin-bottom: 8px !important; display: block;}
.error-text{position: absolute; right: 0; top: 4px;color: var(--textError);}
.suisfiat-height .cust-input{margin-bottom: 0;}
/* swap */
.swap{border-radius:25px; display: flex; justify-content: space-between; align-items: center; padding: 16px 24px;min-height: 105px;}
.swapfrom-card{background-color: var(--bgSwapFrom);  border-bottom-right-radius: 0 !important; }
.swapreceive-card{background-color: var(--bgSwapTo);  border-top-right-radius: 0 !important;}
.card-input{padding:4px 0 !important; font-size: 24px; line-height: 24px; color:var(--textPureWhite);}
.icon.swapfrom-arrow{position: absolute; bottom: 0; right:0;width:43px;height: 26px; background-position: -54px -288px;}
.icon.swapto-arrow{position: absolute; top: 0; right:0;width:43px;height: 26px;margin-right: 12px; background-position: -3px -287px;}
.crypto-search.ant-input-search .ant-input-group input{background-color: var(--bgDark) !important;
height: 38px; color: var(--textWhite30);padding-left: 18px; border: 0; border-radius: 30px 0px 0 30px;}
.crypto-coin{width: 72px; margin-right: 16px;}
.crypto-search.ant-input-search .ant-input-group .ant-input-group-addon{background-color: var(--bgDark) !important;border-radius: 0 20px 20px 0;}
.crypto-search .anticon-search{font-size: 18px; color: var(--textWhite);margin-right: 12px !important;}
.confirm-icon{width: 124px; height: 124px; margin-bottom:36px;}
.loader{position: fixed;top: 0; right: 0; bottom: 0; left: 0;display: flex; align-items: center;font-size: 36px;color: var(--textWhite);
    /* background-color: var(--bgDarkGrey); */
    justify-content: center;}
.select-drpdwn{background-color: var(--bgGloom);margin-right: 30px;padding: 24px 0;border-radius: 10px;}
.select-drpdwn .ant-select-item{padding: 10px 24px;color: var(--textWhite);}
.select-drpdwn .ant-select-item:hover, .select-drpdwn .ant-select-item.ant-select-item-option-active, .select-drpdwn .ant-select-item.ant-select-item-option-selected, .select-drpdwn .ant-select-item.ant-select-item-option-active.ant-select-item-option-selected{background-color: var(--bgDarkGrey);}
.cust-input.ant-select{padding: 0 !important;}
.wallet-title{font-size: 16px; text-transform: uppercase; color: var(--textWhite30); margin-bottom: 0; font-weight: 600;}
.crypto-list .ant-list-item-meta, .wallet-list .ant-list-item-meta{display: flex;align-items: center;}
.crypto-list .ant-list-item, .wallet-list .ant-list-item{padding:5px 0 0 2px !important;margin-top:5px}
.recomoned-bg{background: var(--texthover);font-size: 12px;padding: 0 2px;line-height: 1.2;color: var(--textWhite);float: right;}
.text-width{width: 250px;}
.a-text{color: var(--bgBlue);}
.pop-drpdwn-toogle{background: var(--bgDark);width: 50px; height: 50px; border-radius: 20px;text-align: center;line-height: 50px;}
.alert-popup li{padding: 0;}
.alert-popup{background: #E7F2E1;border-radius: 4px;border-radius: 6px;}
.alert-popup .ant-card-body{padding: 12px;}
.alert-popup .alert-close svg{color: #000}
.alert-success{width: 20px;height: 20px;}
.close-icon{background-position: -143px -35px;}
.find-wallet .ant-select-selector{height: 50px !important;border: 1px solid var(--borderLight);border-radius: 30px !important;background: transparent !important;color: #fff;padding: 0 20px !important;}
.find-wallet .ant-select-selection-item{line-height: 3.5 !important;}
.find-wallet .ant-select-arrow{color: var(--bgWhite); margin-right: 5px;}
.ant-select-arrow{color: var(--textWhite);right:16px; top: 24px;}
.custom-switch{border-bottom: none;}
.agree .ant-form-item-explain-error{text-align: left;}
#memberWalletId .ant-select-selector, #currency .ant-select-selector{border-radius: 25px !important;height: 49px !important;}
#memberWalletId .ant-form-item-has-error,  #currency .ant-form-item-has-error{border: 1px solid var(--red) !important;}
.custom-formcard{width: 500px;margin: 0px auto;background: #515A64;border-radius: 50px;padding-left: 40px;padding-right: 40px;padding-top: 50px;padding-bottom: 50px;}
.ant-form-item-has-error .cust-input{background-color: var(--customBG) !important;border-color: var(--red) !important}
.ant-form-item-explain-error, .ant-form-item-explain-validating{text-align: left;}
.ant-input-suffix, .anticon-eye-invisible.ant-input-password-icon{color: var(--bgWhite);}
.coin.XRP {background-position: -2px -60px;}
.coin.USDT{background-position: -596px -1px;}
.coin.eth{background-position: -55px -1px;}
.coin.btc {background-position: -108px -1px;}
.coin.dat{background-position: -323px -1px;}
.coin.DAT{background-position: -323px -1px;}
.coin.ETH{background-position: -55px -1px;}
.coin.OXBTC{background-position: -596px -180px;}
.coin.BNB{background-position: -653px -1px;}
.coin.ADA{background-position: -702px -1px;}
.coin.USDC{background-position: -649px -57px;}
.coin.DOGE{background-position: -756px -1px;}
.coin.BUSD{background-position: -378px -60px;}
.coin.UNI{background-position: -702px -60px;}
.coin.LTC{background-position: -108px -60px;}
.coin.SOL{background-position: -596px -60px;}
.coin.LINK{background-position:-544px -60px;}
.coin.MATIC{background-position:-488px -60px;}
.coin.THETA{background-position:-435px -60px;}
.coin.DAI{background-position:-2px -120px;}
.coin.VET{background-position:-323px -60px;}
.coin.FIL{background-position:-216px -60px;}
.coin.ALGO{background-position:-435px -120px;}
.coin.CDAI{background-position:-2px -180px;}
.coin.LUNA{background-position:-55px -180px;}
.coin.AAVE{background-position:-216px -120px;}
.coin.SHIB{background-position:-164px -120px;}
.coin.TRX{background-position:-163px -60px;}
.coin.XMR{background-position:-56px -120px;}
.coin.OKB{background-position:-269px -120px;}
.coin.EOS{background-position:-109px -120px;}
.coin.CETH{background-position:-434px -180px;}
.coin.CAKE{background-position:-598px -120px;}
.coin.LEO{background-position:-164px -183px;}
.coin.ATOM{background-position:-488px -120px;}
.coin.MKR{background-position:-702px -116px}
.coin.BSV{background-position:-544px -120px;}
.coin.COMP{background-position:-164px -303px;}
.coin.NEO{background-position:-323px -120px;}
.coin.AVAX{background-position:-108px -183px;}
.coin.MIOTA{background-position:-756px -60px;}
.coin.HBAR{background-position:-378px -180px;}
.coin.KSM{background-position:-378px -120px;}
.coin.HT{background-position:-216px -183px;}
.coin.DASH{background-position:-323px -303px;}
.coin.BTT{background-position:-268px -183px;}
.coin.TUSD{background-position:-863px -303px;}
.coin.NEXO{background-position:-811px -120px;}
.coin.HOT{background-position:-863px -240px;}
.coin.PAX{background-position:-810px -240px;}
.coin.RUNE{background-position:-756px -120px;}
.coin.ZIL{background-position:-920px -3px;}
.coin.ARRR{background-position:-543px -180px;}
.coin.BAL{background-position:-325px -240px;}
.coin.GAS{background-position:-323px -120px;}
.coin.GUSD{background-position:-864px -3px;}
.coin.BAND{background-position:-378px -240px;}
.coin.PAc{background-position:-543px -180px;}
.coin.DOCK{background-position:-378px -303px;}
.coin.GRIN{background-position:-864px -120px;}
.coin.GTO{background-position:-651px -180px;}
.coin.SAI{background-position:-268px -305px;}
.coin.STORM{background-position:-542px -305px;}
.coin.AEON{background-position:-757px -180px;}
.coin.GSC{background-position:-864px -60px;}
.coin.COLX{background-position:-108px -303px;}
.coin.BTDX{background-position:-757px -240px;}
.coin.DROP{background-position:-434px -303px;}
.coin.BSD{background-position:-598px -240px;}
.coin.ARG{background-position:-53px -240px;}
.coin.STPL{background-position:-216px -305px;}
.coin.DTH{background-position:-433px -305px;}
.coin.TBX{background-position:-812px -305px;}
.coin.SPARTA{background-position:-2px -240px;}
.coin.POW{background-position:-811px -180px;}
.coin.CMM{background-position:-57px -305px;}
.coin.ENTRP{background-position:-599px -305px;}
.coin.AMP{background-position:-268px -60px;}
.coin.TFUEL{background-position:-652px -120px;}
.coin.OXY{background-position:-487px -240px;}
.coin.BCH{background-position:-1px -3px;}

.icon.lg.usd-default{background-position:-1px -70px;width:32px !important}
.icon.lg.swap-arrow{background-position:-8px -186px;}
.to-receive{text-transform: uppercase;font-size: 14px;text-align: center;color: var(--textWhite30);font-weight: 500;margin-bottom: 16px !important;margin-top: 0px;}
.swap-updown{position: absolute;right: 0;bottom: -17px;width: 40px;height: 40px; z-index: 1;margin-right: 20px; border-radius: 15px;background-color: var(--bgDark);text-align: center;line-height: 38px}
.swap-updown:hover{background: var(--bgDark);}
.default-circle{border-radius: 50%;padding:12px 4px;}
.swap-from{ background-color: var(--bgSwapTo);}
.swap-to{ background-color: var(--bgSwapFrom);}
.side-drawer-full .ant-table-cell{border-color: var(--borderLight);color: var(--textWhite);vertical-align: top;}
.bg-color{padding: 8px 6px;background: #ffffff1c;border-radius: 6px;}

.ant-table-container table > thead > tr:first-child th:last-child{border-top-right-radius:0}
.widthdraw-pop .ant-modal-close-x{position: absolute;width: 22px;height: 22px;line-height: 22px;top: 16px;right: 16px;}
.markets-panel .ant-table-tbody > tr.ant-table-placeholder:hover > td{background: var(--bgGrey) !important;}
.markets-panel .ant-table-tbody > tr.ant-table-placeholder > td{border-bottom: none !important;}
.markets-popup .custom-table .ant-table-body{padding: 0 0 0 24px !important;}
.user-labels{padding:12px; font-size: 16px; font-weight: 600; color: var(--textWhite); margin-bottom: 0; margin-left:8px}
.user-list{list-style: none;}
.back-up-tab{align-items: center;}
.user-labels.active, .user-labels:hover{background: var(--bgDarkYellow);font-weight: 700; border-radius: 30px; padding:12px; color: var(--textDark);}
.cust-select {min-width: 250px;}
.cust-select .ant-select-selection-placeholder{color:#ED4B9E !important;}
.cust-placeholder-select.cust-select .ant-select-selection-placeholder{color:var(--white) !important;}
.border-bottom{border-bottom: 1px solid var(--borderGrey);}
.backup-admb-0{margin-bottom: 0 !important;}
.custom-label.audit-label{color: var(--subText) !important;font-weight: 400 !important;font-size: 14px !important;}
.basicinfo.crypto-text-mb{margin-bottom: 16px !important;display: inline-block;}
.border-bottom{border-bottom: 1px solid var(--borderGrey);}
.addressbook_tabs .ant-tabs-nav{width:auto !important}
.addressbook_tabs .ant-tabs-tab.ant-tabs-tab-active,.addressbook_tabs .ant-tabs-tab:hover {background: transparent !important;font-weight: 600;  padding: 12px 15px 12px 12px !important; color: var(--textDark);}
.wdr-summary{display: flex; justify-content: space-around; align-items: center;}
.selectcustom-input .ant-select:not(.ant-select-customize-input) .ant-select-selector{background-color: transparent;border: transparent;cursor: pointer;}
.customicon{position: absolute; left: 0px;top: 4px; margin-left: 67px;}
.defaulticon{position: absolute; left: 0px;top: 3px; margin-left: 110px;}
.ant-modal-content{border-radius: 24px;}
.ant-modal-header{border-radius: 24px 24px 0 0;padding: 16px 24px;border-color: var(--borderGrey);}
/* .ant-modal-title{font-size: 24px; line-height: 34px;color: var(--textWhite30);text-transform:capitalize;} */
.ant-modal-body p{font-size: 16px;color: var(--textWhite50);font-weight: 400;}
.ant-modal-footer{padding:16px 24px;border-color: transparent;}
.cust-adon .ant-input-group, .cust-adon .ant-input-group .ant-input:focus, .cust-adon .ant-input, .cust-adon .ant-input-group-addon{border: none !important;box-shadow: none;padding: 0;background: transparent;}
.cust-adon .ant-input{line-height: 45px !important;height: 47px !important;background-color: transparent !important; padding:0 1px !important;margin-bottom:0}
.ant-modal-confirm-btns button:first-child{background-color: transparent;border: none;color: var(--textWhite30);}
.ant-modal-confirm-btns button:nth-child(2){background-color: var(--bgYellow) !important;border: none;color: var(--textDark);border-radius: 16px;padding: 4px 30px}
.ant-modal-confirm-btns button:first-child:hover{color: var(--textYellow);}
.ant-picker-input > input, .ant-form-item-label > label{color: var(--textWhite);}
/* .search-bg{border: 1px solid rgba(0,0,0,.08);color: #313c46 !important;background-color: var(--bgDarkGrey) !important;padding: 15px 10px;} */
.bankview-right{padding: 34px 34px 0 !important;background-color: var(--bgDarkGrey);border-radius: 30px;}
.bankview-right .kpi-val{padding-bottom: 34px;}
.kpi-List .icon{margin: 6px 10px 0 0;}
.kpi-List .icon + div{flex: 1 1;}
.kpi-item .kpi-val{margin-left: 0;}
.preview-file{text-align: center;}
.preview-file img{max-width: 100%;}
.react-pdf__Page__canvas{margin: auto;}
.ant-select-focused .ant-select-selector{box-shadow:none !important}
.selectcustom-input .anticon-search{display: none;}
.custom-add-select{border-bottom-right-radius: 0;border-top-right-radius: 0;}
.ant-select-multiple .ant-select-selection-item{background-color: transparent;height: 38px !important;line-height: 36px !important;border-radius: 24px;padding: 0 16px}
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {display: inline-block;margin-right: 4px;color: var(--textWhite30) !important;font-size: 14px;font-family: SimSun,sans-serif;line-height: 1; content: "*";opacity: 0.6;}
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{content: none;}
.custom-label .ant-form-item-label > label::after{color: transparent !important;}
.custom-label .ant-form-item-label,.custom-label.ant-row{display: block !important;}
.cust-adon .ant-input.ant-input-disabled{cursor:default;}
.staus-lbl{display: inline-block;color: #ffffff;background-color: var(--bgGreen);font-size: 12px;padding: 2px 8px;border-radius: 4px;font-weight: 500;}
.approved{background-color: var(--bgGreen);}
.rejected{background-color: #ff0000;}
.submitted{background-color: #0038FF;}
.wmy-graph .ant-radio-button-wrapper{border: none;background: none;color: var(--textYellow);font-weight: 600;}
.wmy-graph .ant-radio-button-checked{background-color: var(--bgYellow);border-color: var(--bgYellow) !important;border-radius: 16px;}
.wmy-graph .ant-radio-button-wrapper:not(:first-child)::before{content: none;}
.wmy-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{background: none;}
.wmy-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){background: none;color: var(--textDark) !important;}
.ant-message{  bottom: 15px !important; top: auto;}

.docreq-slider .slick-dots li button{width: 30px;height: 30px;color: var(--textWhite30);font-size: 16px;border-radius: 15px;}
.docreq-slider .slick-dots li{width: 30px;}
.coin-select{position: absolute; right:13px; bottom: 13px;}
.ant-modal-close-x{cursor: default;}
.tinycontent{word-break: break-all;max-height: 100px;height: 100px;overflow:hidden;}
.carousel-card:hover .tinycontent{overflow-y: auto;}
.documentmodal-width .preview-pdf-page{border: 1px solid var(--bgDark);margin-bottom: 10px;}
.accordian textarea.ant-input:placeholder-shown{line-height: 48px;}
.accordian textarea.ant-input{min-height: 48px !important;padding-top: 0px !important;}
.coin-bal{display: flex;justify-content: space-between;align-items: center;}
.coin-bal ul li{display: inline-block;list-style: none;font-size: 10px;text-align: center;color: var(--textWhite30);font-weight: 500;margin-left:16px;}
.otp-verify{position: relative;margin-left: 0 !important;}
.otp-verify button{color: var(--textYellow) !important;font-weight: 500;padding: 0 6px 0 0;}
.otp-verify .ant-form-item-label{width: auto;position: absolute;right: 4px;top: 10px;z-index: 1;}
.otp-verify .ant-form-item-label > label::after{content: none !important;}
.min-max-btn label{width: 100%;}
.side-drawer .coin-info:last-child{margin-bottom: 60px;}

/* --Payments --*/

.pay-grid thead th:last-child{width: 500px;}
.ant-table-placeholder:hover{background: transparent;}

/* .more-popover .lbl{font-size: 12px; color: var(--textWhite50);font-weight: 100;} */
/* .more-popover .val{font-size: 14px; color: var(--textWhite30);display: block;margin-bottom: 16px; line-height: normal;} */

.payment-docs .accordian .ant-collapse-content-box, .payment-docs .accordian .ant-collapse-item{background-color: var(--bgGrey);border-radius: 24px;}
.payment-docs .accordian .ant-collapse-item:last-child > .ant-collapse-content{background-color: var(--bgGrey);border-radius: 30px;}
.file-label{border: 1px solid var(--borderLight);border-radius: 6px;padding: 2px 8px;color: var(--bgDarkYellow);background-color: var(--bgDarkGrey);width:100px}
.delete-btn{color: var(--textWhite30);background-color: transparent !important;font-size: 14px;border: none;}
.delete-btn:hover{background-color: transparent !important; color:white;border:none;}
.delete-btn:focus{border:none !important;}
.ant-modal-footer .delete-btn{padding: 0 30px;}
.pay-list{display: flex;justify-content: space-between;align-items: center;}
/*--Zoho--*/
.zsiq_flt_rel{width: 50px !important; height: 50px !important;bottom: 8px;right: -10px;}
.zsiq_theme1 .siqico-chat:before{font-size: 20px !important; line-height: 50px !important;}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-radio-disabled + span{color: var(--textWhite30);}
/** masspayments end**/
.batch-actions{text-align: right;margin-bottom: 16px;}
/* .batch-actions span{margin-left: 16px;} */
.makepayment-section{line-height: 3 !important;}
.masspay-popup{width: 620px !important;}
.ant-modal-close-x .ant-modal-close-icon svg{display: none;}
.uploadxcel-btn{margin-top: 50px !important;}
.drawer-content{display: flex !important; justify-content: center !important; align-items: center !important;height: 500px !important;}
.batch-list{font-size: 20px; font-weight: 500; color: white !important;}
/** masspayments end**/
/* steps start */
.ant-steps-item-title:has(> .last-title){width: 100%;}
.cust-steps-design .ant-steps-item-finish .ant-steps-item-icon, .cust-steps-design .ant-steps-item-active.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon, .cust-steps-design  .ant-steps-item-wait .ant-steps-item-icon{background-color: var(--secondary) !important;border-color: var(--secondary) !important;}
.cust-steps-design .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{color:var(--textWhite) ;}
.cust-steps-design .ant-steps-icon .anticon-check.ant-steps-finish-icon svg{color: var(--viewAll) !important;background: none !important;width: 18px !important;height: 18px !important;}
.cust-steps-design .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after{background-color: #fff !important;}
.cust-steps-design .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{color: var(--viewAll) !important;}
.cust-steps-design .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon{top: -2.5px;}
/* steps end */
/*-- Cases--*/

.icon.md.Type{background-position:-577px -102px;}
.icon.md.State{background-position:-408px -101px;}
.icon.md.Email{background-position:-127px -71px;}
.icon.md.Name{background-position:-212px -70px;}
.icon.md.Currency{background-position:-428px -70px;}
.icon.md.Bank.Name{background-position:-457px -71px;}
.icon.md.Date{background-position:-481px -36px;}
.icon.md.Amount{background-position:-185px -102px;}
.icon.md.description, .icon.md.Alert.Description{background-position:-156px -70px;}
.icon.md.Alert.Number{background-position:-241px -101px;}
.icon.md.Alert.Type{background-position:-128px -134px;}
.icon.md.Alert.Title{background-position:-156px -101px;}
.icon.md.Transaction.Id{background-position:-542px -70px;}
.icon.md.Features{background-position:-128px -102px;}
.icon.md.EURS{background-position: -269px -102px;}
.icon.md.USDS{background-position: -213px -102px;}
.visible-flex{display: flex; justify-content: space-between;align-items: center;}
.addressbook-grid .cust-list>div{position: unset !important;}
.transaction-grid td .gridLink,.cases-grid-style td .gridLink,.address-clear td .gridLink{padding:5px 0 0 0 !important; line-height: normal;}
.reffername{
    text-align: right;
    width: 180px;
    top: 0;
    right: 17px;
    position: absolute;
    color: #ffffff99;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;}
.pix-qr.cust-qr-img{border-radius: 6px;margin-top: 36px;}
@media (min-width: 320px){
    .tlv-header{padding: 8px 8px !important;}
    .header-dropdown{margin-right: 24px;}
    .megamenu-label{margin-top: 24px;}
    .header-right{display: block;}
    .mobile-header-right{display: block;float: right;}
    .sidebar-push{margin: 62px 0 0;}
    .buysell-toggle{ margin: 0 auto 0px !important;}
    .crypto-percent{font-size: 26px;color: #FFFFFF;}
    .ant-typography.crypto-percent{margin-left: -8px;}
    .crypto-percent .percent{font-size: 16px;vertical-align: middle;margin-left: 4px;}
    .drawer-title{font-size: 28px !important;font-weight: 200 !important;color: var(--textWhite30) !important; margin: 16px 0 4px !important;}
    .buysell-toggle + .fs-36{font-size: 28px !important;}
    .footer-links a{margin-right: 30px;}

    .tlv-header .sideropen {background: var(--bgGloom) !important;}
    .tlv-header .mobile-logo {padding-left: 0px;}
    .custom-radio-btn.buysell-toggle.crypto-toggle{text-align: left !important;}
    .ant-drawer-body{padding: 16px;}
    .create-account .ant-form-item-control-input-content{text-align: center;}
}
@media (min-width : 414px) {
    .footer-links a{margin-right: 16px;}
    .enter-val{margin-left: 4px;margin-right:4px}
}
@media (min-width: 576px){
    .fiat-total{display: flex;justify-content: space-between;text-align: center;}
    .crypto-percent{font-size: 26px;line-height: normal;}
    .crypto-percent .percent{font-size: 20px;margin-left: 6px;}
}
@media (max-width: 767px){
    .search-bg.history-crypto-cards .ant-list-items, .search-bg.fait-card-transaction-h .ant-list-items{display: block;}
    .search-bg.history-crypto-cards li.ant-list-item.cytpo-list-style, .search-bg.fait-card-transaction-h li.ant-list-item{width: 100%;}
    .box.mb-flex{display: flex !important;}
    .mb-togglespace .ant-collapse-item.ant-collapse-item-active .downangle, .mb-togglespace .ant-collapse-header .icon.downangle{width: 32px !important;}
    .mb-togglespace .ant-collapse-header{display: flex !important;}
    .custom-label .ant-form-item-label{padding-bottom: 0 !important;}
    .ant-dropdown-placement-topRight > .ant-dropdown-arrow{top: -5px !important;}
    .ant-dropdown-show-arrow.ant-dropdown-placement-topRight{top: 71px !important;}
    .crypto-card .ant-card-body{height: 110px;padding: 16px 24px;}
    .mid-menu{margin: 0 -16px;padding: 0 16px; background-color: var(--bgWhite);}
    .header-logo{padding-left: 0  !important;}
    .mobile-none{display: none}
    .mobile-megalinks, .notify{display: block;}
    .megamenu-label{font-size: 30px !important;}
    .megamenu-title{padding-right: 34px !important;}
    .mb-d-none{display: none;}
    .sidebar-push{margin: 62px 0 0 !important;}
    .copyright-block{display: flex; justify-content: space-between; align-items: center;padding-top: 12px;}
    .swap-subtitlte{width:235px}
    .enter-val{margin-left: 4px;margin-right: 4px;}
    .enter-val input{font-size: 22px !important;}
    .enter-val-container .text-yellow{font-size: 22px !important;}
    .coin.lg{transform: scale(0.8);}
    .card-icons{transform: scale(0.65);margin-right: -24px;}
    .portfolio-list .mobile-list .ant-list-item{display: flex;}
    .notify .ant-alert-icon{float: left;}
    .notify .ant-alert-content{margin-left: 40px;}
    .market-coinname{display: inline-block;max-width: 68px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
    .custom-theme-btn{display: block;}
    .display-flex{display: block; text-align: center;}
    .theme-switch{width: auto;}
    .address-clear{clear: both; margin-top: 12px;}
    .customaudit-select .selectcustom-input{margin-left:0 !important}
    .customaudit-select .custom-btn{width: 100%;}
    .k-pager-info.k-label{display: none;}
    .mobile-headerview li{margin: 0 10px}
    .mobile-headerview li:nth-of-type(6), .mobile-headerview li:nth-of-type(7), .ant-menu-submenu-popup li:nth-of-type(2), .ant-menu-submenu-popup li:nth-of-type(3){display: none;}
    .suisfiat-height{max-height: calc(100vh - -110px);}
    .tlv-header{height: 75px !important;}
    .visible-mobile .side-drawer .side-drawer-header.change_password{align-items: self-start !important;}
    .ribbon-item{border-right: 0 !important;}
    .transaction_resp .pop-btn{width: 100%; margin-top: 20px !important;margin-bottom: 16px;}
    .transaction_resp{ padding:0 15px}
    .transaction_resp .input-label{margin-left: 0 !important;}
    .search-bg{margin-top: 16px; border-radius: 10px;}
    .customaudit-select{margin-bottom: 16px;}
    .footer-links{text-align: center;}
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more{display: none;}
    .tlv-logo{height:46px;padding-left:0;}
    .mobile-content{width:274px !important;}
    .ant-form-item-control-input-content .pop-btn{margin-top: 20px;}
    .dep-withdraw,.sellcrypto-container {
        max-height: calc(100vh - 270px);
    }
    .code-sent-tool-tip{display: flex;}
    .k-pager-sizes.k-label{margin-left: 5px;}
    /* .k-pager-wrap{margin-left: -11px;} */
    .k-dropdown-wrap{margin-right: -8px;}
    .table col{width: 120px;}
    .date-mobile{margin-left: 12px !important;}
    .transaction-grid table col{width: 250px !important;}
    .transaction-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager{overflow: auto;}
    .k-pager-wrap .k-pager-numbers.k-reset li:not(:first-child){display: none;}
    .transaction-type{margin-top: 8px !important;}
    /* .batchpayment-summary{display: block;} */
    .ant-tooltip.ant-tooltip-placement-top{visibility: hidden;}
    .btn-mobile button{padding: 0 30px;}
    .k-pager-wrap.k-pager{font-size: 10px;}
    .sumSub-confirm{width:350px;}
}
@media (max-width: 1024px){
    .custom-gridresponsive .ant-drawer-body{word-wrap: unset !important;}
    .custom-gridresponsive .k-grid-header col:nth-of-type(4){
        width: 20% !important
    }
    .custom-gridresponsive .k-grid-table col:nth-of-type(4){
        width: 20% !important
    }
    .custom-gridresponsive .k-pager-wrap .k-link {font: small-caption;font-size: 10px;}
    .custom-gridresponsive .k-pager-nav{min-width: calc(1.4285714286em + 3px);height: calc(1.4285714286em + 3px) }
	.tlv-header .list-item.ant-menu-item .ant-menu-title-content{padding: 6px 0px;}
    .mobile-viewbtns .ant-form-item-control-input-content .pop-btn{margin-bottom: 0 !important;margin-top: 0 !important;}
}
@media (max-width: 991px){
    .user-profile{width:42px;height: 42px;}
    .user-list{padding-left: 0;display: block !important;}
    .mobile-list .ant-list-item{display: block; width: 100%;}
    .mobile-list .custom-btn, .crypto_btns .custom-btn{min-width: calc(50% - 22px);}
    .mobile-list .custom-btn:first-child{margin-left: 0 !important;}
    .mobile-list .crypto-btns{display: flex;padding-top: 16px;}
    .ant-modal-wrap.megamenu-backdrop{left:12px !important}
    .sidebar-push {margin: 100px 0 0;}
    .mobile-header-right {display: block;flex-grow: 1;flex-shrink: 1;text-align: right;}
    .mobile-headerview{display: none;}
    .mobile-headerview{margin-top: 15px;}
    .mobile-headerview li{margin: 0 34px}
    .mobile-headerview li:nth-of-type(6), .mobile-headerview li:nth-of-type(7){display: none;}
    .crypto_btns{display: flex; width: 100%; margin-top: 16px;}
    .hidden-mobile{display: none;}
    .visible-mobile .ant-tabs-top > .ant-tabs-nav::before{border-bottom: 0 !important;}
    .visible-mobile .user-list .ant-tabs-nav{width: auto;}
    .bill-payment{display: block;}
    .wallet-mb{display: inline-block !important;}
    .mb-list .ant-list-item{display: flex !important;}
    .tlv-header .ant-layout-sider{min-height: 100vh;}
   .portfolio-data{display: block}
   .portfolio-list h1{padding: 0 0 6px;}
   .portfolio-list ul li{display: block !important;}
   .portfolio-title{align-items: center;}
   .ribbon-item{border-right: 0 !important;}
   .responsive_table{overflow-x: auto;}
   .responsive_table .pay-grid{width: 100%;}
   .header-logo .visible-mobile::after{content: '';position: absolute;width: 1px;height: 27px;right: 0;top: -2px !important;background-color: var(--bgWhiteSpin);}
   .mobile-logo {padding-left: 20px;}
   .amt-field .cust-input{width:150px;}
   .sidebar-push .ant-alert{align-items: baseline;}
   .custom-label.ant-form-item{margin-bottom: 16px;}
   .mb-flex-none{display: block !important;}
   /* .mb-view tfoot{background: #515a64 !important;} */
   .cust-cypto-view{width: auto !important;}
   .custom-alert-width{width: auto !important;}
}
.icon.pdf{background-position: -287px -241px;    border-radius: 50%;}
.pdf-name{margin-bottom: 0;margin-left: 24px;}
.flex-1{flex: 1 1;}
.account-summary{color: var(--coinPrice);}
main.ant-layout-content.sidebar-push {    min-height: calc(100vh - 220px);}
.currency-logo{border-radius: 50%;}
.currency-section{text-align: end;flex: 1 1;}
.card-status{position: absolute;}
@media (min-width: 768px){
    .tlv-header{height: 96px !important;border-radius: 0 !important;}
    .tlv-header{height: 100px;align-items: center; justify-content: space-between;padding: 12px 16px 12px !important;border-radius: 0 0 30px 30px;}
    .markets-panel{margin-right: 16px;}
    .megamenu-label{margin-top: 0px;font-size: 33px !important;}
    .side-drawer .ant-drawer-body{padding: 0 20px 20px 20px;}
    .portfolio-count{display: inline-flex;height: 159px;}
    .megamenu-title .tlv-logo{padding-left: 34px !important;}
    .megamenu .ant-modal-header{border: none; padding: 24px 32px;}
    .megamenu .ant-modal-body{background-color: var(--bgGloom) !important}
    .megamenu .ant-modal-close{top: 13px; right: 30px;}
    .megamenu-divider{height: 2px;background-color: var(--bgWhite); margin: 30px 0;}
    .megamenu-link{padding-left: 15px;padding-right: 15px; margin: 0 auto;}
    .mega-menu{padding-right: 30px; padding-left: 30px;}
    .wrapper{display: flex;flex-direction: column;justify-content: space-between;min-height: 230px;}
    .item-wrapper{flex-grow:0; flex-shrink:0;flex-basis:calc(100% - 10px); }
    .copyright{display: none !important;}
    .swap-subtitlte{width:250px}
    .pay-list{display: flex;justify-content: space-between;align-items: center;}
    .verify-options{padding: 24px 70px;}
    .side-drawer .ant-drawer-header{padding:20px !important}
    .markets-panel .custom-table .ant-table-body{height: 875px !important;padding: 0 4px 0 0;}
    .docfile{flex: 0 0 240px;width: auto;}
    .footer-links a{margin-right: 50px;text-align: left;}
    /* .theme-switch{width: 220px;} */
    .themeSwitchOff,.themeSwitchOn{margin-left: 22px !important;}
    .display-flex {display:flex; align-items: center;justify-content: space-between;}
    .address-icons{list-style: none; padding-left: 0; margin-bottom: 0; display: flex;}
    .login-user .header-logo{flex-grow: 1;}
    .dash-mobile-list ul{max-height: 787px;overflow: auto;}
    .create-account .ant-form-item-control-input-content{text-align: right;}
    .batchpayment-summary{display: flex;}
    .btn-mobile button{padding: 0 36px;}
    .crypto-card.select.space-hide{height: 10px !important;}
}
@media (min-width: 992px){
    .summarybal.wallet-copy-width{width: 250px;}
    .search-bg.history-crypto-cards li.ant-list-item.cytpo-list-style, .search-bg.fait-card-transaction-h li.ant-list-item {width: 272px;margin: 0;}
.search-bg.history-crypto-cards  .ant-list-items, .search-bg.fait-card-transaction-h .ant-list-items{display: flex;flex-wrap: wrap;grid-gap: 8px;gap: 8px;}
    .tlv-header{display: flex}
    .user-profile{width:44px;height: 44px;}
    .right-panel{padding: 0 60px;}
    .tlv-header .main-menu li{margin: 0 4px;}
    .sidebar-push{margin: 100px 0 0;}
    .header-right, .portfolio-list .ant-list-split .ant-list-item{display: block;flex-grow: 1;flex-shrink: 1;text-align: right;}
    .header-right.mobile-header-right, .mobile-megalinks{display: none;}
    .crypto_btns{margin-left: 16px !important;}
    .crypto_btns .custom-btn.prime{margin-left: 36px  !important;}
    .portfolio-list .ant-list-split ul li .crypto_btns,
    .portfolio-list .ant-list-split ul li .custom-btn.prime{margin-left: 0 !important}
    .portfolio-list .ant-list-split ul li .crypto_btns{margin-top: 10px;}
    .portfolio-list .ant-list-split ul li .custom-btn{min-width: calc(50% - 8px);}
    .visible-mobile{display: none;}
    .custom-toggle{margin-left: 12px !important;}
    .mobile-headerview{display: block;}
    .visible-mobile,.sideropen{display: none;}
    .portfolio-list h1{padding: 16px 0 16px;}
    .portfolio-data{display: flex;align-items: baseline;}
    .portfolio-title{align-items: baseline;}
    .bill-payment{display: flex; align-items: center; justify-content: space-between;}
    .header-logo li:first-child::after{content: '';position: absolute;width: 1px;height: 27px;right: 0;top: 10px;background-color: var(--bgWhiteSpin);}
    .common-withdraow.withdraw-crypto{margin-right: 0px;}
    .common-withdraow.withdraw-crypto input[type=text]{font-size: 22px !important;}
    .transctns-grid .search-btn{margin-left: 12px !important;}
    .bill-grid .k-grid-header table{width: 1255px !important;}
    .bill-grid .k-grid-content table{width: 1254px !important;}
    .cust-input.cust-disable{width: 280px;}
}
@media (min-width: 1200px){
    .custom-font{font-size:28px !important;}
    .mega-menu{max-width: 1140px;  padding: 29px 0px 24px 60px;}
    .side-drawer .ant-drawer-body{padding: 0 20px 20px 20px;}
    .portfolio-list .mobile-list{height: 1087px;max-height: 1087px; overflow: auto;}
    .markets-panel .custom-table .ant-table-body{height: 900px !important;}
    .trade-legends{display: flex; justify-content: space-between;}
    .portfolio-list .ant-list-split .ant-list-item a + div{min-width: 170px;}
    .portfolio-list .ant-list-split .ant-list-item{display: flex;}
    .portfolio-list .ant-list-split ul li .custom-btn{min-width: 90px;}
    .coin-details.right{min-height: 705px;}
    .side-drawer.custom-drawer-width .ant-drawer-content-wrapper{width:865px !important;}
    .btn-container{padding-right: 20px;}

}
@media (min-width: 1400px){
    .search-bg.history-crypto-cards li.ant-list-item.cytpo-list-style, .search-bg.fait-card-transaction-h li.ant-list-item{width: 296px;}
    .register-blockwid{
        width: 900px !important;
        margin: 0 auto !important;}
    .custom-font{font-size:30px !important;}
    .mega-menu{max-width: 1320px;}
    .side-drawer .side-drawer-header{padding: 15px 0px 15px 0px;}
    .crypto-list-tabs .crypto-list{max-height: calc(100vh - 350px); overflow-y: auto; padding-right: 10px;}
    .sellcrypto-container{max-height: calc(100vh - 230px);}
    .suisfiat-container, .suisfiat-height{max-height: calc(100vh - 195px);}
    .dep-withdraw{max-height: calc(100vh - 260px);}
    .crypto-card .ant-card-body{padding: 16px 24px;height: 110px;}
    .crypto-percent{font-size: 26px;line-height: normal;}
    .coin{transform: scale(1);}
    .drawer-title{font-size: 34px !important;}
    .crypto-search.ant-input-search .ant-input-group input{height: 58px !important;}
    .cancel-btn{height: 57px !important;}
    .custon-btngroup{height: 57px !important;}
    .to-receive{margin-top: 0px;}
    .crypto-list .ant-list-item, .wallet-list .ant-list-item{padding:10px 8px 8px !important}
    .crypto-search.ant-input-search .ant-input-group .ant-input-group-addon{border-radius: 0 30px 30px 0;}
    .pay-list{padding: 16px 0;}
    .crypto-container {max-height: calc(100vh - 325px);}
    .custom-crypto-address .fs-12{font-size: 14px !important;}
    .addbook-height{max-height: calc(100vh - 112px);}
    .pop-btn-46{height:46px;}
    .minmax.custom-minmax{position: relative;right:-332px !important;top:-11px;width:100px;}
    .side-drawer.custom-drawer-width .ant-drawer-content-wrapper{width: 1094px !important;}
    .lg-fontsize{font-size: 14px !important;}
    .cases-grid-view{text-align: center;}
    .cases-grid-view .k-grid .k-grid-header table{width: 1254px !important;}
    .cases-grid-view .k-grid-container .k-grid-table{width: 1254px !important;}
    .transaction-grid .k-grid-header .k-grid-header-wrap table{width: 1874px !important;}
    .transaction-grid .k-grid-container .k-grid-content table{width: 1874px !important;}
}

@media (min-width: 1500){
    .main-container{margin: 0px auto;max-width:min(1460px, 100% - 40px)}
}
.currencyContains {display: inline;color: var(--textWhite30);}
.spinLoader {margin: 20px 0;margin-bottom: 20px;padding: 30px 50px;text-align: center;background: var(--bgWhiteSpin);border-radius: 4px;}
.inputSpinner{display: block;text-align: left;height: 30px}
.notkyc{top: 0; right: 0; bottom: 0; left: 0;display: flex; align-items: center;font-size: 36px;color: var(--textWhite);background-color: var(--bgDarkGrey);justify-content: center;margin-top: 150px;text-align: center;}
.crypto-address.custom-crypto-address .ant-typography-expand, .crypto-address .ant-typography-edit, .crypto-address .ant-typography-copy{font-size:18px;margin-left:0px;}
.share-adrs{display: flex;justify-content: center;padding: 0;border-radius: 10px;background-color: var(--bgDarkGrey);}
.share-adrs li{padding: 16px 20px 10px;}
.share-adrs li:hover{background-color: var(--bgGrey);}
.share-btn{background-color: transparent !important;color: var(--textWhite30) !important;border-color: var(--borderLight);}
.notice-nodata{display: flex; align-items: center;justify-content: center; height: 192px;}
.transaction-tabs .ant-tabs-nav::before{border-bottom: none;}
.transaction-tabs .ant-tabs-nav{margin-bottom: 15px;}
.transaction-tabs .ant-drawer-body{padding: 10px 24px;}
.transaction-tabs .ant-collapse-header{background: var(--bgDarkGrey) !important;color: var(--textWhite) !important;}
.transaction-tabs .ant-collapse{background: transparent;border: 1px solid var(--borderGrey);}
.transaction-tabs .ant-collapse-content{background: transparent;border: none;}
.transaction-tabs .ant-collapse > .ant-collapse-item{border-bottom: 1px solid var(--borderGrey);}
.transaction-tabs .ant-collapse-item .ant-collapse-arrow svg{transform: scale(1.3);}
.transaction-tabs .ant-collapse-item-active .ant-collapse-arrow svg{transform: rotate(90deg) scale(1.3) !important;}
.alltab-space .ant-table{margin: -16px -16px 0;}
.custom-search .anticon-search{display: none;}
.k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer {color: var(--textWhite) !important;background-color: transparent !important;}
.k-grid-content, .k-grid {background-color: transparent !important;color: var(--textWhite) !important;}
.k-grid-content{margin-right: -12px;}
.k-grid-header{padding-right: 0;}
.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover {background-color: var( --bgDarkGrey) !important;}
.k-pager-wrap {color: var(--textWhite);background-color: transparent;}
.change-space .ant-input-password-icon{padding: 10px 16px 10px 0 !important;cursor: pointer;}
.change-space .ant-input-password-icon svg{color: var(--textWhite)}
.hover-passlwngth {display: none}
.pass-onhover:hover + .hover-passlwngth {display: block;position: absolute;right: 0;background-color: #000;z-index: 999;padding: 10px;}
.pass-onhover:hover + .hover-passlwngth span {display: block;color: var(--textWhite);padding: 3px 2px 2px 8px;font-size: 12px;position: relative;}
.pass-onhover:hover + .hover-passlwngth span:first-child {padding: 0;margin-top: -5px;margin-left: -3px;}
.hover-passlwngth:after { content: ""; position: absolute; border: 12px solid transparent;  border-top-color: #000 !important;  border-bottom: 0;  width: 8px;  height: 8px;  left: 2px; transform: rotate( 175deg );  top: -7px; z-index: 99;}
.hover-passlwngth span:after {content: "";position: absolute;border: 6px solid transparent;border-top-color: #fff !important;border-left: 0; width: 4px; height: 4px;left: -7px;transform: rotate( 135deg ); top: 4px;z-index: 99;}
.hover-passlwngth span:first-child:after {display: none}
.pwd-popup.pop-cancel{vertical-align: middle;margin: 10px auto !important; display: flex;height: 36px !important;text-align: center !important;}
.pwd-popup.pop-cancel.ant-btn-block{width: auto !important;}
.colr-comn{color: var(--textPureWhite)!important}
.ant-custumcheck{display: flex;align-items: center;}
.ant-checkbox-inner{background-color: transparent !important;border: 0 !important;}
.customaudit-select .ant-select-single .ant-select-selector .ant-select-selection-search{left: 17px!important}
.customaudit-select .ant-form-item-label > label::after{display: none;}
.buy .ant-list-item-meta-avatar{background-color: var(--bgDarkGreen);}
.sell .ant-list-item-meta-avatar{background-color: var(--bgDarkRed);}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {-webkit-appearance: none;appearance: none;margin: 0;}
.c-notallowed{cursor:not-allowed !important;}
.cust-sumsub-font{width: 400px;}
.sumSub-confirm{background: var(--bgGrey);color: var(--black); padding: 50px 40px;width: 800px;margin: 80px auto;border-radius: 14px;}
.sumSub-confirm.cust-cord{background: var(--gridborder);}
.sumSub-error{color: var(--black); padding: 50px 40px 16px;height:225px;margin: 50px 0 100px 0;border-radius: 14px;}
.sumSub-review{font-size: 16px;font-weight: 700;line-height: 1.29;}
.sumsub-mb{margin-bottom: 16px;}
.sumsubactbtn{margin-left: 0px !important;margin-top: 16px;}
.pay-doc .file-label{white-space: nowrap;max-width: 250px !important;overflow: hidden; text-overflow: ellipsis; width: 200px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.cust-disable.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{color:#fafcfe7a;}
.page-title{font-size: 18px !important;color: var(--textWhite30);font-weight: 500;margin-bottom: 12px !important;}
.delete-disable[disabled]{cursor: not-allowed;}
.payments-modal .ant-modal-footer{border: none !important;}
.payments-modal .modal-para{margin-left: 26px !important;}
.excel-btn{background-color: var(--bgYellow); border-color: var(--bgYellow); border-radius: 40px;height: 42px; text-transform: uppercase;font-weight: 700; transition: 0.3s ease; color: rgba(0, 0, 0, 0.85);padding: 0 15px;}
.excel-btn:hover, .excel-btn:focus, .excel-btn:active{background-color: var(--bgDarkYellow);color: var(--textDark);border-color: var(--bgYellow);}
.pay-docs{white-space: nowrap;max-width: 250px !important;overflow: hidden; text-overflow: ellipsis; width: 65px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.pay-account{white-space: nowrap;max-width: 250px !important;overflow: hidden; text-overflow: ellipsis; width: 100px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.docdetails .docnames{font-weight: 700;color: var(--viewAll); line-height: 16px;max-width: 150px !important;}
.pay-docs{white-space: nowrap;overflow: hidden; text-overflow: ellipsis; width:auto;margin-right:12px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.portfolio-title{display: flex; justify-content: space-between;}
.ant-collapse.profile-collapse, .profile-collapse .ant-collapse-content{background: transparent !important;border: 0 !important;}
.drpdwn-list .ant-collapse > .ant-collapse-item{border-bottom: 0 !important;}
.ant-collapse-icon-position-right.profile-collapse > .ant-collapse-item > .ant-collapse-header{padding: 10px 0 10px 10px;color: var(--textWhite30);font-size: 14px;font-weight: 300;}

.doc-def{width: 460px;margin-left: -2px;}
.submission-sec{width: 550px;color: var(--bgWhite);margin: 0 auto;}

.custom-forminput .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{text-align: center;}
.custom-dropdown.btn-dropdown button{ background-color: var(--bgYellow) !important;  border-color: var(--bgYellow) !important;}
.custom-dropdown.ant-btn-group > .ant-btn:first-child:not(:last-child){border-top-left-radius: 40px; border-bottom-left-radius: 40px; padding-left: 50px;;padding-right: 50px;}
.custom-dropdown.ant-btn-group > .ant-btn:last-child:not(:first-child){ border-top-right-radius: 40px; border-bottom-right-radius: 40px; padding-right: 30px; border-left: 1px solid #333;}
.custom-dropdown .anticon.anticon-ellipsis{display: none;}
.user-list.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations{display: none;}
.basic-info .ant-table{background: transparent;}
.basic-info .ant-table-tbody > tr.ant-table-row:hover > td{background: var(--bgDarkGrey);}
.basic-info .ant-table-thead > tr > th{border-bottom: transparent;}
/* multi selection */
.ant-select-multiple .ant-select-selection-item-remove, .ant-select-multiple .ant-select-selection-item-content{color:var(--textWhite);}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){background-color: var(--profileMenu);color:#fff;}
.ant-select-dropdown{background-color: var(--bgGloom);}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){background-color: var(--bgDarkGrey);color:#fff;}
.ant-select-item{color:#ffff;}
.cust-input-light{background: transparent;border-radius: 30px;height: 50px;border: 1px solid #6e6e74;color:#6e6e74;text-align: center;font-size: 16px;}
.cust-input-light .ant-select-selection-item{ border: 1px solid #6e6e74 !important;}
.cust-input-light .ant-select-selection-item-content{color:#6e6e74 !important;}
.checkbox-red {border-color:var(--textError) !important;}
.check-red, .check-red .ant-checkbox{border-color:var(--textError) !important;}
.ant-select.cust-input-light.ant-select-multiple.ant-select-disabled.ant-select-show-search{padding:2px 0px;}
.cust-input-light.ant-select-disabled.ant-select-multiple .ant-select-selection-item{border-color: #787373;color: rgba(0, 0, 0, 0.25);}
.cust-input-light.ant-select-disabled.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content{color: rgba(255, 255, 255,0.25) !important}
.custom-notify.ant-select-multiple .ant-select-selection-placeholder{top:60%;}
.cust-input-light.ant-select-multiple .ant-select-selection-placeholder{top:70%;}
.cust-input.cust-text-area{padding:26px 16px 0px 16px!important;}
.bill-payment .pay-docs{width:100px;}
.enter-val.enter-vel-field{width:100%}
.k-animation-container.k-animation-container-relative.k-animation-container-shown{z-index: 9999;}
.toggle-space{position: relative;}
.toggle-space::before{content: '';position: absolute;width: 1px;height: 27px;right: -17px;background-color: #cecece; margin-left: 10px;}
.secureDropdown .ant-menu-root {background-color: var(--bgGloom);border-radius: 10px;min-width: 300px;max-width: 300px;padding: 6px 0;color: var(--textWhite);position: absolute;
    max-height: calc(100vh - 75px); overflow-y: auto;border: 0;}
.secureDropdown .ant-menu-root{top:27px !important}
.secureDropdown .ant-menu-root{min-width: 180px;max-width: 180px;}
.secureDropdown .ant-menu-title-content{line-height: 23px;}
.ant-result-title, .ant-result-subtitle{color: var(--textWhite);}
.crypto-card .ant-image-img{ margin-top:5px;margin-right: 0;border: 4px solid var(--coinBg);border-radius: 50%;}
.with-min{position: absolute;top: 52px;right: 46px;}
.with-max{position: absolute;top: 52px;right: 0;}
.crypto-list-custom li {color: #fff;}
.custom-crypto-btns .custom-bnt{background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2)); border-radius: 6px;border:1px solid #ccc;padding:8px 10px;}
.intertrans{display: flex;justify-content: center;align-items: center;height: 478px;}
.crypto-card .ant-card-body{width: 100%;}
.custom-spin .ant-spin-dot-spin{width: 2rem;height: 2rem;}
.custom-spin .ant-spin-dot-spin i{width: 16px;height: 16px;}
.ant-dropdown.secureDropdown{z-index: 995; width: 239px;}
.minmax.custom-minmax{position: relative;right:13px;top:-70px;text-align: right;}
.new-add .ant-btn:hover{border-color: transparent;outline: none !important;}
.markets-panel .custom-table .ant-table-body::-webkit-scrollbar-thumb{background-color: #899097 !important;}
.pay-grid .ant-table-cell-scrollbar{box-shadow: none;}
.pay-grid .ant-table-body{height: 450px;}
td.ant-table-column-sort{background: transparent;}

.custom-radio-btn.buysell-toggle{background-color: transparent;}
.custom-table.pay-grid.view .ant-table-tbody > tr.ant-table-placeholder:hover > td{background-color: transparent;}
.address-book-table .pay-grid tbody td{padding: 4px 8px 4px 8px;}
.side-drawer.custom-drawer-width .ant-drawer-title{color: #fff;}
.custom-radio-btn .ant-radio-button-wrapper{background: transparent;background-image: none;border-radius: 5px; margin-left: 5px;border: 1px solid #9b9bab;margin-right: 5px;color: var(--textWhite);width: auto;height: auto;display: inline-flex;align-items: center;}
.custom-radio-btn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{border: transparent;}
.custom-radio-btn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{border: transparent;}
.custom-radio-btn .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{border: transparent;}
.custom-radio-btn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{border: transparent;}
.custom-radio-btn .ant-radio-button-wrapper span{margin:auto;}
.custom-radio-btn .ant-radio-button-wrapper-checked{border:transparent;}
.cust-input.cust-text-area.address-book-cust{padding:20px 16px 15px 16px!important;}
.custom-radio-btn.buysell-toggle.crypto-toggle .ant-radio-button-checked{background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2));border-radius: 5px;}
.custom-radio-btn.buysell-toggle .ant-radio-button-wrapper-checked{color: #fff !important;;}
.ant-btn.ant-btn-block.profile-btn:focus, .ant-btn.ant-btn-block.profile-btn:active{border: 1px solid #ED4B9E !important;}
.fund-transfer-input .ant-input-group-addon:first-child{border-right: 1px solid #d9d9d9;cursor:not-allowed}
.fund-transfer-input .ant-input-group-addon{background-color: transparent;border: transparent;padding: 0 26px;}
.fund-transfer-input .ant-input-group .ant-input{border:transparent;}
.fund-transfer-input .ant-input:focus, .ant-input-focused{box-shadow:none;}
.fund-transfer-input .ant-input-group-addon{color:var(--textWhite);}
.small-text-align small{font-weight: 400; vertical-align: super;background-color: var(--bgDarkGrey);padding: 2px 6px;color: #fff;border-radius: 10px;}
.custom-radio-btn .ant-radio-button-wrapper.span-text span{line-height: 15px;margin: initial;}
.cust-fund{width: 88%;}
.cust-divide{margin: 0px !important; height: 2px; background-color: var(--bgDarkGrey);}
.create-new{text-decoration: underline !important;}
.cust-address{margin-bottom: 0px !important;}
.search-input .ant-input-wrapper.ant-input-group .ant-input{background-color: var(--bgWhite) !important; border-radius: 0px !important; height:34px !important; border-color: transparent !important; color: var(--bgBlack) !important;}
.search-input .ant-input-wrapper .ant-input-group-addon{background-color: var(--bgWhite) !important; border-radius: 0px !important; height:33px !important;}
.search-input .ant-input-wrapper.ant-input-group .ant-input:hover{border-color: transparent !important;}
.search-input .ant-input-wrapper.ant-input-group .ant-input:focus{box-shadow: none !important;}
.search-input .ant-input-wrapper .ant-input-group-addon .ant-input-search-button:focus,.search-input .ant-input-wrapper .ant-input-group-addon .ant-input-search-button:hover{z-index: 0 !important;}
.search-input .anticon.anticon-search{padding-right: 30px; }
.usr-add .ant-select-arrow{color: var(--bgGrey); }
.usr-add{position: relative; right: 32px; bottom: 6.7px;}
.custom-placeholder .cust-select .ant-select-selection-placeholder{color:#fff !important;}
.text-red-colr{color:#ff5e43 }
.input-label.otp-verify .ant-form-item-control{margin-top: 4px;}
.main-container .k-loading-color{background-color: transparent !important;}
.hy-align .ant-btn-loading::before{background-color: transparent !important;}
.btncolor{background:"gray"}
.cust-select-arrow .ant-select-single .ant-select-selector .ant-select-selection-item::after{margin-left: 8px;}
 .fund-transfer-input .tfunds-inputgroup .ant-input-group-addon{ padding: 0 10px 0 0 !important;}
 .plist-textwrap {width: 471px;word-wrap: break-word;}
 .trn-sumtext {width: 330px;word-wrap: break-word;}
 .send-crypto-sumry .ant-checkbox-wrapper.ant-custumcheck {position: relative;bottom: 10px !important;}
 .fiat-crypto-model .ant-moda{width: 520px;}
 .buy-usdt-btn .ant-btn .ant-btn-lg :focus{box-shadow : none !important;--antd-wave-shadow-color:none !important;}
 /* Standard */
 .k-grid-header .k-header > .k-link{font-size: 16px;font-weight: 500;}
 .k-grid td{font-size: 14px;font-weight: 400;}
 h1.sub-heading {text-transform: capitalize;padding-left:4px; padding-right:4px;font-size:18px;font-weight:500;margin-top: 36px;color:var(--textWhite);}
h1.heading{font-size: 24px;font-weight: 600;color:var(--textWhite);margin-bottom: 16px;padding-left:4px; padding-right:4px;}
.sidebar-push{padding:30px 16px 24px;}
.k-grid-header .k-header > .k-link{padding: 8px;margin: 0;}
.ant-btn{box-shadow: none;}
.custom-header span {padding: 6px 0px;}
.ant-menu-item:active, .ant-menu-submenu-title:active{background-color: transparent;}
.k-grid th{padding:0px 12px 0px 3px; height:42px;}
.cust-pop-up-btn .primary-btn.pop-cancel{border: transparent !important;}
.cust-pop-up-btn .primary-btn.pop-cancel:hover{border: 1px solid var(--textYellow) !important;}
.custom-back-btn .pop-cancel{border: transparent !important;}
.custom-back-btn .pop-cancel:hover{border: 1px solid #f2f2f2 !important;}
.cust-cypto-view,.cust-fiat-view{width: 900px;margin: auto;}
.loading-btn{margin: 0 auto;}
.new-add .loading-btn + button{position:absolute; right:142px !important;}
.bg-box{padding: 24px;border-radius: 0;}
.pop-btn.withdraw-popcancel{width: 150px; height: 46px;  border: transparent; color: var(--textYellow); background-color: transparent;}
.pop-btn.withdraw-popcancel:hover{border: 1px solid #f2f2f2 !important;}
.pop-btn.withdraw-popcancel span{color: var(--textYellow); font-weight: 400;}
.file-label.add-lbl{text-align: center;display: block;}
.gridlink-data{max-width:130px ;}
.custom-btn:hover span{color: white;}
.custom-crypto-btns .custom-bnt span{color: var(--textWhite); font-weight: 400;}
.pop-btn.cancel-btn{background: transparent; border: 1px solid transparent; border-radius: 40px; height: 46px !important; font-weight: 700 !important;}
.pop-btn.cancel-btn:hover{border: 1px solid var(--textYellow)!important;}
.pop-btn.cancel-btn span{color: var(--textYellow) !important;}
.crypto-pop .btn-width{width: 150px !important;height: 46px !important;}
.sc-error{font-weight: 400 !important;}
.crypto-blc-inpt{flex:1 1;}
.cust-tabs-fait .ant-tabs-nav-operations{display: none !important;}
.pop-btn.custom-send.error{margin-left: 0 !important;}
.error-img{margin-left: 50px; width: 300px;}
.error-content{margin-bottom: 40px !important;}
.error-title{margin-bottom:10px !important;}
.notify-viewbtn{margin-bottom: 10px;}
.uploaddoc-margin{margin: 12px 10px 0 0;}
.bill-pop .bill-cancel{border: transparent !important;}
.bill-pop .bill-cancel:hover{border: 1px solid var(--textYellow) !important;}
.carousel-card .carosel-content{color: var(--tablesub);font-weight: 400;font-size: 12px;}
.icon.md.To.Wallet{	background-position: -325px -101px;}
.icon.md.From.Wallet{	background-position: -325px -101px;}
.icon.md.To.Value{background-position: -694px -135px;}
.icon.md.From.Value{background-position: -694px -135px;}
.icon.md.Provider.Commission{background-position: -549px -101px;}
.icon.md.Network{background-position: -325px -101px;}
.icon.md.Wallet.Address{background-position: -325px -101px;}
.icon.md.State{background-position: -636px -135px;}
.icon.md.Bank.Account{background-position: -457px -70px;}
.icon.md.Wallet.Code{background-position: -325px -101px;}
.icon.md.Amount{background-position: -428px -70px;}
.icon.md.Coin{background-position: -325px -101px;}
.icon.md.Requested.Crypto{background-position: -428px -70px;}
.icon.md.Deposited.Coins{background-position: -428px -70px;}
.icon.md.Country.of.Residence{background-position: -436px -101px;}
.icon.md.Customer.ID{background-position: -126px -102px}
.dashb-btmtable table tbody .ant-table-placeholder{background: none;}
.pwdbtn-cancel.pop-btn.cancel-btn:hover{border: 1px solid var(--textYellow) !important;}
.selcerpto-list{padding-right: 10px;}
.wallet-info{padding: 16px 0;font-size: 16px;display: flex;justify-content: space-between;}
.wallet-info span{font-size: 16px;color: var(--textWhite30);font-weight: 600;}
.wallet-info>span:first-child{font-size: 14px;color: var(--textWhite50);font-weight: 200;}
.selectcustom-input .ant-form-item-label > label::after{content: none !important;}
.security-login{background: var(--drawerList);color: var(--black);padding: 50px 40px 16px;margin: 80px auto;border-radius: 14px;width: 500px;height: 350px;}
.security-text{font-size: 22px !important;font-weight: 700 !important;line-height: 1.29 !important;color: var(--white)!important;text-align: center;}
.primary-btn.pop-cancel:hover{border: 1px solid var(--textYellow) !important;}
.transaction_resp .cust-label .ant-select-selection-item{cursor: pointer !important;}
.pay-list.transaction-details h1{flex: 1 1; word-break: break-all;}
.ant-alert-info .ant-alert-icon{color: #ff4d4f;}
/* .ant-alert-info{background-color: #fff2f0; border: 1px solid #ffccc7;} */
.accordian.mb-togglespace .docfile{background-color:var(--drawerBG) !important;}
.docfile.address-upload{background-color: var(--uploadcard) !important;}
.statement-header{display: flex; justify-content: space-between; align-items: baseline;margin: 20px 0 20px 0;width: 100%;}
.header{font-weight: 500;margin-bottom: 0;}
.details-cust{display: grid;text-align: right;}
.statement-header ul{list-style: none !important;padding: 0;}
.statement-header ul li b,.statement-header p .generatedDate{display: inline-block;width: 100px;}
.logo{vertical-align: middle; height: 80px;}
.transaction-pdf-template tr:nth-child(even){background-color: rgb(235 235 235 / 85%)}

/* .statement-header.logo-content{align-items: flex-end;} */
.transaction-pdf-template thead{vertical-align: top;text-align: left;}
.transaction-pdf-template thead th:first-child,tbody tr td:first-child,.fee-discount-table tr td:first-child{padding-left: 10px; }
.transaction-pdf-template tbody tr td{padding-top:8px;padding-bottom:8px; }
.transaction-pdf-template .pdt-data tr td{word-break: break-all;}
.ant-dropdown-menu.history-excel{background-color: var(--profileMenu) !important; border-radius: 10px; min-width: 100px; max-width: 200px; padding: 24px 0;right: 25px;}
.export-pdf button{background-color: #3653E4;border-color: #3653E4;border-radius: 5px;height: 42px;color:#FFFFFF;font-weight: 700;transition: 0.3s ease;}
.export-pdf button:hover,.export-pdf button:active,.export-pdf button:focus{background-color:#7087FF;color:#FFFFFF;border-color:#7087FF;}
.secureDropdown.export-pdf{margin-bottom: 16px;}
.upload-disable{cursor: not-allowed;}
.file-label.batch-upload{width: 100%;}
.icon.md.close.batch-close{transform: scale(0.7);}
.gridLink.batch-filename{display: inline;}
.draft-filename{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.btn-space{margin-left: 8px;}
.webkit-color{-webkit-text-fill-color: #3653E4}
.address-clear.preview-content table tr td:first-child{white-space: nowrap;}
.file-label span.webkit-color{display: inline-block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.export-pdf .ant-dropdown-button button:first-child{cursor: default !important;}
.history-excel .drpdwn-list li a{padding: 10px 0;}
.input-label.selectcustom-inputdate-mobile{margin-bottom: 0 !important;}
/* .search-box > .search-btnexpand:hover, .search-box > .search-btnexpand:focus{border: 1px solid var(--subText);border-right: 0;border-radius: 10px 0px 0px 10px;} */
.cust-cancel-btn.send-crypto-btn{margin-top:28px;}
.cust-disabled.pop-btn.ant-btn[disabled]{background-color: rgb(112, 135, 255) !important;border-color: rgb(112, 135, 255) !important;}

.approve-email{font-size: 14px;font-weight: 400;}
.phone-flex{ display: flex;
    justify-content: space-between;
    align-items: center;}
.commision-fee-custtable .cust-tr-style > th{border-right: 1px solid var(--tableborder)}
.commision-fee-custtable .cust-tr-style > th:last-child{border-right:0}
.cust-send-amountfield.fund-transfer-input .tfunds-inputgroup .ant-select{width:auto;}
.accordian .ant-collapse-extra span{display: inline-block; color: var(--textPureWhite);font-size: 12px;padding: 2px 8px; border-radius: 4px; font-weight: 500;}
.reject-lbl{background-color: red !important;}
.subm-lbl{background-color: blue !important;}
.Requet-lbl{background-color: #c7c70d !important;}
.send-crypto-input.crypto-blc-inpt{position: relative;}
.cust-error-msg-align .ant-form-item-explain{position: absolute;top:90px;}
/* .history-crypto-cards{min-width: 786px;} */
/* .search-bg.fait-card-transaction-h .listitems-design{float: none;} */
/* .search-bg.fait-card-transaction-h .ant-list-item.cytpo-list-style:nth-child(even) {margin-right: 0;background: var(--faitGradeBlue)!important;} */
/* .search-bg.fait-card-transaction-h .ant-list-item.cytpo-list-style{background: var(--faitGradGreen) !important;} */
.trnsaction-mt-36{margin-top: 36px;}
.dashboard-kycbd p .add-custom{justify-content: start;}
.card-bg-style{background: var(--gridborder);padding:24px;border-radius: 12px;margin-bottom: 24px !important;}
.ant-menu-item-disabled .ant-menu-title-content .ant-menu-item{cursor:not-allowed;}
/* * { scrollbar-width: thin; scrollbar-color: var(--scroll);} */
*::-webkit-scrollbar { width: 12px;height: 10px;  }
*::-webkit-scrollbar-thumb {background-color: var(--scroll);border-radius: 20px;}
::-webkit-scrollbar-corner {background: var(--scroll);}
html, .auto-scroll, .ant-table-body, .ant-drawer-body, .mobile-list, .k-grid-content{scrollbar-color: var(--scroll);}
.loader-antd{padding: 30px 50px;text-align: center;color: var(--white);}

body, h1, h2, h3, h4, h5, h6, a, p, div, input{font-family: 'Lato', sans-serif !important;}
.d-flex{display: flex !important;}
.p-relative{position: relative !important;}
.c-pointer{cursor: pointer !important;}
.d-block{display: block !important;}

.ant-spin-dot-item{background-color: var(--primary);}
.icon.md.theme-icon{background-position: -8px -486px;margin-right: 4px;}
.icon.sm.valupp-icon{background-position: -87px -5px;margin-left: 8px;}
.icon.sm.valdown-icon{background-position: -108px -4px;margin-left: 8px;}
.icon.lg.whats-app{background-position: -9px -658px;margin-right: 14px;}
.icon.lg.mail-app{background-position: -52px -658px;}

/*----Font Sizes----*/

.main-head{font-size: 32px;font-weight: 800;}
.sub-head{font-size: 22px;font-weight: 700;}
.header-menu{font-size: 18px;font-weight: 800;}
.header-menulist,
.drawer-subtext,
.coinselect-coinprice{font-size: 16px;font-weight: 400;}
.header-menusub{font-size: 14px;font-weight: 400;}
.drawer-head{font-size: 26px;font-weight: 800;}
.coinselect-name,
.coinselect-balance{font-size: 16px;font-weight: 700;}



/*--Font Weights--*/
.fw-100{font-weight: 100 !important;}
.fw-200{font-weight: 200 !important;}
.fw-300{font-weight: 300 !important;}
.fw-400{font-weight: 400 !important;}
.fw-500{font-weight: 500 !important;}
.fw-600{font-weight: 600 !important;}
.fw-700{font-weight: 700 !important;}
.fw-800{font-weight: 800 !important;}

/*--Alignments--*/
.text-center{text-align: center !important;}
.text-right{text-align: right !important;}
.text-left{text-align: left !important;}
.text-upper{text-transform: uppercase !important;}
.text-captz{text-transform: capitalize !important;}
.align-center{align-items: center !important;}
.justify-content{justify-content: space-between;}
.display-items{display: flex;align-items: center;justify-content: space-between;}

.text-underline{text-decoration: underline !important;}
.valign-initial{vertical-align: initial;}

/*--Text Colors--*/
.text-white{color: var(--textWhite) !important;}



/*----Padding Utilities----*/
.p-0{padding: 0 !important;}

/*----Margin Utilities----*/
.m-0{margin: 0 !important;}
/* icons start */
.new-custom-radiobtn .ant-radio-button-wrapper-checked .icon.lg{background-position:-324px -521px !important;}
.new-custom-radiobtn .ant-radio-button-wrapper .icon.lg{background-position:-324px -485px;}
.icon.md.add-icon-black{background-position: -397px -71px;}
.icon.md.excel-export{background-position: -664px -3px;}
.icon.lg.radio-check{background-position:-324px -521px;}
.icon.lg.radio{background-position:-324px -485px;}
.icon.lg.download{background-position:-234px -518px;}
.icon.lg.search-angle{background-position:-128px -480px;}
.icon.md.bell{background-position:-39px -486px;}
.icon.lg.menu-bar{background-position: -97px -484px;}
.icon.lg.search-angle.drawer-search{background-position:-295px -484px ;}
.icon.sm.valupp-icon.pg-arrow{background-position:-10px -692px;}
.icon.sm.valupp-icon.red-arrow{background-position:-28px -692px;}
.icon.lg.profile-icon{background-position:-9px -556px}
.icon.lg.security-icon{background-position:-52px -556px}
.icon.lg.settings-icon{background-position:-173px -556px}
.icon.lg.info-icon{background-position:-73px -330px}
.icon.lg.logout-icon{background-position:-73px -330px}
.icon.lg.documents-icon{background-position:-176px -329px;}
.icon.lg.addressbook-icon{background-position: -140px -329px}
.icon.lg.cases-icon{background-position:-210px -329px;}
.icon.lg.referral-icon{background-position:-139px -555px;}
.user-labels.active .icon.lg.profile-icon,.user-labels:hover .icon.lg.profile-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.profile-icon, .ant-tabs-tab:hover .icon.lg.profile-icon{background-position:-9px -590px;}
.user-labels.active .icon.lg.security-icon, .user-labels:hover .icon.lg.security-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.security-icon,.ant-tabs-tab:hover .icon.lg.security-icon{background-position:-52px -589px}
.user-labels.active .icon.lg.settings-icon, .user-labels:hover .icon.lg.settings-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.settings-icon,.ant-tabs-tab:hover .icon.lg.settings-icon{background-position:-173px -589px}
.user-labels.active .icon.lg.documents-icon, .user-labels:hover .icon.lg.documents-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.documents-icon,.ant-tabs-tab:hover .icon.lg.documents-icon{background-position:-176px -363px}
.user-labels.active .icon.lg.addressbook-icon, .user-labels:hover .icon.lg.addressbook-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.addressbook-icon,.ant-tabs-tab:hover .icon.lg.addressbook-icon{background-position:-140px -363px}
.user-labels.active .icon.lg.cases-icon, .user-labels:hover .icon.lg.cases-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.cases-icon,.ant-tabs-tab:hover .icon.lg.cases-icon{background-position:-210px -362px;}
.user-labels.active .icon.lg.notification-icon, .user-labels:hover .icon.lg.notification-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.notification-icon,.ant-tabs-tab:hover .icon.lg.notification-icon{background-position: -184px -134px;}
.user-labels.active .icon.lg.referral-icon, .user-labels:hover .icon.lg.referral-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.referral-icon,.ant-tabs-tab:hover .icon.lg.referral-icon{background-position:-139px -589px;}
.user-labels.active .icon.lg.bank-letter, .user-labels:hover .icon.lg.bank-letter,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.bank-letter,.ant-tabs-tab:hover .icon.lg.bank-letter{background-position:-321px -584px;}
.ant-tabs-tab:hover .icon.lg.accountsummary,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.accountsummary{background-position: -351px -585px;}
/* .custom-checkbox input[type=checkbox] { display: block;position: absolute;zoom: 1.5;margin: 0; opacity: 0;} */
.cust-check-outline.custom-checkbox input[type=checkbox]:checked+span {width: 24px; height: 24px;cursor: pointer;background-position:-157px -518px !important;}
.cust-check-outline.custom-checkbox input[type=checkbox]+span{width: 24px;height: 24px;background-position: -120px -517px !important;}
.cust-check-outline input[type='radio'], .ant-form .cust-check-outline input[type='checkbox'], .custom-checkbox.cust-check-outline input[type='checkbox']{display: none;}
/* .new-custom-radiobtn  input[type=radio]:checked+span{background-position:-324px -521px;} */
/*Header start*/
.notification-conunt .bell span{ color: white; position: absolute;right: -8px;top: -8px;width: 18px;height: 18px;background: #3653E4;border-radius: 50%;line-height: 18px;text-align: center;font-size: 10px;font-weight: 600;}
.haead-main{background-color: var(--secondary);position: fixed; z-index: 9999;left: 0; right: 0;}
.tlv-header ul{background-color: transparent; border-bottom: 0;}
.tlv-header ul li{font-weight: 500;}
.tlv-header .main-menu li{border-radius: 5px;}
.tlv-header .main-menu li.ant-menu-item-selected, .tlv-header .main-menu li:hover{background-color: var(--primary) !important; color: var(--textWhite) !important;}
.tlv-header li::after, .tlv-header li:hover:after{border: none !important;}
.tlv-header ul li .cp-link{padding: 6px 0;margin-right: 8px;}
/* .tlv-header ul li .cp-link:hover{background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2)); color: var(--textPureWhite) !important;filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));border-radius: 5px;} */
.header-right li{color: var(--sbCardText) !important;}
/*Header End*/

/*profile css start*/
.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus{border-color: transparent !important;}
.ant-alert-with-description{align-items: center;}
.secureDropdown.export-pdf .excel-export{margin-left: 8px;}
.user-profile{ border-radius: 50%;object-fit: cover;}
.profile-dropdown .user-profile{width:112px;height: 112px; border-radius: 50%;display: block;margin: 0 auto;}
.profile-dropdown {text-align: center;padding:12px 24px;}
.profile-dropdown .drpdwn-list{padding-top: 0px; padding-left: 0; padding-right: 0; margin-left: -24px; margin-right: -24px;}
.profile-btn, .profile-btn:hover, .profile-btn:active, .profile-btn:focus{background: transparent;border: 1px solid var(--green) !important;height: 27px;border-radius: 8px;color: var(--green);font-size: 14px;width: 127px;font-weight: 500;text-align: center;display: flex;align-items: center;padding: 6px 10px;margin-bottom: 10px;font-style: italic;}
.profile-info{width: 96px;height: 96px;padding: 5px;border-radius: 50%;text-align: left;position: relative;background-color: var(--profilebgImg);}
.profile-info .user-profile{width:100%;height: 100%; border-radius: 50%;padding: 6px;object-fit: cover;}
.profile-info .img-upld{position: absolute;right: -14px;bottom: 10px;background-color:#F1F2F6;border:0;}
.profile-info .img-upld:focus{background-color:#F1F2F6;}
.basic-info,.contact-info{padding: 24px;}
.dash-info.basic-info{padding: 6px !important;}
.basicinfo{font-weight: 700 !important;font-size: 18px !important;color: var(--menuFont) !important;margin-bottom: 0 !important;}
.basic-decs{font-weight: 500;font-size: 14px;color: var(--marketTitle);margin-top: 12px;}
.profile-label{font-weight: 500;font-size: 14px;color: var(--marketTitle);}
.profile-value{font-weight: 500;font-size: 16px;color: var(--white);margin-bottom: 0 !important;}
.profileinfo{border-top:1px solid var(--borderColor);padding-top: 24px;padding-bottom: 24px;}
.profileinfo:first-child{border-top:0}
/* .profileinfo.active, .profileinfo:hover{background: var(--bgOrient1); margin-left: -32px; margin-right: -32px;} */
/* .profileinfo.active .profile-block, .profileinfo:hover .profile-block{padding: 0 32px} */
.ant-popover-inner-content .personal-popup, .ant-popover-inner{padding: 8px 12px;}
.ant-popover-inner{background-color: var(--profileMenu);border-radius: 10px;padding: 0;}
.ant-popover-inner-content .personal-popup:hover{background: var(--drpdwnHover);padding: 8px 12px !important;}
.user-tablist .ant-tabs-tab, .user-tablist .ant-tabs-tab.ant-tabs-tab-active, .user-tablist .ant-tabs-tab:hover{padding: 12px 16px 12px 12px !important;}
.user-list .ant-tabs-tab{font-size: 16px; font-weight: 600; color: var(--textWhite); margin-bottom: 0;padding: 12px !important;}
.user-list.ant-tabs-left > .ant-tabs-content-holder{border-left: 0 !important;}
.user-list .ant-tabs-content.ant-tabs-content-left{padding-left: 65px;}
.user-list .ant-tabs-tab.ant-tabs-tab-active,.user-list .ant-tabs-tab:hover {background: var(--profiletab);
    border-radius: 8px;;font-weight: 600;  padding:12px; color: #ffffff;}
.user-list .ant-tabs-ink-bar {background: transparent !important;}
.user-list .ant-tabs-tab .tabtitle{font-weight: 700;font-size: 18px;color: var(--coinPrice);margin-left: 14px;}
.profile-image-content{display: flex;align-items: center;}
.download-content{font-weight: 700;font-size: 16px;color: var(--tabLine);}
.profile-img-style{margin-right: 98px;}
.dwnl-content-style{margin-top: 66px;display: flex;}
.basicprofile-info{background: var(--addressList);border-radius: 13px;padding: 24px;margin-top: 26px;width: 510px;}
.profile-ul{padding-left: 0 !important;list-style: none;}
.security-align, .crypto-address{display: flex;align-items: center;justify-content: space-between;}
.custom-toggle.ant-switch-checked{background-color: var(--profiletab) !important;}
.custom-toggle.ant-switch{background-color: #4E505A;}
.cases-grid-style .k-grid-header .k-grid-header-wrap table{width: 100% !important;}
.cases-grid-style .k-grid-container .k-grid-content .k-grid-table{width: 100% !important;}
/* .small-btn{min-width: 90px;font-weight: 700;font-size: 15px;color: var(--white);border: 1px solid var(--pfsmbtn);border-radius: 5px;padding: 12px 28px;display: inline-block;} */
.profile-sm-btn{text-transform: capitalize;height: 46px;min-width: 120px;font-weight: 700;font-size: 15px;color: var(--white);border: 1px solid var(--pfsmbtn);border-radius: 5px;padding: 4px 28px;}
.passwrd-chang-btn{margin-top: 26px;}
.reset-paswrd-mt{margin-top: 36px;}
.custom-msg .ant-message-notice-content{background:var(--black); color: var(--textPureWhite); padding: 20px 40px; min-width: 200px;}
.security-label-style{font-weight: 400;font-size: 14px;color: var(--checkText);margin-left: 12px;}
.user-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .tabtitle, .user-list  .ant-tabs-tab-btn:hover .tabtitle{color: #ffffff;}
.user-list .ant-tabs-tab:hover .tabtitle{color: #fff;}
.profile-sm-btn:hover, .profile-sm-btn:focus{color: var(--btnhover);border-color: var(--btnhover);}
/* .theme-switch.theme-active{background: var(--bgDark);} */
.theme-switch {border-radius: 30px;height: 52px; padding: 7px 4px;}
.custom-theme-btn{display: flex;align-items: center;}
.setting-btn{margin-top: 36px;}
.mb-copy{color: var(--marketTitle) !important;}
.referral-bg-style.basicprofile-info{padding: 18px 24px;margin-top: 16px;}
.refferral-share-btn{margin-top: 26px;font-weight: 500;font-size: 16px;color: var(--coinPrice);margin-bottom: 20px;}
.crypto-address.share-width-align{width: 510px;}
.referral-patner-btn{margin-top: 26px;margin-left: auto;margin-right: auto;}
/* .crypto-address .custom-display{position: absolute;top: 6px;right: 8px;} */
.theme-txt{font-size: 16px; font-weight: 500; color: var(--coinPrice);margin-left: 4px;display: flex;align-items: center;}
.switch-circle, .theme-txt{margin-bottom: 0 !important;}
/* .theme-active .theme-txt{color: var(--textWhite);font-weight: 400;} */
.info-details{font-weight: 700;font-size: 16px;color: var(--tablesub);}
/*profile css end*/

/* manual addressbook start */
.custom-forminput .ant-form-item-label > label{height: auto;}
.ant-tooltip-arrow-content{background-color: transparent;}
/* manual addressbook end */

/*Common css start*/
.self-images{text-align: left;display: flex;align-items: baseline;grid-gap: 18px;gap: 18px;}
[data-theme='LHT'] .self-images img.light-img, [data-theme='DRT'] .self-images img.dark-img{display: inline-block;}
[data-theme='DRT'] .self-images img.dark-img, [data-theme='LHT'] .self-images img.light-img {display: none;}
.fait-subtext.cust-copy-icon{display: inline-block !important;}
/* a{color: var(--viewAll);font-size: 18px;font-weight: 500;} */
[data-theme='DRT'] .header-logo img, [data-theme='LHT'] .header-logo img{display: none;}
[data-theme='DRT'] .header-logo img.dark, [data-theme='LHT'] .header-logo img.light {display: inline-block;}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{border: 0 !important;}
.newtransfer-card.ant-btn[disabled], .newtransfer-card.ant-btn[disabled]:hover, .newtransfer-card.ant-btn[disabled]:focus, .newtransfer-card.ant-btn[disabled]:active{background: var(--drawerList);border: 2px solid transparent;color: var(--coinBal) !important;}
.ant-btn:hover, .ant-btn:focus, .ant-btn:active{background-color: transparent;}
.icon, .k-i-more-vertical.k-icon,.ant-select-arrow, .cust-check-outline.custom-checkbox input[type=checkbox]+span{background: var(--lightThemeIcons) no-repeat; display: inline-block; vertical-align: middle;}
.ant-select-arrow .anticon{display: none;}
.ant-select-arrow{width: 20px;height: 20px;background-position: -351px -5px;opacity: 0.5;}
.main-container{padding: 24px 16px; margin: auto;}
.main-container .k-loading-color{background-color: transparent !important;}
.text-white{color: #FFFFFF;}
.ant-menu-item.ant-menu-item-only-child .ant-menu-title-content:hover{color:var(--white)}
/* body{background:var(--bodyBg);} */
/* url(../images/dark-bg2.0.svg) no-repeat top center; */
.loader{background-color: #000000;position: fixed;top: 0; right: 0; bottom: 0; left: 0;display: flex; align-items: center;font-size: 36px;color: var(--textWhite);justify-content: center;}
.side-drawer .ant-drawer-mask, .side-drawer-full .ant-drawer-mask{background: var(--drawerMask);}
.side-drawer .ant-drawer-body, .side-drawer .ant-drawer-header{background-color: var(--drawerBG);}
.cust-input, .expiry-input, .cust-input .ant-input{background: var( --inputBg);color: var(--white);height: 50px;margin-bottom: 6px;font-size: 16px;border: 1px solid var(--labelBorder);border-radius: 5px;text-align: left;}
.ant-select-selection-placeholder{color: var(--white);}
.ant-select-dropdown{background-color:var(--profileMenu);border-radius: 5px;}
.select-drpdwn .ant-select-item:hover, .select-drpdwn .ant-select-item.ant-select-item-option-active, .select-drpdwn .ant-select-item.ant-select-item-option-selected, .select-drpdwn .ant-select-item.ant-select-item-option-active.ant-select-item-option-selected{background-color: var(--drpdwnHover);}
.ant-drawer-content{ background-color: var(--drawerBG);}
.ant-drawer-header{border-radius: 0;background-color: transparent;}
.side-drawer .ant-drawer-content-wrapper{width: 100% !important;}
.ant-drawer-wrapper-body{padding-top:90px;}
.ant-layout{background: none;}
.ant-btn{background-color: transparent;}
.custom-btn{font-weight:600;font-size: 15px;color: #FFFFFF;}
.drpdwn-list{padding-left: 0;}
.text-green{color: var(--green);}
.text-red{color: var(--red);}
.fait-wallets-style{display: flex;align-items: center;justify-content: space-between;margin-top: 36px;}
.ant-table{background-color: transparent !important;}
.ant-table-tbody > tr.ant-table-row:hover > td{background-color: transparent;}
.ant-table-thead > tr > th,.fee-table th{background-color:var(--grid) !important;border-bottom: transparent !important;}
.ant-table-tbody > tr > td{border-bottom:rgba(255, 255, 255, 0.2) ;}
.ant-table-container table > thead > tr:first-child th:first-child,.table-border th:first-child{border-top-left-radius: 20px;}
.currency-style{color: var(--coinPrice);font-weight: 400;font-size: 24px;}
.coin-style{font-weight: 700;font-size: 16px;color: var(--coinBal);}
.coinbal-style{font-weight: 400;font-size: 16px;color:var(--coinPrice);}
.ant-table-thead th.ant-table-column-has-sorters:hover{background:var(--grid);}
.ant-input-group-addon{background-color:transparent ;}
.cust-search .ant-input-affix-wrapper{background-color: var(--searchBg) !important;border: 1px solid var(--subText) !important;border-radius: 10px !important;height: 48px;}
.cust-search::placeholder{color: var(--placeholders);font-weight: 400;font-size: 16px;}
.cust-search .ant-input{color: var(--primary);font-weight: 400;font-size: 16px;}
.drawer-list-font{font-weight: 700;font-size: 16px;color: var(--coinPrice);}
.drawer-coin .ant-image, .crypto-curr-align .ant-image, .listitems-design .ant-image, .cytpo-list-style .ant-image, .crypto-bg, .drawer-list-fiat .ant-image-img{border: 4px solid var(--coinBg);border-radius: 50%;}
.sendfiat-coins .ant-image-img{ border: none !important;}
.sendfiat-coins .ant-list-item-meta-avatar{height: 46px;}
.drawer-coin .ant-image-img, .crypto-curr-align .ant-image-img, .listitems-design .ant-image-img, .cytpo-list-style .ant-image-img, .crypto-bg, .drawer-list-fiat .ant-image{border-radius: 50%;}
.cytpo-list-style .ant-list-item-meta{align-items: center;}
.crypto-bg{width: 52px;height: 52px;}
.label-style, .ant-form-item-label > label{color: var(--subText); font-weight: 400;font-size: 14px;margin-bottom:4px !important;}
.ant-form-item-label > label{margin-bottom: 4px !important;}
.ant-card.crypto-card{background: none;}
.ant-select-item .ant-select-option-active, .ant-select-item-option-content, .ant-select-selection-item{color:var(--menuFont) ;}
.ant-alert-error, .ant-alert-warning, .ant-alert-info{background-color: var(--alertBG);border: 1px solid #ff5747;}
.ant-alert-error .ant-alert-icon, .ant-alert-warning .ant-alert-icon{color: #ff4d4f;}
.ant-alert-message{color: #ff5747 !important;}
.ant-alert-description {font-size: 16px !important;color: var(--red) !important}
.ant-alert-description span{font-size: 16px !important;color: var(--red) !important}
.ant-alert-description span a{color:var(--termsText) !important}
.ant-alert{border-radius: 5px;}
.ant-notification-rtl{z-index:9999;top:75px !important;}
.ant-notification-notice .ant-notification-close-icon{color: var(--textWhite30);}
.ant-notification{direction: ltr;}
.ant-notification-notice-closable .ant-notification-notice-message{padding-left: 0 !important;}
.ant-notification-rtl .ant-notification-notice-close{left: auto;right: 15px;}
.ant-notification-notice-message{color: var(--textWhite30)!important;}
.ant-notification-notice-description{color: var(--textSecondary)!important;}
.ant-notification-notice{border-radius: 10px;font-size: 14px;background: var(--accountType);}
.notify{padding: 16px;margin-bottom: 36px;}
.notify .ant-alert-action{margin: 15px 30px 0 30px;}
.notify .ant-alert-action .ant-btn{color: var(--viewAll);font-size: 18px;font-weight: 500;}
.notify .ant-alert-close-icon{font-size: 18px;color: var(--textDark) !important;}
.custom-notify.cust-input .ant-select-selection-item, .cust-input .ant-select-selector, .cust-input .ant-input, .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{line-height: 36px !important;height: 38px !important;margin-top:3px;}
.drawer-balnstyle{font-size: 16px;font-weight: 700;color: var(--coinBal);}
.newtransfer-card.ant-btn:hover, .newtransfer-card.ant-btn:focus, .newtransfer-card.ant-btn:active{background-color: var(--radioFill) !important; border: 2px solid var(--radioBorderfill);color: var(--coinPrice) !important;}
.fund-transfer-input{margin-bottom: 0 !important;}
.send-drawerbtn{margin: 36px;}
.input-subtext{font-weight: 400 !important;font-size: 16px !important;color: var(--checksub) !important;}
.custom-radiobtn{background: var(--tabBg);border: 1px solid var(--inputBorder);border-radius: 8px;padding:6px}
.custom-radiobtn .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{background: #3653E4;border-radius: 5px; border: 0;height: auto; padding: 6px 10px;color: #FFFFFF;font-weight: 700;font-size: 16px;}
.custom-radiobtn .ant-radio-button-wrapper{font-weight: 500;font-size: 16px;color: var(--marketTitle);background-color: transparent;border: 0;text-transform: capitalize;padding: 0 8px;}
.custom-radiobtn .ant-radio-button-wrapper:not(:first-child)::before{content: none;}
.custom-radiobtn .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{border: transparent;}
.custom-radiobtn .ant-radio-button-wrapper:first-child{border: 0;}
.custom-radiobtn .ant-radio-button-wrapper:hover{color: var(--marketTitle);}
.custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{color: #FFFFFF;}
.custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{color: #FFFFFF;border-color: transparent;}
.custom-label .ant-form-item-label, .custom-label.ant-row{display: block !important;padding: 0 0 0px !important;}
.send-fiat-input .ant-form-item-label > label{height: 22px;}
.ant-form-item-label{text-align: left !important;}
.ant-input:focus, .ant-input-focused, .ant-input:hover{border: 1px solid #CCC3FF;box-shadow:none;}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow:none;
}
.round-pills .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{border-color: var(--radioBorderfill);}
/* .ant-tooltip.ant-tooltip-placement-top{left: 660px !important;top: 321px  !important;} */
.ant-tooltip-inner{background-color: var(--profileMenu);color: var(--white);}
.footer-links a, .footer-links span{font-size: 14px; color: var(--white); font-weight: 500; display: inline-block;margin-right: 30px;text-align: center;}
.footer-links a:hover{color: var(--textAqua) !important;}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .cust-input:hover{border-color: #CCC3FF;}
.custom-forminput .cust-input .ant-input{text-align: left;}
.cust-input .ant-select-selector, .cust-input .ant-input{background-color: transparent !important;}
.ant-form-item-has-error .ant-input:not(.ant-input-disabled), .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled), .ant-form-item-has-error .ant-input:not(.ant-input-disabled):hover, .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{background-color: transparent;}
.crypto-card.select .ant-card-body .select-currency .cust-input .ant-select-selector{line-height: 44px !important;}
.crypto-card.select .ant-card-body .select-currency .ant-select-single .ant-select-selector .ant-select-selection-item{line-height: 42px !important;}
.profile-img-style .profile-info .loader-antd{padding: 36px 30px 30px 30px !important;}
.newcase-style{margin-bottom: 16px;align-items: center;}
.ant-alert-with-description{align-items: center !important;}
.summarybal .gridLink:hover{color: var(--viewAll);}
.pay-list.modal-listbr .summarybal .gridLink{word-break: break-all;text-align: right;width: 326px;}
.paste-rece-style{padding-top: 0 !important;margin-top: 16px !important;}
/*Common css End*/

/*Coin Selection End*/
.wallet-list.ant-list-split .ant-list-item{background: var(--drawerList);border-radius: 13px;border:2px solid transparent;height: 75px;padding: 10px 18px !important;}
.wallet-list .ant-list-item:hover, .wallet-list .ant-list-item.select, .addCryptoList li.select{background: rgba(92, 97, 255, 0.35);border: 2px solid #CCC3FF !important;border-radius: 13px;}
.wallet-list.ant-list-split .ant-list-item:last-child{border:2px solid transparent;}
.code-lbl{margin-bottom: 4px !important;margin-left: 4px;}
.fiatdep-info.fiat-list-items .alert-info-custom.kpi-List .fait-box.kpi-divstyle:last-child{border: 0;}
/*Coin Selection End*/
/* button styles start */
.select-drpdwn .ant-select-item:hover, .select-drpdwn .ant-select-item.ant-select-item-option-active, .select-drpdwn .ant-select-item.ant-select-item-option-selected, .select-drpdwn .ant-select-item.ant-select-item-option-active.ant-select-item-option-selected, .ant-select-item-option-selected:not(.ant-select-item-option-disabled){background-color: var(--drpdwnHover)}
.ant-select-item.ant-select-item-option.ant-select-item-option-selected,.ant-select-item-option:hover{background-color: var(--drpdwnHover) ;}
.btn-dropdown button, .pop-btn{background-color: #3653E4; border-color: #3653E4; border-radius: 5px;height: 42px;font-weight: 600; transition: 0.3s ease;font-size: 16px;color: #FFFFFF;}
.btn-dropdown button, .pop-btn:hover, .pop-btn:focus, .pop-btn:active, .download-btn:hover{background-color:#7087FF !important;color: #FFFFFF !important;border-color: #7087FF !important;}
.custom-btn.prime, .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover{background-color: #3653E4;font-weight:600;font-size: 15px;color: #FFFFFF;}
.custom-btn.prime:hover,.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover{background-color: #7087FF;}
.custom-btn.sec{background: transparent;background-image: none;border: 1px solid var(--subText); color: var(--textWhite);margin-left: 16px;}
.custom-btn.sec:hover{background-color: #3653E4;}
.cust-cancel-btn{border: 1px solid var(--inputBorder);border-radius: 5px;height: 46px;background-color: transparent;color:var(--white);font-weight: 600;transition: 0.3s ease;font-size: 16px;width:100%;margin-bottom: 20px;}
.cust-cancel-btn.ant-btn:hover, .cust-cancel-btn.ant-btn:focus, .cust-cancel-btn.ant-btn:active{border: 1px solid #CCC3FF;border-radius: 5px;height: 46px;background-color: transparent;color: var(--white);font-weight: 600;transition: 0.3s ease;font-size: 16px;}
/* button styles end */
/*Kendo start*/
.k-grid-header .k-header > .k-link,.commision-fee-custtable .k-link,.fee-discount-table thead th,.table-border th{font-size: 16px;font-weight: 700;color: var(--gridtitle);}
.k-grid td,.commision-fee-custtable td{font-size: 14px;font-weight: 400;}
/* .ant-form-item-label > label{color: var(--subText);font-weight: 400;font-size: 14px;margin-bottom: 2px !important;text-transform: capitalize;} */
h1.sub-heading {text-transform: capitalize;padding-left:4px; padding-right:4px;font-size:18px;font-weight:500;margin-top: 10px;color:var(--textWhite);margin-bottom: 26px !important;}
h1.heading{font-size: 24px;font-weight: 600;color:var(--textWhite);margin-bottom: 16px;padding-left:4px; padding-right:4px;}
.pop-btn, .confirm-btn{height: 46px;margin-bottom: 20px;}
.sidebar-push{padding:30px 16px 24px;}
.k-grid-header .k-header > .k-link{padding: 8px;margin: 0;}
.k-pager-numbers .k-link.k-state-selected,.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected ,.k-list .k-item.k-state-selected:hover,.k-list-optionlabel.k-state-selected:hover{background-color: #3653E4;color: #ffffff !important;}
.k-pager-numbers .k-link{color: var(--white);}
.k-button, .k-textbox, .k-pager-numbers .k-link, .k-pager-numbers .k-link.k-state-selected{border-radius: 30px;}
.k-i-sort-asc-sm::before, .k-i-sort-desc-sm::before,.k-i-arrow-60-left::before,.k-i-arrow-60-right::before, .k-i-arrow-end-left::before,.k-i-arrow-end-right::before{color: var(--white);}
.k-button.k-primary.excel-btn{background: #3653E4; color: #fff; border-color: #3653E4;padding: 10px 24px;font-weight: 500;text-transform: uppercase;}
.k-button.k-primary{background: #3653E4; color: #fff; border-color: #3653E4;padding: 4px 24px;font-weight: 500;text-transform: uppercase;}
.k-button.k-primary:active, .k-button.k-primary:hover{background: #7087FF; color: #fff; border-color: #7087FF;}
.k-grid thead{}.k-i-more-vertical.k-icon{width:20px; height: 20px; background-position: -350px -1px; transform: scale(0.9);margin-top: 10px;}
.k-grouping-row .k-i-collapse::before, .k-grouping-row .k-i-expand::before, .k-i-more-vertical:before{display: none;}
.ant-picker-suffix{color: var(--textWhite);}
.k-pager-sizes .k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap, .k-pager-sizes .k-dropdown .k-dropdown-wrap:hover{background: var(--bgDarkGrey);color: var(--textWhite);}
.k-pager-nav.k-link:hover{background-color: var(--drawerList);border-radius: 50%;}
.fiatdep-info .anticon-copy, .fiatdep-info .anticon-check{font-size: 16px;}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{background: transparent !important; border: 0;}
.k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer{color: var(--textWhite) !important;background-color: transparent !important;}
.k-grid-content, .k-grid{background-color: transparent !important;color: var(--textWhite) !important;}
.k-grid-content{margin-right: -12px;}
.k-grid-header{padding-right: 0;}
.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover,.markets-grid tbody tr:hover,.fee-discount-table tbody, .table-border tbody{background-color: var( --drawerList) !important;}
.k-pager-wrap{color: var(--white);background-color: transparent;}
.k-actions.k-actions-stretched .k-button.k-button-md.k-button-solid.k-button-solid-base.k-rounded-md{background-color:#E8E8E8 !important ;border-radius: 30px;}
.k-actions.k-actions-stretched .k-button-solid-primary.k-disabled{background-color:#3653E4 !important ;border-radius: 30px;border:transparent;color:#000;text-transform: uppercase;font-weight: 500;}
.k-actions.k-actions-stretched .k-button-solid-primary{background-color:#3653E4 !important ;border-radius: 30px;border:transparent;color:#000;text-transform: uppercase;font-weight: 500;}
/*Kendo End*/
/*Modal start*/
.ant-modal-content{border-radius: 24px;}
.ant-modal-header{border-radius: 24px 24px 0 0;padding: 16px 24px;border-bottom:1px solid #4E505A !important;}
.ant-modal-header, .ant-modal-content{background: var(--drawerBG);}
.ant-modal-title{font-size: 24px; line-height: 34px;color: var(--white);text-transform:capitalize;}
.ant-modal-body p{font-size: 16px;color: var(--white);font-weight: 400;}
.ant-modal-footer{padding:16px 24px;border-color: transparent;}
.footer-border .ant-modal-footer{border-top:1px solid #4E505A !important;}
.cust-adon .ant-input-group, .cust-adon .ant-input-group .ant-input:focus, .cust-adon .ant-input, .cust-adon .ant-input-group-addon{border: none !important;box-shadow: none;padding: 0;background: transparent;}
.cust-adon .ant-input{line-height: 45px !important;height: 47px !important;background-color: transparent !important; padding:0 1px !important;margin-bottom:0}
/* .ant-drawer{z-index: 999 !important;} */
.ant-modal-confirm-btns button:first-child{background-color: transparent;border: none;color: var(--white);}
.ant-modal-confirm-btns button:nth-child(2){background-color: var(--bgYellow) !important;border: none;color: var(--textDark);border-radius: 16px;padding: 4px 30px}
.ant-modal-confirm-btns button:first-child:hover{color: var(--textYellow);}
/* .ant-picker-input > input, .ant-form-item-label > label{color: var(--textWhite);} */
.search-bg{border: 1px solid rgba(0,0,0,.08);color: #313c46 !important;background-color: var(--gridheader) !important;padding: 15px 10px;border-radius: 20px;}
.newcust-bg{background: var(--secondary);opacity: 0.9;border: 1px solid rgba(255, 255, 255, 0.6);border-radius: 8px;}
.down-tansactions{font-weight: 700;background: transparent;margin-bottom: 6px;cursor: pointer;font-size: 16px;color: var(--termsText);}
.anticon-copy svg, .anticon-check svg{color: transparent;background: var(--lightThemeIcons) no-repeat !important; display: inline-block; vertical-align: middle;width: 26px;height: 26px;background-position: -8px -624px !important;}
/*Modal End*/

/* Dashboard start */
.new-viewpage{justify-content: flex-start;}
.price-valgreen{display: flex;align-items: center; height: 36px;padding: 4px 8px;margin-left: 16px;border-radius: 5px;}
.pg-text{color: var(--priceVal);}
.price-valred{display: flex;align-items: center; height: 36px;padding: 4px 8px;margin-left: 16px;border-radius: 5px;}
.red-text{color: var(--priceRed);}
.acount-type{padding: 8px 16px;background: var(--accountType);border-radius: 5px;height: 36px;color: var(--coinPrice);font-weight: 400;font-size: 16px;align-items: center;display: flex;margin-left: 16px;margin-bottom: 40px;}
.tlv-logo{height: 60px;padding-left: 20px;}
.user-left{margin-left: 16px !important;}
.ant-list-split .ant-list-item.cytpo-list-style{background: var(--cardBG);border-radius: 13px;margin-bottom: 10px;padding: 12px 20px !important;border-bottom:0;}
.db-titles{font-weight: 700 !important;color:var(--primary) !important;font-size: 22px !important;}
.secureDropdown .ant-dropdown-menu{background-color: var(--profileMenu);box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.35);border-radius: 10px;min-width: 239px; max-width: 239px; padding: 14px 0;}
.depwith-drpdown .ant-dropdown-menu{min-width: 173px !important; max-width: 173px !important;}
.drpdwn-list li:hover{background-color: var(--drpdwnHover);}
/* .drpdwn-list li:hover span.icon{margin-right: -3px ;} */
.secureDropdown .ant-dropdown-arrow{display:none;}
.secureDropdown .ant-dropdown-arrow{border-color: var(--bgGloom) !important;}
.drpdwn-list{list-style: none;}
.drpdwn-list li{font-size: 14px; padding: 0 22px; transition: 0.3s ease;cursor: pointer;}
.drpdwn-list li a{color: var(--menuFont);display: flex; align-items: center; padding: 10px 0 10px 10px;}
.personal-popup a{color: var(--menuFont);}
.crypto-card-design{display: flex;align-items: center;}
.custlist-align{margin-left: 26px;}
.listitems-design{border-radius: 13px;margin-bottom: 10px;padding: 14px 20px !important;display: block;width:356px;float: left;margin-right: 16px;border-bottom: 0 !important;background: var(--faitGradGreen) !important;}
.listitems-design:nth-child(even) {margin-right: 0;background: var(--faitGradeBlue)!important;}
.crypto-btn-top{margin-top: 28px;}
.dbchart-link span{color: var(--viewAll);font-size: 18px;font-weight: 500;}
.market-panel-newstyle{clear: both;}
.icon-space{margin-left: 12px;margin-bottom: 12px;}
.pipeline{color: var(--tableText);margin-right: 10px;}
.sb-card{border-radius: 20px;min-height: 248px;width: 438px;}
.sb-innercard{padding:24px;display: flex;justify-content: center;align-items: center;}
.sb-text{color: var(--sbCardText);letter-spacing: 5px;font-weight: 700;font-size: 24px;}
.sb-accounts{padding: 2px;}
.cardlogo-height{margin-bottom: 109px;}
.crypto-style{margin-top: 50px;}
.transaction-panel{margin-top: 40px;}
.transaction-title{display: flex;align-items: center;width: 100%;}
.trans-align{display: flex;align-items: center;justify-content: space-between;}
.bash-market-table{background-color: var(--cardBG);border-top-left-radius: 20px;padding-bottom:16px;border-top-right-radius: 20px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}
.ant-table-container table > thead > tr:first-child th:last-child,.table-border th:last-child{border-top-right-radius: 20px;}
.db-main-title{font-weight: 800 !important;font-size: 32px !important;color: var(--menuFont) !important;margin-bottom: 40px !important;}
.marketcap-mt{margin-top: 34px;}
.full-screenable-node.marketcap-mt{margin-top: 30px;}
.crypto-curr-align{display: flex;justify-content: space-between;align-items: center;}
.iban-list .ant-list-item-meta{display:block;}
.ts-wallet .ts-coin{font-weight: 500;font-size: 14px;color: var(--tablesub);margin-bottom: 4px;}
.ts-wallet .ts-type{font-weight: 400;font-size: 14px;color: var(--tableInner);margin: 0 !important;}
.ts-tbdate .ts-date{font-weight: 400;font-size: 14px;color: var(--tableText);margin: 0 !important;}
.crypto-btns .ant-space-item, .crypto-curr-align .ant-space-item{margin-left:4px;}
.listitems-design.iban-style .ant-list-item-meta-avatar{margin-right:0 !important;}
.listitems-design.iban-style{background: var(--cardBG) !important;}
.bash-market-table .ant-table-column-sorters .ant-table-column-title{color: var(--marketTitle);font-weight: 700;font-size: 16px;}
.bash-market-table .ant-table-column-sorters .ant-table-column-sorter{color: var(--marketTitle);}
.dashbord-space{padding-top: 0px !important;}
.ac-topspace{padding-top: 10px;}
.listitems-design.iban-style .ant-list-item-meta-content{width: 100%;height: 70px;}
.fund-transfer-input .ant-form-item-label{text-align: left;}
.ant-btn.ant-btn-block.profile-btn:focus, .ant-btn.ant-btn-block.profile-btn:active{border: 1px solid var(--green) !important;}
.coin-typo, .coin-price-style{text-align: right;}
.crypto-values{min-width: 120px;}
.mobile-list.dash-mobile-list ul li:last-child{display: none;}
.mobile-list.dash-mobile-list ul li:last-child{margin-bottom: 0}
.side-drawer-full .ant-table-cell:nth-child(2) .ant-table-column-title{width: 105px;}
.side-drawer-full .ant-table-cell:nth-child(3) .ant-table-column-title{width: 100px;}
.side-drawer-full tr th{border: none;}
.side-drawer-full tr th.ant-table-cell-scrollbar{box-shadow: none;}
.side-drawer-full .ant-table-tbody p.fs-16{line-height: normal;}
.side-drawer-full .ant-pagination-disabled .ant-pagination-item-link, .side-drawer-full .ant-pagination-disabled:hover .ant-pagination-item-link, .side-drawer-full .ant-pagination-disabled:focus-visible .ant-pagination-item-link{color: rgb(255 251 251 / 25%);background-color: transparent;}
.side-drawer-full .ant-pagination-item-active{border-color: #ffffff !important;background: transparent;}
.side-drawer-full .ant-pagination-item-active a, .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active{color: #3653E4 !important;}
.side-drawer-full .ant-table-column-title{text-transform: capitalize;font-size: 16px;font-weight: 500;}
/* .ant-table-thead th.ant-table-column-sort{background: var(--iconBg);} */
.db-titles.internal-titles{font-size: 36px !important;margin-top: 16px;}
.ant-carousel .notices-content .slick-dots-bottom{bottom: -25px !important;}
.card-text-style{text-align: right;padding: 10px 16px 0;}
.card-text-style span, .card-bottom-style span{color: var(--textWhite) !important;font-weight: 700;font-size: 16px;}
.card-bottom-style{padding: 0px 16px 16px;align-items: flex-end;}
.card-bottom-style img{width: 80px;}
.master-text{margin-bottom: 0 ;}
.cust-card-btn{margin-top: 52px;}
/* Dashboard end */

/*Buy/sell drawer start*/
.drawer-maintitle{font-weight: 800;font-size: 26px;color: var(--menuFont) !important;text-transform: capitalize;}
.crypto-list.auto-scroll.wallet-list{margin-top: 30px;}
.crypto-list .ant-list-item, .wallet-list .ant-list-item{margin-bottom: 8px;}
.search-style{color: var(--menuFont);font-weight: 600;font-size: 18px;margin-bottom: 2px !important;text-transform: capitalize;}
.buycoin-style{font-weight: 700;font-size: 16px;color: var(--coinBal);}
.coin-entryval{display: flex;align-items: center;}
.marginL{margin-left: 4px;}
.crypto-details{display: flex;justify-content: right;align-items: center;}
.buy-para-bg{border-radius: 10px;background-color: var(--cardBG);padding: 8px 28px;margin: auto;margin-top: 20px;}
/* .auto-scroll{overflow-y: auto;} */
.enter-val-container.swap-com{display: flex;align-items: center;justify-content: center;}
.inputfont-style{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size: 36px !important;}
/* .inputbg-fonts{background: var( --inputBg);height: 50px;margin-bottom: 6px;border: 1px solid var(--subText);border-radius: 5px;}
.inputbg-fonts:hover, .inputbg-fonts:focus, .inputbg-fonts.ant-input-borderless:focus{background: var( --inputBg);height: 50px;margin-bottom: 6px;border: 1px solid var(--subText);border-radius: 5px;} */
.buy-paragraph{color: var( --paraBg);font-weight: 500;font-size: 16px;text-align: center;margin-bottom: 0 !important;margin-top:4px;}
.crycoin-bal{font-weight: 400 !important;font-size: 16px !important;color: var(--coinPrice) !important;}
.pop-btn{margin-top: 16px;margin-bottom: 16px;}
.select-currency{margin-top: 50px;}
.swap-text-sub{text-align: left;font-weight: 500;font-size: 22px;color: var(--white);}
.enrty-field-style{margin-bottom: 12px;}
.sellcrypto-style{color:var(--coinPrice);font-weight: 400;font-size: 16px;}
.sellbal-style{font-weight: 700;font-size: 16px;color: var(--balInput);word-break: break-all;}
.round-pills{width: 100%;text-align: center;}
.round-pills .ant-radio-button-wrapper{background: var(--radioBg);height: 36px;border-radius: 10px;min-width: 60px; line-height: 34px;color: var(--coinPrice);border:1px solid rgba(0, 0, 0, 0.3);;font-size: 16px;font-weight: 500;margin: 0 16px 0px 0;text-align: center;}
.round-pills .ant-radio-button-wrapper-checked, .default-radio .ant-radio-button-wrapper-checked, .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{background-color: var(--radioFill) !important; color:var(--balInput) !important;font-size: 16px;font-weight:500;border: 2px solid var(--radioBorderfill);}
.round-pills .ant-radio-button-wrapper::before, .default-radio .ant-radio-button-wrapper::before{content: none;}
.val-updown{position: absolute;right: 0;top: 100px;width: 28px;height: 28px;border-radius: 10px;background-color: var(--gridborder);text-align: center;line-height: 34px;}
.val-updown:hover .swaparrow, .swap-updown:hover .swaparrow{transform: rotate(180deg);transition: 0.5s;background-position: -237px -35px;}
.buy-balance{font-weight: 400;font-size: 16px;color: var(--coinPrice);}
.crypto-amount{margin-bottom: 14px;}
.kpi-List .kpi-label, .adressbook-fiatview .kpi-label{font-weight: 500;font-size: 14px;color: var(--sbCardText);}
.kpi-List .kpi-val, .adressbook-fiatview .kpi-val{font-size: 16px;font-weight: 500;color: var(--white);word-break: break-all;}
.kpi-List .ant-col{margin-bottom: 20px;display: flex;}
.receiveank-inner{padding-bottom: 20px;}
.new-swap-design{border-radius: 5px;}
.new-swap-subtext{background: var(--swapBg);height: 62px;border-radius: 5px 5px 0 0;display: flex;align-items: center;padding: 0 12px;margin-top: 0 !important;}
.swap-entryvalue{flex: 0 0 175px;text-align: left;font-size: 26px;}
.swapcoin-alignemnt{ flex: auto;text-align: right;color: var(--sbCardText);font-size: 24px;}
.swap-currencytext{background: var(--swapvalBg);height:62px;border-radius: 0 0 5px 5px;display: flex;align-items: center;padding: 0 12px;}
.swap-custinputfield{border: 0 !important;padding: 0 !important;}
.buy-usdt-btn .pop-btn, .sell-btn-style .pop-btn{margin-top: 20px;}
.sell-radiobtn-style{flex: 0 0 236px;}
.selection-currency-list{margin-bottom: 50px;}
.drawer-title-style{display: flex;align-items: center;justify-content:center;margin-bottom: 50px;}
/* .selctcoin-style{margin-bottom: 50px;} */
.drawer-crypto-bg{margin-right: 21px;}
.label-style.drawer-subtextstyle{font-weight: 400;font-size: 16px;color: var(--paraBg);}
/* .crypto-coin-mbspace,.swap-custinputfield{margin-bottom: 10px;} */
.crypto-coin-mtspace{display: inline-block;}
/*Buy/sell drawer End*/
/* receive fait start */
.drawer-subtext{font-weight: 700;font-size: 18px;color: var(--tablesub);}
.fait-title{font-weight: 500 !important;font-size: 14px !important;color: var(--subText) !important;}
.fait-subtext{display: block !important;font-size: 16px !important;font-weight: 500 !important;color: var(--white) !important;}
.fait-box{margin-bottom: 20px;}
.fait-maintext{font-weight: 700;font-size: 18px;color: var(--tablesub);margin-bottom: 16px !important;margin-top: 41px;}
.refer-text{font-weight: 400;font-size: 16px;color: var(--tableText);}
.walletadrs{font-weight: 700;font-size: 16px;color: var(--coinPrice);}
.import-note{background-color: var(--cardBG);width: auto;border-radius: 10px;padding: 24px 70px 24px 24px;color: var(--tablesub);font-weight: 400;font-size: 20px;margin-top: 38px;}
.imp-note{font-weight: 700;font-size: 20px;color: var(--tablesub);}
.custom-display{margin-left: 12px;}
.cust-tabs-fait .ant-tabs-tab + .ant-tabs-tab{margin: 0 0 0 0px;}
.cust-tabs-fait .ant-tabs-nav{margin: 0 auto;justify-content: center;margin-bottom: 26px;width: 480px;}
.form-marginB{margin-bottom: 0;}
.send-drawerbtn.tabs-innertabs{margin: 16px 0 40px;}
.swap-entryvalue.send-crypto-style{margin-top: 0;}
.coin-name-mb{margin-bottom: 0;}
/* width: 350px; */
/* .cust-tabs-fait .ant-tabs-nav{width: 350px;margin: 0 auto;justify-content: center;margin-bottom: 26px;} */
/* receive fait end */
/* receive crypto start */
.rec-bottom{margin-bottom: 18px !important;}
.recive-subtext{font-weight: 400;font-size: 16px;color: var(--sbCardText);margin-bottom: 16px;}
.scanner-img{width: 170px; height: 170px; margin: 35px auto 40px; padding: 10px;background-color: var(--bgWhite);border-radius: 6px;}
.recive-lable{font-weight: 400;font-size: 16px;color: var(--tableText);}
.recive-copy{font-weight: 700;font-size: 16px;color: var(--coinPrice);margin-top: 12px;margin-bottom: 30px;}
.recive-text, .recive-text .list-dot{font-weight: 400;font-size: 14px;color: var(--tableText);}
.recive-runtext{font-weight: 400;font-size: 14px;color: var(--tableText);}
.list-dot{list-style: initial;margin-bottom: 10px !important;}
.recive-share{margin-top: 30px;font-size: 16px;font-weight: 500;color: var(--tableText);margin-bottom: 18px;}
.network-display{display: flex;align-items: center;justify-content: center;}
.ant-btn:focus, .ant-btn:active{box-shadow: none !important;}
.ant-btn-loading-icon:focus, .ant-btn-loading-icon:active{box-shadow: none !important;}
.ant-btn-loading:before, .ant-btn-loading-icon:after{background: none;box-shadow: none;}
.pop-validate-btn .ant-btn-loading-icon:after{background: none;box-shadow: none;}
.ant-btn::after + .ant-btn:focus  {background: none;box-shadow: none;}
.ant-btn::after:focus{background: none;box-shadow: none;}
.img-upld:hover{background-color: #F1F2F6;}
/* receive Crypto end */
/*Send Address start*/
.thank-subtext{font-size: 16px;font-weight: 400;color:#ffffff;}
.adbook-head{font-weight: 700 !important;font-size: 18px !important;color: var(--checkText) !important;margin-bottom: 18px !important;margin-top: 30px;}
.fund-border{margin-top: 15px;background: var(--addressList);margin-right: 12px;border: 2px solid  var(--addressList);border-radius: 13px;padding: 22px;cursor: pointer !important;align-items: center !important;}
.fund-border:first-child{margin-top: 0;}
.fund-border:hover{background-color: var(--radioFill);border: 2px solid var(--radioBorderfill);}
.fund-circle{width: 44px;height: 44px;border-radius: 50%;text-align: center;padding: 11px;background-color: var(--borderColor);margin-right: 12px;}
.address-name{font-weight: 700;font-size: 16px;color: var(--coinBal);cursor: pointer;}
.address-subtext{font-weight: 700;font-size: 12px;color: var(--coinPrice);}
.addCryptoList{list-style: none; padding-left: 0;max-height: 436px;}
.adbs-paste-recepients{padding-top: 30px;}
.addCryptoList li{padding:10px; color: var(--textWhite);border-bottom: 1px solid var(--borderLight);font-size: 16px;}
.addCryptoList li:hover{background-color:var(--bgBlu); cursor: pointer;}
.sub-abovesearch{color: var(--subText) !important;font-weight: 400 !important;font-size: 14px !important;margin-bottom: 6px !important;text-transform: capitalize;}
.code-lbl.text-upper-remove{color: var(--subText) !important;font-weight: 400 !important;font-size: 14px !important;margin-bottom: 6px !important;}
.uplaod-inner{color: var(--white) !important;}
.upload .ant-upload-text{font-weight: 600;color: var(--white) !important;font-size: 16px;}
.imprt-bg{
    /* background-color: var(--cardBG);padding: 24px;border-radius: 10px; */
    margin-bottom: 16px;}
.ab-note-style{font-weight: 700;font-size: 16px;color: var(--gridcontent);}
.note-cont{font-weight: 400;font-size: 16px;color: var(--gridcontent);}
.addressbook-grid .cust-tabs-fait .ant-tabs-nav{width: 100%;}
.custom-alert-width{padding: 28px 22px;background-color:var(--addressList) !important;border-radius: 13px;color: var(--textWhite);}
.kpi-List, .adressbook-fiatview{margin-top: 16px;}
.custom-alert-width .pop-btn{width:auto;}
.adbook-newdesign{width: 100%;}
.file-label.doc-def{margin-right: 12px;}
.transacton-drpdwn .ant-dropdown-menu{max-width: 170px !important;min-width: 200px !important;}
.cust-start-style{font-size: 20px;color: var(--subText);font-weight: 400;margin-top: 6px;margin-left: 2px;}
/* .font-size-align */
.abbook-title-mt{margin-top: 16px;}
/*Send Address End*/
/* --Payments Start--*/
.pay-grid thead th{padding: 8px;width: 250px; color: var(--textWhite30);font-weight: 500;text-align: left;font-size: 16px;}
.pay-grid thead th:last-child{width: 500px;}
.pay-grid tbody td{padding: 8px; color: var(--menuFont);font-weight: 500;font-size: 14px ;}
.ant-table-placeholder:hover, .ant-table-placeholder:hover .ant-table-cell{background: #2a2a2a26 !important;}
.pay-grid {width: 100%;}
/* .ant-popover-inner{background-color: var(--bgGloom); border-radius: 10px;} */
/* . .ant-popover */
.pay-list{display: flex;justify-content: space-between;align-items: center;}
.ant-table-placeholder:hover{background: transparent;}
/* .ant-popover-inner{background-color: var(--bgGloom); border-radius: 10px;} */
.more-popover .lbl{font-size: 12px; color: var(--textWhite50);font-weight: 100;}
.more-popover .val{font-size: 14px; color: var(--textWhite30);display: block;margin-bottom: 16px; line-height: normal;}
.ant-popover-arrow{border-color: var(--bgGloom) !important;}
.payment-docs .accordian .ant-collapse-content-box, .payment-docs .accordian .ant-collapse-item{background-color: var(--bgGrey);border-radius: 24px;}
.payment-docs .accordian .ant-collapse-item:last-child > .ant-collapse-content{background-color: var(--bgGrey);border-radius: 30px;}
.file-label{border: 1px solid var(--borderColor);border-radius: 6px;padding: 2px 8px;color: var(--viewAll) !important;background-color: var(--bgDarkGrey);width:115px;margin-bottom: 5px;}
.delete-btn{color: var(--textWhite30);background-color: transparent !important;font-size: 14px;border: none;}
.delete-btn:hover{background-color: transparent !important; color:white;border:none;}
.delete-btn:focus{border:none !important;}
.ant-modal-footer .delete-btn{padding: 0 30px;}
.pay-list{display: flex;justify-content: space-between;align-items: center;}
.transaction-custom-table{background: var(--cardBG);border-radius: 20px;padding-top: 12px;padding-bottom: 12px;margin-top: 10px;}
.transaction-custom-table .pay-grid tbody td{border: 0;}
.transaction-custom-table .pay-grid tr{border-bottom: 1px solid var(--tableborder);}
.transaction-custom-table .pay-grid tr:last-child{border-bottom: 0;}
.transaction-custom-table .pay-grid thead tr, .bash-market-table .pay-grid tbody tr td{border-bottom: 1px solid var(--tableborder) !important;}
/* .transaction-custom-table .pay-grid thead tr th:first-child{padding-left: 30px;} */
/* .transaction-custom-table .pay-grid tbody tr td:first-child{padding-left: 30px;} */
.bash-market-table .pay-grid thead  tr th{border-bottom: 0;}
.bash-market-table .pay-grid tbody tr:last-child td, .bash-market-table .pay-grid tbody tr:first-child td{border-bottom: 0 !important;}
.detail-popbtn{width: auto;margin-top: 16px;}
.paynow-btn-ml{margin-left: 12px;}
.total-align{padding: 8px; color: var(--menuFont);}
.amt-field.icons-display{display: flex;align-items: center;}
.delete-btn.delete-disable, .btn-upload-style{margin-left: 12px;}
.ant-popover-inner-content .iban-hov:hover{background-color: var(--bgDark);}
.ant-popover-arrow{background: transparent !important;border-color: transparent;}
.ant-popover-arrow{border-color: var(--profileMenu) !important;}
.ant-popover-inner{background-color: var(--profileMenu); border-radius: 10px;}
.ant-popover-inner-content{padding: 10px 0px !important;}
.ant-popover-inner{padding: 8px 9px;}
.ant-popover-inner-content .rarrow-white{margin-left: 0px!important;}
.more-popover.payments-card, .more-popover.payments-card:hover{background-color:transparent !important;}
.ant-popover{z-index: 9999;}
.popover-mb-12{margin-bottom: 12px;}
.attach-iconalign{margin-top: 12px;margin-left: 8px;}
.amt-field .form-margin-bottom{margin-bottom: 0;}
.docdetails.pay-docdetails{margin-left: 0;width: 100px;}
.cust-cancel-btn.pay-cust-btn{margin-top: 16px;}
.dashb-btmtable,.mobile-list{margin-top: 10px;}
.cust-pop-up-btn.crypto-pop .detail-popbtn{width: 100px;}
.des-mr{margin-right: 12px;}
.view-level-btn .cust-cancel-btn{width: 136px;margin-top: 16px;}
.docdetails.doc-view{margin-left: 0 !important;}
.detail-popbtn.paynow-btn-ml{width:136px;}
.text-wrap-style{max-width: 100%;min-width: 149px;}
.wrap-text-width{max-width: 100%;min-width: 129px;}
.gridLink.grid-level-style{display: block;}
.alert-info-custom.kpi-List.adbook-newdesign, .docfile.address-book-viewlevel{background-color:var(--coinviewradio) !important;}
.error-style{margin-top: 16px;}
/* --Payments End--*/

/*--Zoho start--*/
.zsiq_flt_rel{width: 50px !important; height: 50px !important;bottom: 8px;right: -10px;}
.zsiq_theme1 .siqico-chat:before{font-size: 20px !important; line-height: 50px !important;}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-radio-disabled + span{color: var(--textWhite30);}

/*--Zoho end--*/

/* summary start */
.cust-summary-new{background: var(--drawerList);border-radius: 13px;padding-left: 20px;padding-right: 20px;padding-bottom: 20px;padding-top: 10px;}
.pay-list:last-child{border:0;padding-bottom: 0;}
.pay-list{padding: 12px 0;}
.cust-coin-value{font-weight: 500;font-size: 45px;text-align: center;color: var(--white);}
.cust-summary-text{text-align: left;margin-top: 16px;}
.text-style{font-weight: 400 !important;font-size: 16px !important;color:var(--checksub) !important;margin-top: 16px !important;}
.terms-link{color:var(--termsText) !important;font-weight: 700;}
.faitcurrency-style{font-weight: 700;font-size: 16px;color: var(--white);text-align: center;margin-bottom: 30px;}
.summary-liststyle{font-weight: 700;font-size: 16px;color: var(--coinBal);text-transform: capitalize;}
.summarybal{font-weight: 400;font-size: 16px;color:var(--coinBal);word-break: break-all;}
.cust-agreecheck{font-weight: 400;font-size: 14px;color: var( --checkText);}
.agree-check label{height: 34px;margin-right: 14px;}
.agree-check input[type=checkbox]{display: none;}
.agree-check input[type=checkbox]+span{background: var(--lightThemeIcons);background-position:-115px -513px;transform:scale(1.5);display: inline-block;width: 34px;height: 34px;vertical-align: text-top;cursor: pointer;}
.agree-check input[type=checkbox]:checked+span{background: var(--lightThemeIcons);background-position:-153px -513px;border-color: transparent !important;}
.agree-check input[type=checkbox]+span:hover{background-position:-190.5px -513px;}
.agree-check input[type=checkbox]:checked+span:hover{background-position:-153px -513px;}
.pay-list{padding: 16px 0; border-bottom: 1px solid var(--borderColor);}
/* summary end */

/* success start */
.cryptosummary-container{max-height: calc(100vh - 0px);}
.success-title{font-weight: 800 !important;font-size: 26px !important;color: var(--white) !important;}
.successsubtext{font-weight: 500;font-size: 16px;color: var(--coinPrice) !important;}
.custom-declaraton{
    /* height: calc(100vh - 225px); */
    align-items: center;display: flex;justify-content: center;}
.pop-cancel span{color:var(--textYellow);text-transform: uppercase;font-weight: 700;}
.custom-declaraton .declaration-content{width: 600px;}
.side-drawer .side-drawer-header{align-items: flex-start;}
.cust-summary-new.kpi-List.sendfiat-summarystyle{margin-top: 0 !important;}
.new-add .loading-btn + .btn-clickable-style{position: unset !important;}
.addCryptoList.adbook-scroll.paste-recept-style .success-title.oops-title{margin-bottom: 0;}
.addCryptoList.adbook-scroll.paste-recept-style{height: 210px;}
/* success end */

/* crypto-coins */
.crypto-icon{ height: 44px; width:44px;}
  .crypto-icon{background: var(--coinBg) url(/static/media/cryptoicons.22681f6d.svg)  no-repeat; display: inline-block;border-radius: 100%;}
  .crypto-icon.BTC{background-position:-56px -6px; }
  .crypto-icon.ETH{background-position: -106px -6px;}
  .crypto-icon.USDT{background-position:-208px -6px;}
  .crypto-icon.USDC{background-position: -157px -6px;}
  .dash-mobile-list .ant-list-item-meta{align-items: center;}
/* crypto-coins end   */
/* send fiat start */
.fund-transfer-input .tfunds-inputgroup .ant-select{width: 122px;}
/* .fund-transfer-input .tfunds-inputgroup .ant-select-selection-item{} */
.alert-info-custom{background-color:var(--addressList) !important;border-radius: 13px;color: var(--textWhite);padding: 20px 22px;}
.kpi-divstyle{border-bottom:1px solid var(--borderColor);padding-bottom: 20px;width: 100%;}
.custom-forminput.custom-label{margin-bottom: 16px;}
.upload-btn-mt{margin-top: 16px;}
.cust-tabs-fait .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color: var(--tabLine) !important;font-size: 16px;font-weight: 700;}
.cust-tabs-fait  .ant-tabs-ink-bar{background: var(--tabLine);}
.ant-upload-drag.upload{border-radius: 10px !important;background-color: var(--upload) !important;border-color:var( --borderColor) !important;}
.upload .ant-upload{padding: 30px 0 !important;}
.cust-tabs-fait .ant-tabs-tab .ant-tabs-tab-btn{color: var(--sbCardText)!important;font-size: 16px;font-weight: 400;}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{border-bottom: 1px solid var(--inputBorder);}
.cust-tabs-fait .ant-tabs-tab{padding-left: 10px;padding-right: 10px;}
.pop-validate-btn{width:150px;margin-top:30px; height:42px;}
.verify-btn-from, .crypto-summaryform{margin-top: 36px;margin-bottom: 18px;}
.small-text-align{margin-left: 8px;}
.docfile{background-color:var(--addressList) !important;border-radius: 20px;padding: 16px;display: flex;align-items: center;margin: 12px 6px 4px 0;flex: 1 0 240px;}
.docfilecrypto{background-color:var(--coinviewradio) !important;border-radius: 20px;padding: 16px;display: flex;align-items: center;margin: 12px 6px 4px 0;flex: 1 0 240px;}
.docdetails{flex: 1 1; margin-left: 12px;}
.cust-upload-sy{margin-bottom: 20px !important;}
.docdetails .docname{font-weight: 700;color: var(--textWhite30); line-height: 16px;max-width: 150px !important;}
.docfile-container{display: flex; flex-wrap: wrap;}
.upload-filesize{font-weight: 700;font-size: 16px;color: var(--coinPrice);}
.cust-networkstyle{background-color: var(--profiletab);padding: 10px 20px;border-radius: 10px;color: #FFFFFF;font-weight: 700;font-size: 16px;}
.swap-fontsize{color: #ffffff;font-weight: 700;font-size: 16px;}
.network{background-color: var(--drpdwnHover);padding: 10px 20px;border-radius: 10px;margin: 0 12px}
.network .swap-fontsize{color: var(--white);}
.custom-height {max-height: calc(100vh - 926px);}
.nodata-text{margin-top: 10px !important;display: inline-block;color:var(--termsText);}
.nodata-text:hover{color:var(--termsText);}
.cust-input.change-space .ant-input{height: 34px !important;}
.nodata-image{margin-bottom: 10px;}
.custom-upload{ margin: 12px 0px 4px 0;}
.send-fiatform .ant-alert-with-description{margin-top: 20px;}
.cust-input.iban-input{border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;border-right: 0 !important;}
.iban-validate{border-left-color: var(--inputBg)!important; height: 50px !important;border-top-left-radius: 0 !important;border-bottom-left-radius: 0 !important;border-top: 1px solid var(--labelBorder) !important;border-right: 1px solid var(--labelBorder) !important;border-bottom: 1px solid var(--labelBorder) !important;background: var(--inputBg) !important;margin-top: 26px !important;}
.iban-validate span{border-left: 1px solid var(--white) !important;color: var(--termsText);font-size: 16px;font-weight: 700;padding-left: 20px !important;display: inline-block;}
.iban-validate::after{box-shadow: transparent !important;}
.iban-validate{width: 186px;margin-right: 0 !important;}
.validateiban-content:hover input,.validateiban-content:hover button{border-color: #CCC3FF !important;}
.slick-slider.docreq-slider.slick-initialized .slick-dots li.slick-active button{background: var(--cardBG) !important;color: var(--primary);}
.docreq-slider .slick-dots li button{color: var(--secondary);}
.cases-lefttext::before{border-color: 1px solid #ccc;}
.ant-form-item-explain.ant-form-item-explain-error, .ant-form-item-explain.ant-form-item-explain-validating{color: var(--red);}
/* send fiat end */
/* send crypto start */
.min-btn{border: 1px solid rgba(0, 0, 0, 0.3);background: var(--radioBg) !important;height: 36px;border-radius: 10px;min-width: 60px;line-height: 30px;color: var(--coinPrice);font-size: 16px;font-weight: 500; margin: 0 10px 10px 0;}
.min-btn:active ,.min-btn:focus{background-color: var(--radioFill) !important;border: 2px solid var(--radioBorderfill) !important;font-size: 16px;font-weight: 500 !important;color: var(--coinPrice) !important;}
.min-btn:hover{border-color: rgba(0, 0, 0, 0.3) !important;color: var(--coinPrice) !important;}
.newtransfer-card{background: var(--drawerList);border-radius: 13px;border: 2px solid transparent;height: 90px;padding: 10px 43px !important;font-size: 16px; font-weight: 700; color: var(--coinBal);width: 100%;text-align: center;}
.newtransfer-card:hover,.newtransfer-card:active{background-color: var(--radioFill) !important;border: 2px solid var(--radioBorderfill);color: var(--coinPrice) !important;}
.newtransfer-card::before{ background: var(--drawerList) !important;}
.addcrypto-whitelist{margin-bottom: 45px;}
.sub-title{font-weight: 700 !important; font-size: 18px !important;margin-bottom: 26px !important; color: var(--white) !important;}
.addcrypto-benficiary .ant-form-item{margin-bottom: 16px;}
.upload-empty-div{margin-top: -6px;}
.hy-align, .code-tooltip-align{margin:auto 0; display: flex;align-items: center;}
.send-crypto-enrty{border-radius:5px;margin-bottom: 12px;}
.transfer-designstyle{margin-top: 60px;}
/* .inputfont-style::placeholder{font-size: 20px;} */
.new-swap-subtext.send-crypto-enrty{width:97%;margin:0 auto;}
/* send crypto end */

/* crypto summary start */
/* .crypto-summaryform{padding-top: 32px;} */
.get-code{ text-align: center;border: 1px solid var(--borderLight);color: var(--textWhite30);font-size: 16px;height: 50px;background: var( --inputBg);}
.cust-input.custom-add-select{margin-bottom: 0 !important; border-radius: 0 !important; border-top-left-radius: 5px !important;border-bottom-left-radius: 5px !important; border-right: transparent;}
.new-add{padding: 14px;border-bottom-right-radius: 5px; border-top-right-radius: 5px; border-left: transparent;}
.cust-input[disabled]{background: var( --inputBg);color: var(--textSecondary);}
.new-add button{border-left: 1px solid var(--white) !important; padding-left: 20px; }
.new-add button:hover{border-left: 1px solid var(--white) !important;}
.cust-input.custom-add-select:hover{border-color: var(--subText);}
.custom-send.sell-btc-btn span{color: #ffffff;}
.new-add button span{color: var(--termsText); font-size: 16px; font-weight: 700;}
.new-add .check-ml-align{margin-left: 4px;margin-bottom:4px;}
.verification-text{color: var(--white) !important;font-weight: 400;  font-size: 14px;}
.sendfiat-summarystyle .kpi-divstyle{padding-top: 20px;}
.sendfiat-summarystyle{padding-top: 0 !important;}

/* crypto summary end  */
/* validations */
.ibanborder-field .ant-input-group .ant-input{border:0;border-right: 1px solid  var(--white);}
.ibanborder-field .ant-input-group .ant-input-group-addon{border: 0;}
.ibanborder-field .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-primary{border: 0;}
.ibanborder-field .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-primary span{color: var(--termsText);font-size: 16px;font-weight: 700;}
.cust-input.ibanborder-field{border-top-right-radius: 5 !important;border-bottom-right-radius: 5 !important;border-right: 1 !important;}
.custom-add-select.mb-0.ibanborder-field .new-add{padding: 0 !important;}
.custom-add-select.mb-0.ibanborder-field .ant-input{border-right: 0;}
.custom-add-select.mb-0.ibanborder-field{border-right: 1px solid var(--labelBorder);border-radius: 5px !important;}
.batch-title-align{margin-left:26px;}
/* crypto sucess start  */
.success-title{font-weight: 800; font-size: 26px; color: var(--white);}
.success-content{font-weight: 500;font-size: 16px;line-height: 180%; color: var(--white);opacity: 0.8;}
/* .send-crypto-sumry .d-flex.agree-check{margin-top: 150px;} */
.ant-checkbox.ant-checkbox-checked{border: none !important;}
.ant-custumcheck .ant-checkbox-checked , label:hover .ant-checkbox-checked, .ant-checkbox-checked::after{ border-color:transparent !important;}
/* crypto success end  */

/* send crypto addressbook start */
/* .batchpayment-summary, .batch-style{margin-bottom: 16px !important;} */
.batchpayment-summary{justify-content: space-between;align-items: center;}
.admin-actions.address-icons li{margin-left: 20px;}
.sub-title.address-book{ margin: 36px 0 16px 0 !important;}
.box{overflow: hidden; margin-bottom: 28px;}
.adressbook-style{margin-bottom: 16px;}
.admin-actions{display: flex;padding: 0;list-style: none;margin-bottom: 0px;}
/* .admin-actions li{margin-right: 16px;} */
.admin-actions li:last-child{margin-right: 0;}
.addressbook-mb{margin-bottom: 0 !important;font-weight: 800 !important;font-size: 28px !important;color: var(--white) !important;}
.new-custom-radiobtn, .new-custom-radiobtn .ant-radio-button-wrapper, .new-custom-radiobtn .ant-radio-button.ant-radio-button-checked, .new-custom-radiobtn .ant-radio-button-wrapper:first-child, .new-custom-radiobtn .ant-radio-button-wrapper:not(:first-child)::before, .new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){background: none;border: 0;}
.new-custom-radiobtn .ant-radio-button-wrapper-checked, .new-custom-radiobtn .ant-radio-button-wrapper{font-weight: 500;font-size: 16px;color: var( --radiocheccolor);text-transform: capitalize;}
.new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{color: var( --radiocheccolor);border-color: transparent;}
.new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before{background-color: transparent;}
.new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){border-color: transparent;}
.new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{box-shadow:none;}
.new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{color: var( --radiocheccolor);}
.addCryptoList.adbook-scroll .declaration-content .successsubtext{height:50px !important;}
.search-space{padding-left: 0!important;padding-right: 0!important;}
/* send crypto addressbook end */

/* transactions start  */
.grid-title{font-weight: 800 !important;font-size: 28px !important;color: var(--white) !important;margin-bottom: 24px !important;}
.cust-list .k-grid{border-radius: 20px;}
.cust-list{padding-top: 0 !important;}
.cust-list .k-grid-header-wrap,.markets-drawer .ant-table-header,.fee-discount-table thead tr{border-top-left-radius: 20px;border-top-right-radius: 20px;background-color: var(--gridheader);}
.ant-select-selection-item{font-weight: 500;font-size: 14px;color: var(--white) !important;}
.filter-content{align-items:center}
.pop-btn.search-btn{margin: 22px 0 0 12px; height: 48px; width: 148px;}
.ant-picker-dropdown{z-index: 9999;}
.excel-button{margin-top: 20px;}
/* .k-grid-table,.ant-table-tbody{background-color: var(--tablebody);} */
.k-grid td,.markets-grid td,.fee-withdraw td,.fee-discount-table td{border-right: 1px solid var(--gridborder) !important;border-bottom: 1px solid var(--gridborder) !important; color: var(--gridcontent);}
.table-border td{border-right: 1px solid var(--gridborder) !important;border-bottom: 1px solid var(--gridborder) !important; color: var(--gridcontent);}
.k-grid th,.markets-grid th,.fee-discount-table th{border-right: 1px solid  var(--tableborder) !important;}
.custom-gridresponsive .ant-drawer-header,.custom-gridresponsive .ant-drawer-body{background: var(--bodyBg) !important;}
.k-pager-wrap.k-pager.k-widget.k-grid-pager{background: var(--gridheader);border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}
.k-pager-numbers .k-link:hover{background-color: #3653E4;color: #ffffff !important;}
/* .k-grid th,.k-grid td{padding: 8px 20px;} */
.k-grid th:last-child,.k-grid td:last-child,.markets-grid td:last-child,.markets-grid th:last-child,.fee-discount-table th:last-child{border-right-color: transparent !important;}
.cust-input.cust-adon .ant-input-wrapper.ant-input-group{padding: 0 11px;}
.icon.md.search-angle{background-position:-360.5px -488px; margin-left: 8px;}
.custom-model-label .ant-form-item-label > label{height: 20px;}
.marketcap-coinsize .ant-table-cell img{width:18px !important;height:18px !important;}
.btn-right{float:right;}
/* transactions end  */
/* audit logs start  */
.addressbook-grid .custom-checkbox input{display: none;}
.addressbook-content .search-bg{margin-bottom: 24px;}
.k-pager-wrap .k-link{background-color: transparent;}
.k-pager-numbers-wrap .k-pager-numbers.k-reset li{margin: 0 2px !important;}
.ant-picker:hover{border-color: #CCC3FF;}
/* audit logs end  */
/* coin detail view start*/
.coin-details{background-color: var(--addressList);border-radius: 24px;padding: 30px;}
.coin-titlestyle{color: var(--coinPrice) !important;font-size: 24px !important;font-weight: 700 !important;}
.coin-date{display: block;text-align: right;color: var(--coindate);font-weight: 400;font-size: 10px;}
.coin-green-ml{margin-left: 8px;}
.bank-referrel-color{background-color: #5c61ff; padding: 6px 12px !important;border-radius: 5px;color: white; display: flex;grid-gap: 8px;gap: 8px;align-items: center;font-weight: bold;}
.sell-title-styels{display: flex;justify-content: center;}
.sell-title-styels .ant-image{margin-right: 12px;}
.sell-title-styels .ant-image .ant-image-img{border: 4px solid var(--coinBg);background-color:var(--coinBg);border-radius: 50%;}
.ant-input-group-addon .ant-select.currecny-drpdwn{margin: -5px -11px -11px 0px;}
.currecny-drpdwn .ant-select-arrow{top: 22px;}
/* coin detail view end */
/*Auth0 start*/
.text-hover:hover{color:#40a9ff;}
.cust-phone .ant-input-group input{height:48px !important;margin-top: 0;}
.cust-phone .ant-input-group-addon{border-right:0 !important;}
.cust-phone{margin-bottom: 1px;}
.cust-phone .cust-input{margin-bottom:0 !important;}
.pa-alert{background-color: transparent;border:none;margin-top: -40px;}
.pa-alert .ant-alert-message,.mb-alert .ant-alert-message{font-size: 14px !important;}
.field-width{width:150px;}
/* .mb-alert{background-color: transparent;border:none;padding-left: 0;} */

.cust-input.Approved .ant-select-selector{cursor:pointer !important;}
.ant-select-selection-search-input{color:#fff !important;}
.width-inc{width:188px !important;}
.text-spacedec{width: 62px;display: flex;margin: auto;cursor: pointer;}
.form-block{width: 900px;position: relative;z-index: 25;padding: 54px 64px;border-radius: 20px;background: var(--cardBG);margin: auto}
.text-personal{font-weight: 700;font-size: 18px;color:var(--termsText)}
.form-block .heading {font-size: 25px;font-weight: 800;color: var(--textWhite);margin-bottom: 24px;line-height: 28px;}
.form-block .new-custom-radiobtn .ant-radio-button-wrapper:first-child{padding-left: 0 !important;}
.mb-24{margin-bottom: 24px;}
.regulartext-color{font-size: 16px;color: rgb(255 255 255 / 80%);font-weight: 500;line-height: 180%;}
.mobile-verification{width: 660px !important;margin: 0 auto;}
.my-24{margin-top: 24px;margin-bottom: 24px;}
.custom-forminput.form-arrowicon .cust-input .ant-select-arrow{background-position: -347px -5px;}
.custom-forminput .cust-input  .ant-input-group-addon{border: 0;border-right:1px solid var(--labelBorder);}
.custom-forminput .ant-select-selection-item{font-size: 16px;}
#busssinessForm_phoneNumber, #persionalAccount_phoneNumber{border: 0;}
.form-arrowicon .ant-select-selection-item, .form-arrowicon .ant-select-selection-item, .form-arrowicon .ant-select-selection-placeholder{color: #bfbfbf;}
.policy-content {margin-top: 54px;margin-bottom: 45px;}
.terms-text{font-weight: 400;font-size: 14px;color: var(--checkText);margin-left: 12px;}
.blue-color{color:var(--termsText);text-decoration: none;font-weight: 700;}
.text-personal:hover{color: #1890ff !important;}
.form-arrowicon .ant-select-selector{width: 80px;}
.phone-he{    height: 50px !important;
    margin-top: 0;
    margin-bottom: 0;}
    .custom-forminput .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{text-align: left !important;}
.px-0{padding-left: 0 !important;padding-right: 0 !important;}
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
} */
.flex-1{flex:1 1;}
/*Auth0 End*/
/* coin view start */
.trade-graph .ant-radio-button-wrapper{background-color: var(--coinviewradio);border-color: var(--coinviewradio) !important;}
.trade-graph .ant-radio-button-wrapper:first-child{border-radius: 8px 0 0 8px;}
.trade-graph .ant-radio-button-wrapper:last-child{border-radius: 0 8px 8px 0;}
.trade-graph .ant-radio-button-wrapper-checked{background-color: var(--coinviewradio);border-color:var(--coinviewradio) !important;}
.trade-graph .ant-radio-button-wrapper:not(:first-child)::before{content: none;}
/* .trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{background: none;} */
.trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{color: var(--white);border-color: var(--coinviewradio);}
/* .trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){background: none;color: var(--textDark) !important;} */
.trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){color: var(--white);font-size: 14px;font-weight: 700;}
.trade-graph .ant-radio-button-wrapper{color: var(--marketTitle);font-size: 14px;font-weight: 500;}
.coin-viewstyle {font-size: 28px !important;font-weight: 800 !important;color: var(--white);margin-bottom: 36px;text-transform: capitalize;}
.summary-count{margin-left: 12px;}
.summary-count .coin-balstyle, .coin-name{font-size: 24px;font-weight: 800;color: var(--white);margin-bottom: 0;}
.coin-name{margin-left:8px;}
.active span{color:var(--white) !important}
.coin-subbalval{color: var(--marketTitle);font-size: 14px;font-weight: 500;}
.value-up, .value-down{margin-left: 16px;}
.cust-tabs-fait.coinview-tabs .ant-tabs-nav{width: 100%;}
.coin-bal ul li div{width: 40px;height: 40px;border-radius: 50%;background-color:#3653E4;margin: 0 auto 6px;line-height: 38px;box-shadow: 0 3px 16px rgba(0, 0, 0, 0.15);}
.coin-bal ul li div:hover{background-color:#7087FF;}
.walletsdrawer .mobile-list{margin: 0;}
.wallet-head{padding: 8px 16px; background-color: var(--bgDarkGrey);border-radius: 10px;margin-bottom: 6px;}
.crypto-wallets .ant-list-item-meta-content{width: auto;}
.minmax{ position: relative;top: -75px;right: 13px;}
.coin-fs{font-weight: 700;font-size: 16px;color: var(--white);}
.coin-details-spin{display: flex;align-items: center;justify-content: center;min-height: 400px;}
.coin-info{padding: 16px 0;border-top: 1px solid var(--coinViewborder);font-size: 16px;display: flex;justify-content: space-between;}
.backarrow-mr{margin-right: 16px;}
.back-btnarrow{font-size: 20px;font-weight: 500;color: var(--white);}
.backbtn-arrowmb{margin-bottom: 12px;}
.billpaycoin-style .coin-viewstyle{margin-bottom: 0 !important;}
.billpaycoin-style{display: flex;align-items: center;}
.bill-grid .custom-checkbox input{display: none;}
.ant-checkbox-checked::after, .ant-checkbox-checked .ant-checkbox-inner::after{display: none;}
.pop-btn.custmodel-pop{margin-top: 0;}
.addicon-content{font-size: 16px;color: #FFFFFF;font-weight: 600;margin-right: 12px;}
.coin-bal ul{ margin-top: 16px !important; text-align: center;}
.graph{border-radius: 20px;margin-bottom: 16px;background-color:var(--addressList);border-color:var(--addressList);padding: 30px;}
.graph .graph-title{border-bottom: 1px solid var(--addressList);padding: 16px;}
.graph .graph-body{min-height: 430px;padding: 8px;}
.db-container{padding: 0;}
.db-kpi{background-color: var(--addressList);border-radius: 20px;padding: 16px 24px;margin-bottom: 16px;min-height: 112px;}
.db-kpi-label{font-size: 14px;color: var(--sbCardText);display: block;font-weight: 500;}
.db-kpi-val{font-size: 24px;color:var(--white);font-weight: 500;line-height: 34px;margin: 0 16px 3px 0;display: block;}
.db-kpi .badge{background-color:var(--gridheader);padding: 3px 10px;font-size: 12px;font-weight: 500;color: #D13F00;border-radius: 16px;vertical-align: text-bottom;display: inline-block;}
.db-kpi .icon-bg{width: 48px;height: 48px;border-radius: 8px;background-color: var(--bgDarkGrey);margin-right: 20px;text-align: center; line-height: 48px;}
.db-kpi.vthicon{display: flex;align-items: center;min-height: 112px;}
.custom-radiobtn.coin-alldetail{margin-top: 16px;margin-bottom: 16px;}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger){margin-left: 12px;}
.inputfont-style:hover, .inputfont-style:focus{border: 0;}
.icon.danger-alert{background-position:-329px -242px;width:36px;height:36px;}
.icon.danger-alert input{display:none;}
/* coin view end */
/* expand search */
.pay-grid .ant-table-body{height: 736px;}
.search-box {position:relative;height: 46px;padding: 10px;flex:300px 0;display: flex;align-items: center;}
.search-box:hover > .search-text{width: 240px;padding: 6px;border-radius: 0px 10px 10px 0px;border-left: 0;}
.search-box:focus, .search-box:hover{border:1px solid var(--subText);border-radius: 10px;margin-left: 10px; margin-right: 6px;}
.search-btnexpand {position: absolute;left:0 ;z-index:1;transition: 0.4s;cursor: pointer;text-decoration: none;}
.search-text {border: none;background: none;outline: none;padding: 0;color: white;font-size: 16px;font-weight: normal;transition: 0.4s;line-height: 26px;width: 0px;margin-left:29px;}
.search-text::placeholder{color: var(--placeholders);}
/* expand search end */
/* cases */
.case-lbl{font-weight: 500; font-size:14px; color: var(--sbCardText);}
.case-val{color:var(--white) !important;font-size: 16px;font-weight:500;}
.requested{background-color: #3653E4;}
.gridLink{color: var(--viewAll);}
td .gridLink{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 8px 12px;}
.gridLink{cursor: pointer;display: inline-block;}
.pay-list.modal-listbr{border-bottom: 1px solid var(--borderColor);padding: 16px 0px;}
.ant-modal{top:36%;}
.accordian.ant-collapse > .ant-collapse-item{border-bottom: 0;}
.accordian.ant-collapse{background: none;border: none;margin-bottom: 10px;}
.accordian.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{border-radius: 20px;}
.accordian .ant-collapse-item:last-child > .ant-collapse-content{border-color: var(--borderGrey);background-color: var(--addressList);border: 0;}
.accordian .ant-collapse-header{border-radius: 20px !important;font-size: 18px;font-weight: 600;background-color:var(--scroll);color: var(--textWhite30) !important;padding: 16px 20px 16px 24px !important;text-transform: capitalize;}
.accordian{border-color: var(--bgOrion) !important;border-radius: 24px;}
.accordian .ant-collapse-header .icon.downangle{position: relative;margin-right: 34px !important;}
.accordian .ant-collapse-header .icon.downangle::after{content: '';position: absolute; right: -16px; top: 0; border-right: 1px solid var(--borderGrey);height: 20px;}
.accordian .ant-collapse-item.ant-collapse-item-active .ant-collapse-header{border-radius: 20px 20px 0 0 !important;}
.accordian .ant-collapse-arrow{vertical-align: text-bottom !important;}
.accordian .ant-collapse-item.ant-collapse-item-active .downangle{background-position: -329px 0;}
.accordian .ant-collapse-content-box{padding: 20px;background-color: var(--addressList);border-radius: 0 0 24px 24px;border-bottom: 1px solid var(--drawerBG);}
.accordian .ant-collapse-item:last-child > .ant-collapse-content{border-radius: 0 0 24px 24px;}
.ant-empty-description{color: var(--white);text-transform: capitalize;}
.case-ribbon{background-color:var(--addressList);padding: 20px;border-radius: 16px;}
.faitview-btn{margin-top: 36px;}
.cases-lefttext{margin-left: 16px;}
.case-stripe, .case-remarksstyle{border: 1px dashed var(--borderColor); padding: 16px 20px; border-radius: 16px; margin-bottom: 16px}
.ribbon-item{display: flex; align-items: center;margin-bottom: 16px;border-right: 1px solid var(--borderColor);}
.case-val.cases-subtext{word-break: break-all; padding-right: 12px;}
.ant-upload-hint.upload-text{color: var(--sbCardText) !important;font-size: 14px !important;}
/* .notify-alerticon .ant-alert-icon{margin: 0px 15px 18px 0px !important;} */
.btn-textstyle{display: block !important;color: var(--viewAll) !important;font-size: 16px;font-weight: 500;}
.file-sizestyle{font-weight: 500;font-size: 14px;color: var(--sbCardText);}
.case-remarksstyle{margin-top: 10px;}
.case-lbl.remark-casestyle{word-break: break-all;}
.reply-date{color: var(--coindate);font-weight: 400;font-size: 12px;}
.reply-container{display: flex;margin-bottom: 24px;}
.reply-container .user-shortname{width: 46px; height: 46px; border-radius: 50%;background-color: #3653E4;margin-right: 16px;color: #FFFFFF;font-size: 18px;text-align: center;line-height: 46px;font-weight: 500;text-transform: uppercase;}
.reply-body{flex: 1 1;}
.reply-txt{font-size: 14px;color: var(--marketTitle);font-weight: 500;margin-bottom: 0;word-break: break-all;}
.reply-username{color: var(--white) !important;font-size: 16px;font-weight: 500;display: inline-block;margin-right: 10px;}
.send-circle{background: var(--bgYellow); width: 40px;height: 40px; line-height: 40px; text-align: center;border-radius: 50%;}
.chat-send{display: flex;border: 1px solid var(--borderLight); padding:12px; border-radius: 20px; justify-content: space-between; align-items: center;margin-bottom: 16px;}
.chat-send input{border:0; flex: 1 1;font-size: 16px;color: var(--textWhite30);font-weight: 200;}
.chat-send input:focus{outline: none;}
/* cases */
/* notifications */
.buybg.mb-0{margin-bottom: 0 !important;}
.notifications-list .ant-list-item{padding:16px 0; border-bottom: 1px solid var(--borderColor) !important;}
.notifications-list .ant-list-item-meta-avatar{border-radius: 50%;width: 38px;height: 38px;text-align: center;line-height: 38px;}
.notifications-list .ant-list-item-meta-title{margin-bottom: 0 !important;}
.notifications-list .ant-list-item-meta{align-items: center;}

.approvebg .ant-list-item-meta-avatar, .depositbg .ant-list-item-meta-avatar, .buybg .ant-list-item-meta-avatar, .deposit.fiatbg .ant-list-item-meta-avatar, .deposit.cryptobg .ant-list-item-meta-avatar, .paymentsbg .ant-list-item-meta-avatar{background-color: var(--bgDarkGreen);}
.withdrawbg .ant-list-item-meta-avatar, .depositsbg .ant-list-item-meta-avatar, .sellbg .ant-list-item-meta-avatar, .withdraw.fiatbg .ant-list-item-meta-avatar, .withdraw.cryptobg .ant-list-item-meta-avatar{background-color: var(--bgDarkRed);}
.requestbg .ant-list-item-meta-avatar, .documentsbg .ant-list-item-meta-avatar, .cutomersbg .ant-list-item-meta-avatar, .casesbg .ant-list-item-meta-avatar{background-color: var(--bgBlue);}
.ant-alert-with-description{width: 100%; align-items:center;}
/* batchpayments start  */
.download-excel a{color: var(--termsText);}
.pay-list .file-name{width: 250px;word-break: break-all;}
.icon.md.back{margin-right: 8px;}
.preview-back{margin-right: 8px;}
.buysell-balances{text-align: center;margin: 0 0 20px 0 !important;}
.faitwallet-cards .ant-list-items li:nth-child(n+3){display: none;}
.cust-cancel-btn.back-btn-sumsub{margin-top: 12px !important;}
/* .header-right .ant-menu-title-content:hover,.header-right .ant-typography.custom-header:hover, .header-right .ant-typography.custom-header:active, .ant-menu-item.ant-menu-item-active{color:var(--white);} */
/* .header-right .ant-menu-title-content{color:var(--coinPrice)!important;}
.header-right .ant-menu-title-content .custom-header{color:var(--textWhite30) !important;}
.header-right .ant-menu-title-content .custom-inactive{color:var(--coinPrice) !important;}
.header-right .ant-menu-title-content.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child{color: var(--white);}
.header-right .ant-menu-title-content.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child .custom-header{color: var(--white);} */
/* .custom-header{color:var(--coinPrice) !important;} */
/* ul .mobile-header-default:active span{color:var(--white) !important} */
/* .custom-inactive{color:var(--sbCardText) !important} */
.sumsub-content{margin: 10px 0;}
.icon.md.refresh-icon{background-position: -271px -33px;}
.cust-cancel-btn.pop-space{margin-right: 8px;margin-bottom: 0;}
.markets-drawer .ant-drawer-wrapper-body{background: var(--bodyBg) url(/static/media/dark-bg2.0.0e1c9286.svg) no-repeat top center !important;}
.markets-grid tr:last-child td:first-child{border-bottom-left-radius: 20px;}
.markets-grid tr:last-child td:last-child{border-bottom-right-radius: 20px;}
.markets-grid .ant-table-tbody{border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}
.markets-grid .side-drawer-header{padding: 0 16px 0 0;}
.header-space{margin-bottom: 0 !important;}
.basicinfo.batch-style{margin-bottom: 18px !important;}
.bill-payment.bill-margin{margin-bottom: 10px !important;}
/* batchpayments end */
.no-transaction{padding-top: 18px !important;}
.search-box .ant-input, .search-box .ant-input:focus,.search-box .ant-input:hover{color: var(--textWhite); border: 0;}
.ant-btn.dbchart-link{padding-left: 0px;padding-right: 0px; margin-right: 8px;background-color: transparent;border: 0;}
.main-container.grid-error{padding: 0 16px;}
.fait-subtext .ant-typography-copy,.custom-copy .ant-typography-copy{transform: scale(0.8);}
.fait-subtext .ant-typography-copy svg,.custom-copy .ant-typography-copy svg{margin-bottom: 5px;}
.main-container.grid-demo{padding-top: 0;}
.cust-list.main-container.case-demo{padding-bottom: 0;}
.cust-pop-up-btn.ad-popup button:nth-child(2){margin-left: 0;}
.kpi-divstyle.ad-rec-detyails{border-bottom: transparent;}
/* .kpi-val.adview-name{width: 225px;} */
/* .batchpayment-summary ~.ant-alert-with-description {width: 97% !important;margin-left: 20px;} */
.preview-grid .cust-list,.box.text-white .cust-list{padding: 0;}
.Supporting-Documents{margin-top: 30px;}
.icon.md.Batch{background-position: -738px -37px;}
.notkyc a{color:var(--viewAll);}
.thank-you-btn{margin-top: 40px;}
.proceed-section{margin-bottom: 34px;}
.custom-declaraton.send-success{margin-top: 40px;}
.custom-declaraton.send-success button,.success-pop.sell-success{margin-top: 26px;}
.transaction-custom-table .pay-docs.bill-bank{text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 50%;}
.cust-cancel-btn.transaction-cancelbtn{margin-left: 0 !important;}
.value-description{font-weight: 400;color:var(--marketTitle);font-size: 14px;}
.icon.xl.video{background-position: -241px -241px;}
.kpi-val.adview-name{width: 230px;}
.input-disabled-style .ant-select-selection-item{color: var(--textSecondary);}
.travel-success{margin-top: 65px;}
.dashbord-space .notices-content{margin-bottom: 36px;}.db-transactions thead,.fee-discount-table thead,.table-border thead{ background-color: var(--grid) !important;}.db-transactions{padding-top: 0;margin-top: 14px;height: 505px;overflow-y: auto;}.db-transactions::-webkit-scrollbar{display: none;}.db-transactions thead tr:first-child{border-bottom: none !important;}
.download-btn{background-color: #3653E4; border-color: #3653E4;border-radius: 5px;font-weight: 600;transition: 0.3s ease; font-size: 16px; color: #FFFFFF;    padding: 10px 28px;   display: inline-block;    margin-left: 16px;}
.validateiban-content:hover .ant-form-item-has-error .cust-input{border-color: var(--red) !important;}
.crypto-list.auto-scroll.wallet-list .drawer-list-fiat .ant-image{vertical-align: middle;}
.preview-file{color: var(--white);}
.documentmodal-width .ant-spin.ant-spin-spinning{background: var(--drawerBG);}
.new-add.hy-align .text-yellow.pr-24.align-check{vertical-align: middle;margin-right: 3px;}
.align-declaration .declaration-content{margin-top: 50px;}
.batch-upload .docnames{text-overflow: ellipsis; overflow: hidden;}
.grid-content{color: var(--gridcontent);}
.commission-text{color: var(--termsText);margin-bottom: 0;}
.icon.lg.suissebase-credit{background-position: -213px -555px;}
.user-labels.active .icon.lg.suissebase-credit, .user-labels:hover .icon.lg.suissebase-credit, .ant-tabs-tab.ant-tabs-tab-active .icon.lg.suissebase-credit, .ant-tabs-tab:hover .icon.lg.suissebase-credit{background-position: -213px -589px;}
.Effective-Fees{color: var(--termsText);cursor: pointer;}
.icon.lg.down-arrow{background-position: -389px -486px;margin-left: 4px;}
.icon.lg.up-arrow{background-position: -411px -486px;margin-left: 4px;}
.icon.lg.backup-icon{background-position:-288px -552px;}
.user-labels.active .icon.lg.backup-icon, .user-labels:hover .icon.lg.backup-icon, .ant-tabs-tab.ant-tabs-tab-active .icon.lg.backup-icon, .ant-tabs-tab:hover .icon.lg.backup-icon{background-position: -288px -585px;}
/* commissions  start   */
.first-header th{text-align: center !important;}
.fee-withdraw{padding: 0 !important;border-right: 1px solid var(--gridborder) !important;}
.fee-withdraw table tr td:last-child,.fee-discount-table tr td:last-child,.table-border td:last-child{border-right: transparent !important;}
.fee-withdraw tr:last-child td,.fee-discount-table tr:last-child td,.table-border tr:last-child td{border-bottom: transparent !important;}
.fee-withdraw:last-child{border: transparent !important;}
.cust-tr-style th{text-align: center !important;}
.fee-table{height: -moz-fit-content;height: fit-content;padding-bottom: 0;}
.fee-discount-table .ant-table-cell,.table-border th{padding: 8px 12px;}
.fee-discount-table .ant-table-thead > tr:first-child th{border-bottom:1px solid var(--gridborder) !important;}
.fee-table th:first-child{border-top-left-radius: 20px;}
.fee-table th:last-child{border-top-right-radius: 20px;}
.view-commition-table,.view-commition-table label{color: var(--gridcontent);}
.view-commition-table{width: 100%;}
.table-border tr .row-border:not(:first-child) tr{border-top:1px solid var(--gridborder);}
.inside-table tr td{ border-right: 1px solid rgba(0, 0, 0, 0.08);}
.table-border-right{border-right: 1px solid rgba(0, 0, 0, 0.08);}
.table-border{border: transparent;}
.table-border td{padding: 8px 12px;}
.table-border thead{background-color: rgb(114 114 114 / 40%) !important;}
table tr:last-child td:first-child{border-bottom-left-radius: 20px;}
table tr:last-child td:last-child{border-bottom-right-radius: 20px;}
.icon.commission-toggle{background-position: -256px -555px;height: 24px;width: 24px;cursor: pointer;position: absolute;
    right: 10px;z-index: 1;}
.icon.commission-toggle:hover{background-position: -256px -589px;}
.summarybal.total-amount{padding: 12px;border-radius: 12px;font-weight: 600;font-size: 18px;text-align: center;}
.minmax-value{vertical-align: middle;font-weight: 700;}
.crypto-details.crypto-bal{align-items: start;}
.display-items.moblie-order .radio-grp{flex: 170px 0;}
.fee-volume{display: flex;align-items: center;grid-gap: 6px;gap: 6px;}
.customised-input{margin-bottom: 0;}
.swap-entryvalue.send-crypto-style .ant-form-item-explain.ant-form-item-explain-error, .swap-entryvalue.send-crypto-style .ant-form-item-explain.ant-form-item-explain-validating{width: 100%;}
.swap-entryvalue.send-crypto-style .ant-form-item-control-input {min-height: 120px;}
.swap-entryvalue.send-crypto-style .ant-form-item-control {display: flex;flex-direction: inherit;}
/* commissions  end */
.video-block{padding: 8px;border: 1px solid var(--gridborder);}
.video-section{border: 1px solid var(--gridborder);border-radius: 6px;}
.video-section p{padding: 8px;background-color: var(--gridheader);}
.icon.lg.backup-icon{background-position:-288px -552px;}
.icon.lg.accountsummary{background-position: -351px -554px;}
.user-labels.active .icon.lg.backup-icon, .user-labels:hover .icon.lg.backup-icon, .ant-tabs-tab.ant-tabs-tab-active .icon.lg.backup-icon, .ant-tabs-tab:hover .icon.lg.backup-icon{background-position: -288px -585px;}
.btn.lightbox-231763449585064,.submit-button{background-color: var(--profiletab) !important;border-radius: 8px;border-color: var(--profiletab) !important;}
.icon.lg.bank-letter{background-position: -321px -552px;}
.jt-title{background-color: rgb(191 17 49) !important;}
.submit-button{background-color: #1890ff !important;}
.justify-content-center{justify-content: center;}
.inactive-badge{display: inline-block;margin-left: 10px;}
.cust-badge-style .file-label.add-lbl{width: auto;}
.cust-badge-style .file-labels{margin-left: 8px;font-size: 12px;}
.cust-badge-style{align-items: center;}
.fee-personal-badge .file-label.add-lbl{display: inline;margin-left: 8px}
.maxbuy-amount .ant-typography.buy-paragraph{display: inline-block; margin: 0; width: -moz-fit-content; width: fit-content;}
.align-start{align-items: start !important;}
.more-dropdown{margin-top: 4px;display: inline-block;}
.marketplace-list.listitems-design{margin-right: 0 !important;}
.align-start{align-items: start !important;}
.more-dropdown{margin-top: 4px;display: inline-block;}
.summary-download{min-width: 64px;}
.minh-38{min-height: 38px;}
.mt-36{margin-top: 36px;}
.screen-height{height:50px;clear: both;}
.mt-16{margin-top: 16px;}
main.ant-layout-content.sidebar-push {    min-height: calc(100vh - 220px);}
.menu-text{display: flex;align-items: center;justify-content: space-between;color: var(--textPureWhite);}
.menu-text span{color: var(--textPureWhite);}
.marketplace-list{min-height: 179px;}
.marketcap-coinsize .ant-table-body{height: 1050px;}
.z-10{z-index: 10;}
.gap30{grid-gap: 30px;gap: 30px;}
.marketplace-value{color: var(--white) !important;font-weight: 600;}
.min-h-value{min-height: 44px;}
.coming-soon span{color: #fff;background:#5b4ae5; padding: 4px 12px;border-radius: 14px;font-weight: 600;}
.coming-soon .sepa-bg{background-color: #5b5b5c;font-size: 14px;}
.coming-soon:has(> .sepa-bg) {display: inline-block;margin-left: 10px;}
.fs-16:has(> .coin-style){font-size: 16px;font-weight: 600;line-height: normal;}
.coin-style.mb-10{margin-bottom: 10px;}
.rounded-full{border-radius: 50%;width: 45px !important;}
.sb-card div:first-child{padding-top: 14px;align-items: center !important;grid-gap: 16px;gap: 16px;}
.mt-87{margin-top: 87px;}
.megamenu-title{font-size: 18px;font-weight: 600;display: inline-block;color: var(--primary) !important;margin-bottom:8px;}
/* .appmenu-list {border: 1px solid var(--gridborder);border-bottom: 1px solid var(--gridborder) !important;background-color: var(--cardBG) !important;} */
.appmenu-list {display: flex;flex-wrap: wrap;padding: 0;grid-gap: 8px;gap: 8px;}
.appmenu-list li {text-align: center;list-style: none;padding: 8px;display: flex;align-items: center;}
.appmenu-list li a{font-size: 18px;color: var(--coinBal);}
.appmenu-list img{width: 46px;}
.icon.lg.refdownload{background-position: -624px -33px; margin: 5px;}
.bank-referrel-color .loader-antd{padding: 0 !important;margin: 5px;}
.bg-wallester{background: var(--faitGradeBlue)!important;}
.mb-0{margin-bottom: 0;}
.pix-qr{width: 218px;height: 218px;}
.suissebasefait-newbtn{display: flex;flex-wrap: wrap;grid-gap: 16px;gap: 16px;align-items: center;}
.suissebasefait-newbtn{display: flex;flex-wrap: wrap;grid-gap: 16px;gap: 16px;}
.suissebasefait-newbtn .custom-btn.sec{margin-left: 0;}
.self-sign{height: 475px;position: relative; border-radius: 20px;}
.self-sign .loader-antd{position: absolute;width: 100%;height: 100%;padding-top: 100px;background: #000000ad;border-radius: 20px;}
.self-sign .save-sign{position: absolute;width: 100%;height: 100%;padding-top: 150px; z-index: 9999; inset: 0;}
/*@media start*/
.custom-fund-buttons.iban-list .ant-list-items .marketplace-list:last-child{background: var(--faitGradGreen) !important;}
.custom-fund-buttons.iban-list .ant-list-items .marketplace-list:first-child{background: var(--faitGradeBlue) !important;}
.fait-subtext.cust-copy-icon{word-break: break-all;}
.crypto-anylytics-note .sub-abovesearch{text-transform: none;}
@media (min-width: 768px){
    .appmenu-list li {width: 32%;}
    .search-bg.history-crypto-cards{height: 100%;}
    .sb-card{width: 438px;}
    .flex-1 .ant-form-item-label > label::after{right: 0 !important;}
    .listitems-design{width: 340px;}
    .marketplace-list.listitems-design{width: 438px;min-height: 248px;position: relative;}
    .tlv-header{height: 90px !important;}
    .header-logo{padding-left: 0 !important;}
    .visible-mobile{padding-left: 16px !important;}
    .agree-check input[type=checkbox]+span{background-position: -115px -519px;}
    .agree-check input[type=checkbox]:checked+span:hover{background-position: -153px -519px;}
    .agree-check input[type=checkbox]:checked+span:hover{background-position:-153px -519px;}
    .agree-check input[type=checkbox]:checked+span{background-position:-153px -519px;}
    .agree-check input[type=checkbox]+span:hover{background-position: -190.5px -519px;}
    .text-style{margin-top: 16px !important;}
    /* .successsubtext{height: 400px;} */
    .round-pills .ant-radio-button-wrapper{line-height: 30px;}
    /* .select-currency{height: 400px;} */
    .val-updown{right: 20px;top:45px;}
    .import-note{width: auto;}
    .side-drawer .ant-drawer-header{padding:12px 30px 0px 30px !important}
    .inputfont-style{font-size:36px;}
    .buy-paragraph{width: 300px;margin: auto;}
    .buy-paragraph.buy-para-wd{width: auto !important;}
    .crypto-card .ant-card-body{padding: 16px 14px;height: 94px;}
    .custom-radiobtn .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{padding:10px 36px;}
    .custom-radiobtn .ant-radio-button-wrapper{padding: 0 15px;}
    .basicprofile-info{width: auto;}
    .moble-mb-style{margin-bottom: 16px;}
    .arrow-ml{margin-left: 12px;}

}
@media (min-width: 992px){
    .appmenu-list li {width: 16%;}
    .grid-gap-16 .ant-list-items{grid-gap: 16px !important;display: flex;}
    .profile-alert-style{width: 510px !important;align-items: center;}
    .bill-payment{display: flex; align-items: center; justify-content: space-between;margin-bottom: 36px;}
    .visible-mobile, .sideropen{display: none;}
    .bill-grid .k-grid-header table{width: 1266px !important;}
    .bill-grid .k-grid-content table{width: 1266px !important;}
    .side-drawer .ant-drawer-content-wrapper{width: 524px !important;}
    /* .buycoin-style{font-size: 50px;} */
    .cryptosummary-container{max-height: calc(100vh - 76px);}
    /* .successsubtext{height:230px;} */
    /* .auto-scroll{overflow-y: auto;padding-right: 10px;} */
    .agree-check input[type=checkbox]+span {background-position: -115px -513px;}
    .agree-check input[type=checkbox]+span{background: var(--lightThemeIcons);background-position:-115px -513px;transform:scale(1.5);display: inline-block;width: 34px;height: 34px;vertical-align: text-top;cursor: pointer;}
    .agree-check input[type=checkbox]:checked+span{background: var(--lightThemeIcons);background-position:-153px -513px;border-color: transparent !important;}
    .agree-check input[type=checkbox]+span:hover{background-position:-190.5px -513px;}
    .agree-check input[type=checkbox]:checked+span:hover{background-position:-153px -513px;}
    /* .successsubtext{height: auto;} */
    .text-style{margin: 30px 0 !important;}
    /* .select-currency {height: 122px;} */
    /* .text-style{height: 140px;} */
    /* .full-screenable-node{overflow: auto;} */
    .val-updown{top: 45px;right: 18px;}
    .enter-val-container.swap-com{margin-bottom: 4px;}
    .import-note{width: 463px;}
    .tlv-header{max-width: 1200px;margin: auto;padding: 12px 0 !important;align-items: center;}
    .basicprofile-info{width: 510px;}
    .swap-entryvalue{flex: 0 0 305px;}
    .swap-text-sub{margin-top: -4px;}
    .new-swap-subtext.send-crypto-enrty{margin-bottom: 24px;}
    .summary-liststyle{margin-right: 26px;}
    .search-box.mobile-search{display: none;}
    .suissebasefait-newbtn{margin-top: 75px;}
    .full-screenable-node.marketcap-mt .db-titles,.full-screenable-node.marketcap-mt .icon-space{margin-bottom: 0 !important;}
    .markets-panel.transaction-panel .db-titles, .markets-panel.transaction-panel .icon-space, .fait-wallets-style .db-titles{margin-bottom: 0;}
    .marketcap-mt .db-titles.crypto-style{margin-bottom:0}
    .full-screenable-node.marketcap-mt, .transaction-panel{margin-top:30px;}
.suissebasefait-newbtn{margin-top:36px;}
.crypto-style{margin-top:36px}
.fait-wallets-style{margin-top:36px;}
.notices-style .db-titles{margin-bottom:16px;}
.fait-wallets-style.notices-style, .full-screenable-node.marketcap-mt{margin-top: -6px;}
.addressbook-crypto-view .ad-rec-detyails{margin-right: 24PX;}
.cust-tabs-fait .ant-tabs-nav{width: 100%;}
}

@media (min-width: 1024px){

}
@media (min-width: 1200px){
    .coinveiw-newpage{width: 900px;margin:0 auto ;}
    /* .sb-card{width: 482px;} */
    .iban-list .iban-style{width: auto !important;float: unset; padding: 12px !important;flex: 1 1;margin-right: 0;}
    .iban-list .ant-list-items{display: flex;grid-gap: 16px;gap: 16px;}
    .iban-list .custom-btn{min-width: 81px;}
    .main-container{max-width: 1200px; padding: 30px 16px 24px;}
    .crypto-list .ant-list-item, .wallet-list .ant-list-item{margin-top:12px;}
    .inputfont-style{font-size: 36px;}
    .custom-radiobtn .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{padding:10px 36px;}
    .custom-radiobtn .ant-radio-button-wrapper{padding: 0 15px;}
    /* .ant-popover.ant-popover-placement-left{left: 872px !important;} */
    .transctns-grid .ant-drawer-header, .transctns-grid .ant-drawer-body{padding-left: 100px;padding-right: 100px;}
    .adbook-scroll{max-height: 418px;overflow: auto;}
    .your-card{margin-top: 8px;}
    .gridLink.batch-filename{padding-left: 0;}
}
@media (min-width: 1400px){
    .video-block{flex: 421.9px 1;}
    .transctns-grid.preview-grid .ant-drawer-header .side-drawer-header,.transctns-grid.preview-grid .sub-heading,.batch-btns{max-width: 1300px; margin: 0 auto;}
    .main-container{max-width: 1300px; margin: 0 auto;}
    .iban-list .ant-list-items{display: flex;}
    .listitems-design{width:376px;}
    /* .marketplace-list.listitems-design{width: 305px;} */
    .listitems-design.iban-style{width: 242px;}
    .side-drawer .ant-drawer-body{padding: 0 30px 16px 30px;}
    /* .marketplace-list.listitems-design{width: 305px;} */
    .agree-check{height: 100px;}
    .travel-custcheck{height: auto;}
    /* .successsubtext{height: calc(100vh - 592px);} */
    .custom-height {max-height: calc(100vh - 926px);}
    /* .select-currency {height: calc(100vh - 590px);} */
    .iban-list .ant-list-items{display: 238px;}
    /* .full-screenable-node{width: 430px;} */
    .crypto-list .ant-list-item, .wallet-list .ant-list-item{margin-top: 16px;}
    .inputfont-style{font-size: 36px;}
    .side-drawer .ant-drawer-content-wrapper{width: 524px !important;}
    .val-updown{top:45px;right: 20px;}
    .iban-list .iban-style{padding: 14px 20px;}
    .addCryptoList{max-height: 590px;}
    .addCryptoList .xxl-fs{font-size:14px !important;}
    .addCryptoList .xxl-fs-16{font-size:16px !important;}
    .tlv-header{max-width: 1300px; }
    .cust-col-design{flex: 0 0 482px;}
    .db-flex-style{flex:1 1;}
    .adbook-scroll{max-height: 610px;}

}
@media (max-width: 991.98px){
    .price-valred{margin-left: 0;}
    .visible-mobile .ant-tabs-top > .ant-tabs-nav::before{border-bottom: 0 !important;}
    .visible-mobile .user-list .ant-tabs-nav{width: auto;}
    .visible-mobile{padding-left: 8px;padding-right: 24px;}
    .tlv-header .sideropen {background: var(--profileMenu) !important;}
    .sideropen{margin-left: -16px;padding: 12px;margin-top: 14px;border-radius: 0;min-height: 100vh;
        /* border-top-right-radius: 6px;border-bottom-right-radius: 6px; */
    }
    .sideropen .ant-menu-vertical,.ant-menu-vertical{border-right: 0 !important;}
    .sideropen .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{background-color: transparent;}
    .sideropen .list-item.ant-menu-item-selected .ant-menu-title-content{min-width: 150px !important; padding: 8px 5px 8px 5px !important}
    .login-user{width: 100%;padding: 2px 0;}
    .video-block .text-white{text-align: center;}
}
@media (max-width: 767.98px){
    .drawer-list-font.cust-draw-ellipsis{width: 100px;text-overflow: ellipsis;overflow: hidden;}
    .sm-d-none{display: none !important ;}
    .coming-soon:has(> .sepa-bg) {margin-left: 0px;margin: 8px 0;}
    .topupcard-btns .ant-dropdown-trigger{flex: 1 1;text-align: right;}
    .topupcard-btns .custom-btn.sec{margin-left: 0;}
    .crypto-btns.topupcard-btns{flex-wrap: wrap;grid-gap: 16px;gap: 16px;}
    .mb-wallester{margin-bottom: 16px;}
    .appmenu-list li{flex: 45% 1;}
    .marketplace-list{min-height: 240px;}
    .sb-card{width: 100% !important;}
    .cust-col-design{min-height: auto !important;}
    .header-right ul:last-child li:last-child {line-height: 20px;}
    .video-tutorials{display: block;}
    .newSpace{display: flex; padding-top: 14px;}
    .field-width{width:120px !important;}
    .new-custom-radiobtn.mb-24 label:first-child{margin-left:-3px; margin-right:10px;}
    .flex-1 .ant-form-item-label > label::after{top:10px;right:85px;}
    .form-block {
        width: 100% !important;
        padding: 60px 20px !important;
        margin: 0 0 40px 0 !important;
    }
    .video-tutorials{display: block;}
    .sumSub-confirm{width:auto;height: auto;}
    .cust-sumsub-font{width: auto;}
    .new-viewpage .search-box{margin-left: 0;}
    .fait-wallets-style.new-viewpage{display: block !important;}
    .footer-links a{margin-right: 0px !important;}
    .sidebar-push{margin: 62px 0 0 !important;}
    .visible-mobile .side-drawer .side-drawer-header.change_password{align-items: self-start !important;}
    /* .visible-mobile{margin-right: 24px;padding-left: 24px;padding-right: 24px;} */
    .main-container{padding-left: 0 !important;padding-right: 0 !important;}
    .listitems-design{width: 344px;margin-bottom: 16px;}
    .val-updown{top: 46px;right: 18px;}
    .footer-links{text-align: center;margin: 0 10px;}
    .fund-border{padding: 12px;}
    .small-text-align{margin-left: 18px;}
    .address-name{font-size: 14px;}
    .mobile-btn-pd .ant-form-item{margin-bottom: 16px;}
    .profile-image-content{display: block;}
    .dwnl-content-style{margin-top: 16px;}
    .profile-info{margin: 0 auto;}
    .profile-img-style{margin-right: 0;}
    .basicprofile-info{width: auto;}
    .security-align{display: block;}
    .moble-mb-style{margin-bottom: 16px;}
    .arrow-ml{margin-left: 12px;}
    .crypto-address.share-width-align{width: auto;}
    .profile-value.mv-profile-value{word-break: break-all;}
    .custom-display{margin-left: 0;}
    .crypto-address.share-width-align, .adbs-mb{display: flex;}
    .coin-bal ul{padding: 0;}
    .search-box:hover > .search-text{width: 120px;}
    .sb-innercard{padding: 16px;}
    .coin-bal{display: block !important;}
    /* .kpi-divstyle:last-child{border-bottom: 0;} */
    .theme-switch{width: auto;margin-bottom: 0;}
    .walletadrs{margin-left: 30px;}
    .ant-tabs-tab + .ant-tabs-tab{margin: 0 0 0 26px;}
    .display-items{display: block;}
    .crypto-details{justify-content: center;}
    .batch-actions{margin-top: 16px;}
    /* .k-loading-image::before, .k-loading-image::after{top:60% !important;position: absolute !important;} */
    .custom-theme-btn{display: flex;}
    .search-box:hover > .search-text.search-view input,.search-box.mobile-search:hover > .search-text input{width: 300px;}
    .search-box.markets-search,.search-box.markets-search:hover > .search-text input{width: 280px;}
    .theme-text{display: none;}
    .price-valgreen div.lg-fontsize { word-break: break-all;}
    .mobile-viewall{margin-bottom: 11px;}
    .bill-payment {display: flex;justify-content: space-between;align-items: center;margin-bottom: 8px;}
    .addressbook-mb{font-size: 22px !important;}
    .gridLink.batch-filename{padding-left: 0;}
    .preview-grid .grid-title{margin-bottom: 0 !important;}
    .new-custom-radiobtn .ant-radio-button-wrapper{padding:0 2px;}
    .cust-tabs-fait .ant-tabs-nav{width: 308px;overflow: auto;}
    .ant-drawer-header{padding: 0 24px;}
    .ant-drawer-body{padding-top: 0;}
    .db-titles.your-card{margin-top: 50px;}
    .search-box.mobile-hide{display: none;}
    .search-box.mobile-search{margin: 0;}
    .cust-tabs-fait .ant-tabs-nav{width: 100%;overflow: auto;} .cust-heightmbl{height: -moz-fit-content;height: fit-content;}
    .send-minmax{margin-top: 15px;}
    .moblie-order {display: flex;flex-direction: column-reverse;margin-top: 20px;}
    .crypto-card.select.space-hide{display: none;}
    .send-crypto-mobile .swap-entryvalue {flex: 0 0 200px;}
    .send-crypto-mobile .swap-text-sub, .send-crypto-mobile .swapcoin-alignemnt{font-size: 18px;}
    .send-crypto-err .custom-forminput .ant-form-item-control .ant-form-item-explain-error, .send-crypto-err .custom-forminput .ant-form-item-control .ant-form-item-explain-validating{  position: absolute; bottom: -41px !important;}
    .crypto-btn-mobile{margin-top: 40px;}
    .adbook-mobile{margin-left: 8px;}
    .adbook-mobile.past-respnt{margin-left: 16px;}
    .mobile-scroll{max-height: -moz-fit-content !important;max-height: fit-content !important;}
    .paste-recept-style.mobile-scroll{height: -moz-fit-content !important;height: fit-content !important;}
    .checkbox-mobile{margin-top: 16px;}
    .sb-text{font-size: 18px;}
    .tlv-logo{height: 40px;}
   .transaction_resp .ant-form-item-label > label,.customaudit-select .selectcustom-input .ant-form-item-label > label{ height: auto !important;}
   .transaction_resp .ant-form-item .ant-form-item-label,.customaudit-select .selectcustom-input.ant-form-item .ant-form-item-label{ padding-bottom: 0 !important;}
   .transaction_resp .search-btn,.customaudit-select .search-btn{ float: right;}
    .ant-drawer-header{padding-bottom: 0px;}
    .moblie-order.buy-sell-mobile .sell-radiobtn-style { flex: 0 0;}
    .moblie-order.buy-sell-mobile .round-pills.sell-radiobtn-style{width: -moz-fit-content;width: fit-content;margin-top: 16px;}
    .mobile-receive-fiat{margin-left: 0px;}
    .mobile-transaction{left: 144px !important;}
    .new-custom-radiobtn .ant-radio-button-wrapper-checked, .new-custom-radiobtn .ant-radio-button-wrapper{font-size: 14px;}
    .sideropen .ant-menu-vertical > .ant-menu-item{height: 30px; line-height: 30px;}
    .sideropen .drpdwn-list li a{padding: 6px 0 6px 6px;}
    .transacton-drpdwn{position: relative;}
    .new-crypto-style .cytpo-list-style .crypto-card-design{display: block;}
    .new-crypto-style .cytpo-list-style .crypto-card-design .price-valgreen{margin-left: 0px;padding: 4px;}
    .transaction-panel, .crypto-style{margin-top: 30px;}
    .table-border td { min-width: 150px;    }
    .inputfont-style{font-size: 26px !important;}
    .sumSub-confirm{width:auto;height: auto;}
    .cust-sumsub-font{width: auto;}
    .sm-mb-16{margin-bottom: 16px;}
    .display-items.moblie-order .radio-grp{flex: 0 1;}
}
@media (min-width: 375px){@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
    /* * { scrollbar-width: thin; scrollbar-color: var(--scroll);} */
    *::-webkit-scrollbar { width: 12px;height: 10px;  }
    *::-webkit-scrollbar-thumb {background-color: var(--scroll);border-radius: 20px;}
    ::-webkit-scrollbar-corner {background: var(--scroll);}
    html, .auto-scroll, .ant-table-body, .ant-drawer-body, .mobile-list, .k-grid-content{scrollbar-color: var(--scroll);}
    .loader-antd{padding: 30px 50px;text-align: center;color: var(--white);}
    
    body, h1, h2, h3, h4, h5, h6, a, p, div, input{font-family: 'Lato', sans-serif !important;}
    .d-flex{display: flex !important;}
    .p-relative{position: relative !important;}
    .c-pointer{cursor: pointer !important;}
    .d-block{display: block !important;}
    
    .ant-spin-dot-item{background-color: var(--primary);}
    .icon.md.theme-icon{background-position: -8px -486px;margin-right: 4px;}
    .icon.sm.valupp-icon{background-position: -87px -5px;margin-left: 8px;}
    .icon.sm.valdown-icon{background-position: -108px -4px;margin-left: 8px;}
    .icon.lg.whats-app{background-position: -9px -658px;margin-right: 14px;}
    .icon.lg.mail-app{background-position: -52px -658px;}
    
    /*----Font Sizes----*/
    
    .main-head{font-size: 32px;font-weight: 800;}
    .sub-head{font-size: 22px;font-weight: 700;}
    .header-menu{font-size: 18px;font-weight: 800;}
    .header-menulist,
    .drawer-subtext,
    .coinselect-coinprice{font-size: 16px;font-weight: 400;}
    .header-menusub{font-size: 14px;font-weight: 400;}
    .drawer-head{font-size: 26px;font-weight: 800;}
    .coinselect-name,
    .coinselect-balance{font-size: 16px;font-weight: 700;}
    
    
    
    /*--Font Weights--*/
    .fw-100{font-weight: 100 !important;}
    .fw-200{font-weight: 200 !important;}
    .fw-300{font-weight: 300 !important;}
    .fw-400{font-weight: 400 !important;}
    .fw-500{font-weight: 500 !important;}
    .fw-600{font-weight: 600 !important;}
    .fw-700{font-weight: 700 !important;}
    .fw-800{font-weight: 800 !important;}
    
    /*--Alignments--*/
    .text-center{text-align: center !important;}
    .text-right{text-align: right !important;}
    .text-left{text-align: left !important;}
    .text-upper{text-transform: uppercase !important;}
    .text-captz{text-transform: capitalize !important;}
    .align-center{align-items: center !important;}
    .justify-content{justify-content: space-between;}
    .display-items{display: flex;align-items: center;justify-content: space-between;}
    
    .text-underline{text-decoration: underline !important;}
    .valign-initial{vertical-align: initial;}
    
    /*--Text Colors--*/
    .text-white{color: var(--textWhite) !important;}
    
    
    
    /*----Padding Utilities----*/
    .p-0{padding: 0 !important;}
    
    /*----Margin Utilities----*/
    .m-0{margin: 0 !important;}
    /* icons start */
    .new-custom-radiobtn .ant-radio-button-wrapper-checked .icon.lg{background-position:-324px -521px !important;}
    .new-custom-radiobtn .ant-radio-button-wrapper .icon.lg{background-position:-324px -485px;}
    .icon.md.add-icon-black{background-position: -397px -71px;}
    .icon.md.excel-export{background-position: -664px -3px;}
    .icon.lg.radio-check{background-position:-324px -521px;}
    .icon.lg.radio{background-position:-324px -485px;}
    .icon.lg.download{background-position:-234px -518px;}
    .icon.lg.search-angle{background-position:-128px -480px;}
    .icon.md.bell{background-position:-39px -486px;}
    .icon.lg.menu-bar{background-position: -97px -484px;}
    .icon.lg.search-angle.drawer-search{background-position:-295px -484px ;}
    .icon.sm.valupp-icon.pg-arrow{background-position:-10px -692px;}
    .icon.sm.valupp-icon.red-arrow{background-position:-28px -692px;}
    .icon.lg.profile-icon{background-position:-9px -556px}
    .icon.lg.security-icon{background-position:-52px -556px}
    .icon.lg.settings-icon{background-position:-173px -556px}
    .icon.lg.info-icon{background-position:-73px -330px}
    .icon.lg.logout-icon{background-position:-73px -330px}
    .icon.lg.documents-icon{background-position:-176px -329px;}
    .icon.lg.addressbook-icon{background-position: -140px -329px}
    .icon.lg.cases-icon{background-position:-210px -329px;}
    .icon.lg.referral-icon{background-position:-139px -555px;}
    .user-labels.active .icon.lg.profile-icon,.user-labels:hover .icon.lg.profile-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.profile-icon, .ant-tabs-tab:hover .icon.lg.profile-icon{background-position:-9px -590px;}
    .user-labels.active .icon.lg.security-icon, .user-labels:hover .icon.lg.security-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.security-icon,.ant-tabs-tab:hover .icon.lg.security-icon{background-position:-52px -589px}
    .user-labels.active .icon.lg.settings-icon, .user-labels:hover .icon.lg.settings-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.settings-icon,.ant-tabs-tab:hover .icon.lg.settings-icon{background-position:-173px -589px}
    .user-labels.active .icon.lg.documents-icon, .user-labels:hover .icon.lg.documents-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.documents-icon,.ant-tabs-tab:hover .icon.lg.documents-icon{background-position:-176px -363px}
    .user-labels.active .icon.lg.addressbook-icon, .user-labels:hover .icon.lg.addressbook-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.addressbook-icon,.ant-tabs-tab:hover .icon.lg.addressbook-icon{background-position:-140px -363px}
    .user-labels.active .icon.lg.cases-icon, .user-labels:hover .icon.lg.cases-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.cases-icon,.ant-tabs-tab:hover .icon.lg.cases-icon{background-position:-210px -362px;}
    .user-labels.active .icon.lg.notification-icon, .user-labels:hover .icon.lg.notification-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.notification-icon,.ant-tabs-tab:hover .icon.lg.notification-icon{background-position: -184px -134px;}
    .user-labels.active .icon.lg.referral-icon, .user-labels:hover .icon.lg.referral-icon,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.referral-icon,.ant-tabs-tab:hover .icon.lg.referral-icon{background-position:-139px -589px;}
    .user-labels.active .icon.lg.bank-letter, .user-labels:hover .icon.lg.bank-letter,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.bank-letter,.ant-tabs-tab:hover .icon.lg.bank-letter{background-position:-321px -584px;}
    .ant-tabs-tab:hover .icon.lg.accountsummary,.ant-tabs-tab.ant-tabs-tab-active .icon.lg.accountsummary{background-position: -351px -585px;}
    /* .custom-checkbox input[type=checkbox] { display: block;position: absolute;zoom: 1.5;margin: 0; opacity: 0;} */
    .cust-check-outline.custom-checkbox input[type=checkbox]:checked+span {width: 24px; height: 24px;cursor: pointer;background-position:-157px -518px !important;}
    .cust-check-outline.custom-checkbox input[type=checkbox]+span{width: 24px;height: 24px;background-position: -120px -517px !important;}
    .cust-check-outline input[type='radio'], .ant-form .cust-check-outline input[type='checkbox'], .custom-checkbox.cust-check-outline input[type='checkbox']{display: none;}
    /* .new-custom-radiobtn  input[type=radio]:checked+span{background-position:-324px -521px;} */
    /*Header start*/
    .notification-conunt .bell span{ color: white; position: absolute;right: -8px;top: -8px;width: 18px;height: 18px;background: #3653E4;border-radius: 50%;line-height: 18px;text-align: center;font-size: 10px;font-weight: 600;}
    .haead-main{background-color: var(--secondary);position: fixed; z-index: 9999;left: 0; right: 0;}
    .tlv-header ul{background-color: transparent; border-bottom: 0;}
    .tlv-header ul li{font-weight: 500;}
    .tlv-header .main-menu li{border-radius: 5px;}
    .tlv-header .main-menu li.ant-menu-item-selected, .tlv-header .main-menu li:hover{background-color: var(--primary) !important; color: var(--textWhite) !important;}
    .tlv-header li::after, .tlv-header li:hover:after{border: none !important;}
    .tlv-header ul li .cp-link{padding: 6px 0;margin-right: 8px;}
    /* .tlv-header ul li .cp-link:hover{background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2)); color: var(--textPureWhite) !important;filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));border-radius: 5px;} */
    .header-right li{color: var(--sbCardText) !important;}
    /*Header End*/
    
    /*profile css start*/
    .ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus{border-color: transparent !important;}
    .ant-alert-with-description{align-items: center;}
    .secureDropdown.export-pdf .excel-export{margin-left: 8px;}
    .user-profile{ border-radius: 50%;object-fit: cover;}
    .profile-dropdown .user-profile{width:112px;height: 112px; border-radius: 50%;display: block;margin: 0 auto;}
    .profile-dropdown {text-align: center;padding:12px 24px;}
    .profile-dropdown .drpdwn-list{padding-top: 0px; padding-left: 0; padding-right: 0; margin-left: -24px; margin-right: -24px;}
    .profile-btn, .profile-btn:hover, .profile-btn:active, .profile-btn:focus{background: transparent;border: 1px solid var(--green) !important;height: 27px;border-radius: 8px;color: var(--green);font-size: 14px;width: 127px;font-weight: 500;text-align: center;display: flex;align-items: center;padding: 6px 10px;margin-bottom: 10px;font-style: italic;}
    .profile-info{width: 96px;height: 96px;padding: 5px;border-radius: 50%;text-align: left;position: relative;background-color: var(--profilebgImg);}
    .profile-info .user-profile{width:100%;height: 100%; border-radius: 50%;padding: 6px;object-fit: cover;}
    .profile-info .img-upld{position: absolute;right: -14px;bottom: 10px;background-color:#F1F2F6;border:0;}
    .profile-info .img-upld:focus{background-color:#F1F2F6;}
    .basic-info,.contact-info{padding: 24px;}
    .dash-info.basic-info{padding: 6px !important;}
    .basicinfo{font-weight: 700 !important;font-size: 18px !important;color: var(--menuFont) !important;margin-bottom: 0 !important;}
    .basic-decs{font-weight: 500;font-size: 14px;color: var(--marketTitle);margin-top: 12px;}
    .profile-label{font-weight: 500;font-size: 14px;color: var(--marketTitle);}
    .profile-value{font-weight: 500;font-size: 16px;color: var(--white);margin-bottom: 0 !important;}
    .profileinfo{border-top:1px solid var(--borderColor);padding-top: 24px;padding-bottom: 24px;}
    .profileinfo:first-child{border-top:0}
    /* .profileinfo.active, .profileinfo:hover{background: var(--bgOrient1); margin-left: -32px; margin-right: -32px;} */
    /* .profileinfo.active .profile-block, .profileinfo:hover .profile-block{padding: 0 32px} */
    .ant-popover-inner-content .personal-popup, .ant-popover-inner{padding: 8px 12px;}
    .ant-popover-inner{background-color: var(--profileMenu);border-radius: 10px;padding: 0;}
    .ant-popover-inner-content .personal-popup:hover{background: var(--drpdwnHover);padding: 8px 12px !important;}
    .user-tablist .ant-tabs-tab, .user-tablist .ant-tabs-tab.ant-tabs-tab-active, .user-tablist .ant-tabs-tab:hover{padding: 12px 16px 12px 12px !important;}
    .user-list .ant-tabs-tab{font-size: 16px; font-weight: 600; color: var(--textWhite); margin-bottom: 0;padding: 12px !important;}
    .user-list.ant-tabs-left > .ant-tabs-content-holder{border-left: 0 !important;}
    .user-list .ant-tabs-content.ant-tabs-content-left{padding-left: 65px;}
    .user-list .ant-tabs-tab.ant-tabs-tab-active,.user-list .ant-tabs-tab:hover {background: var(--profiletab);
        border-radius: 8px;;font-weight: 600;  padding:12px; color: #ffffff;}
    .user-list .ant-tabs-ink-bar {background: transparent !important;}
    .user-list .ant-tabs-tab .tabtitle{font-weight: 700;font-size: 18px;color: var(--coinPrice);margin-left: 14px;}
    .profile-image-content{display: flex;align-items: center;}
    .download-content{font-weight: 700;font-size: 16px;color: var(--tabLine);}
    .profile-img-style{margin-right: 98px;}
    .dwnl-content-style{margin-top: 66px;display: flex;}
    .basicprofile-info{background: var(--addressList);border-radius: 13px;padding: 24px;margin-top: 26px;width: 510px;}
    .profile-ul{padding-left: 0 !important;list-style: none;}
    .security-align, .crypto-address{display: flex;align-items: center;justify-content: space-between;}
    .custom-toggle.ant-switch-checked{background-color: var(--profiletab) !important;}
    .custom-toggle.ant-switch{background-color: #4E505A;}
    .cases-grid-style .k-grid-header .k-grid-header-wrap table{width: 100% !important;}
    .cases-grid-style .k-grid-container .k-grid-content .k-grid-table{width: 100% !important;}
    /* .small-btn{min-width: 90px;font-weight: 700;font-size: 15px;color: var(--white);border: 1px solid var(--pfsmbtn);border-radius: 5px;padding: 12px 28px;display: inline-block;} */
    .profile-sm-btn{text-transform: capitalize;height: 46px;min-width: 120px;font-weight: 700;font-size: 15px;color: var(--white);border: 1px solid var(--pfsmbtn);border-radius: 5px;padding: 4px 28px;}
    .passwrd-chang-btn{margin-top: 26px;}
    .reset-paswrd-mt{margin-top: 36px;}
    .custom-msg .ant-message-notice-content{background:var(--black); color: var(--textPureWhite); padding: 20px 40px; min-width: 200px;}
    .security-label-style{font-weight: 400;font-size: 14px;color: var(--checkText);margin-left: 12px;}
    .user-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .tabtitle, .user-list  .ant-tabs-tab-btn:hover .tabtitle{color: #ffffff;}
    .user-list .ant-tabs-tab:hover .tabtitle{color: #fff;}
    .profile-sm-btn:hover, .profile-sm-btn:focus{color: var(--btnhover);border-color: var(--btnhover);}
    /* .theme-switch.theme-active{background: var(--bgDark);} */
    .theme-switch {border-radius: 30px;height: 52px; padding: 7px 4px;}
    .custom-theme-btn{display: flex;align-items: center;}
    .setting-btn{margin-top: 36px;}
    .mb-copy{color: var(--marketTitle) !important;}
    .referral-bg-style.basicprofile-info{padding: 18px 24px;margin-top: 16px;}
    .refferral-share-btn{margin-top: 26px;font-weight: 500;font-size: 16px;color: var(--coinPrice);margin-bottom: 20px;}
    .crypto-address.share-width-align{width: 510px;}
    .referral-patner-btn{margin-top: 26px;margin-left: auto;margin-right: auto;}
    /* .crypto-address .custom-display{position: absolute;top: 6px;right: 8px;} */
    .theme-txt{font-size: 16px; font-weight: 500; color: var(--coinPrice);margin-left: 4px;display: flex;align-items: center;}
    .switch-circle, .theme-txt{margin-bottom: 0 !important;}
    /* .theme-active .theme-txt{color: var(--textWhite);font-weight: 400;} */
    .info-details{font-weight: 700;font-size: 16px;color: var(--tablesub);}
    /*profile css end*/
    
    /* manual addressbook start */
    .custom-forminput .ant-form-item-label > label{height: auto;}
    .ant-tooltip-arrow-content{background-color: transparent;}
    /* manual addressbook end */
    
    /*Common css start*/
    /* a{color: var(--viewAll);font-size: 18px;font-weight: 500;} */
    [data-theme='DRT'] .header-logo img, [data-theme='LHT'] .header-logo img{display: none;}
    [data-theme='DRT'] .header-logo img.dark, [data-theme='LHT'] .header-logo img.light {display: inline-block;}
    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{border: 0 !important;}
    .newtransfer-card.ant-btn[disabled], .newtransfer-card.ant-btn[disabled]:hover, .newtransfer-card.ant-btn[disabled]:focus, .newtransfer-card.ant-btn[disabled]:active{background: var(--drawerList);border: 2px solid transparent;color: var(--coinBal) !important;}
    .ant-btn:hover, .ant-btn:focus, .ant-btn:active{background-color: transparent;}
    .icon, .k-i-more-vertical.k-icon,.ant-select-arrow, .cust-check-outline.custom-checkbox input[type=checkbox]+span{background: var(--lightThemeIcons) no-repeat; display: inline-block; vertical-align: middle;}
    .ant-select-arrow .anticon{display: none;}
    .ant-select-arrow{width: 20px;height: 20px;background-position: -351px -5px;opacity: 0.5;}
    .main-container{padding: 24px 16px; margin: auto;}
    .main-container .k-loading-color{background-color: transparent !important;}
    .text-white{color: #FFFFFF;}
    .ant-menu-item.ant-menu-item-only-child .ant-menu-title-content:hover{color:var(--white)}
    /* body{background:var(--bodyBg);} */
    /* url(../images/dark-bg2.0.svg) no-repeat top center; */
    .loader{background-color: #000000;position: fixed;top: 0; right: 0; bottom: 0; left: 0;display: flex; align-items: center;font-size: 36px;color: var(--textWhite);justify-content: center;}
    .side-drawer .ant-drawer-mask, .side-drawer-full .ant-drawer-mask{background: var(--drawerMask);}
    .side-drawer .ant-drawer-body, .side-drawer .ant-drawer-header{background-color: var(--drawerBG);}
    .cust-input, .expiry-input, .cust-input .ant-input{background: var( --inputBg);color: var(--white);height: 50px;margin-bottom: 6px;font-size: 16px;border: 1px solid var(--labelBorder);border-radius: 5px;text-align: left;}
    .ant-select-selection-placeholder{color: var(--white);}
    .ant-select-dropdown{background-color:var(--profileMenu);border-radius: 5px;}
    .select-drpdwn .ant-select-item:hover, .select-drpdwn .ant-select-item.ant-select-item-option-active, .select-drpdwn .ant-select-item.ant-select-item-option-selected, .select-drpdwn .ant-select-item.ant-select-item-option-active.ant-select-item-option-selected{background-color: var(--drpdwnHover);}
    .ant-drawer-content{ background-color: var(--drawerBG);}
    .ant-drawer-header{border-radius: 0;background-color: transparent;}
    .side-drawer .ant-drawer-content-wrapper{width: 100% !important;}
    .ant-drawer-wrapper-body{padding-top:90px;}
    .ant-layout{background: none;}
    .ant-btn{background-color: transparent;}
    .custom-btn{font-weight:600;font-size: 15px;color: #FFFFFF;}
    .drpdwn-list{padding-left: 0;}
    .text-green{color: var(--green);}
    .text-red{color: var(--red);}
    .fait-wallets-style{display: flex;align-items: center;justify-content: space-between;margin-top: 36px;}
    .ant-table{background-color: transparent !important;}
    .ant-table-tbody > tr.ant-table-row:hover > td{background-color: transparent;}
    .ant-table-thead > tr > th,.fee-table th{background-color:var(--grid) !important;border-bottom: transparent !important;}
    .ant-table-tbody > tr > td{border-bottom:rgba(255, 255, 255, 0.2) ;}
    .ant-table-container table > thead > tr:first-child th:first-child,.table-border th:first-child{border-top-left-radius: 20px;}
    .currency-style{color: var(--coinPrice);font-weight: 400;font-size: 24px;}
    .coin-style{font-weight: 700;font-size: 16px;color: var(--coinBal);}
    .coinbal-style{font-weight: 400;font-size: 16px;color:var(--coinPrice);}
    .ant-table-thead th.ant-table-column-has-sorters:hover{background:var(--grid);}
    .ant-input-group-addon{background-color:transparent ;}
    .cust-search .ant-input-affix-wrapper{background-color: var(--searchBg) !important;border: 1px solid var(--subText) !important;border-radius: 10px !important;height: 48px;}
    .cust-search::placeholder{color: var(--placeholders);font-weight: 400;font-size: 16px;}
    .cust-search .ant-input{color: var(--primary);font-weight: 400;font-size: 16px;}
    .drawer-list-font{font-weight: 700;font-size: 16px;color: var(--coinPrice);}
    .drawer-coin .ant-image, .crypto-curr-align .ant-image, .listitems-design .ant-image, .cytpo-list-style .ant-image, .crypto-bg, .drawer-list-fiat .ant-image-img{border: 4px solid var(--coinBg);border-radius: 50%;}
    .sendfiat-coins .ant-image-img{ border: none !important;}
    .sendfiat-coins .ant-list-item-meta-avatar{height: 46px;}
    .drawer-coin .ant-image-img, .crypto-curr-align .ant-image-img, .listitems-design .ant-image-img, .cytpo-list-style .ant-image-img, .crypto-bg, .drawer-list-fiat .ant-image{border-radius: 50%;}
    .cytpo-list-style .ant-list-item-meta{align-items: center;}
    .crypto-bg{width: 52px;height: 52px;}
    .label-style, .ant-form-item-label > label{color: var(--subText); font-weight: 400;font-size: 14px;margin-bottom:4px !important;}
    .ant-form-item-label > label{margin-bottom: 4px !important;}
    .ant-card.crypto-card{background: none;}
    .ant-select-item .ant-select-option-active, .ant-select-item-option-content, .ant-select-selection-item{color:var(--menuFont) ;}
    .ant-alert-error, .ant-alert-warning, .ant-alert-info{background-color: var(--alertBG);border: 1px solid #ff5747;}
    .ant-alert-error .ant-alert-icon, .ant-alert-warning .ant-alert-icon{color: #ff4d4f;}
    .ant-alert-message{color: #ff5747 !important;}
    .ant-alert-description {font-size: 16px !important;color: var(--red) !important}
    .ant-alert-description span{font-size: 16px !important;color: var(--red) !important}
    .ant-alert-description span a{color:var(--termsText) !important}
    .ant-alert{border-radius: 5px;}
    .ant-notification-rtl{z-index:9999;top:75px !important;}
    .ant-notification-notice .ant-notification-close-icon{color: var(--textWhite30);}
    .ant-notification{direction: ltr;}
    .ant-notification-notice-closable .ant-notification-notice-message{padding-left: 0 !important;}
    .ant-notification-rtl .ant-notification-notice-close{left: auto;right: 15px;}
    .ant-notification-notice-message{color: var(--textWhite30)!important;}
    .ant-notification-notice-description{color: var(--textSecondary)!important;}
    .ant-notification-notice{border-radius: 10px;font-size: 14px;background: var(--accountType);}
    .notify{padding: 16px;margin-bottom: 36px;}
    .notify .ant-alert-action{margin: 15px 30px 0 30px;}
    .notify .ant-alert-action .ant-btn{color: var(--viewAll);font-size: 18px;font-weight: 500;}
    .notify .ant-alert-close-icon{font-size: 18px;color: var(--textDark) !important;}
    .custom-notify.cust-input .ant-select-selection-item, .cust-input .ant-select-selector, .cust-input .ant-input, .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{line-height: 36px !important;height: 38px !important;margin-top:3px;}
    .drawer-balnstyle{font-size: 16px;font-weight: 700;color: var(--coinBal);}
    .newtransfer-card.ant-btn:hover, .newtransfer-card.ant-btn:focus, .newtransfer-card.ant-btn:active{background-color: var(--radioFill) !important; border: 2px solid var(--radioBorderfill);color: var(--coinPrice) !important;}
    .fund-transfer-input{margin-bottom: 0 !important;}
    .send-drawerbtn{margin: 36px;}
    .input-subtext{font-weight: 400 !important;font-size: 16px !important;color: var(--checksub) !important;}
    .custom-radiobtn{background: var(--tabBg);border: 1px solid var(--inputBorder);border-radius: 8px;padding:6px}
    .custom-radiobtn .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{background: #3653E4;border-radius: 5px; border: 0;height: auto; padding: 6px 10px;color: #FFFFFF;font-weight: 700;font-size: 16px;}
    .custom-radiobtn .ant-radio-button-wrapper{font-weight: 500;font-size: 16px;color: var(--marketTitle);background-color: transparent;border: 0;text-transform: capitalize;padding: 0 8px;}
    .custom-radiobtn .ant-radio-button-wrapper:not(:first-child)::before{content: none;}
    .custom-radiobtn .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{border: transparent;}
    .custom-radiobtn .ant-radio-button-wrapper:first-child{border: 0;}
    .custom-radiobtn .ant-radio-button-wrapper:hover{color: var(--marketTitle);}
    .custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{color: #FFFFFF;}
    .custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{color: #FFFFFF;border-color: transparent;}
    .custom-label .ant-form-item-label, .custom-label.ant-row{display: block !important;padding: 0 0 0px !important;}
    .send-fiat-input .ant-form-item-label > label{height: 22px;}
    .ant-form-item-label{text-align: left !important;}
    .ant-input:focus, .ant-input-focused, .ant-input:hover{border: 1px solid #CCC3FF;box-shadow:none;}
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
        -webkit-box-shadow:none;
    }
    .round-pills .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{border-color: var(--radioBorderfill);}
    /* .ant-tooltip.ant-tooltip-placement-top{left: 660px !important;top: 321px  !important;} */
    .ant-tooltip-inner{background-color: var(--profileMenu);color: var(--white);}
    .footer-links a, .footer-links span{font-size: 14px; color: var(--white); font-weight: 500; display: inline-block;margin-right: 30px;text-align: center;}
    .footer-links a:hover{color: var(--textAqua) !important;}
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .cust-input:hover{border-color: #CCC3FF;}
    .custom-forminput .cust-input .ant-input{text-align: left;}
    .cust-input .ant-select-selector, .cust-input .ant-input{background-color: transparent !important;}
    .ant-form-item-has-error .ant-input:not(.ant-input-disabled), .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled), .ant-form-item-has-error .ant-input:not(.ant-input-disabled):hover, .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{background-color: transparent;}
    .crypto-card.select .ant-card-body .select-currency .cust-input .ant-select-selector{line-height: 44px !important;}
    .crypto-card.select .ant-card-body .select-currency .ant-select-single .ant-select-selector .ant-select-selection-item{line-height: 42px !important;}
    .profile-img-style .profile-info .loader-antd{padding: 36px 30px 30px 30px !important;}
    .newcase-style{margin-bottom: 16px;align-items: center;}
    .ant-alert-with-description{align-items: center !important;}
    .summarybal .gridLink:hover{color: var(--viewAll);}
    .pay-list.modal-listbr .summarybal .gridLink{word-break: break-all;text-align: right;width: 326px;}
    .paste-rece-style{padding-top: 0 !important;margin-top: 16px !important;}
    /*Common css End*/
    
    /*Coin Selection End*/
    .wallet-list.ant-list-split .ant-list-item{background: var(--drawerList);border-radius: 13px;border:2px solid transparent;height: 75px;padding: 10px 18px !important;}
    .wallet-list .ant-list-item:hover, .wallet-list .ant-list-item.select, .addCryptoList li.select{background: rgba(92, 97, 255, 0.35);border: 2px solid #CCC3FF !important;border-radius: 13px;}
    .wallet-list.ant-list-split .ant-list-item:last-child{border:2px solid transparent;}
    .code-lbl{margin-bottom: 4px !important;margin-left: 4px;}
    .fiatdep-info.fiat-list-items .alert-info-custom.kpi-List .fait-box.kpi-divstyle:last-child{border: 0;}
    /*Coin Selection End*/
    /* button styles start */
    .select-drpdwn .ant-select-item:hover, .select-drpdwn .ant-select-item.ant-select-item-option-active, .select-drpdwn .ant-select-item.ant-select-item-option-selected, .select-drpdwn .ant-select-item.ant-select-item-option-active.ant-select-item-option-selected, .ant-select-item-option-selected:not(.ant-select-item-option-disabled){background-color: var(--drpdwnHover)}
    .ant-select-item.ant-select-item-option.ant-select-item-option-selected,.ant-select-item-option:hover{background-color: var(--drpdwnHover) ;}
    .btn-dropdown button, .pop-btn{background-color: #3653E4; border-color: #3653E4; border-radius: 5px;height: 42px;font-weight: 600; transition: 0.3s ease;font-size: 16px;color: #FFFFFF;}
    .btn-dropdown button, .pop-btn:hover, .pop-btn:focus, .pop-btn:active, .download-btn:hover{background-color:#7087FF !important;color: #FFFFFF !important;border-color: #7087FF !important;}
    .custom-btn.prime, .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover{background-color: #3653E4;font-weight:600;font-size: 15px;color: #FFFFFF;}
    .custom-btn.prime:hover,.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover{background-color: #7087FF;}
    .custom-btn.sec{background: transparent;background-image: none;border: 1px solid var(--subText); color: var(--textWhite);margin-left: 16px;}
    .custom-btn.sec:hover{background-color: #3653E4;}
    .cust-cancel-btn{border: 1px solid var(--inputBorder);border-radius: 5px;height: 46px;background-color: transparent;color:var(--white);font-weight: 600;transition: 0.3s ease;font-size: 16px;width:100%;margin-bottom: 20px;}
    .cust-cancel-btn.ant-btn:hover, .cust-cancel-btn.ant-btn:focus, .cust-cancel-btn.ant-btn:active{border: 1px solid #CCC3FF;border-radius: 5px;height: 46px;background-color: transparent;color: var(--white);font-weight: 600;transition: 0.3s ease;font-size: 16px;}
    /* button styles end */
    /*Kendo start*/
    .k-grid-header .k-header > .k-link,.commision-fee-custtable .k-link,.fee-discount-table thead th,.table-border th{font-size: 16px;font-weight: 700;color: var(--gridtitle);}
    .k-grid td,.commision-fee-custtable td{font-size: 14px;font-weight: 400;}
    /* .ant-form-item-label > label{color: var(--subText);font-weight: 400;font-size: 14px;margin-bottom: 2px !important;text-transform: capitalize;} */
    h1.sub-heading {text-transform: capitalize;padding-left:4px; padding-right:4px;font-size:18px;font-weight:500;margin-top: 10px;color:var(--textWhite);margin-bottom: 26px !important;}
    h1.heading{font-size: 24px;font-weight: 600;color:var(--textWhite);margin-bottom: 16px;padding-left:4px; padding-right:4px;}
    .pop-btn, .confirm-btn{height: 46px;margin-bottom: 20px;}
    .sidebar-push{padding:30px 16px 24px;}
    .k-grid-header .k-header > .k-link{padding: 8px;margin: 0;}
    .k-pager-numbers .k-link.k-state-selected,.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected ,.k-list .k-item.k-state-selected:hover,.k-list-optionlabel.k-state-selected:hover{background-color: #3653E4;color: #ffffff !important;}
    .k-pager-numbers .k-link{color: var(--white);}
    .k-button, .k-textbox, .k-pager-numbers .k-link, .k-pager-numbers .k-link.k-state-selected{border-radius: 30px;}
    .k-i-sort-asc-sm::before, .k-i-sort-desc-sm::before,.k-i-arrow-60-left::before,.k-i-arrow-60-right::before, .k-i-arrow-end-left::before,.k-i-arrow-end-right::before{color: var(--white);}
    .k-button.k-primary.excel-btn{background: #3653E4; color: #fff; border-color: #3653E4;padding: 10px 24px;font-weight: 500;text-transform: uppercase;}
    .k-button.k-primary{background: #3653E4; color: #fff; border-color: #3653E4;padding: 4px 24px;font-weight: 500;text-transform: uppercase;}
    .k-button.k-primary:active, .k-button.k-primary:hover{background: #7087FF; color: #fff; border-color: #7087FF;}
    .k-grid thead{}.k-i-more-vertical.k-icon{width:20px; height: 20px; background-position: -350px -1px; transform: scale(0.9);margin-top: 10px;}
    .k-grouping-row .k-i-collapse::before, .k-grouping-row .k-i-expand::before, .k-i-more-vertical:before{display: none;}
    .ant-picker-suffix{color: var(--textWhite);}
    .k-pager-sizes .k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap, .k-pager-sizes .k-dropdown .k-dropdown-wrap:hover{background: var(--bgDarkGrey);color: var(--textWhite);}
    .k-pager-nav.k-link:hover{background-color: var(--drawerList);border-radius: 50%;}
    .fiatdep-info .anticon-copy, .fiatdep-info .anticon-check{font-size: 16px;}
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{background: transparent !important; border: 0;}
    .k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer{color: var(--textWhite) !important;background-color: transparent !important;}
    .k-grid-content, .k-grid{background-color: transparent !important;color: var(--textWhite) !important;}
    .k-grid-content{margin-right: -12px;}
    .k-grid-header{padding-right: 0;}
    .k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover,.markets-grid tbody tr:hover,.fee-discount-table tbody, .table-border tbody{background-color: var( --drawerList) !important;}
    .k-pager-wrap{color: var(--white);background-color: transparent;}
    .k-actions.k-actions-stretched .k-button.k-button-md.k-button-solid.k-button-solid-base.k-rounded-md{background-color:#E8E8E8 !important ;border-radius: 30px;}
    .k-actions.k-actions-stretched .k-button-solid-primary.k-disabled{background-color:#3653E4 !important ;border-radius: 30px;border:transparent;color:#000;text-transform: uppercase;font-weight: 500;}
    .k-actions.k-actions-stretched .k-button-solid-primary{background-color:#3653E4 !important ;border-radius: 30px;border:transparent;color:#000;text-transform: uppercase;font-weight: 500;}
    /*Kendo End*/
    /*Modal start*/
    .ant-modal-content{border-radius: 24px;}
    .ant-modal-header{border-radius: 24px 24px 0 0;padding: 16px 24px;border-bottom:1px solid #4E505A !important;}
    .ant-modal-header, .ant-modal-content{background: var(--drawerBG);}
    .ant-modal-title{font-size: 24px; line-height: 34px;color: var(--white);text-transform:capitalize;}
    .ant-modal-body p{font-size: 16px;color: var(--white);font-weight: 400;}
    .ant-modal-footer{padding:16px 24px;border-color: transparent;}
    .footer-border .ant-modal-footer{border-top:1px solid #4E505A !important;}
    .cust-adon .ant-input-group, .cust-adon .ant-input-group .ant-input:focus, .cust-adon .ant-input, .cust-adon .ant-input-group-addon{border: none !important;box-shadow: none;padding: 0;background: transparent;}
    .cust-adon .ant-input{line-height: 45px !important;height: 47px !important;background-color: transparent !important; padding:0 1px !important;margin-bottom:0}
    /* .ant-drawer{z-index: 999 !important;} */
    .ant-modal-confirm-btns button:first-child{background-color: transparent;border: none;color: var(--white);}
    .ant-modal-confirm-btns button:nth-child(2){background-color: var(--bgYellow) !important;border: none;color: var(--textDark);border-radius: 16px;padding: 4px 30px}
    .ant-modal-confirm-btns button:first-child:hover{color: var(--textYellow);}
    /* .ant-picker-input > input, .ant-form-item-label > label{color: var(--textWhite);} */
    .search-bg{border: 1px solid rgba(0,0,0,.08);color: #313c46 !important;background-color: var(--gridheader) !important;padding: 15px 10px;border-radius: 20px;}
    .newcust-bg{background: var(--secondary);opacity: 0.9;border: 1px solid rgba(255, 255, 255, 0.6);border-radius: 8px;}
    .down-tansactions{font-weight: 700;background: transparent;margin-bottom: 6px;cursor: pointer;font-size: 16px;color: var(--termsText);}
    .anticon-copy svg, .anticon-check svg{color: transparent;background: var(--lightThemeIcons) no-repeat !important; display: inline-block; vertical-align: middle;width: 26px;height: 26px;background-position: -8px -624px !important;}
    /*Modal End*/
    
    /* Dashboard start */
    .new-viewpage{justify-content: flex-start;}
    .price-valgreen{display: flex;align-items: center; height: 36px;padding: 4px 8px;margin-left: 16px;border-radius: 5px;}
    .pg-text{color: var(--priceVal);}
    .price-valred{display: flex;align-items: center; height: 36px;padding: 4px 8px;margin-left: 16px;border-radius: 5px;}
    .red-text{color: var(--priceRed);}
    .acount-type{padding: 8px 16px;background: var(--accountType);border-radius: 5px;height: 36px;color: var(--coinPrice);font-weight: 400;font-size: 16px;align-items: center;display: flex;margin-left: 16px;margin-bottom: 40px;}
    .tlv-logo{height: 60px;padding-left: 20px;}
    .user-left{margin-left: 16px !important;}
    .ant-list-split .ant-list-item.cytpo-list-style{background: var(--cardBG);border-radius: 13px;margin-bottom: 10px;padding: 12px 20px !important;border-bottom:0;}
    .db-titles{font-weight: 700 !important;color:var(--primary) !important;font-size: 22px !important;}
    .secureDropdown .ant-dropdown-menu{background-color: var(--profileMenu);box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.35);border-radius: 10px;min-width: 239px; max-width: 239px; padding: 14px 0;}
    .depwith-drpdown .ant-dropdown-menu{min-width: 173px !important; max-width: 173px !important;}
    .drpdwn-list li:hover{background-color: var(--drpdwnHover);}
    /* .drpdwn-list li:hover span.icon{margin-right: -3px ;} */
    .secureDropdown .ant-dropdown-arrow{display:none;}
    .secureDropdown .ant-dropdown-arrow{border-color: var(--bgGloom) !important;}
    .drpdwn-list{list-style: none;}
    .drpdwn-list li{font-size: 14px; padding: 0 22px; transition: 0.3s ease;cursor: pointer;}
    .drpdwn-list li a{color: var(--menuFont);display: flex; align-items: center; padding: 10px 0 10px 10px;}
    .personal-popup a{color: var(--menuFont);}
    .crypto-card-design{display: flex;align-items: center;}
    .custlist-align{margin-left: 26px;}
    .listitems-design{border-radius: 13px;margin-bottom: 10px;padding: 14px 20px !important;display: block;width:356px;float: left;margin-right: 16px;border-bottom: 0 !important;background: var(--faitGradGreen) !important;}
    .listitems-design:nth-child(even) {margin-right: 0;background: var(--faitGradeBlue)!important;}
    .crypto-btn-top{margin-top: 28px;}
    .dbchart-link span{color: var(--viewAll);font-size: 18px;font-weight: 500;}
    .market-panel-newstyle{clear: both;}
    .icon-space{margin-left: 12px;margin-bottom: 12px;}
    .pipeline{color: var(--tableText);margin-right: 10px;}
    .sb-card{border-radius: 20px;min-height: 248px;width: 438px;}
    .sb-innercard{padding:24px;display: flex;justify-content: center;align-items: center;}
    .sb-text{color: var(--sbCardText);letter-spacing: 5px;font-weight: 700;font-size: 24px;}
    .sb-accounts{padding: 2px;}
    .cardlogo-height{margin-bottom: 109px;}
    .crypto-style{margin-top: 50px;}
    .transaction-panel{margin-top: 40px;}
    .transaction-title{display: flex;align-items: center;width: 100%;}
    .trans-align{display: flex;align-items: start;justify-content: space-between;}
    .bash-market-table{background-color: var(--cardBG);border-top-left-radius: 20px;padding-bottom:16px;border-top-right-radius: 20px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}
    .ant-table-container table > thead > tr:first-child th:last-child,.table-border th:last-child{border-top-right-radius: 20px;}
    .db-main-title{font-weight: 800 !important;font-size: 32px !important;color: var(--menuFont) !important;margin-bottom: 40px !important;}
    .marketcap-mt{margin-top: 34px;}
    .full-screenable-node.marketcap-mt{margin-top: 30px;}
    .crypto-curr-align{display: flex;justify-content: space-between;align-items: center;}
    .iban-list .ant-list-item-meta{display:block;}
    .ts-wallet .ts-coin{font-weight: 500;font-size: 14px;color: var(--tablesub);margin-bottom: 4px;}
    .ts-wallet .ts-type{font-weight: 400;font-size: 14px;color: var(--tableInner);margin: 0 !important;}
    .ts-tbdate .ts-date{font-weight: 400;font-size: 14px;color: var(--tableText);margin: 0 !important;}
    .crypto-btns .ant-space-item, .crypto-curr-align .ant-space-item{margin-left:4px;}
    .listitems-design.iban-style .ant-list-item-meta-avatar{margin-right:0 !important;}
    .listitems-design.iban-style{background: var(--cardBG) !important;}
    .bash-market-table .ant-table-column-sorters .ant-table-column-title{color: var(--marketTitle);font-weight: 700;font-size: 16px;}
    .bash-market-table .ant-table-column-sorters .ant-table-column-sorter{color: var(--marketTitle);}
    .dashbord-space{padding-top: 0px !important;}
    .ac-topspace{padding-top: 10px;}
    .listitems-design.iban-style .ant-list-item-meta-content{width: 100%;height: 70px;}
    .fund-transfer-input .ant-form-item-label{text-align: left;}
    .ant-btn.ant-btn-block.profile-btn:focus, .ant-btn.ant-btn-block.profile-btn:active{border: 1px solid var(--green) !important;}
    .coin-typo, .coin-price-style{text-align: right;}
    .crypto-values{min-width: 120px;}
    .mobile-list.dash-mobile-list ul li:last-child{display: none;}
    .mobile-list.dash-mobile-list ul li:last-child{margin-bottom: 0}
    .side-drawer-full .ant-table-cell:nth-child(2) .ant-table-column-title{width: 105px;}
    .side-drawer-full .ant-table-cell:nth-child(3) .ant-table-column-title{width: 100px;}
    .side-drawer-full tr th{border: none;}
    .side-drawer-full tr th.ant-table-cell-scrollbar{box-shadow: none;}
    .side-drawer-full .ant-table-tbody p.fs-16{line-height: normal;}
    .side-drawer-full .ant-pagination-disabled .ant-pagination-item-link, .side-drawer-full .ant-pagination-disabled:hover .ant-pagination-item-link, .side-drawer-full .ant-pagination-disabled:focus-visible .ant-pagination-item-link{color: rgb(255 251 251 / 25%);background-color: transparent;}
    .side-drawer-full .ant-pagination-item-active{border-color: #ffffff !important;background: transparent;}
    .side-drawer-full .ant-pagination-item-active a, .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active{color: #3653E4 !important;}
    .side-drawer-full .ant-table-column-title{text-transform: capitalize;font-size: 16px;font-weight: 500;}
    /* .ant-table-thead th.ant-table-column-sort{background: var(--iconBg);} */
    .db-titles.internal-titles{font-size: 36px !important;margin-top: 16px;}
    .ant-carousel .notices-content .slick-dots-bottom{bottom: -25px !important;}
    .card-text-style{text-align: right;padding: 10px 16px 0;}
    .card-text-style span, .card-bottom-style span{color: var(--textWhite) !important;font-weight: 700;font-size: 16px;}
    .card-bottom-style{padding: 0px 16px 16px;align-items: flex-end;}
    .card-bottom-style img{width: 80px;}
    .master-text{margin-bottom: 0 ;}
    .cust-card-btn{margin-top: 52px;}
    /* Dashboard end */
    
    /*Buy/sell drawer start*/
    .drawer-maintitle{font-weight: 800;font-size: 26px;color: var(--menuFont) !important;text-transform: capitalize;}
    .crypto-list.auto-scroll.wallet-list{margin-top: 30px;}
    .crypto-list .ant-list-item, .wallet-list .ant-list-item{margin-bottom: 8px;}
    .search-style{color: var(--menuFont);font-weight: 600;font-size: 18px;margin-bottom: 2px !important;text-transform: capitalize;}
    .buycoin-style{font-weight: 700;font-size: 16px;color: var(--coinBal);}
    .coin-entryval{display: flex;align-items: center;}
    .marginL{margin-left: 4px;}
    .crypto-details{display: flex;justify-content: right;align-items: center;}
    .buy-para-bg{border-radius: 10px;background-color: var(--cardBG);padding: 8px 28px;margin: auto;margin-top: 20px;}
    /* .auto-scroll{overflow-y: auto;} */
    .enter-val-container.swap-com{display: flex;align-items: center;justify-content: center;}
    .inputfont-style{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size: 36px !important;}
    /* .inputbg-fonts{background: var( --inputBg);height: 50px;margin-bottom: 6px;border: 1px solid var(--subText);border-radius: 5px;}
    .inputbg-fonts:hover, .inputbg-fonts:focus, .inputbg-fonts.ant-input-borderless:focus{background: var( --inputBg);height: 50px;margin-bottom: 6px;border: 1px solid var(--subText);border-radius: 5px;} */
    .buy-paragraph{color: var( --paraBg);font-weight: 500;font-size: 16px;text-align: center;margin-bottom: 0 !important;margin-top:4px;}
    .crycoin-bal{font-weight: 400 !important;font-size: 16px !important;color: var(--coinPrice) !important;}
    .pop-btn{margin-top: 16px;margin-bottom: 16px;}
    .select-currency{margin-top: 50px;}
    .swap-text-sub{text-align: left;font-weight: 500;font-size: 22px;color: var(--white);}
    .enrty-field-style{margin-bottom: 12px;}
    .sellcrypto-style{color:var(--coinPrice);font-weight: 400;font-size: 16px;}
    .sellbal-style{font-weight: 700;font-size: 16px;color: var(--balInput);word-break: break-all;}
    .round-pills{width: 100%;text-align: center;}
    .round-pills .ant-radio-button-wrapper{background: var(--radioBg);height: 36px;border-radius: 10px;min-width: 60px; line-height: 34px;color: var(--coinPrice);border:1px solid rgba(0, 0, 0, 0.3);;font-size: 16px;font-weight: 500;margin: 0 16px 0px 0;text-align: center;}
    .round-pills .ant-radio-button-wrapper-checked, .default-radio .ant-radio-button-wrapper-checked, .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{background-color: var(--radioFill) !important; color:var(--balInput) !important;font-size: 16px;font-weight:500;border: 2px solid var(--radioBorderfill);}
    .round-pills .ant-radio-button-wrapper::before, .default-radio .ant-radio-button-wrapper::before{content: none;}
    .val-updown{position: absolute;right: 0;top: 100px;width: 28px;height: 28px;border-radius: 10px;background-color: var(--gridborder);text-align: center;line-height: 34px;}
    .val-updown:hover .swaparrow, .swap-updown:hover .swaparrow{transform: rotate(180deg);transition: 0.5s;background-position: -237px -35px;}
    .buy-balance{font-weight: 400;font-size: 16px;color: var(--coinPrice);}
    .crypto-amount{margin-bottom: 14px;}
    .kpi-List .kpi-label, .adressbook-fiatview .kpi-label{font-weight: 500;font-size: 14px;color: var(--sbCardText);}
    .kpi-List .kpi-val, .adressbook-fiatview .kpi-val{font-size: 16px;font-weight: 500;color: var(--white);word-break: break-all;}
    .kpi-List .ant-col{margin-bottom: 20px;display: flex;}
    .receiveank-inner{padding-bottom: 20px;}
    .new-swap-design{border-radius: 5px;}
    .new-swap-subtext{background: var(--swapBg);height: 62px;border-radius: 5px 5px 0 0;display: flex;align-items: center;padding: 0 12px;margin-top: 0 !important;}
    .swap-entryvalue{flex: 0 0 175px;text-align: left;font-size: 26px;}
    .swapcoin-alignemnt{ flex: auto;text-align: right;color: var(--sbCardText);font-size: 24px;}
    .swap-currencytext{background: var(--swapvalBg);height:62px;border-radius: 0 0 5px 5px;display: flex;align-items: center;padding: 0 12px;}
    .swap-custinputfield{border: 0 !important;padding: 0 !important;}
    .buy-usdt-btn .pop-btn, .sell-btn-style .pop-btn{margin-top: 20px;}
    .sell-radiobtn-style{flex: 0 0 236px;}
    .selection-currency-list{margin-bottom: 50px;}
    .drawer-title-style{display: flex;align-items: center;justify-content:center;margin-bottom: 50px;}
    /* .selctcoin-style{margin-bottom: 50px;} */
    .drawer-crypto-bg{margin-right: 21px;}
    .label-style.drawer-subtextstyle{font-weight: 400;font-size: 16px;color: var(--paraBg);}
    /* .crypto-coin-mbspace,.swap-custinputfield{margin-bottom: 10px;} */
    .crypto-coin-mtspace{display: inline-block;}
    /*Buy/sell drawer End*/
    /* receive fait start */
    .drawer-subtext{font-weight: 700;font-size: 18px;color: var(--tablesub);}
    .fait-title{font-weight: 500 !important;font-size: 14px !important;color: var(--subText) !important;}
    .fait-subtext{display: block !important;font-size: 16px !important;font-weight: 500 !important;color: var(--white) !important;}
    .fait-box{margin-bottom: 20px;}
    .fait-maintext{font-weight: 700;font-size: 18px;color: var(--tablesub);margin-bottom: 16px !important;margin-top: 41px;}
    .refer-text{font-weight: 400;font-size: 16px;color: var(--tableText);}
    .walletadrs{font-weight: 700;font-size: 16px;color: var(--coinPrice);}
    .import-note{background-color: var(--cardBG);width: auto;border-radius: 10px;padding: 24px 70px 24px 24px;color: var(--tablesub);font-weight: 400;font-size: 20px;margin-top: 38px;}
    .imp-note{font-weight: 700;font-size: 20px;color: var(--tablesub);}
    .custom-display{margin-left: 12px;}
    .cust-tabs-fait .ant-tabs-tab + .ant-tabs-tab{margin: 0 0 0 0px;}
    .cust-tabs-fait .ant-tabs-nav{margin: 0 auto;justify-content: center;margin-bottom: 26px;width: 480px;}
    .form-marginB{margin-bottom: 0;}
    .send-drawerbtn.tabs-innertabs{margin: 16px 0 40px;}
    .swap-entryvalue.send-crypto-style{margin-top: 0;}
    .coin-name-mb{margin-bottom: 0;}
    /* width: 350px; */
    /* .cust-tabs-fait .ant-tabs-nav{width: 350px;margin: 0 auto;justify-content: center;margin-bottom: 26px;} */
    /* receive fait end */
    /* receive crypto start */
    .rec-bottom{margin-bottom: 18px !important;}
    .recive-subtext{font-weight: 400;font-size: 16px;color: var(--sbCardText);margin-bottom: 16px;}
    .scanner-img{width: 170px; height: 170px; margin: 35px auto 40px; padding: 10px;background-color: var(--bgWhite);border-radius: 6px;}
    .recive-lable{font-weight: 400;font-size: 16px;color: var(--tableText);}
    .recive-copy{font-weight: 700;font-size: 16px;color: var(--coinPrice);margin-top: 12px;margin-bottom: 30px;}
    .recive-text, .recive-text .list-dot{font-weight: 400;font-size: 14px;color: var(--tableText);}
    .recive-runtext{font-weight: 400;font-size: 14px;color: var(--tableText);}
    .list-dot{list-style: initial;margin-bottom: 10px !important;}
    .recive-share{margin-top: 30px;font-size: 16px;font-weight: 500;color: var(--tableText);margin-bottom: 18px;}
    .network-display{display: flex;align-items: center;justify-content: center;}
    .ant-btn:focus, .ant-btn:active{box-shadow: none !important;}
    .ant-btn-loading-icon:focus, .ant-btn-loading-icon:active{box-shadow: none !important;}
    .ant-btn-loading:before, .ant-btn-loading-icon:after{background: none;box-shadow: none;}
    .pop-validate-btn .ant-btn-loading-icon:after{background: none;box-shadow: none;}
    .ant-btn::after + .ant-btn:focus  {background: none;box-shadow: none;}
    .ant-btn::after:focus{background: none;box-shadow: none;}
    .img-upld:hover{background-color: #F1F2F6;}
    /* receive Crypto end */
    /*Send Address start*/
    .thank-subtext{font-size: 16px;font-weight: 400;color:#ffffff;}
    .adbook-head{font-weight: 700 !important;font-size: 18px !important;color: var(--checkText) !important;margin-bottom: 18px !important;margin-top: 30px;}
    .fund-border{margin-top: 15px;background: var(--addressList);margin-right: 12px;border: 2px solid  var(--addressList);border-radius: 13px;padding: 22px;cursor: pointer !important;align-items: center !important;}
    .fund-border:first-child{margin-top: 0;}
    .fund-border:hover{background-color: var(--radioFill);border: 2px solid var(--radioBorderfill);}
    .fund-circle{width: 44px;height: 44px;border-radius: 50%;text-align: center;padding: 11px;background-color: var(--borderColor);margin-right: 12px;}
    .address-name{font-weight: 700;font-size: 16px;color: var(--coinBal);cursor: pointer;}
    .address-subtext{font-weight: 700;font-size: 12px;color: var(--coinPrice);}
    .addCryptoList{list-style: none; padding-left: 0;max-height: 436px;}
    .adbs-paste-recepients{padding-top: 30px;}
    .addCryptoList li{padding:10px; color: var(--textWhite);border-bottom: 1px solid var(--borderLight);font-size: 16px;}
    .addCryptoList li:hover{background-color:var(--bgBlu); cursor: pointer;}
    .sub-abovesearch{color: var(--subText) !important;font-weight: 400 !important;font-size: 14px !important;margin-bottom: 6px !important;text-transform: capitalize;}
    .code-lbl.text-upper-remove{color: var(--subText) !important;font-weight: 400 !important;font-size: 14px !important;margin-bottom: 6px !important;}
    .uplaod-inner{color: var(--white) !important;}
    .upload .ant-upload-text{font-weight: 600;color: var(--white) !important;font-size: 16px;}
    .imprt-bg{
        /* background-color: var(--cardBG);padding: 24px;border-radius: 10px; */
        margin-bottom: 16px;}
    .ab-note-style{font-weight: 700;font-size: 16px;color: var(--gridcontent);}
    .note-cont{font-weight: 400;font-size: 16px;color: var(--gridcontent);}
    .addressbook-grid .cust-tabs-fait .ant-tabs-nav{width: 100%;}
    .custom-alert-width{padding: 28px 22px;background-color:var(--addressList) !important;border-radius: 13px;color: var(--textWhite);}
    .kpi-List, .adressbook-fiatview{margin-top: 16px;}
    .custom-alert-width .pop-btn{width:auto;}
    .adbook-newdesign{width: 100%;}
    .file-label.doc-def{margin-right: 12px;}
    .transacton-drpdwn .ant-dropdown-menu{max-width: 170px !important;min-width: 200px !important;}
    .cust-start-style{font-size: 20px;color: var(--subText);font-weight: 400;margin-top: 6px;margin-left: 2px;}
    /* .font-size-align */
    .abbook-title-mt{margin-top: 16px;}
    /*Send Address End*/
    /* --Payments Start--*/
    .pay-grid thead th{padding: 8px;width: 250px; color: var(--textWhite30);font-weight: 500;text-align: left;font-size: 16px;}
    .pay-grid thead th:last-child{width: 500px;}
    .pay-grid tbody td{padding: 8px; color: var(--menuFont);font-weight: 500;font-size: 14px ;}
    .ant-table-placeholder:hover, .ant-table-placeholder:hover .ant-table-cell{background: #2a2a2a26 !important;}
    .pay-grid {width: 100%;}
    /* .ant-popover-inner{background-color: var(--bgGloom); border-radius: 10px;} */
    /* . .ant-popover */
    .pay-list{display: flex;justify-content: space-between;align-items: center;}
    .ant-table-placeholder:hover{background: transparent;}
    /* .ant-popover-inner{background-color: var(--bgGloom); border-radius: 10px;} */
    .more-popover .lbl{font-size: 12px; color: var(--textWhite50);font-weight: 100;}
    .more-popover .val{font-size: 14px; color: var(--textWhite30);display: block;margin-bottom: 16px; line-height: normal;}
    .ant-popover-arrow{border-color: var(--bgGloom) !important;}
    .payment-docs .accordian .ant-collapse-content-box, .payment-docs .accordian .ant-collapse-item{background-color: var(--bgGrey);border-radius: 24px;}
    .payment-docs .accordian .ant-collapse-item:last-child > .ant-collapse-content{background-color: var(--bgGrey);border-radius: 30px;}
    .file-label{border: 1px solid var(--borderColor);border-radius: 6px;padding: 2px 8px;color: var(--viewAll) !important;background-color: var(--bgDarkGrey);width:115px;margin-bottom: 5px;}
    .delete-btn{color: var(--textWhite30);background-color: transparent !important;font-size: 14px;border: none;}
    .delete-btn:hover{background-color: transparent !important; color:white;border:none;}
    .delete-btn:focus{border:none !important;}
    .ant-modal-footer .delete-btn{padding: 0 30px;}
    .pay-list{display: flex;justify-content: space-between;align-items: center;}
    .transaction-custom-table{background: var(--cardBG);border-radius: 20px;padding-top: 12px;padding-bottom: 12px;margin-top: 10px;}
    .transaction-custom-table .pay-grid tbody td{border: 0;}
    .transaction-custom-table .pay-grid tr{border-bottom: 1px solid var(--tableborder);}
    .transaction-custom-table .pay-grid tr:last-child{border-bottom: 0;}
    .transaction-custom-table .pay-grid thead tr, .bash-market-table .pay-grid tbody tr td{border-bottom: 1px solid var(--tableborder) !important;}
    /* .transaction-custom-table .pay-grid thead tr th:first-child{padding-left: 30px;} */
    /* .transaction-custom-table .pay-grid tbody tr td:first-child{padding-left: 30px;} */
    .bash-market-table .pay-grid thead  tr th{border-bottom: 0;}
    .bash-market-table .pay-grid tbody tr:last-child td, .bash-market-table .pay-grid tbody tr:first-child td{border-bottom: 0 !important;}
    .detail-popbtn{width: auto;margin-top: 16px;}
    .paynow-btn-ml{margin-left: 12px;}
    .total-align{padding: 8px; color: var(--menuFont);}
    .amt-field.icons-display{display: flex;align-items: center;}
    .delete-btn.delete-disable, .btn-upload-style{margin-left: 12px;}
    .ant-popover-inner-content .iban-hov:hover{background-color: var(--bgDark);}
    .ant-popover-arrow{background: transparent !important;border-color: transparent;}
    .ant-popover-arrow{border-color: var(--profileMenu) !important;}
    .ant-popover-inner{background-color: var(--profileMenu); border-radius: 10px;}
    .ant-popover-inner-content{padding: 10px 0px !important;}
    .ant-popover-inner{padding: 8px 9px;}
    .ant-popover-inner-content .rarrow-white{margin-left: 0px!important;}
    .more-popover.payments-card, .more-popover.payments-card:hover{background-color:transparent !important;}
    .ant-popover{z-index: 9999;}
    .popover-mb-12{margin-bottom: 12px;}
    .attach-iconalign{margin-top: 12px;margin-left: 8px;}
    .amt-field .form-margin-bottom{margin-bottom: 0;}
    .docdetails.pay-docdetails{margin-left: 0;width: 100px;}
    .cust-cancel-btn.pay-cust-btn{margin-top: 16px;}
    .dashb-btmtable,.mobile-list{margin-top: 10px;}
    .cust-pop-up-btn.crypto-pop .detail-popbtn{width: 100px;}
    .des-mr{margin-right: 12px;}
    .view-level-btn .cust-cancel-btn{width: 136px;margin-top: 16px;}
    .docdetails.doc-view{margin-left: 0 !important;}
    .detail-popbtn.paynow-btn-ml{width:136px;}
    .text-wrap-style{max-width: 100%;min-width: 149px;}
    .wrap-text-width{max-width: 100%;min-width: 129px;}
    .gridLink.grid-level-style{display: block;}
    .alert-info-custom.kpi-List.adbook-newdesign, .docfile.address-book-viewlevel{background-color:var(--coinviewradio) !important;}
    .error-style{margin-top: 16px;}
    /* --Payments End--*/
    
    /*--Zoho start--*/
    .zsiq_flt_rel{width: 50px !important; height: 50px !important;bottom: 8px;right: -10px;}
    .zsiq_theme1 .siqico-chat:before{font-size: 20px !important; line-height: 50px !important;}
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-radio-disabled + span{color: var(--textWhite30);}
    
    /*--Zoho end--*/
    
    /* summary start */
    .cust-summary-new{background: var(--drawerList);border-radius: 13px;padding-left: 20px;padding-right: 20px;padding-bottom: 20px;padding-top: 10px;}
    .pay-list:last-child{border:0;padding-bottom: 0;}
    .pay-list{padding: 12px 0;}
    .cust-coin-value{font-weight: 500;font-size: 45px;text-align: center;color: var(--white);}
    .cust-summary-text{text-align: left;margin-top: 16px;}
    .text-style{font-weight: 400 !important;font-size: 16px !important;color:var(--checksub) !important;margin-top: 16px !important;}
    .terms-link{color:var(--termsText) !important;font-weight: 700;}
    .faitcurrency-style{font-weight: 700;font-size: 16px;color: var(--white);text-align: center;margin-bottom: 30px;}
    .summary-liststyle{font-weight: 700;font-size: 16px;color: var(--coinBal);text-transform: capitalize;}
    .summarybal{font-weight: 400;font-size: 16px;color:var(--coinBal);word-break: break-all;}
    .cust-agreecheck{font-weight: 400;font-size: 14px;color: var( --checkText);}
    .agree-check label{height: 34px;margin-right: 14px;}
    .agree-check input[type=checkbox]{display: none;}
    .agree-check input[type=checkbox]+span{background: var(--lightThemeIcons);background-position:-115px -513px;transform:scale(1.5);display: inline-block;width: 34px;height: 34px;vertical-align: text-top;cursor: pointer;}
    .agree-check input[type=checkbox]:checked+span{background: var(--lightThemeIcons);background-position:-153px -513px;border-color: transparent !important;}
    .agree-check input[type=checkbox]+span:hover{background-position:-190.5px -513px;}
    .agree-check input[type=checkbox]:checked+span:hover{background-position:-153px -513px;}
    .pay-list{padding: 16px 0; border-bottom: 1px solid var(--borderColor);}
    /* summary end */
    
    /* success start */
    .cryptosummary-container{max-height: calc(100vh - 0px);}
    .success-title{font-weight: 800 !important;font-size: 26px !important;color: var(--white) !important;}
    .successsubtext{font-weight: 500;font-size: 16px;color: var(--coinPrice) !important;}
    .custom-declaraton{
        /* height: calc(100vh - 225px); */
        align-items: center;display: flex;justify-content: center;}
    .pop-cancel span{color:var(--textYellow);text-transform: uppercase;font-weight: 700;}
    .custom-declaraton .declaration-content{width: 600px;}
    .side-drawer .side-drawer-header{align-items: flex-start;}
    .cust-summary-new.kpi-List.sendfiat-summarystyle{margin-top: 0 !important;}
    .new-add .loading-btn + .btn-clickable-style{position: unset !important;}
    .addCryptoList.adbook-scroll.paste-recept-style .success-title.oops-title{margin-bottom: 0;}
    .addCryptoList.adbook-scroll.paste-recept-style{height: 210px;}
    /* success end */
    
    /* crypto-coins */
    .crypto-icon{ height: 44px; width:44px;}
      .crypto-icon{background: var(--coinBg) url(/static/media/cryptoicons.22681f6d.svg)  no-repeat; display: inline-block;border-radius: 100%;}
      .crypto-icon.BTC{background-position:-56px -6px; }
      .crypto-icon.ETH{background-position: -106px -6px;}
      .crypto-icon.USDT{background-position:-208px -6px;}
      .crypto-icon.USDC{background-position: -157px -6px;}
      .dash-mobile-list .ant-list-item-meta{align-items: center;}
    /* crypto-coins end   */
    /* send fiat start */
    .fund-transfer-input .tfunds-inputgroup .ant-select{width: 122px;}
    /* .fund-transfer-input .tfunds-inputgroup .ant-select-selection-item{} */
    .alert-info-custom{background-color:var(--addressList) !important;border-radius: 13px;color: var(--textWhite);padding: 20px 22px;}
    .kpi-divstyle{border-bottom:1px solid var(--borderColor);padding-bottom: 20px;width: 100%;}
    .custom-forminput.custom-label{margin-bottom: 16px;}
    .upload-btn-mt{margin-top: 16px;}
    .cust-tabs-fait .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color: var(--tabLine) !important;font-size: 16px;font-weight: 700;}
    .cust-tabs-fait  .ant-tabs-ink-bar{background: var(--tabLine);}
    .ant-upload-drag.upload{border-radius: 10px !important;background-color: var(--upload) !important;border-color:var( --borderColor) !important;}
    .upload .ant-upload{padding: 30px 0 !important;}
    .cust-tabs-fait .ant-tabs-tab .ant-tabs-tab-btn{color: var(--sbCardText)!important;font-size: 16px;font-weight: 400;}
    .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{border-bottom: 1px solid var(--inputBorder);}
    .cust-tabs-fait .ant-tabs-tab{padding-left: 10px;padding-right: 10px;}
    .pop-validate-btn{width:150px;margin-top:30px; height:42px;}
    .verify-btn-from, .crypto-summaryform{margin-top: 36px;margin-bottom: 18px;}
    .small-text-align{margin-left: 8px;}
    .docfile{background-color:var(--addressList) !important;border-radius: 20px;padding: 16px;display: flex;align-items: center;margin: 12px 6px 4px 0;flex: 1 0 240px;}
    .docfilecrypto{background-color:var(--coinviewradio) !important;border-radius: 20px;padding: 16px;display: flex;align-items: center;margin: 12px 6px 4px 0;flex: 1 0 240px;}
    .docdetails{flex: 1 1; margin-left: 12px;}
    .cust-upload-sy{margin-bottom: 20px !important;}
    .docdetails .docname{font-weight: 700;color: var(--textWhite30); line-height: 16px;max-width: 150px !important;}
    .docfile-container{display: flex; flex-wrap: wrap;}
    .upload-filesize{font-weight: 700;font-size: 16px;color: var(--coinPrice);}
    .cust-networkstyle{background-color: var(--profiletab);padding: 10px 20px;border-radius: 10px;color: #FFFFFF;font-weight: 700;font-size: 16px;}
    .swap-fontsize{color: #ffffff;font-weight: 700;font-size: 16px;}
    .network{background-color: var(--drpdwnHover);padding: 10px 20px;border-radius: 10px;margin: 0 12px}
    .network .swap-fontsize{color: var(--white);}
    .custom-height {max-height: calc(100vh - 926px);}
    .nodata-text{margin-top: 10px !important;display: inline-block;color:var(--termsText);}
    .nodata-text:hover{color:var(--termsText);}
    .cust-input.change-space .ant-input{height: 34px !important;}
    .nodata-image{margin-bottom: 10px;}
    .custom-upload{ margin: 12px 0px 4px 0;}
    .send-fiatform .ant-alert-with-description{margin-top: 20px;}
    .cust-input.iban-input{border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;border-right: 0 !important;}
    .iban-validate{border-left-color: var(--inputBg)!important; height: 50px !important;border-top-left-radius: 0 !important;border-bottom-left-radius: 0 !important;border-top: 1px solid var(--labelBorder) !important;border-right: 1px solid var(--labelBorder) !important;border-bottom: 1px solid var(--labelBorder) !important;background: var(--inputBg) !important;margin-top: 26px !important;}
    .iban-validate span{border-left: 1px solid var(--white) !important;color: var(--termsText);font-size: 16px;font-weight: 700;padding-left: 20px !important;display: inline-block;}
    .iban-validate::after{box-shadow: transparent !important;}
    .iban-validate{width: 186px;margin-right: 0 !important;}
    .validateiban-content:hover input,.validateiban-content:hover button{border-color: #CCC3FF !important;}
    .slick-slider.docreq-slider.slick-initialized .slick-dots li.slick-active button{background: var(--cardBG) !important;color: var(--primary);}
    .docreq-slider .slick-dots li button{color: var(--secondary);}
    .cases-lefttext::before{border-color: 1px solid #ccc;}
    .ant-form-item-explain.ant-form-item-explain-error, .ant-form-item-explain.ant-form-item-explain-validating{color: var(--red);}
    /* send fiat end */
    /* send crypto start */
    .min-btn{border: 1px solid rgba(0, 0, 0, 0.3);background: var(--radioBg) !important;height: 36px;border-radius: 10px;min-width: 60px;line-height: 30px;color: var(--coinPrice);font-size: 16px;font-weight: 500; margin: 0 10px 10px 0;}
    .min-btn:active ,.min-btn:focus{background-color: var(--radioFill) !important;border: 2px solid var(--radioBorderfill) !important;font-size: 16px;font-weight: 500 !important;color: var(--coinPrice) !important;}
    .min-btn:hover{border-color: rgba(0, 0, 0, 0.3) !important;color: var(--coinPrice) !important;}
    .newtransfer-card{background: var(--drawerList);border-radius: 13px;border: 2px solid transparent;height: 90px;padding: 10px 43px !important;font-size: 16px; font-weight: 700; color: var(--coinBal);width: 100%;text-align: center;}
    .newtransfer-card:hover,.newtransfer-card:active{background-color: var(--radioFill) !important;border: 2px solid var(--radioBorderfill);color: var(--coinPrice) !important;}
    .newtransfer-card::before{ background: var(--drawerList) !important;}
    .addcrypto-whitelist{margin-bottom: 45px;}
    .sub-title{font-weight: 700 !important; font-size: 18px !important;margin-bottom: 26px !important; color: var(--white) !important;}
    .addcrypto-benficiary .ant-form-item{margin-bottom: 16px;}
    .upload-empty-div{margin-top: -6px;}
    .hy-align, .code-tooltip-align{margin:auto 0; display: flex;align-items: center;}
    .send-crypto-enrty{border-radius:5px;margin-bottom: 12px;}
    .transfer-designstyle{margin-top: 60px;}
    /* .inputfont-style::placeholder{font-size: 20px;} */
    .new-swap-subtext.send-crypto-enrty{width:97%;margin:0 auto;}
    /* send crypto end */
    
    /* crypto summary start */
    /* .crypto-summaryform{padding-top: 32px;} */
    .get-code{ text-align: center;border: 1px solid var(--borderLight);color: var(--textWhite30);font-size: 16px;height: 50px;background: var( --inputBg);}
    .cust-input.custom-add-select{margin-bottom: 0 !important; border-radius: 0 !important; border-top-left-radius: 5px !important;border-bottom-left-radius: 5px !important; border-right: transparent;}
    .new-add{padding: 14px;border-bottom-right-radius: 5px; border-top-right-radius: 5px; border-left: transparent;}
    .cust-input[disabled]{background: var( --inputBg);color: var(--textSecondary);}
    .new-add button{border-left: 1px solid var(--white) !important; padding-left: 20px; }
    .new-add button:hover{border-left: 1px solid var(--white) !important;}
    .cust-input.custom-add-select:hover{border-color: var(--subText);}
    .custom-send.sell-btc-btn span{color: #ffffff;}
    .new-add button span{color: var(--termsText); font-size: 16px; font-weight: 700;}
    .new-add .check-ml-align{margin-left: 4px;margin-bottom:4px;}
    .verification-text{color: var(--white) !important;font-weight: 400;  font-size: 14px;}
    .sendfiat-summarystyle .kpi-divstyle{padding-top: 20px;}
    .sendfiat-summarystyle{padding-top: 0 !important;}
    
    /* crypto summary end  */
    /* validations */
    .ibanborder-field .ant-input-group .ant-input{border:0;border-right: 1px solid  var(--white);}
    .ibanborder-field .ant-input-group .ant-input-group-addon{border: 0;}
    .ibanborder-field .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-primary{border: 0;}
    .ibanborder-field .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-primary span{color: var(--termsText);font-size: 16px;font-weight: 700;}
    .cust-input.ibanborder-field{border-top-right-radius: 5 !important;border-bottom-right-radius: 5 !important;border-right: 1 !important;}
    .custom-add-select.mb-0.ibanborder-field .new-add{padding: 0 !important;}
    .custom-add-select.mb-0.ibanborder-field .ant-input{border-right: 0;}
    .custom-add-select.mb-0.ibanborder-field{border-right: 1px solid var(--labelBorder);border-radius: 5px !important;}
    .batch-title-align{margin-left:26px;}
    /* crypto sucess start  */
    .success-title{font-weight: 800; font-size: 26px; color: var(--white);}
    .success-content{font-weight: 500;font-size: 16px;line-height: 180%; color: var(--white);opacity: 0.8;}
    /* .send-crypto-sumry .d-flex.agree-check{margin-top: 150px;} */
    .ant-checkbox.ant-checkbox-checked{border: none !important;}
    .ant-custumcheck .ant-checkbox-checked , label:hover .ant-checkbox-checked, .ant-checkbox-checked::after{ border-color:transparent !important;}
    /* crypto success end  */
    
    /* send crypto addressbook start */
    /* .batchpayment-summary, .batch-style{margin-bottom: 16px !important;} */
    .batchpayment-summary{justify-content: space-between;align-items: center;}
    .admin-actions.address-icons li{margin-left: 20px;}
    .sub-title.address-book{ margin: 36px 0 16px 0 !important;}
    .box{overflow: hidden; margin-bottom: 28px;}
    .adressbook-style{margin-bottom: 16px;}
    .admin-actions{display: flex;padding: 0;list-style: none;margin-bottom: 0px;}
    /* .admin-actions li{margin-right: 16px;} */
    .admin-actions li:last-child{margin-right: 0;}
    .addressbook-mb{margin-bottom: 0 !important;font-weight: 800 !important;font-size: 28px !important;color: var(--white) !important;}
    .new-custom-radiobtn, .new-custom-radiobtn .ant-radio-button-wrapper, .new-custom-radiobtn .ant-radio-button.ant-radio-button-checked, .new-custom-radiobtn .ant-radio-button-wrapper:first-child, .new-custom-radiobtn .ant-radio-button-wrapper:not(:first-child)::before, .new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){background: none;border: 0;}
    .new-custom-radiobtn .ant-radio-button-wrapper-checked, .new-custom-radiobtn .ant-radio-button-wrapper{font-weight: 500;font-size: 16px;color: var( --radiocheccolor);text-transform: capitalize;}
    .new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{color: var( --radiocheccolor);border-color: transparent;}
    .new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before{background-color: transparent;}
    .new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){border-color: transparent;}
    .new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{box-shadow:none;}
    .new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{color: var( --radiocheccolor);}
    .addCryptoList.adbook-scroll .declaration-content .successsubtext{height:50px !important;}
    .search-space{padding-left: 0!important;padding-right: 0!important;}
    /* send crypto addressbook end */
    
    /* transactions start  */
    .grid-title{font-weight: 800 !important;font-size: 28px !important;color: var(--white) !important;margin-bottom: 24px !important;}
    .cust-list .k-grid{border-radius: 20px;}
    .cust-list{padding-top: 0 !important;}
    .cust-list .k-grid-header-wrap,.markets-drawer .ant-table-header,.fee-discount-table thead tr{border-top-left-radius: 20px;border-top-right-radius: 20px;background-color: var(--gridheader);}
    .ant-select-selection-item{font-weight: 500;font-size: 14px;color: var(--white) !important;}
    .filter-content{align-items:center}
    .pop-btn.search-btn{margin: 22px 0 0 12px; height: 48px; width: 148px;}
    .ant-picker-dropdown{z-index: 9999;}
    .excel-button{margin-top: 20px;}
    /* .k-grid-table,.ant-table-tbody{background-color: var(--tablebody);} */
    .k-grid td,.markets-grid td,.fee-withdraw td,.fee-discount-table td{border-right: 1px solid var(--gridborder) !important;border-bottom: 1px solid var(--gridborder) !important; color: var(--gridcontent);}
    .table-border td{border-right: 1px solid var(--gridborder) !important;border-bottom: 1px solid var(--gridborder) !important; color: var(--gridcontent);}
    .k-grid th,.markets-grid th,.fee-discount-table th{border-right: 1px solid  var(--tableborder) !important;}
    .custom-gridresponsive .ant-drawer-header,.custom-gridresponsive .ant-drawer-body{background: var(--bodyBg) !important;}
    .k-pager-wrap.k-pager.k-widget.k-grid-pager{background: var(--gridheader);border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}
    .k-pager-numbers .k-link:hover{background-color: #3653E4;color: #ffffff !important;}
    /* .k-grid th,.k-grid td{padding: 8px 20px;} */
    .k-grid th:last-child,.k-grid td:last-child,.markets-grid td:last-child,.markets-grid th:last-child,.fee-discount-table th:last-child{border-right-color: transparent !important;}
    .cust-input.cust-adon .ant-input-wrapper.ant-input-group{padding: 0 11px;}
    .icon.md.search-angle{background-position:-360.5px -488px; margin-left: 8px;}
    .custom-model-label .ant-form-item-label > label{height: 20px;}
    .marketcap-coinsize .ant-table-cell img{width:18px !important;height:18px !important;}
    .btn-right{float:right;}
    /* transactions end  */
    /* audit logs start  */
    .addressbook-grid .custom-checkbox input{display: none;}
    .addressbook-content .search-bg{margin-bottom: 24px;}
    .k-pager-wrap .k-link{background-color: transparent;}
    .k-pager-numbers-wrap .k-pager-numbers.k-reset li{margin: 0 2px !important;}
    .ant-picker:hover{border-color: #CCC3FF;}
    /* audit logs end  */
    /* coin detail view start*/
    .coin-details{background-color: var(--addressList);border-radius: 24px;padding: 30px;}
    .coin-titlestyle{color: var(--coinPrice) !important;font-size: 24px !important;font-weight: 700 !important;}
    .coin-date{display: block;text-align: right;color: var(--coindate);font-weight: 400;font-size: 10px;}
    .coin-green-ml{margin-left: 8px;}
    .bank-referrel-color{background-color: #5c61ff; padding: 6px 12px !important;border-radius: 5px;color: white; display: flex;grid-gap: 8px;gap: 8px;align-items: center;font-weight: bold;}
    .sell-title-styels{display: flex;justify-content: center;}
    .sell-title-styels .ant-image{margin-right: 12px;}
    .sell-title-styels .ant-image .ant-image-img{border: 4px solid var(--coinBg);background-color:var(--coinBg);border-radius: 50%;}
    .ant-input-group-addon .ant-select.currecny-drpdwn{margin: -5px -11px -11px 0px;}
    .currecny-drpdwn .ant-select-arrow{top: 22px;}
    /* coin detail view end */
    /*Auth0 start*/
    .text-hover:hover{color:#40a9ff;}
    .cust-phone .ant-input-group input{height:48px !important;margin-top: 0;}
    .cust-phone .ant-input-group-addon{border-right:0 !important;}
    .cust-phone{margin-bottom: 1px;}
    .cust-phone .cust-input{margin-bottom:0 !important;}
    .pa-alert{background-color: transparent;border:none;margin-top: -40px;}
    .pa-alert .ant-alert-message,.mb-alert .ant-alert-message{font-size: 14px !important;}
    .field-width{width:150px;}
    /* .mb-alert{background-color: transparent;border:none;padding-left: 0;} */
    
    .cust-input.Approved .ant-select-selector{cursor:pointer !important;}
    .ant-select-selection-search-input{color:#fff !important;}
    .width-inc{width:188px !important;}
    .text-spacedec{width: 62px;display: flex;margin: auto;cursor: pointer;}
    .form-block{width: 900px;position: relative;z-index: 25;padding: 54px 64px;border-radius: 20px;background: var(--cardBG);margin: auto}
    .text-personal{font-weight: 700;font-size: 18px;color:var(--termsText)}
    .form-block .heading {font-size: 25px;font-weight: 800;color: var(--textWhite);margin-bottom: 24px;line-height: 28px;}
    .form-block .new-custom-radiobtn .ant-radio-button-wrapper:first-child{padding-left: 0 !important;}
    .mb-24{margin-bottom: 24px;}
    .regulartext-color{font-size: 16px;color: rgb(255 255 255 / 80%);font-weight: 500;line-height: 180%;}
    .mobile-verification{width: 660px !important;margin: 0 auto;}
    .my-24{margin-top: 24px;margin-bottom: 24px;}
    .custom-forminput.form-arrowicon .cust-input .ant-select-arrow{background-position: -347px -5px;}
    .custom-forminput .cust-input  .ant-input-group-addon{border: 0;border-right:1px solid var(--labelBorder);}
    .custom-forminput .ant-select-selection-item{font-size: 16px;}
    #busssinessForm_phoneNumber, #persionalAccount_phoneNumber{border: 0;}
    .form-arrowicon .ant-select-selection-item, .form-arrowicon .ant-select-selection-item, .form-arrowicon .ant-select-selection-placeholder{color: #bfbfbf;}
    .policy-content {margin-top: 54px;margin-bottom: 45px;}
    .terms-text{font-weight: 400;font-size: 14px;color: var(--checkText);margin-left: 12px;}
    .blue-color{color:var(--termsText);text-decoration: none;font-weight: 700;}
    .text-personal:hover{color: #1890ff !important;}
    .form-arrowicon .ant-select-selector{width: 80px;}
    .phone-he{    height: 50px !important;
        margin-top: 0;
        margin-bottom: 0;}
        .custom-forminput .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{text-align: left !important;}
    .px-0{padding-left: 0 !important;padding-right: 0 !important;}
    /* input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 1px solid green;
      -webkit-text-fill-color: green;
      -webkit-box-shadow: 0 0 0px 1000px #000 inset;
      transition: background-color 5000s ease-in-out 0s;
    } */
    .flex-1{flex:1 1;}
    /*Auth0 End*/
    /* coin view start */
    .trade-graph .ant-radio-button-wrapper{background-color: var(--coinviewradio);border-color: var(--coinviewradio) !important;}
    .trade-graph .ant-radio-button-wrapper:first-child{border-radius: 8px 0 0 8px;}
    .trade-graph .ant-radio-button-wrapper:last-child{border-radius: 0 8px 8px 0;}
    .trade-graph .ant-radio-button-wrapper-checked{background-color: var(--coinviewradio);border-color:var(--coinviewradio) !important;}
    .trade-graph .ant-radio-button-wrapper:not(:first-child)::before{content: none;}
    /* .trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{background: none;} */
    .trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{color: var(--white);border-color: var(--coinviewradio);}
    /* .trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){background: none;color: var(--textDark) !important;} */
    .trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){color: var(--white);font-size: 14px;font-weight: 700;}
    .trade-graph .ant-radio-button-wrapper{color: var(--marketTitle);font-size: 14px;font-weight: 500;}
    .coin-viewstyle {font-size: 28px !important;font-weight: 800 !important;color: var(--white);margin-bottom: 36px;text-transform: capitalize;}
    .summary-count{margin-left: 12px;}
    .summary-count .coin-balstyle, .coin-name{font-size: 24px;font-weight: 800;color: var(--white);margin-bottom: 0;}
    .coin-name{margin-left:8px;}
    .active span{color:var(--white) !important}
    .coin-subbalval{color: var(--marketTitle);font-size: 14px;font-weight: 500;}
    .value-up, .value-down{margin-left: 16px;}
    .cust-tabs-fait.coinview-tabs .ant-tabs-nav{width: 100%;}
    .coin-bal ul li div{width: 40px;height: 40px;border-radius: 50%;background-color:#3653E4;margin: 0 auto 6px;line-height: 38px;box-shadow: 0 3px 16px rgba(0, 0, 0, 0.15);}
    .coin-bal ul li div:hover{background-color:#7087FF;}
    .walletsdrawer .mobile-list{margin: 0;}
    .wallet-head{padding: 8px 16px; background-color: var(--bgDarkGrey);border-radius: 10px;margin-bottom: 6px;}
    .crypto-wallets .ant-list-item-meta-content{width: auto;}
    .minmax{ position: relative;top: -75px;right: 13px;}
    .coin-fs{font-weight: 700;font-size: 16px;color: var(--white);}
    .coin-details-spin{display: flex;align-items: center;justify-content: center;min-height: 400px;}
    .coin-info{padding: 16px 0;border-top: 1px solid var(--coinViewborder);font-size: 16px;display: flex;justify-content: space-between;}
    .backarrow-mr{margin-right: 16px;}
    .back-btnarrow{font-size: 20px;font-weight: 500;color: var(--white);}
    .backbtn-arrowmb{margin-bottom: 12px;}
    .billpaycoin-style .coin-viewstyle{margin-bottom: 0 !important;}
    .billpaycoin-style{display: flex;align-items: center;}
    .bill-grid .custom-checkbox input{display: none;}
    .ant-checkbox-checked::after, .ant-checkbox-checked .ant-checkbox-inner::after{display: none;}
    .pop-btn.custmodel-pop{margin-top: 0;}
    .addicon-content{font-size: 16px;color: #FFFFFF;font-weight: 600;margin-right: 12px;}
    .coin-bal ul{ margin-top: 16px !important; text-align: center;}
    .graph{border-radius: 20px;margin-bottom: 16px;background-color:var(--addressList);border-color:var(--addressList);padding: 30px;}
    .graph .graph-title{border-bottom: 1px solid var(--addressList);padding: 16px;}
    .graph .graph-body{min-height: 430px;padding: 8px;}
    .db-container{padding: 0;}
    .db-kpi{background-color: var(--addressList);border-radius: 20px;padding: 16px 24px;margin-bottom: 16px;min-height: 112px;}
    .db-kpi-label{font-size: 14px;color: var(--sbCardText);display: block;font-weight: 500;}
    .db-kpi-val{font-size: 24px;color:var(--white);font-weight: 500;line-height: 34px;margin: 0 16px 3px 0;display: block;}
    .db-kpi .badge{background-color:var(--gridheader);padding: 3px 10px;font-size: 12px;font-weight: 500;color: #D13F00;border-radius: 16px;vertical-align: text-bottom;display: inline-block;}
    .db-kpi .icon-bg{width: 48px;height: 48px;border-radius: 8px;background-color: var(--bgDarkGrey);margin-right: 20px;text-align: center; line-height: 48px;}
    .db-kpi.vthicon{display: flex;align-items: center;min-height: 112px;}
    .custom-radiobtn.coin-alldetail{margin-top: 16px;margin-bottom: 16px;}
    .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger){margin-left: 12px;}
    .inputfont-style:hover, .inputfont-style:focus{border: 0;}
    .icon.danger-alert{background-position:-329px -242px;width:36px;height:36px;}
    .icon.danger-alert input{display:none;}
    /* coin view end */
    /* expand search */
    .pay-grid .ant-table-body{height: 736px;}
    .search-box {position:relative;height: 46px;padding: 10px;flex:300px 0;display: flex;align-items: center;}
    .search-box:hover > .search-text{width: 240px;padding: 6px;border-radius: 0px 10px 10px 0px;border-left: 0;}
    .search-box:focus, .search-box:hover{border:1px solid var(--subText);border-radius: 10px;margin-left: 10px; margin-right: 6px;}
    .search-btnexpand {position: absolute;left:0 ;z-index:1;transition: 0.4s;cursor: pointer;text-decoration: none;}
    .search-text {border: none;background: none;outline: none;padding: 0;color: white;font-size: 16px;font-weight: normal;transition: 0.4s;line-height: 26px;width: 0px;margin-left:29px;}
    .search-text::placeholder{color: var(--placeholders);}
    /* expand search end */
    /* cases */
    .case-lbl{font-weight: 500; font-size:14px; color: var(--sbCardText);}
    .case-val{color:var(--white) !important;font-size: 16px;font-weight:500;}
    .requested{background-color: #3653E4;}
    .gridLink{color: var(--viewAll);}
    td .gridLink{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 8px 12px;}
    .gridLink{cursor: pointer;display: inline-block;}
    .pay-list.modal-listbr{border-bottom: 1px solid var(--borderColor);padding: 16px 0px;}
    .ant-modal{top:36%;}
    .accordian.ant-collapse > .ant-collapse-item{border-bottom: 0;}
    .accordian.ant-collapse{background: none;border: none;margin-bottom: 10px;}
    .accordian.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{border-radius: 20px;}
    .accordian .ant-collapse-item:last-child > .ant-collapse-content{border-color: var(--borderGrey);background-color: var(--addressList);border: 0;}
    .accordian .ant-collapse-header{border-radius: 20px !important;font-size: 18px;font-weight: 600;background-color:var(--scroll);color: var(--textWhite30) !important;padding: 16px 20px 16px 24px !important;text-transform: capitalize;}
    .accordian{border-color: var(--bgOrion) !important;border-radius: 24px;}
    .accordian .ant-collapse-header .icon.downangle{position: relative;margin-right: 34px !important;}
    .accordian .ant-collapse-header .icon.downangle::after{content: '';position: absolute; right: -16px; top: 0; border-right: 1px solid var(--borderGrey);height: 20px;}
    .accordian .ant-collapse-item.ant-collapse-item-active .ant-collapse-header{border-radius: 20px 20px 0 0 !important;}
    .accordian .ant-collapse-arrow{vertical-align: text-bottom !important;}
    .accordian .ant-collapse-item.ant-collapse-item-active .downangle{background-position: -329px 0;}
    .accordian .ant-collapse-content-box{padding: 20px;background-color: var(--addressList);border-radius: 0 0 24px 24px;border-bottom: 1px solid var(--drawerBG);}
    .accordian .ant-collapse-item:last-child > .ant-collapse-content{border-radius: 0 0 24px 24px;}
    .ant-empty-description{color: var(--white);text-transform: capitalize;}
    .case-ribbon{background-color:var(--addressList);padding: 20px;border-radius: 16px;}
    .faitview-btn{margin-top: 36px;}
    .cases-lefttext{margin-left: 16px;}
    .case-stripe, .case-remarksstyle{border: 1px dashed var(--borderColor); padding: 16px 20px; border-radius: 16px; margin-bottom: 16px}
    .ribbon-item{display: flex; align-items: center;margin-bottom: 16px;border-right: 1px solid var(--borderColor);}
    .case-val.cases-subtext{word-break: break-all; padding-right: 12px;}
    .ant-upload-hint.upload-text{color: var(--sbCardText) !important;font-size: 14px !important;}
    /* .notify-alerticon .ant-alert-icon{margin: 0px 15px 18px 0px !important;} */
    .btn-textstyle{display: block !important;color: var(--viewAll) !important;font-size: 16px;font-weight: 500;}
    .file-sizestyle{font-weight: 500;font-size: 14px;color: var(--sbCardText);}
    .case-remarksstyle{margin-top: 10px;}
    .case-lbl.remark-casestyle{word-break: break-all;}
    .reply-date{color: var(--coindate);font-weight: 400;font-size: 12px;}
    .reply-container{display: flex;margin-bottom: 24px;}
    .reply-container .user-shortname{width: 46px; height: 46px; border-radius: 50%;background-color: #3653E4;margin-right: 16px;color: #FFFFFF;font-size: 18px;text-align: center;line-height: 46px;font-weight: 500;text-transform: uppercase;}
    .reply-body{flex: 1 1;}
    .reply-txt{font-size: 14px;color: var(--marketTitle);font-weight: 500;margin-bottom: 0;word-break: break-all;}
    .reply-username{color: var(--white) !important;font-size: 16px;font-weight: 500;display: inline-block;margin-right: 10px;}
    .send-circle{background: var(--bgYellow); width: 40px;height: 40px; line-height: 40px; text-align: center;border-radius: 50%;}
    .chat-send{display: flex;border: 1px solid var(--borderLight); padding:12px; border-radius: 20px; justify-content: space-between; align-items: center;margin-bottom: 16px;}
    .chat-send input{border:0; flex: 1 1;font-size: 16px;color: var(--textWhite30);font-weight: 200;}
    .chat-send input:focus{outline: none;}
    /* cases */
    /* notifications */
    .buybg.mb-0{margin-bottom: 0 !important;}
    .notifications-list .ant-list-item{padding:16px 0; border-bottom: 1px solid var(--borderColor) !important;}
    .notifications-list .ant-list-item-meta-avatar{border-radius: 50%;width: 38px;height: 38px;text-align: center;line-height: 38px;}
    .notifications-list .ant-list-item-meta-title{margin-bottom: 0 !important;}
    .notifications-list .ant-list-item-meta{align-items: center;}
    
    .approvebg .ant-list-item-meta-avatar, .depositbg .ant-list-item-meta-avatar, .buybg .ant-list-item-meta-avatar, .deposit.fiatbg .ant-list-item-meta-avatar, .deposit.cryptobg .ant-list-item-meta-avatar, .paymentsbg .ant-list-item-meta-avatar{background-color: var(--bgDarkGreen);}
    .withdrawbg .ant-list-item-meta-avatar, .depositsbg .ant-list-item-meta-avatar, .sellbg .ant-list-item-meta-avatar, .withdraw.fiatbg .ant-list-item-meta-avatar, .withdraw.cryptobg .ant-list-item-meta-avatar{background-color: var(--bgDarkRed);}
    .requestbg .ant-list-item-meta-avatar, .documentsbg .ant-list-item-meta-avatar, .cutomersbg .ant-list-item-meta-avatar, .casesbg .ant-list-item-meta-avatar{background-color: var(--bgBlue);}
    .ant-alert-with-description{width: 100%; align-items:center;}
    /* batchpayments start  */
    .download-excel a{color: var(--termsText);}
    .pay-list .file-name{width: 250px;word-break: break-all;}
    .icon.md.back{margin-right: 8px;}
    .preview-back{margin-right: 8px;}
    .buysell-balances{text-align: center;margin: 0 0 20px 0 !important;}
    .faitwallet-cards .ant-list-items li:nth-child(n+3){display: none;}
    .cust-cancel-btn.back-btn-sumsub{margin-top: 12px !important;}
    /* .header-right .ant-menu-title-content:hover,.header-right .ant-typography.custom-header:hover, .header-right .ant-typography.custom-header:active, .ant-menu-item.ant-menu-item-active{color:var(--white);} */
    /* .header-right .ant-menu-title-content{color:var(--coinPrice)!important;}
    .header-right .ant-menu-title-content .custom-header{color:var(--textWhite30) !important;}
    .header-right .ant-menu-title-content .custom-inactive{color:var(--coinPrice) !important;}
    .header-right .ant-menu-title-content.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child{color: var(--white);}
    .header-right .ant-menu-title-content.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child .custom-header{color: var(--white);} */
    /* .custom-header{color:var(--coinPrice) !important;} */
    /* ul .mobile-header-default:active span{color:var(--white) !important} */
    /* .custom-inactive{color:var(--sbCardText) !important} */
    .sumsub-content{margin: 10px 0;}
    .icon.md.refresh-icon{background-position: -271px -33px;}
    .cust-cancel-btn.pop-space{margin-right: 8px;margin-bottom: 0;}
    .markets-drawer .ant-drawer-wrapper-body{background: var(--bodyBg) url(/static/media/dark-bg2.0.0e1c9286.svg) no-repeat top center !important;}
    .markets-grid tr:last-child td:first-child{border-bottom-left-radius: 20px;}
    .markets-grid tr:last-child td:last-child{border-bottom-right-radius: 20px;}
    .markets-grid .ant-table-tbody{border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}
    .markets-grid .side-drawer-header{padding: 0 16px 0 0;}
    .header-space{margin-bottom: 0 !important;}
    .basicinfo.batch-style{margin-bottom: 18px !important;}
    .bill-payment.bill-margin{margin-bottom: 10px !important;}
    /* batchpayments end */
    .no-transaction{padding-top: 18px !important;}
    .search-box .ant-input, .search-box .ant-input:focus,.search-box .ant-input:hover{color: var(--textWhite); border: 0;}
    .ant-btn.dbchart-link{padding-left: 0px;padding-right: 0px; margin-right: 8px;background-color: transparent;border: 0;}
    .main-container.grid-error{padding: 0 16px;}
    .fait-subtext .ant-typography-copy,.custom-copy .ant-typography-copy{transform: scale(0.8);}
    .fait-subtext .ant-typography-copy svg,.custom-copy .ant-typography-copy svg{margin-bottom: 5px;}
    .main-container.grid-demo{padding-top: 0;}
    .cust-list.main-container.case-demo{padding-bottom: 0;}
    .cust-pop-up-btn.ad-popup button:nth-child(2){margin-left: 0;}
    .kpi-divstyle.ad-rec-detyails{border-bottom: transparent;}
    /* .kpi-val.adview-name{width: 225px;} */
    /* .batchpayment-summary ~.ant-alert-with-description {width: 97% !important;margin-left: 20px;} */
    .preview-grid .cust-list,.box.text-white .cust-list{padding: 0;}
    .Supporting-Documents{margin-top: 30px;}
    .icon.md.Batch{background-position: -738px -37px;}
    .notkyc a{color:var(--viewAll);}
    .thank-you-btn{margin-top: 40px;}
    .proceed-section{margin-bottom: 34px;}
    .custom-declaraton.send-success{margin-top: 40px;}
    .custom-declaraton.send-success button,.success-pop.sell-success{margin-top: 26px;}
    .transaction-custom-table .pay-docs.bill-bank{text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 50%;}
    .cust-cancel-btn.transaction-cancelbtn{margin-left: 0 !important;}
    .value-description{font-weight: 400;color:var(--marketTitle);font-size: 14px;}
    .icon.xl.video{background-position: -241px -241px;}
    .kpi-val.adview-name{width: 230px;}
    .input-disabled-style .ant-select-selection-item{color: var(--textSecondary);}
    .travel-success{margin-top: 65px;}
    .dashbord-space .notices-content{margin-bottom: 36px;}.db-transactions thead,.fee-discount-table thead,.table-border thead{ background-color: var(--grid) !important;}.db-transactions{padding-top: 0;margin-top: 14px;height: 505px;overflow-y: auto;}.db-transactions::-webkit-scrollbar{display: none;}.db-transactions thead tr:first-child{border-bottom: none !important;}
    .download-btn{background-color: #3653E4; border-color: #3653E4;border-radius: 5px;font-weight: 600;transition: 0.3s ease; font-size: 16px; color: #FFFFFF;    padding: 10px 28px;   display: inline-block;    margin-left: 16px;}
    .validateiban-content:hover .ant-form-item-has-error .cust-input{border-color: var(--red) !important;}
    .crypto-list.auto-scroll.wallet-list .drawer-list-fiat .ant-image{vertical-align: middle;}
    .preview-file{color: var(--white);}
    .documentmodal-width .ant-spin.ant-spin-spinning{background: var(--drawerBG);}
    .new-add.hy-align .text-yellow.pr-24.align-check{vertical-align: middle;margin-right: 3px;}
    .align-declaration .declaration-content{margin-top: 50px;}
    .batch-upload .docnames{text-overflow: ellipsis; overflow: hidden;}
    .grid-content{color: var(--gridcontent);}
    .commission-text{color: var(--termsText);margin-bottom: 0;}
    .icon.lg.suissebase-credit{background-position: -213px -555px;}
    .user-labels.active .icon.lg.suissebase-credit, .user-labels:hover .icon.lg.suissebase-credit, .ant-tabs-tab.ant-tabs-tab-active .icon.lg.suissebase-credit, .ant-tabs-tab:hover .icon.lg.suissebase-credit{background-position: -213px -589px;}
    .Effective-Fees{color: var(--termsText);cursor: pointer;}
    .icon.lg.down-arrow{background-position: -389px -486px;margin-left: 4px;}
    .icon.lg.up-arrow{background-position: -411px -486px;margin-left: 4px;}
    .icon.lg.backup-icon{background-position:-288px -552px;}
    .user-labels.active .icon.lg.backup-icon, .user-labels:hover .icon.lg.backup-icon, .ant-tabs-tab.ant-tabs-tab-active .icon.lg.backup-icon, .ant-tabs-tab:hover .icon.lg.backup-icon{background-position: -288px -585px;}
    /* commissions  start   */
    .first-header th{text-align: center !important;}
    .fee-withdraw{padding: 0 !important;border-right: 1px solid var(--gridborder) !important;}
    .fee-withdraw table tr td:last-child,.fee-discount-table tr td:last-child,.table-border td:last-child{border-right: transparent !important;}
    .fee-withdraw tr:last-child td,.fee-discount-table tr:last-child td,.table-border tr:last-child td{border-bottom: transparent !important;}
    .fee-withdraw:last-child{border: transparent !important;}
    .cust-tr-style th{text-align: center !important;}
    .fee-table{height: -moz-fit-content;height: fit-content;padding-bottom: 0;}
    .fee-discount-table .ant-table-cell,.table-border th{padding: 8px 12px;}
    .fee-discount-table .ant-table-thead > tr:first-child th{border-bottom:1px solid var(--gridborder) !important;}
    .fee-table th:first-child{border-top-left-radius: 20px;}
    .fee-table th:last-child{border-top-right-radius: 20px;}
    .view-commition-table,.view-commition-table label{color: var(--gridcontent);}
    .view-commition-table{width: 100%;}
    .table-border tr .row-border:not(:first-child) tr{border-top:1px solid var(--gridborder);}
    .inside-table tr td{ border-right: 1px solid rgba(0, 0, 0, 0.08);}
    .table-border-right{border-right: 1px solid rgba(0, 0, 0, 0.08);}
    .table-border{border: transparent;}
    .table-border td{padding: 8px 12px;}
    .table-border thead{background-color: rgb(114 114 114 / 40%) !important;}
    table tr:last-child td:first-child{border-bottom-left-radius: 20px;}
    table tr:last-child td:last-child{border-bottom-right-radius: 20px;}
    .icon.commission-toggle{background-position: -256px -555px;height: 24px;width: 24px;cursor: pointer;position: absolute;
        right: 10px;z-index: 1;}
    .icon.commission-toggle:hover{background-position: -256px -589px;}
    .summarybal.total-amount{padding: 12px;border-radius: 12px;font-weight: 600;font-size: 18px;text-align: center;}
    .minmax-value{vertical-align: middle;font-weight: 700;}
    .crypto-details.crypto-bal{align-items: start;}
    .display-items.moblie-order .radio-grp{flex: 170px 0;}
    .fee-volume{display: flex;align-items: center;grid-gap: 6px;gap: 6px;}
    .customised-input{margin-bottom: 0;}
    .swap-entryvalue.send-crypto-style .ant-form-item-explain.ant-form-item-explain-error, .swap-entryvalue.send-crypto-style .ant-form-item-explain.ant-form-item-explain-validating{width: 100%;}
    .swap-entryvalue.send-crypto-style .ant-form-item-control-input {min-height: 120px;}
    .swap-entryvalue.send-crypto-style .ant-form-item-control {display: flex;flex-direction: inherit;}
    /* commissions  end */
    .video-block{padding: 8px;border: 1px solid var(--gridborder);}
    .video-section{border: 1px solid var(--gridborder);border-radius: 6px;}
    .video-section p{padding: 8px;background-color: var(--gridheader);}
    .icon.lg.backup-icon{background-position:-288px -552px;}
    .icon.lg.accountsummary{background-position: -351px -554px;}
    .user-labels.active .icon.lg.backup-icon, .user-labels:hover .icon.lg.backup-icon, .ant-tabs-tab.ant-tabs-tab-active .icon.lg.backup-icon, .ant-tabs-tab:hover .icon.lg.backup-icon{background-position: -288px -585px;}
    .btn.lightbox-231763449585064,.submit-button{background-color: var(--profiletab) !important;border-radius: 8px;border-color: var(--profiletab) !important;}
    .icon.lg.bank-letter{background-position: -321px -552px;}
    .jt-title{background-color: rgb(191 17 49) !important;}
    .submit-button{background-color: #1890ff !important;}
    .justify-content-center{justify-content: center;}
    .inactive-badge{display: inline-block;margin-left: 10px;}
    .cust-badge-style .file-label.add-lbl{width: auto;}
    .cust-badge-style .file-labels{margin-left: 8px;font-size: 12px;}
    .cust-badge-style{align-items: center;}
    .fee-personal-badge .file-label.add-lbl{display: inline;margin-left: 8px}
    .maxbuy-amount .ant-typography.buy-paragraph{display: inline-block; margin: 0; width: -moz-fit-content; width: fit-content;}
    .align-start{align-items: start !important;}
    .more-dropdown{margin-top: 4px;display: inline-block;}
    .marketplace-list.listitems-design{margin-right: 0 !important;}
    .align-start{align-items: start !important;}
    .more-dropdown{margin-top: 4px;display: inline-block;}
    .summary-download{min-width: 64px;}
    .minh-38{min-height: 38px;}
    .mt-36{margin-top: 36px;}
    .screen-height{height:50px;clear: both;}
    .mt-16{margin-top: 16px;}
    main.ant-layout-content.sidebar-push {    min-height: calc(100vh - 220px);}
    .menu-text{display: flex;align-items: center;justify-content: space-between;color: var(--textPureWhite);}
    .menu-text span{color: var(--textPureWhite);}
    .marketplace-list{min-height: 179px;}
    .marketcap-coinsize .ant-table-body{height: 1050px;}
    .z-10{z-index: 10;}
    .gap30{grid-gap: 30px;gap: 30px;}
    .marketplace-value{color: var(--white) !important;font-weight: 600;}
    .min-h-value{min-height: 44px;}
    .coming-soon span{color: #fff;background:#5b4ae5; padding: 4px 12px;border-radius: 14px;font-weight: 600;}
    .coming-soon .sepa-bg{background-color: #5b5b5c;font-size: 14px;}
    .coming-soon:has(> .sepa-bg) {display: inline-block;margin-left: 10px;}
    .fs-16:has(> .coin-style){font-size: 16px;font-weight: 600;line-height: normal;}
    .coin-style.mb-10{margin-bottom: 10px;}
    .rounded-full{border-radius: 50%;width: 45px !important;}
    .sb-card div:first-child{padding-top: 14px;align-items: center !important;grid-gap: 16px;gap: 16px;}
    .mt-87{margin-top: 87px;}
    .megamenu-title{font-size: 18px;font-weight: 600;display: inline-block;color: var(--primary) !important;margin-bottom:8px;}
    /* .appmenu-list {border: 1px solid var(--gridborder);border-bottom: 1px solid var(--gridborder) !important;background-color: var(--cardBG) !important;} */
    .appmenu-list {display: flex;flex-wrap: wrap;padding: 0;grid-gap: 8px;gap: 8px;}
    .appmenu-list li {text-align: center;list-style: none;padding: 8px;display: flex;align-items: center;}
    .appmenu-list li a{font-size: 18px;color: var(--coinBal);}
    .appmenu-list img{width: 46px;}
    .icon.lg.refdownload{background-position: -624px -33px; margin: 5px;}
    .bank-referrel-color .loader-antd{padding: 0 !important;margin: 5px;}
    .bg-wallester{background: var(--faitGradeBlue)!important;}
    .mb-0{margin-bottom: 0;}
    .pix-qr{width: 218px;height: 218px;}
    .suissebasefait-newbtn{display: flex;flex-wrap: wrap;grid-gap: 16px;gap: 16px;}
    .suissebasefait-newbtn .custom-btn.sec{margin-left: 0;}
    /*@media start*/
    @media (min-width: 768px){
        .appmenu-list li {width: 32%;}
        .search-bg.history-crypto-cards{height: 100%;}
        .sb-card{width: 438px;}
        .flex-1 .ant-form-item-label > label::after{right: 0 !important;}
        .listitems-design{width: 340px;}
        .marketplace-list.listitems-design{width: 438px;min-height: 248px;position: relative;}
        .tlv-header{height: 90px !important;}
        .header-logo{padding-left: 0 !important;}
        .visible-mobile{padding-left: 16px !important;}
        .agree-check input[type=checkbox]+span{background-position: -115px -519px;}
        .agree-check input[type=checkbox]:checked+span:hover{background-position: -153px -519px;}
        .agree-check input[type=checkbox]:checked+span:hover{background-position:-153px -519px;}
        .agree-check input[type=checkbox]:checked+span{background-position:-153px -519px;}
        .agree-check input[type=checkbox]+span:hover{background-position: -190.5px -519px;}
        .text-style{margin-top: 16px !important;}
        /* .successsubtext{height: 400px;} */
        .round-pills .ant-radio-button-wrapper{line-height: 30px;}
        /* .select-currency{height: 400px;} */
        .val-updown{right: 20px;top:45px;}
        .import-note{width: auto;}
        .side-drawer .ant-drawer-header{padding:12px 30px 0px 30px !important}
        .inputfont-style{font-size:36px;}
        .buy-paragraph{width: 300px;margin: auto;}
        .buy-paragraph.buy-para-wd{width: auto !important;}
        .crypto-card .ant-card-body{padding: 16px 14px;height: 94px;}
        .custom-radiobtn .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{padding:10px 36px;}
        .custom-radiobtn .ant-radio-button-wrapper{padding: 0 15px;}
        .basicprofile-info{width: auto;}
        .moble-mb-style{margin-bottom: 16px;}
        .arrow-ml{margin-left: 12px;}
    
    }
    @media (min-width: 992px){
        .appmenu-list li {width: 16%;}
        .grid-gap-16 .ant-list-items{grid-gap: 16px !important;display: flex;}
        .profile-alert-style{width: 510px !important;align-items: center;}
        .bill-payment{display: flex; align-items: center; justify-content: space-between;margin-bottom: 36px;}
        .visible-mobile, .sideropen{display: none;}
        .bill-grid .k-grid-header table{width: 1266px !important;}
        .bill-grid .k-grid-content table{width: 1266px !important;}
        .side-drawer .ant-drawer-content-wrapper{width: 524px !important;}
        /* .buycoin-style{font-size: 50px;} */
        .cryptosummary-container{max-height: calc(100vh - 76px);}
        /* .successsubtext{height:230px;} */
        /* .auto-scroll{overflow-y: auto;padding-right: 10px;} */
        .agree-check input[type=checkbox]+span {background-position: -115px -513px;}
        .agree-check input[type=checkbox]+span{background: var(--lightThemeIcons);background-position:-115px -513px;transform:scale(1.5);display: inline-block;width: 34px;height: 34px;vertical-align: text-top;cursor: pointer;}
        .agree-check input[type=checkbox]:checked+span{background: var(--lightThemeIcons);background-position:-153px -513px;border-color: transparent !important;}
        .agree-check input[type=checkbox]+span:hover{background-position:-190.5px -513px;}
        .agree-check input[type=checkbox]:checked+span:hover{background-position:-153px -513px;}
        /* .successsubtext{height: auto;} */
        .text-style{margin: 30px 0 !important;}
        /* .select-currency {height: 122px;} */
        /* .text-style{height: 140px;} */
        /* .full-screenable-node{overflow: auto;} */
        .val-updown{top: 45px;right: 18px;}
        .enter-val-container.swap-com{margin-bottom: 4px;}
        .import-note{width: 463px;}
        .tlv-header{max-width: 1200px;margin: auto;padding: 12px 0 !important;align-items: center;}
        .basicprofile-info{width: 510px;}
        .swap-entryvalue{flex: 0 0 305px;}
        .swap-text-sub{margin-top: -4px;}
        .new-swap-subtext.send-crypto-enrty{margin-bottom: 24px;}
        .summary-liststyle{margin-right: 26px;}
        .search-box.mobile-search{display: none;}
        .suissebasefait-newbtn{margin-top: 75px;}
        .full-screenable-node.marketcap-mt .db-titles,.full-screenable-node.marketcap-mt .icon-space{margin-bottom: 0 !important;}
        .markets-panel.transaction-panel .db-titles, .markets-panel.transaction-panel .icon-space, .fait-wallets-style .db-titles{margin-bottom: 0;}
        .marketcap-mt .db-titles.crypto-style{margin-bottom:0}
        .full-screenable-node.marketcap-mt, .transaction-panel{margin-top:30px;}
    .suissebasefait-newbtn{margin-top:36px;}
    .crypto-style{margin-top:36px}
    .fait-wallets-style{margin-top:36px;}
    .notices-style .db-titles{margin-bottom:16px;}
    .fait-wallets-style.notices-style, .full-screenable-node.marketcap-mt{margin-top: -6px;}
    .addressbook-crypto-view .ad-rec-detyails{margin-right: 24PX;}
    .cust-tabs-fait .ant-tabs-nav{width: 100%;}
    }
    
    @media (min-width: 1024px){
    
    }
    @media (min-width: 1200px){
        .coinveiw-newpage{width: 900px;margin:0 auto ;}
        /* .sb-card{width: 482px;} */
        .iban-list .iban-style{width: auto !important;float: unset; padding: 12px !important;flex: 1 1;margin-right: 0;}
        .iban-list .ant-list-items{display: flex;grid-gap: 16px;gap: 16px;}
        .iban-list .custom-btn{min-width: 81px;}
        .main-container{max-width: 1200px; padding: 30px 16px 24px;}
        .crypto-list .ant-list-item, .wallet-list .ant-list-item{margin-top:12px;}
        .inputfont-style{font-size: 36px;}
        .custom-radiobtn .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{padding:10px 36px;}
        .custom-radiobtn .ant-radio-button-wrapper{padding: 0 15px;}
        /* .ant-popover.ant-popover-placement-left{left: 872px !important;} */
        .transctns-grid .ant-drawer-header, .transctns-grid .ant-drawer-body{padding-left: 100px;padding-right: 100px;}
        .adbook-scroll{max-height: 418px;overflow: auto;}
        .your-card{margin-top: 8px;}
        .gridLink.batch-filename{padding-left: 0;}
    }
    @media (min-width: 1400px){
        .video-block{flex: 421.9px 1;}
        .transctns-grid.preview-grid .ant-drawer-header .side-drawer-header,.transctns-grid.preview-grid .sub-heading,.batch-btns{max-width: 1300px; margin: 0 auto;}
        .main-container{max-width: 1300px; margin: 0 auto;}
        .iban-list .ant-list-items{display: flex;}
        .listitems-design{width:376px;}
        /* .marketplace-list.listitems-design{width: 305px;} */
        .listitems-design.iban-style{width: 242px;}
        .side-drawer .ant-drawer-body{padding: 0 30px 16px 30px;}
        /* .marketplace-list.listitems-design{width: 305px;} */
        .agree-check{height: 100px;}
        .travel-custcheck{height: auto;}
        /* .successsubtext{height: calc(100vh - 592px);} */
        .custom-height {max-height: calc(100vh - 926px);}
        /* .select-currency {height: calc(100vh - 590px);} */
        .iban-list .ant-list-items{display: 238px;}
        /* .full-screenable-node{width: 430px;} */
        .crypto-list .ant-list-item, .wallet-list .ant-list-item{margin-top: 16px;}
        .inputfont-style{font-size: 36px;}
        .side-drawer .ant-drawer-content-wrapper{width: 524px !important;}
        .val-updown{top:45px;right: 20px;}
        .iban-list .iban-style{padding: 14px 20px;}
        .addCryptoList{max-height: 590px;}
        .addCryptoList .xxl-fs{font-size:14px !important;}
        .addCryptoList .xxl-fs-16{font-size:16px !important;}
        .tlv-header{max-width: 1300px; }
        .cust-col-design{flex: 0 0 482px;}
        .db-flex-style{flex:1 1;}
        .adbook-scroll{max-height: 610px;}
    
    }
    @media (max-width: 991.98px){
        .price-valred{margin-left: 0;}
        .visible-mobile .ant-tabs-top > .ant-tabs-nav::before{border-bottom: 0 !important;}
        .visible-mobile .user-list .ant-tabs-nav{width: auto;}
        .visible-mobile{padding-left: 8px;padding-right: 24px;}
        .tlv-header .sideropen {background: var(--profileMenu) !important;}
        .sideropen{margin-left: -16px;padding: 12px;margin-top: 14px;border-radius: 0;min-height: 100vh;
            /* border-top-right-radius: 6px;border-bottom-right-radius: 6px; */
        }
        .sideropen .ant-menu-vertical,.ant-menu-vertical{border-right: 0 !important;}
        .sideropen .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{background-color: transparent;}
        .sideropen .list-item.ant-menu-item-selected .ant-menu-title-content{min-width: 150px !important; padding: 8px 5px 8px 5px !important}
        .login-user{width: 100%;padding: 2px 0;}
        .video-block .text-white{text-align: center;}
    }
    @media (max-width: 767.98px){
        .mb-wallester{margin-bottom: 16px;}
        .appmenu-list li{flex: 45% 1;}
        .marketplace-list{min-height: 240px;}
        .sb-card{width: 100% !important;}
        .cust-col-design{min-height: auto !important;}
        .header-right ul:last-child li:last-child {line-height: 20px;}
        .video-tutorials{display: block;}
        .newSpace{display: flex; padding-top: 14px;}
        .field-width{width:120px !important;}
        .new-custom-radiobtn.mb-24 label:first-child{margin-left:-3px; margin-right:10px;}
        .flex-1 .ant-form-item-label > label::after{top:10px;right:85px;}
        .form-block {
            width: 100% !important;
            padding: 60px 20px !important;
            margin: 0 0 40px 0 !important;
        }
        .video-tutorials{display: block;}
        .sumSub-confirm{width:auto;height: auto;}
        .cust-sumsub-font{width: auto;}
        .new-viewpage .search-box{margin-left: 0;}
        .fait-wallets-style.new-viewpage{display: block !important;}
        .footer-links a{margin-right: 0px !important;}
        .sidebar-push{margin: 62px 0 0 !important;}
        .visible-mobile .side-drawer .side-drawer-header.change_password{align-items: self-start !important;}
        /* .visible-mobile{margin-right: 24px;padding-left: 24px;padding-right: 24px;} */
        .main-container{padding-left: 0 !important;padding-right: 0 !important;}
        .listitems-design{width: 344px;margin-bottom: 16px;}
        .val-updown{top: 46px;right: 18px;}
        .footer-links{text-align: center;margin: 0 10px;}
        .fund-border{padding: 12px;}
        .small-text-align{margin-left: 18px;}
        .address-name{font-size: 14px;}
        .mobile-btn-pd .ant-form-item{margin-bottom: 16px;}
        .profile-image-content{display: block;}
        .dwnl-content-style{margin-top: 16px;}
        .profile-info{margin: 0 auto;}
        .profile-img-style{margin-right: 0;}
        .basicprofile-info{width: auto;}
        .security-align{display: block;}
        .moble-mb-style{margin-bottom: 16px;}
        .arrow-ml{margin-left: 12px;}
        .crypto-address.share-width-align{width: auto;}
        .profile-value.mv-profile-value{word-break: break-all;}
        .custom-display{margin-left: 0;}
        .crypto-address.share-width-align, .adbs-mb{display: flex;}
        .coin-bal ul{padding: 0;}
        .search-box:hover > .search-text{width: 120px;}
        .sb-innercard{padding: 16px;}
        .coin-bal{display: block !important;}
        /* .kpi-divstyle:last-child{border-bottom: 0;} */
        .theme-switch{width: auto;margin-bottom: 0;}
        .walletadrs{margin-left: 30px;}
        .ant-tabs-tab + .ant-tabs-tab{margin: 0 0 0 26px;}
        .display-items{display: block;}
        .crypto-details{justify-content: center;}
        .batch-actions{margin-top: 16px;}
        /* .k-loading-image::before, .k-loading-image::after{top:60% !important;position: absolute !important;} */
        .custom-theme-btn{display: flex;}
        .search-box:hover > .search-text.search-view input,.search-box.mobile-search:hover > .search-text input{width: 300px;}
        .search-box.markets-search,.search-box.markets-search:hover > .search-text input{width: 280px;}
        .theme-text{display: none;}
        .price-valgreen div.lg-fontsize { word-break: break-all;}
        .mobile-viewall{margin-bottom: 11px;}
        .bill-payment {display: flex;justify-content: space-between;align-items: center;margin-bottom: 8px;}
        .addressbook-mb{font-size: 22px !important;}
        .gridLink.batch-filename{padding-left: 0;}
        .preview-grid .grid-title{margin-bottom: 0 !important;}
        .new-custom-radiobtn .ant-radio-button-wrapper{padding:0 2px;}
        .cust-tabs-fait .ant-tabs-nav{width: 308px;overflow: auto;}
        .ant-drawer-header{padding: 0 24px;}
        .ant-drawer-body{padding-top: 0;}
        .db-titles.your-card{margin-top: 50px;}
        .search-box.mobile-hide{display: none;}
        .search-box.mobile-search{margin: 0;}
        .cust-tabs-fait .ant-tabs-nav{width: 100%;overflow: auto;} .cust-heightmbl{height: -moz-fit-content;height: fit-content;}
        .send-minmax{margin-top: 15px;}
        .moblie-order {display: flex;flex-direction: column-reverse;margin-top: 20px;}
        .crypto-card.select.space-hide{display: none;}
        .send-crypto-mobile .swap-entryvalue {flex: 0 0 200px;}
        .send-crypto-mobile .swap-text-sub, .send-crypto-mobile .swapcoin-alignemnt{font-size: 18px;}
        .send-crypto-err .custom-forminput .ant-form-item-control .ant-form-item-explain-error, .send-crypto-err .custom-forminput .ant-form-item-control .ant-form-item-explain-validating{  position: absolute; bottom: -41px !important;}
        .crypto-btn-mobile{margin-top: 40px;}
        .adbook-mobile{margin-left: 8px;}
        .adbook-mobile.past-respnt{margin-left: 16px;}
        .mobile-scroll{max-height: -moz-fit-content !important;max-height: fit-content !important;}
        .paste-recept-style.mobile-scroll{height: -moz-fit-content !important;height: fit-content !important;}
        .checkbox-mobile{margin-top: 16px;}
        .sb-text{font-size: 18px;}
        .tlv-logo{height: 40px;}
       .transaction_resp .ant-form-item-label > label,.customaudit-select .selectcustom-input .ant-form-item-label > label{ height: auto !important;}
       .transaction_resp .ant-form-item .ant-form-item-label,.customaudit-select .selectcustom-input.ant-form-item .ant-form-item-label{ padding-bottom: 0 !important;}
       .transaction_resp .search-btn,.customaudit-select .search-btn{ float: right;}
        .ant-drawer-header{padding-bottom: 0px;}
        .moblie-order.buy-sell-mobile .sell-radiobtn-style { flex: 0 0;}
        .moblie-order.buy-sell-mobile .round-pills.sell-radiobtn-style{width: -moz-fit-content;width: fit-content;margin-top: 16px;}
        .mobile-receive-fiat{margin-left: 0px;}
        .mobile-transaction{left: 144px !important;}
        .new-custom-radiobtn .ant-radio-button-wrapper-checked, .new-custom-radiobtn .ant-radio-button-wrapper{font-size: 14px;}
        .sideropen .ant-menu-vertical > .ant-menu-item{height: 30px; line-height: 30px;}
        .sideropen .drpdwn-list li a{padding: 6px 0 6px 6px;}
        .transacton-drpdwn{position: relative;}
        .new-crypto-style .cytpo-list-style .crypto-card-design{display: block;}
        .new-crypto-style .cytpo-list-style .crypto-card-design .price-valgreen{margin-left: 0px;padding: 4px;}
        .transaction-panel, .crypto-style{margin-top: 30px;}
        .table-border td { min-width: 150px;    }
        .inputfont-style{font-size: 26px !important;}
        .sumSub-confirm{width:auto;height: auto;}
        .cust-sumsub-font{width: auto;}
        .sm-mb-16{margin-bottom: 16px;}
    }
    @media (min-width: 375px){
        .flex-1 .ant-form-item-label > label::after{right: 78px !important;}
    }
    @media (min-width: 390px){
        .flex-1 .ant-form-item-label > label::after{right: 108px !important;}
    }
    @media (min-width: 414px){
        .flex-1 .ant-form-item-label > label::after{right: 130px !important;}
    }
    
    @media (min-width: 768.1px){
        .flex-1 .ant-form-item-label > label::after{right: 0 !important;}
        .sumSub-confirm{display: flex;justify-content: center;}
        .md-d-flex{display: flex;}
    
    }
    .mb-8{margin-bottom: 8px !important;}
    .mt-0{margin-top: 0 !important;}
    
    /*@media end*/
    .Effective-Fees-fs .ant-form-item-label>label{color: var(--termsText);}
    .approve-inprogress{padding: 13px;}
    .disable-card{opacity: 0.4 !important; cursor: not-allowed !important;}
    .cust-badge-design{font-weight: 600;font-size: 14px;background: #d85e5e;padding:0px 8px;border-radius: 13px;display: inline-block;color: #fff !important; border: 1px solid #cecece;}
    .summary-loader .loader-antd{padding: 0;}
    .marketplace-list.bg-blue{background-color: linear-gradient(180deg, #627EEA -115.75%, #00061d 100%) !important;}
    .flex-1 .ant-form-item-label > label::after{right: 78px !important;}
}
@media (min-width: 390px){
    .flex-1 .ant-form-item-label > label::after{right: 108px !important;}
}
@media (min-width: 414px){
    .flex-1 .ant-form-item-label > label::after{right: 130px !important;}
}

@media (min-width: 768.1px){
    .flex-1 .ant-form-item-label > label::after{right: 0 !important;}
    .sumSub-confirm{display: flex;justify-content: center;}
    .md-d-flex{display: flex;}

}
.mb-8{margin-bottom: 8px !important;}
.mt-0{margin-top: 0 !important;}

/*@media end*/
.mbottom-0{margin-bottom: 0 !important;}
.Effective-Fees-fs .ant-form-item-label>label{color: var(--termsText);}
.approve-inprogress{padding: 13px;}
.disable-card{opacity: 0.4 !important; cursor: not-allowed !important;}
.cust-badge-design{font-weight: 600;font-size: 14px;background: #d85e5e;padding:0px 8px;border-radius: 13px;display: inline-block;color: #fff !important; border: 1px solid #cecece;}
.summary-loader .loader-antd{padding: 0;}
.marketplace-list.bg-blue{background-color: linear-gradient(180deg, #627EEA -115.75%, #00061d 100%) !important;}
.ant-layout-header{
    background: #fff !important;
}
