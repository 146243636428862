@import url('../fonts/stylesheet.css');
/* * { scrollbar-width: thin; scrollbar-color: var(--scroll) var(--scroll);} */
*::-webkit-scrollbar { width: 12px;height: 10px;  }
::-webkit-scrollbar-corner {background: var(--scroll);}
*::-webkit-scrollbar-thumb {background-color: var(--scroll);border-radius: 20px;}
/* .ant-layout, body{background-color: var(--bgDarkGrey);} */
.ant-btn{font-weight: var(--fw500);}
.ant-drawer-close{display: none;}
.d-inlineblock{display: inline-block !important;}


.ant-alert{border-radius: 10px;margin-bottom: 16px;}
.ant-alert-message{font-size: 18px !important;font-weight: 400;color: var(--textDark) !important;line-height: normal;}
.ant-alert-description{font-size: 16px !important;color: var(--textSecondary) !important;}
.grey-search.ant-input-search .ant-input-group input, .grey-search.ant-input-search .ant-input-group .ant-input-group-addon{background-color: var(--bgGrey) !important;}
.ant-input-search .ant-input-group-addon .ant-btn{background-color: transparent; border: none; width: 42px;box-shadow: none;}
.ant-dropdown-arrow{width: 10px; height: 10px; background-color: var(--bgGloom);top: 5px !important;}
.dark-search +.ant-table-wrapper .ant-table-container{background-color: var(--bgGrey) !important;}
/*--Dashboard--*/


.chart-loader{display: flex;justify-content: center;height: 400px; align-items: center;}
#graph-container, #container{width: 100%; height: 100%;}
.markets-panel .ant-list-split .ant-list-item, .portfolio-list .ant-list-split .ant-list-item{border-color: var(--borderGrey) !important; padding: 14px 0;transition: 0.3s ease;}
.portfolio-list h1{padding: 36px 0 16px;}
.portfolio-list .ant-list-split .ant-list-item, .markets-line .ant-list-split .ant-list-item{border-top: 1px solid var(--borderDark) !important;}
.portfolio-list .ant-list-split .ant-list-item:last-child{border-bottom: 1px solid var(--borderDark) !important;}
.portfolio-list .ant-list-split .ant-list-item .ant-list-item-meta-avatar{margin-top: 4px;}
.btn-primary{background-color: var(--btnPrimary) !important;}
.btn-back{background-color: transparent;border: 0;border-radius: 40px;height: 42px;text-transform: uppercase;transition: 0.3s ease;}
.btn-back:hover{background-color: var(--bgDarkYellow);color: var(--bgDark);font-weight: 500;}
.login-user, .side-drawer .side-drawer-header, .side-drawer-full .side-drawer-header, .wire-trans, .crypto-card-bottom{display: flex;justify-content: space-between;align-items: center;}
.header-logo{list-style: none;display: inline-flex;padding-right: 0 !important;align-items: center;margin-bottom: 0;}

.header-logo li:first-child::after{content: '';position: absolute;width: 1px;height: 27px;right: 0;top: 16px;background-color: var(--bgWhiteSpin);}
.tlv-header .list-item.ant-menu-item .ant-menu-title-content{padding:6px 12px;}
.tlv-header .list-item.ant-menu-item-selected .ant-menu-title-content, .tlv-header .list-item.ant-menu-item-active .ant-menu-title-content{
background-image: linear-gradient(99.09deg, var(--btnGrad1) 0%, var(--btnGrad2) 100%); filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));margin: 0px;border-radius: 5px;border:0;
line-height: 16px;min-width:90px;color:var(--textPureWhite);}
.custom-btn{border-radius: 5px;border:0;line-height: 18px;min-width:90px;height: 36px;font-weight: 300;font-size: 16px;filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));transition: 0.5s ease;}
.custom-btn.outline{border-color: var(--bgDarkGrey) !important;}
.custom-btn.sec.network{border: 1px solid #8a8ab8; padding: 8px 10px;line-height: 18px; margin-right: 5px;font-size: 12px;}
.carousel-card{background-color:var(--cardBG) !important;border-radius: 13px;height: 110px;padding: 14px 20px;overflow-y: auto;}
.ant-carousel .slick-dots-bottom{bottom: 10px  !important;}
.ant-carousel .slick-dots li button{background: var(--primary) !important; opacity: 0.5;height: 4px;border-radius: 2px;}
.ant-carousel .slick-dots li.slick-active button{background: var(--primary) !important; opacity: 1;}
.ant-carousel .slick-dots li{width: 8px; }
.ant-carousel .slick-dots li.slick-active{width: 30px;}

.ant-modal.megamenu{top: 0 !important;bottom: 0 !important;width: 100% !important;max-width: 100%;}
.megamenu-backdrop, .megamenu .ant-modal-header{background-color: var(--bgGloom);}
.megamenu .ant-modal-header{border: none;}
.megamenu-title{display: flex; justify-content: space-between; padding-right: 60px;align-items:center ; }
.megamenu .ant-modal-content{box-shadow: none; background-color: var(--bgGloom) !important;min-height: calc(100vh - 70px); max-height: calc(100vh - 70px);}
.megamenu-link a, .megamenu-sublink a,.megamenu-link .menu_Link{font-size: 18px; color: var(--textWhite30); display: block;font-weight: 300;cursor: pointer;margin-bottom: 0;}
.megamenu-sublink a{font-size: 14px; margin-bottom: 24px;}
.megamenu-link a:hover, .megamenu-sublink a:hover,.megamenu-link .menu_Link:hover{color: var(--textAqua) !important;}


.dropdown-flex-top{display: flex;justify-content: space-between;align-items: center;}
.drpdwn-list li:first-child a:first-child{border-top: none}
.dropdown-flex{border-bottom: 1px solid var(--bgDarkGrey);display: flex;justify-content: space-between;align-items: center;}
.drpdwn-list li.no-hover:hover{background-color: transparent !important;}
.drpdwn-list li span.icon{transition: margin 0.3s ease-in; margin-left: auto;}

.icon.xl{width: 32px; height: 32px;}
.icon.md{width: 20px; height: 20px;}
.icon.xxxl{width: 70px; height: 70px;}
.icon.xl.closewhite{background-position: -2px -243px;}
.icon.md.close{background-position: -42px -70px;}
.icon.lg.greencheck{background-position: -68px -65px; position: absolute;top: 37px;right: 19px;transform: scale(0.8);}
.icon.lg.close{background-position: -262px -516px; position: absolute;top: 37px;right: 19px;transform: scale(0.8);}
.icon.lg{width: 26px; height: 26px;}
.icon.lg.drawer-close{background-position: -1px -112px;}
.icon.lg.check-ylw{background-position: -36px -111px;}
.icon.lg.chat{background-position: -429px -1px;}
.icon.md.bell{background-position: -1px 0;}
.icon.md.gear{background-position: -30px -1px;}
.icon.md.hamburger{background-position: -59px -1px;}
.icon.md.lose{background-position: -271px -2px;}
.icon.md.gain{background-position: -302px -2px;}
.icon.md.downarrow{background-position: -59px -2px;}
.icon.md.close-white{background-position: -170px 0;}
.icon.md.date-white{background-position: -480px -37px;}
.icon.md.search-white{background-position: -197px 0;}
.icon.md.load{background-position: -242px 0;float: right; margin-top: 2px;}
.icon.md.info{background-position: -74px -327px;transform: scale(0.8); width: 28px;height: 28px;}
.icon.md.lftarw-white{background-position: -377px 0;}
.icon.md.downarrow-icon{background-position: -350px 0;}
.icon.md.history-white{background-position:-272px -33px;}
.icon.md.card{background-position: -480px -1px;}
.icon.md.phone{background-position: 0px -35px;}
.icon.md.qrcode{background-position: -140px -34px;}
.icon.md.swaparrow{background-position: -236px -35px;margin-bottom: 6px;}
.icon.md.sentarrow{background-position: -307px -35px}
.icon.md.recivearrow{background-position: -338px -35px}
.icon.md.check-arrow{background-position: -366px -36px}
.icon.lg.camera{background-position: -397px -31px;}
.icon.md.eye{background-position: -173px -34px;}
.icon.md.address-book{background-position:-239px -70px}
.icon.md.approve{background-position:-184px -70px}
.icon.md.profit-arw{background-position:-308px -34px}
.icon.md.lose-arw{background-position:-338px -34px}
.icon.md.download{background-position:-627px -38px;}
.icon.md.delete{background-position:-465px -102px;}
.icon.md.delete-icon{background-position:-465px -101.5px;}
.icon.lg.hamburg{background-position: -55px 2px; transform: scale(1.4);}
.icon.md.notifyIcon.deposit{background-position: -375px 0px;transform: rotateZ(235deg);}
.icon.md.notifyIcon.deposits, .icon.md.notifyIcon.withdraw{background-position: -376px -1px;transform: rotateZ( 45deg);}
.icon.md.notifyIcon.documents, .icon.md.notifyIcon.cutomers{background-position: -156px -71px;}
.icon.md.notifyIcon.cases{background-position: -213px -332px;}
.icon.md.notifyIcon.payments{background-position: -481px -3px;}
.icon.sm{width: 12px; height: 12px;}
.icon.sm.downarrow{background-position: -107px -4px;margin-left: 8px;}
.icon.sm.uparrow{background-position: -87px -4px;}
.icon.sm.rightarrow{background-position: -78px -40px;}
.icon.sm.leftarrow{background-position: -92px -40px;}
.icon.sm.downarrow-white{background-position: -148px -5px;transform: scale(1.1);}
.icon.sm.uparrow-white{background-position: -128px -6px;}
.icon.sm.r-arrow-o-white{background-position:-222px -5px;}
.icon.sm.uparw-o-white{background-position:-332px -6px;}
.icon.sm.dwnarw-o-white{background-position:-355px -5px;}
.icon.sm.rightthemearrow{background-position: -149px -4px;}
.icon.sm.add{background-position:-32px -40px;}
.icon.sm.right-angle{background-position:-510px -6px;}
.icon.sm.search-angle{background-position:-561px -8px; margin-top: -3px}
.icon.md.diag-arrow{background-position:-111px -36px;}
.icon.md.leftarrow{background-position:-376px -1px}
.icon.md.rarrow-white{background-position:-217px -1px;}
.icon.md.signal-white{background-position: -68px -112px;width: 25px !important;height: 25px !important;}
.icon.md.h-more{background-position:-53px -37px;}
.icon.md.downangle{background-position: -352px 0;}
.icon.md.upangle{background-position: -352px 0;}
.icon.md.greyCheck{background-position: -97px -71px;}
.icon.md.greenCheck{background-position: -71px -71px;}
.icon.md.attach{background-position: -427px -33px;}
.icon.md.send-icon{background-position:-452px -34px;}
.icon.md.reply{background-position:-602px -4px;}
.icon.md.file{background-position:-156px -70px;}
.icon.md.buy{background-position:-568px -35px;}
.icon.md.sell{background-position:-538px -36px;}
.icon.md.menu-bar{background-position:-321px -134px;}
.icon.md.withdraw{background-position:-510px -34px;}
.icon.md.deposit{background-position:-655px -38px;}
.icon.md.user{background-position:-212px -70px;}
.icon.md.calendar{background-position:-481px -36px;}
.icon.md.status{background-position:-365px -69px}
.icon.md.add-icon{background-position:-397px -70px;}
.icon.md.process-icon{background-position: -680px -37px;}
.icon.md.back{background-position:-377px -1px;}
.icon.md.add-icon-black{background-position:-533px -2px;}
.icon.md.edit-icon{background-position:-336px -70px;}
.icon.lg.edit-icon{background-position:-336px -70px;}
.icon.md.email{background-position:-127px -70px;}
.icon.lg.add-icon{background-position:-397px -70px;}
.icon.lg.app-menu{background-position:-235px -130px;}
.icon.md.status-icon{background-position:-637px -134px;}
.icon.xl.image{background-position:-47px -241px;}
.icon.xl.file{background-position:-87px -241px;}
.icon.xxxl.doc-upload{background-position:1px -398px;}
.icon.lg.beneficiary-icon {background-position: -207px -130px;}
.icon.md.address-book-icon{background-position: -581px -3px;}
.header-right li{padding: 0 !important;margin: 0 6px; font-size: 16px; color: var(--textWhite30) !important;}
.header-dropdown{font-size: 24px; color: var(--textBlack) !important;}
.mid-menu{display: flex; align-items: center;}
.box.dash-info{border-radius: 0 !important;}

.trade-btn{border-radius: 5px;  line-height: normal; height: 20px;padding: 0 4px;}
/* Buy/sell */
/* .side-drawer .ant-drawer-body, .side-drawer .ant-drawer-header, .side-drawer-full .ant-drawer-header, .side-drawer-full .ant-drawer-body, .side-drawer-full .ant-table-cell, .side-drawer-full .ant-table-tbody > tr.ant-table-row:hover > td, .side-drawer-full .ant-table-thead th.ant-table-column-sort, .side-drawer-full .ant-table-thead th.ant-table-column-has-sorters:hover{background-color: var(--bgGrey);} */
.side-drawer .ant-drawer-mask, .side-drawer-full .ant-drawer-mask{background-color: var(--bgDarkGrey-95);}
.side-drawer .ant-drawer-header,.side-drawer-full .ant-drawer-header, .crypto-list-tabs .ant-tabs-nav::before{border: none;}
.side-drawer-header.custom-drawer-header{padding: 30px 46px 30px 0px !important;}
.side-drawer-full .ant-drawer-body{padding-top: 0 !important;}
.side-drawer-full .side-drawer-header{font-size: 22px;font-weight: 400;}
.buysell-toggle{border-radius: 20px; background: var(--bgDarkGrey); padding: 10px;display: inline-block;margin: 0 auto 50px}
.buysell-toggle .ant-radio-button-wrapper{width: auto;border-radius: 15px;border: none;background: transparent;color: var(--textYellow);text-transform: uppercase;font-weight: 600;padding: 20px 40px; line-height: 0;font-size: 14px;text-align: center;}
.buysell-toggle .ant-radio-button{border-radius: 15px;font-size: 12px;}
.buysell-toggle .ant-radio-button-checked{background: var(--bgYellow);border-left: none;}
.buysell-toggle .ant-radio-button-wrapper-checked{color: var(--textDark) !important; z-index: 0;}
.buysell-toggle .ant-radio-button-wrapper::before, .crypto-card .ant-card-body::before, .crypto-card .ant-card-body::after{content: none;}
.crypto-list-tabs .crypto-list{max-height: calc(100vh - 391px); overflow-y: auto; padding-right: 10px;}
.crypto-list-tabs .ant-input-search-button, .ant-input-search-middle .ant-input-search-button{display: none;}
.crypto-list-tabs .ant-input-group-addon .icon, .ant-input-search .ant-input-group-addon .icon{margin-right: 16px !important;}
.sellcrypto-container{max-height: calc(100vh - 285px);}
.crypto-container{max-height: calc(100vh - 270px);}
.suisfiat-container{max-height: calc(100vh - 165px);}
.suisfiat-height{max-height: calc(100vh - 158px);}
.addbook-height{max-height: calc(100vh - 84px);}
.verify-container{max-height: calc(100vh - 140px);}
.dep-withdraw{max-height: calc(100vh - 265px);}
.selectcrypto-container{max-height: calc(100vh - 101px);}
.auto-scroll::-webkit-scrollbar-thumb{background-color: var(--bgDark1);}
.ant-list-split .ant-list-item:last-child .crypto-list-tabs .ant-list-item, .wallet-list .ant-list-item{border-color: var(--borderLight);}
.wallet-list .ant-list-item:hover, .wallet-list .ant-list-item.select, .addCryptoList li.select{background-color: var(--bgBlu);}
.wallet-list li a, .crypto-list li a{display: inline-flex; align-items: center;flex-grow: 1; padding: 0px 5px;}
.wallet-list h4{margin-bottom: 0;}
.crypto-toggle .ant-radio-button-wrapper{padding: 22px;}
.crypto-list-tabs .ant-tabs-tab{font-size: 16px; margin: 0; padding-bottom: 0;}
.crypto-list-tabs .ant-tabs-tab-btn{padding: 3px 33px;color: var(--textYellow);}
.crypto-list-tabs .ant-tabs-tab-btn:focus{color: var(--textYellow);}
.crypto-list-tabs .ant-tabs-tab-active .ant-tabs-tab-btn{color: var(--textDark) !important; background: var(--bgYellow); border-radius: 30px;}
.crypto-list-tabs .ant-tabs-ink-bar{display: none;}
.pop-btn,.confirm-btn{font-size: 14px;}
.confirm-btn{height: 48px;}
.confirm-btn, .confirm-btn:hover, .confirm-btn:focus, .confirm-btn:active {background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2));  border-radius: 40px;text-transform: uppercase;font-weight: 700; transition: 0.5s ease; color: var(--textPureWhite) !important;border: 1px solid linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2)) !important;}
.pop-btn.ant-btn[disabled]{background-color: #3653E4 !important; border-color: #3653E4   !important;color: #FFFFFF; cursor: not-allowed !important; }
.btn-dropdown .ant-btn:hover{background-color: var(--bgDarkYellow); color: #000;}
.pop-cancel{background-color: transparent !important;color: var(--White) !important; border-color: transparent !important; border-radius: 40px;height: 57px;padding: 0;}
.pop-cancel:hover span{color: var(--textYellow) !important;}
.cancel-btn{height: 48px; text-transform: uppercase;font-weight: 700;text-align: center;}
.custon-btngroup{height: 48px; text-transform: uppercase; border:0;font-weight: 700;text-align: center; margin-top: 10px; width:50%}
.pick-btn{ border-radius:0 30px 30px 0; background-color: var(--bgYellow);}
.pick-btn:hover, .pick-btn:focus, .pick-btn:active{background-color:var(--bgDarkYellow);color: var(--textDark);  }
.cancel-btngroup:hover{background-color: var(--bgYellow);color: var(--textDark); }
.cancel-btngroup{ border-radius:30px 0 0 30px;background-color:var(--bgDarkYellow); color:var(--textWhite);}
.crypto-card.normal-card{background-color: var(--bgDark);box-shadow:none;}
.crypto-card.fiatcard{background-image: url(../images/fiatcard-bg.png);background-size: cover;border-radius: 1.8rem;}
.crypto-card-top{display: flex;justify-content: space-between;align-items: flex-start;}
.textc-white{color:var(--textPureWhite);}
.enter-val input{background-color: transparent;line-height: normal; font-size: 36px; color: var(--textWhite30);font-weight: var(--fw-300);}
.enter-val input::placeholder{color: var(--textWhite30);}
.billing-address{border: 1px solid var(--borderLight);border-radius: 30px; padding: 28px 40px;}

/* .crypto-address{padding: 8px;border: 1px solid var(--borderLight);border-radius: 12px;position: relative;} */
.recomnd-tag{background-color: var(--bgBlue);border-radius: 3px; padding: 1px 5px;}
/* Sell */
.default-radio{display: flex !important; justify-content: flex-start !important;margin-bottom: 16px;}
.default-radio .ant-radio-button-wrapper{background-color: transparent !important; color: var(--textWhite) !important;border-radius: 30px;min-width: 106px; line-height: 32px;border: none;font-size: 12px;font-weight: 500;text-align: center;}
.icon-delete{width:35px;height: 35px; border: 1px solid var(--borderGrey); border-radius: 50%;}
.icon-delete .anticon-delete{margin-left: 7px; margin-top:7px; font-size: 18px !important;}
/* Footer */

.selct-lang, .selct-lang:hover, .selct-lang:active, .selct-lang:focus{width: 42px; height: 40px;border-radius: 50%;background-color: var(--bgDarkGreen);border: none;color: var(--textWhite);box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);}
.theme-btn{box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25)}
.theme-dark, .theme-dark:hover, .theme-dark:focus{background-color: var(--bgWhite) !important;color: var(--textBlack);border-color: var(--bgWhite);}
/* Coins */
.coin{width: 43px; height: 43px;background: var(--cryptoIcons) no-repeat; display: inline-block; border-radius: 6px;}
.coin.DOT{background-position: -55px -60px}
.coin.BTC{background-position: -108px -1px;}
.coin.XLM{background-position: -162px -1px;}
.coin.usd-d{background-position: -216px -1px;}
.coin.oxbtc{background-position: -108px -1px;}
.coin.act{background-position: -270px -1px;}
.coin.EUR{background-position: -378px -1px;}
.coin.usd{background-position: -216px -1px;}
.coin.eur{background-position: -378px -1px;}
.coin.gbp{background-position: -432px -1px;}
.coin.md{width: 40px; height: 40px; box-shadow: none;}
.coin.lg{width: 52px; height: 52px;box-shadow: none;}
.coin-circle{width: 52px !important;height: 52px !important;}
.select .coin.lg.btc-white{background-position:-155px -769px;}
.coin.lg.BTC{background-position: 5px -767px;}
.coin.lg.USDC{background-position: -753px -768px;}
.coin.lg.DOT{background-position: -155px -769px;}
.coin.lg.ETH{background-position: -47px -767px;}
.coin.lg.USDT{background-position: -287px -768px;}
.coin.lg.ADA{background-position: -218px -768px;}
.coin.lg.BNB{background-position: -358px -768px;}
.coin.lg.XRP{background-position: -423px -768px;}
.coin.lg.BUSD{background-position: -563px -768px;}
.coin.lg.DOGE{background-position: -490px -768px;}
.coin.lg.GBP{background-position: -698px -769px;}
.coin.lg.USD{background-position: -98px -769px;}
.coin.lg.EUR{background-position: -630px -771px;}
.coin.lg.BCH{background-position: -890px -768px;}
.coin.lg.LINK{background-position: -1010px -768px;}
.coin.lg.UNI{background-position: 1px -838px;}
.coin.lg.COMP{background-position: -950px -769px;}
.coin.lg.XLM{background-position: -69px -838px;}
.coin.lg.LTC{background-position: -1076px -769px;}
.coin.lg.MKR{background-position: -1144px -769px;}
.coin.md.BTC{background-position: 5px -404px;}
.coin.md.USDC{background-position: -97px -404px;}
.coin.md.DOT{background-position: -135px -404px;}
.coin.md.ETH{background-position: -47px -404px;}
.coin.md.USDT{background-position: -252px -406px;}
.coin.md.ADA{background-position: -194px -404px;}
.coin.md.BNB{background-position: -314px -404px;}
.coin.md.XRP{background-position: -374px -406px;}
.coin.md.BUSD{background-position: -563px -404px;}
.coin.md.DOGE{background-position:-436px -404px;}
.coin.md.MKR{background-position:-931px -404px;}
.coin.md.LTC{background-position:-879px -404px;}
.coin.md.LINK{background-position:-829px -406px;}
.coin.md.COMP{background-position:-782px -406px;}
.coin.md.XLM{background-position:-1036px -406px;}
.coin.md.BCH{background-position:-735px -406px;}
.coin.md.USDC{background-position:-633px -406px;}
.coin.md.UNI{background-position:-984px -406px;}
.coin.md.usd{background-position: -79px -1112px}
.coin.md.btc-white{background-position: 1px -405px}
.coin.md.eth-white{background-position: -48px -405px}
.coin.md.usdt-white{background-position: -93px -405px}
.coin.md.eur-white{background-position: -14px -1112px;}
.coin.md.visa-white{background-position: -15px -1053px;width: 56px !important; height: 35px !important;}
.coin.md.visa-black{background-position: -150px -1053px;width: 56px !important; height: 35px !important;}
.coin.md.mastercard-white{background-position: -83px -1055px;width: 54px !important;height: 35px !important;}
.coin.credit-white{background-position: -488px -2px;box-shadow: none;}
.coin.deposit-white{background-position: -542px 0;box-shadow: none;}
.expiry-input{width: 200px;}
.expiry-input input{width: 50px; padding: 0 4px;height: 50px;}
.input-label{color: var(--textWhite30); font-size: 14px;font-weight: 600;margin-left: 12px;margin-bottom: 8px !important; display: block;}
.error-text{position: absolute; right: 0; top: 4px;color: var(--textError);}
.suisfiat-height .cust-input{margin-bottom: 0;}
/* swap */
.swap{border-radius:25px; display: flex; justify-content: space-between; align-items: center; padding: 16px 24px;min-height: 105px;}
.swapfrom-card{background-color: var(--bgSwapFrom);  border-bottom-right-radius: 0 !important; }
.swapreceive-card{background-color: var(--bgSwapTo);  border-top-right-radius: 0 !important;}
.card-input{padding:4px 0 !important; font-size: 24px; line-height: 24px; color:var(--textPureWhite);}
.icon.swapfrom-arrow{position: absolute; bottom: 0; right:0;width:43px;height: 26px; background-position: -54px -288px;}
.icon.swapto-arrow{position: absolute; top: 0; right:0;width:43px;height: 26px;margin-right: 12px; background-position: -3px -287px;}
.crypto-search.ant-input-search .ant-input-group input{background-color: var(--bgDark) !important;
height: 38px; color: var(--textWhite30);padding-left: 18px; border: 0; border-radius: 30px 0px 0 30px;}
.crypto-coin{width: 72px; margin-right: 16px;}
.crypto-search.ant-input-search .ant-input-group .ant-input-group-addon{background-color: var(--bgDark) !important;border-radius: 0 20px 20px 0;}
.crypto-search .anticon-search{font-size: 18px; color: var(--textWhite);margin-right: 12px !important;}
.confirm-icon{width: 124px; height: 124px; margin-bottom:36px;}
.loader{position: fixed;top: 0; right: 0; bottom: 0; left: 0;display: flex; align-items: center;font-size: 36px;color: var(--textWhite);
    /* background-color: var(--bgDarkGrey); */
    justify-content: center;}
.select-drpdwn{background-color: var(--bgGloom);margin-right: 30px;padding: 24px 0;border-radius: 10px;}
.select-drpdwn .ant-select-item{padding: 10px 24px;color: var(--textWhite);}
.select-drpdwn .ant-select-item:hover, .select-drpdwn .ant-select-item.ant-select-item-option-active, .select-drpdwn .ant-select-item.ant-select-item-option-selected, .select-drpdwn .ant-select-item.ant-select-item-option-active.ant-select-item-option-selected{background-color: var(--bgDarkGrey);}
.cust-input.ant-select{padding: 0 !important;}
.wallet-title{font-size: 16px; text-transform: uppercase; color: var(--textWhite30); margin-bottom: 0; font-weight: 600;}
.crypto-list .ant-list-item-meta, .wallet-list .ant-list-item-meta{display: flex;align-items: center;}
.crypto-list .ant-list-item, .wallet-list .ant-list-item{padding:5px 0 0 2px !important;margin-top:5px}
.recomoned-bg{background: var(--texthover);font-size: 12px;padding: 0 2px;line-height: 1.2;color: var(--textWhite);float: right;}
.text-width{width: 250px;}
.a-text{color: var(--bgBlue);}
.pop-drpdwn-toogle{background: var(--bgDark);width: 50px; height: 50px; border-radius: 20px;text-align: center;line-height: 50px;}
.alert-popup li{padding: 0;}
.alert-popup{background: #E7F2E1;border-radius: 4px;border-radius: 6px;}
.alert-popup .ant-card-body{padding: 12px;}
.alert-popup .alert-close svg{color: #000}
.alert-success{width: 20px;height: 20px;}
.close-icon{background-position: -143px -35px;}
.find-wallet .ant-select-selector{height: 50px !important;border: 1px solid var(--borderLight);border-radius: 30px !important;background: transparent !important;color: #fff;padding: 0 20px !important;}
.find-wallet .ant-select-selection-item{line-height: 3.5 !important;}
.find-wallet .ant-select-arrow{color: var(--bgWhite); margin-right: 5px;}
.ant-select-arrow{color: var(--textWhite);right:16px; top: 24px;}
.custom-switch{border-bottom: none;}
.agree .ant-form-item-explain-error{text-align: left;}
#memberWalletId .ant-select-selector, #currency .ant-select-selector{border-radius: 25px !important;height: 49px !important;}
#memberWalletId .ant-form-item-has-error,  #currency .ant-form-item-has-error{border: 1px solid var(--red) !important;}
.custom-formcard{width: 500px;margin: 0px auto;background: #515A64;border-radius: 50px;padding-left: 40px;padding-right: 40px;padding-top: 50px;padding-bottom: 50px;}
.ant-form-item-has-error .cust-input{background-color: var(--customBG) !important;border-color: var(--red) !important}
.ant-form-item-explain-error, .ant-form-item-explain-validating{text-align: left;}
.ant-input-suffix, .anticon-eye-invisible.ant-input-password-icon{color: var(--bgWhite);}
.coin.XRP {background-position: -2px -60px;}
.coin.USDT{background-position: -596px -1px;}
.coin.eth{background-position: -55px -1px;}
.coin.btc {background-position: -108px -1px;}
.coin.dat{background-position: -323px -1px;}
.coin.DAT{background-position: -323px -1px;}
.coin.ETH{background-position: -55px -1px;}
.coin.OXBTC{background-position: -596px -180px;}
.coin.BNB{background-position: -653px -1px;}
.coin.ADA{background-position: -702px -1px;}
.coin.USDC{background-position: -649px -57px;}
.coin.DOGE{background-position: -756px -1px;}
.coin.BUSD{background-position: -378px -60px;}
.coin.UNI{background-position: -702px -60px;}
.coin.LTC{background-position: -108px -60px;}
.coin.SOL{background-position: -596px -60px;}
.coin.LINK{background-position:-544px -60px;}
.coin.MATIC{background-position:-488px -60px;}
.coin.THETA{background-position:-435px -60px;}
.coin.DAI{background-position:-2px -120px;}
.coin.VET{background-position:-323px -60px;}
.coin.FIL{background-position:-216px -60px;}
.coin.ALGO{background-position:-435px -120px;}
.coin.CDAI{background-position:-2px -180px;}
.coin.LUNA{background-position:-55px -180px;}
.coin.AAVE{background-position:-216px -120px;}
.coin.SHIB{background-position:-164px -120px;}
.coin.TRX{background-position:-163px -60px;}
.coin.XMR{background-position:-56px -120px;}
.coin.OKB{background-position:-269px -120px;}
.coin.EOS{background-position:-109px -120px;}
.coin.CETH{background-position:-434px -180px;}
.coin.CAKE{background-position:-598px -120px;}
.coin.LEO{background-position:-164px -183px;}
.coin.ATOM{background-position:-488px -120px;}
.coin.MKR{background-position:-702px -116px}
.coin.BSV{background-position:-544px -120px;}
.coin.COMP{background-position:-164px -303px;}
.coin.NEO{background-position:-323px -120px;}
.coin.AVAX{background-position:-108px -183px;}
.coin.MIOTA{background-position:-756px -60px;}
.coin.HBAR{background-position:-378px -180px;}
.coin.KSM{background-position:-378px -120px;}
.coin.HT{background-position:-216px -183px;}
.coin.DASH{background-position:-323px -303px;}
.coin.BTT{background-position:-268px -183px;}
.coin.TUSD{background-position:-863px -303px;}
.coin.NEXO{background-position:-811px -120px;}
.coin.HOT{background-position:-863px -240px;}
.coin.PAX{background-position:-810px -240px;}
.coin.RUNE{background-position:-756px -120px;}
.coin.ZIL{background-position:-920px -3px;}
.coin.ARRR{background-position:-543px -180px;}
.coin.BAL{background-position:-325px -240px;}
.coin.GAS{background-position:-323px -120px;}
.coin.GUSD{background-position:-864px -3px;}
.coin.BAND{background-position:-378px -240px;}
.coin.PAc{background-position:-543px -180px;}
.coin.DOCK{background-position:-378px -303px;}
.coin.GRIN{background-position:-864px -120px;}
.coin.GTO{background-position:-651px -180px;}
.coin.SAI{background-position:-268px -305px;}
.coin.STORM{background-position:-542px -305px;}
.coin.AEON{background-position:-757px -180px;}
.coin.GSC{background-position:-864px -60px;}
.coin.COLX{background-position:-108px -303px;}
.coin.BTDX{background-position:-757px -240px;}
.coin.DROP{background-position:-434px -303px;}
.coin.BSD{background-position:-598px -240px;}
.coin.ARG{background-position:-53px -240px;}
.coin.STPL{background-position:-216px -305px;}
.coin.DTH{background-position:-433px -305px;}
.coin.TBX{background-position:-812px -305px;}
.coin.SPARTA{background-position:-2px -240px;}
.coin.POW{background-position:-811px -180px;}
.coin.CMM{background-position:-57px -305px;}
.coin.ENTRP{background-position:-599px -305px;}
.coin.AMP{background-position:-268px -60px;}
.coin.TFUEL{background-position:-652px -120px;}
.coin.OXY{background-position:-487px -240px;}
.coin.BCH{background-position:-1px -3px;}

.icon.lg.usd-default{background-position:-1px -70px;width:32px !important}
.icon.lg.swap-arrow{background-position:-8px -186px;}
.to-receive{text-transform: uppercase;font-size: 14px;text-align: center;color: var(--textWhite30);font-weight: 500;margin-bottom: 16px !important;margin-top: 0px;}
.swap-updown{position: absolute;right: 0;bottom: -17px;width: 40px;height: 40px; z-index: 1;margin-right: 20px; border-radius: 15px;background-color: var(--bgDark);text-align: center;line-height: 38px}
.swap-updown:hover{background: var(--bgDark);}
.default-circle{border-radius: 50%;padding:12px 4px;}
.swap-from{ background-color: var(--bgSwapTo);}
.swap-to{ background-color: var(--bgSwapFrom);}
.side-drawer-full .ant-table-cell{border-color: var(--borderLight);color: var(--textWhite);vertical-align: top;}
.bg-color{padding: 8px 6px;background: #ffffff1c;border-radius: 6px;}

.ant-table-container table > thead > tr:first-child th:last-child{border-top-right-radius:0}
.widthdraw-pop .ant-modal-close-x{position: absolute;width: 22px;height: 22px;line-height: 22px;top: 16px;right: 16px;}
.markets-panel .ant-table-tbody > tr.ant-table-placeholder:hover > td{background: var(--bgGrey) !important;}
.markets-panel .ant-table-tbody > tr.ant-table-placeholder > td{border-bottom: none !important;}
.markets-popup .custom-table .ant-table-body{padding: 0 0 0 24px !important;}
.user-labels{padding:12px; font-size: 16px; font-weight: 600; color: var(--textWhite); margin-bottom: 0; margin-left:8px}
.user-list{list-style: none;}
.back-up-tab{align-items: center;}
.user-labels.active, .user-labels:hover{background: var(--bgDarkYellow);font-weight: 700; border-radius: 30px; padding:12px; color: var(--textDark);}
.cust-select {min-width: 250px;}
.cust-select .ant-select-selection-placeholder{color:#ED4B9E !important;}
.cust-placeholder-select.cust-select .ant-select-selection-placeholder{color:var(--white) !important;}
.border-bottom{border-bottom: 1px solid var(--borderGrey);}
.backup-admb-0{margin-bottom: 0 !important;}
.custom-label.audit-label{color: var(--subText) !important;font-weight: 400 !important;font-size: 14px !important;}
.basicinfo.crypto-text-mb{margin-bottom: 16px !important;display: inline-block;}
.border-bottom{border-bottom: 1px solid var(--borderGrey);}
.addressbook_tabs .ant-tabs-nav{width:auto !important}
.addressbook_tabs .ant-tabs-tab.ant-tabs-tab-active,.addressbook_tabs .ant-tabs-tab:hover {background: transparent !important;font-weight: 600;  padding: 12px 15px 12px 12px !important; color: var(--textDark);}
.wdr-summary{display: flex; justify-content: space-around; align-items: center;}
.selectcustom-input .ant-select:not(.ant-select-customize-input) .ant-select-selector{background-color: transparent;border: transparent;cursor: pointer;}
.customicon{position: absolute; left: 0px;top: 4px; margin-left: 67px;}
.defaulticon{position: absolute; left: 0px;top: 3px; margin-left: 110px;}
.ant-modal-content{border-radius: 24px;}
.ant-modal-header{border-radius: 24px 24px 0 0;padding: 16px 24px;border-color: var(--borderGrey);}
/* .ant-modal-title{font-size: 24px; line-height: 34px;color: var(--textWhite30);text-transform:capitalize;} */
.ant-modal-body p{font-size: 16px;color: var(--textWhite50);font-weight: 400;}
.ant-modal-footer{padding:16px 24px;border-color: transparent;}
.cust-adon .ant-input-group, .cust-adon .ant-input-group .ant-input:focus, .cust-adon .ant-input, .cust-adon .ant-input-group-addon{border: none !important;box-shadow: none;padding: 0;background: transparent;}
.cust-adon .ant-input{line-height: 45px !important;height: 47px !important;background-color: transparent !important; padding:0 1px !important;margin-bottom:0}
.ant-modal-confirm-btns button:first-child{background-color: transparent;border: none;color: var(--textWhite30);}
.ant-modal-confirm-btns button:nth-child(2){background-color: var(--bgYellow) !important;border: none;color: var(--textDark);border-radius: 16px;padding: 4px 30px}
.ant-modal-confirm-btns button:first-child:hover{color: var(--textYellow);}
.ant-picker-input > input, .ant-form-item-label > label{color: var(--textWhite);}
/* .search-bg{border: 1px solid rgba(0,0,0,.08);color: #313c46 !important;background-color: var(--bgDarkGrey) !important;padding: 15px 10px;} */
.bankview-right{padding: 34px 34px 0 !important;background-color: var(--bgDarkGrey);border-radius: 30px;}
.bankview-right .kpi-val{padding-bottom: 34px;}
.kpi-List .icon{margin: 6px 10px 0 0;}
.kpi-List .icon + div{flex: 1;}
.kpi-item .kpi-val{margin-left: 0;}
.preview-file{text-align: center;}
.preview-file img{max-width: 100%;}
.react-pdf__Page__canvas{margin: auto;}
.ant-select-focused .ant-select-selector{box-shadow:none !important}
.selectcustom-input .anticon-search{display: none;}
.custom-add-select{border-bottom-right-radius: 0;border-top-right-radius: 0;}
.ant-select-multiple .ant-select-selection-item{background-color: transparent;height: 38px !important;line-height: 36px !important;border-radius: 24px;padding: 0 16px}
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {display: inline-block;margin-right: 4px;color: var(--textWhite30) !important;font-size: 14px;font-family: SimSun,sans-serif;line-height: 1; content: "*";opacity: 0.6;}
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{content: none;}
.custom-label .ant-form-item-label > label::after{color: transparent !important;}
.custom-label .ant-form-item-label,.custom-label.ant-row{display: block !important;}
.cust-adon .ant-input.ant-input-disabled{cursor:default;}
.staus-lbl{display: inline-block;color: #ffffff;background-color: var(--bgGreen);font-size: 12px;padding: 2px 8px;border-radius: 4px;font-weight: 500;}
.approved{background-color: var(--bgGreen);}
.rejected{background-color: #ff0000;}
.submitted{background-color: #0038FF;}
.wmy-graph .ant-radio-button-wrapper{border: none;background: none;color: var(--textYellow);font-weight: 600;}
.wmy-graph .ant-radio-button-checked{background-color: var(--bgYellow);border-color: var(--bgYellow) !important;border-radius: 16px;}
.wmy-graph .ant-radio-button-wrapper:not(:first-child)::before{content: none;}
.wmy-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{background: none;}
.wmy-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){background: none;color: var(--textDark) !important;}
.ant-message{  bottom: 15px !important; top: auto;}

.docreq-slider .slick-dots li button{width: 30px;height: 30px;color: var(--textWhite30);font-size: 16px;border-radius: 15px;}
.docreq-slider .slick-dots li{width: 30px;}
.coin-select{position: absolute; right:13px; bottom: 13px;}
.ant-modal-close-x{cursor: default;}
.tinycontent{word-break: break-all;max-height: 100px;height: 100px;overflow:hidden;}
.carousel-card:hover .tinycontent{overflow-y: auto;}
.documentmodal-width .preview-pdf-page{border: 1px solid var(--bgDark);margin-bottom: 10px;}
.accordian textarea.ant-input:placeholder-shown{line-height: 48px;}
.accordian textarea.ant-input{min-height: 48px !important;padding-top: 0px !important;}
.coin-bal{display: flex;justify-content: space-between;align-items: center;}
.coin-bal ul li{display: inline-block;list-style: none;font-size: 10px;text-align: center;color: var(--textWhite30);font-weight: 500;margin-left:16px;}
.otp-verify{position: relative;margin-left: 0 !important;}
.otp-verify button{color: var(--textYellow) !important;font-weight: 500;padding: 0 6px 0 0;}
.otp-verify .ant-form-item-label{width: auto;position: absolute;right: 4px;top: 10px;z-index: 1;}
.otp-verify .ant-form-item-label > label::after{content: none !important;}
.min-max-btn label{width: 100%;}
.side-drawer .coin-info:last-child{margin-bottom: 60px;}

/* --Payments --*/

.pay-grid thead th:last-child{width: 500px;}
.ant-table-placeholder:hover{background: transparent;}

/* .more-popover .lbl{font-size: 12px; color: var(--textWhite50);font-weight: 100;} */
/* .more-popover .val{font-size: 14px; color: var(--textWhite30);display: block;margin-bottom: 16px; line-height: normal;} */

.payment-docs .accordian .ant-collapse-content-box, .payment-docs .accordian .ant-collapse-item{background-color: var(--bgGrey);border-radius: 24px;}
.payment-docs .accordian .ant-collapse-item:last-child > .ant-collapse-content{background-color: var(--bgGrey);border-radius: 30px;}
.file-label{border: 1px solid var(--borderLight);border-radius: 6px;padding: 2px 8px;color: var(--bgDarkYellow);background-color: var(--bgDarkGrey);width:100px}
.delete-btn{color: var(--textWhite30);background-color: transparent !important;font-size: 14px;border: none;}
.delete-btn:hover{background-color: transparent !important; color:white;border:none;}
.delete-btn:focus{border:none !important;}
.ant-modal-footer .delete-btn{padding: 0 30px;}
.pay-list{display: flex;justify-content: space-between;align-items: center;}
/*--Zoho--*/
.zsiq_flt_rel{width: 50px !important; height: 50px !important;bottom: 8px;right: -10px;}
.zsiq_theme1 .siqico-chat:before{font-size: 20px !important; line-height: 50px !important;}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-radio-disabled + span{color: var(--textWhite30);}
/** masspayments end**/
.batch-actions{text-align: right;margin-bottom: 16px;}
/* .batch-actions span{margin-left: 16px;} */
.makepayment-section{line-height: 3 !important;}
.masspay-popup{width: 620px !important;}
.ant-modal-close-x .ant-modal-close-icon svg{display: none;}
.uploadxcel-btn{margin-top: 50px !important;}
.drawer-content{display: flex !important; justify-content: center !important; align-items: center !important;height: 500px !important;}
.batch-list{font-size: 20px; font-weight: 500; color: white !important;}
/** masspayments end**/
/* steps start */
.ant-steps-item-title:has(> .last-title){width: 100%;}
.cust-steps-design .ant-steps-item-finish .ant-steps-item-icon, .cust-steps-design .ant-steps-item-active.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon, .cust-steps-design  .ant-steps-item-wait .ant-steps-item-icon{background-color: var(--secondary) !important;border-color: var(--secondary) !important;}
.cust-steps-design .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{color:var(--textWhite) ;}
.cust-steps-design .ant-steps-icon .anticon-check.ant-steps-finish-icon svg{color: var(--viewAll) !important;background: none !important;width: 18px !important;height: 18px !important;}
.cust-steps-design .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after{background-color: #fff !important;}
.cust-steps-design .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{color: var(--viewAll) !important;}
.cust-steps-design .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon{top: -2.5px;}
/* steps end */
/*-- Cases--*/

.icon.md.Type{background-position:-577px -102px;}
.icon.md.State{background-position:-408px -101px;}
.icon.md.Email{background-position:-127px -71px;}
.icon.md.Name{background-position:-212px -70px;}
.icon.md.Currency{background-position:-428px -70px;}
.icon.md.Bank.Name{background-position:-457px -71px;}
.icon.md.Date{background-position:-481px -36px;}
.icon.md.Amount{background-position:-185px -102px;}
.icon.md.description, .icon.md.Alert.Description{background-position:-156px -70px;}
.icon.md.Alert.Number{background-position:-241px -101px;}
.icon.md.Alert.Type{background-position:-128px -134px;}
.icon.md.Alert.Title{background-position:-156px -101px;}
.icon.md.Transaction.Id{background-position:-542px -70px;}
.icon.md.Features{background-position:-128px -102px;}
.icon.md.EURS{background-position: -269px -102px;}
.icon.md.USDS{background-position: -213px -102px;}
.visible-flex{display: flex; justify-content: space-between;align-items: center;}
.addressbook-grid .cust-list>div{position: unset !important;}
.transaction-grid td .gridLink,.cases-grid-style td .gridLink,.address-clear td .gridLink{padding:5px 0 0 0 !important; line-height: normal;}
.reffername{
    text-align: right;
    width: 180px;
    top: 0;
    right: 17px;
    position: absolute;
    color: #ffffff99;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;}
.pix-qr.cust-qr-img{border-radius: 6px;margin-top: 36px;}
@media (min-width: 320px){
    .tlv-header{padding: 8px 8px !important;}
    .header-dropdown{margin-right: 24px;}
    .megamenu-label{margin-top: 24px;}
    .header-right{display: block;}
    .mobile-header-right{display: block;float: right;}
    .sidebar-push{margin: 62px 0 0;}
    .buysell-toggle{ margin: 0 auto 0px !important;}
    .crypto-percent{font-size: 26px;color: #FFFFFF;}
    .ant-typography.crypto-percent{margin-left: -8px;}
    .crypto-percent .percent{font-size: 16px;vertical-align: middle;margin-left: 4px;}
    .drawer-title{font-size: 28px !important;font-weight: 200 !important;color: var(--textWhite30) !important; margin: 16px 0 4px !important;}
    .buysell-toggle + .fs-36{font-size: 28px !important;}
    .footer-links a{margin-right: 30px;}

    .tlv-header .sideropen {background: var(--bgGloom) !important;}
    .tlv-header .mobile-logo {padding-left: 0px;}
    .custom-radio-btn.buysell-toggle.crypto-toggle{text-align: left !important;}
    .ant-drawer-body{padding: 16px;}
    .create-account .ant-form-item-control-input-content{text-align: center;}
}
@media (min-width : 414px) {
    .footer-links a{margin-right: 16px;}
    .enter-val{margin-left: 4px;margin-right:4px}
}
@media (min-width: 576px){
    .fiat-total{display: flex;justify-content: space-between;text-align: center;}
    .crypto-percent{font-size: 26px;line-height: normal;}
    .crypto-percent .percent{font-size: 20px;margin-left: 6px;}
}
@media (max-width: 767px){
    .search-bg.history-crypto-cards .ant-list-items, .search-bg.fait-card-transaction-h .ant-list-items{display: block;}
    .search-bg.history-crypto-cards li.ant-list-item.cytpo-list-style, .search-bg.fait-card-transaction-h li.ant-list-item{width: 100%;}
    .box.mb-flex{display: flex !important;}
    .mb-togglespace .ant-collapse-item.ant-collapse-item-active .downangle, .mb-togglespace .ant-collapse-header .icon.downangle{width: 32px !important;}
    .mb-togglespace .ant-collapse-header{display: flex !important;}
    .custom-label .ant-form-item-label{padding-bottom: 0 !important;}
    .ant-dropdown-placement-topRight > .ant-dropdown-arrow{top: -5px !important;}
    .ant-dropdown-show-arrow.ant-dropdown-placement-topRight{top: 71px !important;}
    .crypto-card .ant-card-body{height: 110px;padding: 16px 24px;}
    .mid-menu{margin: 0 -16px;padding: 0 16px; background-color: var(--bgWhite);}
    .header-logo{padding-left: 0  !important;}
    .mobile-none{display: none}
    .mobile-megalinks, .notify{display: block;}
    .megamenu-label{font-size: 30px !important;}
    .megamenu-title{padding-right: 34px !important;}
    .mb-d-none{display: none;}
    .sidebar-push{margin: 62px 0 0 !important;}
    .copyright-block{display: flex; justify-content: space-between; align-items: center;padding-top: 12px;}
    .swap-subtitlte{width:235px}
    .enter-val{margin-left: 4px;margin-right: 4px;}
    .enter-val input{font-size: 22px !important;}
    .enter-val-container .text-yellow{font-size: 22px !important;}
    .coin.lg{transform: scale(0.8);}
    .card-icons{transform: scale(0.65);margin-right: -24px;}
    .portfolio-list .mobile-list .ant-list-item{display: flex;}
    .notify .ant-alert-icon{float: left;}
    .notify .ant-alert-content{margin-left: 40px;}
    .market-coinname{display: inline-block;max-width: 68px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
    .custom-theme-btn{display: block;}
    .display-flex{display: block; text-align: center;}
    .theme-switch{width: auto;}
    .address-clear{clear: both; margin-top: 12px;}
    .customaudit-select .selectcustom-input{margin-left:0 !important}
    .customaudit-select .custom-btn{width: 100%;}
    .k-pager-info.k-label{display: none;}
    .mobile-headerview li{margin: 0 10px}
    .mobile-headerview li:nth-of-type(6), .mobile-headerview li:nth-of-type(7), .ant-menu-submenu-popup li:nth-of-type(2), .ant-menu-submenu-popup li:nth-of-type(3){display: none;}
    .suisfiat-height{max-height: calc(100vh - -110px);}
    .tlv-header{height: 75px !important;}
    .visible-mobile .side-drawer .side-drawer-header.change_password{align-items: self-start !important;}
    .ribbon-item{border-right: 0 !important;}
    .transaction_resp .pop-btn{width: 100%; margin-top: 20px !important;margin-bottom: 16px;}
    .transaction_resp{ padding:0 15px}
    .transaction_resp .input-label{margin-left: 0 !important;}
    .search-bg{margin-top: 16px; border-radius: 10px;}
    .customaudit-select{margin-bottom: 16px;}
    .footer-links{text-align: center;}
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more{display: none;}
    .tlv-logo{height:46px;padding-left:0;}
    .mobile-content{width:274px !important;}
    .ant-form-item-control-input-content .pop-btn{margin-top: 20px;}
    .dep-withdraw,.sellcrypto-container {
        max-height: calc(100vh - 270px);
    }
    .code-sent-tool-tip{display: flex;}
    .k-pager-sizes.k-label{margin-left: 5px;}
    /* .k-pager-wrap{margin-left: -11px;} */
    .k-dropdown-wrap{margin-right: -8px;}
    .table col{width: 120px;}
    .date-mobile{margin-left: 12px !important;}
    .transaction-grid table col{width: 250px !important;}
    .transaction-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager{overflow: auto;}
    .k-pager-wrap .k-pager-numbers.k-reset li:not(:first-child){display: none;}
    .transaction-type{margin-top: 8px !important;}
    /* .batchpayment-summary{display: block;} */
    .ant-tooltip.ant-tooltip-placement-top{visibility: hidden;}
    .btn-mobile button{padding: 0 30px;}
    .k-pager-wrap.k-pager{font-size: 10px;}
    .sumSub-confirm{width:350px;}
}
@media (max-width: 1024px){
    .custom-gridresponsive .ant-drawer-body{word-wrap: unset !important;}
    .custom-gridresponsive .k-grid-header col:nth-of-type(4){
        width: 20% !important
    }
    .custom-gridresponsive .k-grid-table col:nth-of-type(4){
        width: 20% !important
    }
    .custom-gridresponsive .k-pager-wrap .k-link {font: small-caption;font-size: 10px;}
    .custom-gridresponsive .k-pager-nav{min-width: calc(1.4285714286em + 3px);height: calc(1.4285714286em + 3px) }
	.tlv-header .list-item.ant-menu-item .ant-menu-title-content{padding: 6px 0px;}
    .mobile-viewbtns .ant-form-item-control-input-content .pop-btn{margin-bottom: 0 !important;margin-top: 0 !important;}
}
@media (max-width: 991px){
    .user-profile{width:42px;height: 42px;}
    .user-list{padding-left: 0;display: block !important;}
    .mobile-list .ant-list-item{display: block; width: 100%;}
    .mobile-list .custom-btn, .crypto_btns .custom-btn{min-width: calc(50% - 22px);}
    .mobile-list .custom-btn:first-child{margin-left: 0 !important;}
    .mobile-list .crypto-btns{display: flex;padding-top: 16px;}
    .ant-modal-wrap.megamenu-backdrop{left:12px !important}
    .sidebar-push {margin: 100px 0 0;}
    .mobile-header-right {display: block;flex-grow: 1;flex-shrink: 1;text-align: right;}
    .mobile-headerview{display: none;}
    .mobile-headerview{margin-top: 15px;}
    .mobile-headerview li{margin: 0 34px}
    .mobile-headerview li:nth-of-type(6), .mobile-headerview li:nth-of-type(7){display: none;}
    .crypto_btns{display: flex; width: 100%; margin-top: 16px;}
    .hidden-mobile{display: none;}
    .visible-mobile .ant-tabs-top > .ant-tabs-nav::before{border-bottom: 0 !important;}
    .visible-mobile .user-list .ant-tabs-nav{width: auto;}
    .bill-payment{display: block;}
    .wallet-mb{display: inline-block !important;}
    .mb-list .ant-list-item{display: flex !important;}
    .tlv-header .ant-layout-sider{min-height: 100vh;}
   .portfolio-data{display: block}
   .portfolio-list h1{padding: 0 0 6px;}
   .portfolio-list ul li{display: block !important;}
   .portfolio-title{align-items: center;}
   .ribbon-item{border-right: 0 !important;}
   .responsive_table{overflow-x: auto;}
   .responsive_table .pay-grid{width: 100%;}
   .header-logo .visible-mobile::after{content: '';position: absolute;width: 1px;height: 27px;right: 0;top: -2px !important;background-color: var(--bgWhiteSpin);}
   .mobile-logo {padding-left: 20px;}
   .amt-field .cust-input{width:150px;}
   .sidebar-push .ant-alert{align-items: baseline;}
   .custom-label.ant-form-item{margin-bottom: 16px;}
   .mb-flex-none{display: block !important;}
   /* .mb-view tfoot{background: #515a64 !important;} */
   .cust-cypto-view{width: auto !important;}
   .custom-alert-width{width: auto !important;}
}
.icon.pdf{background-position: -287px -241px;    border-radius: 50%;}
.pdf-name{margin-bottom: 0;margin-left: 24px;}
.flex-1{flex: 1;}
.account-summary{color: var(--coinPrice);}
main.ant-layout-content.sidebar-push {    min-height: calc(100vh - 220px);}
.currency-logo{border-radius: 50%;}
.currency-section{text-align: end;flex: 1;}
.card-status{position: absolute;}
@media (min-width: 768px){
    .tlv-header{height: 96px !important;border-radius: 0 !important;}
    .tlv-header{height: 100px;align-items: center; justify-content: space-between;padding: 12px 16px 12px !important;border-radius: 0 0 30px 30px;}
    .markets-panel{margin-right: 16px;}
    .megamenu-label{margin-top: 0px;font-size: 33px !important;}
    .side-drawer .ant-drawer-body{padding: 0 20px 20px 20px;}
    .portfolio-count{display: inline-flex;height: 159px;}
    .megamenu-title .tlv-logo{padding-left: 34px !important;}
    .megamenu .ant-modal-header{border: none; padding: 24px 32px;}
    .megamenu .ant-modal-body{background-color: var(--bgGloom) !important}
    .megamenu .ant-modal-close{top: 13px; right: 30px;}
    .megamenu-divider{height: 2px;background-color: var(--bgWhite); margin: 30px 0;}
    .megamenu-link{padding-left: 15px;padding-right: 15px; margin: 0 auto;}
    .mega-menu{padding-right: 30px; padding-left: 30px;}
    .wrapper{display: flex;flex-direction: column;justify-content: space-between;min-height: 230px;}
    .item-wrapper{flex-grow:0; flex-shrink:0;flex-basis:calc(100% - 10px); }
    .copyright{display: none !important;}
    .swap-subtitlte{width:250px}
    .pay-list{display: flex;justify-content: space-between;align-items: center;}
    .verify-options{padding: 24px 70px;}
    .side-drawer .ant-drawer-header{padding:20px !important}
    .markets-panel .custom-table .ant-table-body{height: 875px !important;padding: 0 4px 0 0;}
    .docfile{flex: 0 0 240px;width: auto;}
    .footer-links a{margin-right: 50px;text-align: left;}
    /* .theme-switch{width: 220px;} */
    .themeSwitchOff,.themeSwitchOn{margin-left: 22px !important;}
    .display-flex {display:flex; align-items: center;justify-content: space-between;}
    .address-icons{list-style: none; padding-left: 0; margin-bottom: 0; display: flex;}
    .login-user .header-logo{flex-grow: 1;}
    .dash-mobile-list ul{max-height: 787px;overflow: auto;}
    .create-account .ant-form-item-control-input-content{text-align: right;}
    .batchpayment-summary{display: flex;}
    .btn-mobile button{padding: 0 36px;}
    .crypto-card.select.space-hide{height: 10px !important;}
}
@media (min-width: 992px){
    .summarybal.wallet-copy-width{width: 250px;}
    .search-bg.history-crypto-cards li.ant-list-item.cytpo-list-style, .search-bg.fait-card-transaction-h li.ant-list-item {width: 272px;margin: 0;}
.search-bg.history-crypto-cards  .ant-list-items, .search-bg.fait-card-transaction-h .ant-list-items{display: flex;flex-wrap: wrap;gap: 8px;}
    .tlv-header{display: flex}
    .user-profile{width:44px;height: 44px;}
    .right-panel{padding: 0 60px;}
    .tlv-header .main-menu li{margin: 0 4px;}
    .sidebar-push{margin: 100px 0 0;}
    .header-right, .portfolio-list .ant-list-split .ant-list-item{display: block;flex-grow: 1;flex-shrink: 1;text-align: right;}
    .header-right.mobile-header-right, .mobile-megalinks{display: none;}
    .crypto_btns{margin-left: 16px !important;}
    .crypto_btns .custom-btn.prime{margin-left: 36px  !important;}
    .portfolio-list .ant-list-split ul li .crypto_btns,
    .portfolio-list .ant-list-split ul li .custom-btn.prime{margin-left: 0 !important}
    .portfolio-list .ant-list-split ul li .crypto_btns{margin-top: 10px;}
    .portfolio-list .ant-list-split ul li .custom-btn{min-width: calc(50% - 8px);}
    .visible-mobile{display: none;}
    .custom-toggle{margin-left: 12px !important;}
    .mobile-headerview{display: block;}
    .visible-mobile,.sideropen{display: none;}
    .portfolio-list h1{padding: 16px 0 16px;}
    .portfolio-data{display: flex;align-items: baseline;}
    .portfolio-title{align-items: baseline;}
    .bill-payment{display: flex; align-items: center; justify-content: space-between;}
    .header-logo li:first-child::after{content: '';position: absolute;width: 1px;height: 27px;right: 0;top: 10px;background-color: var(--bgWhiteSpin);}
    .common-withdraow.withdraw-crypto{margin-right: 0px;}
    .common-withdraow.withdraw-crypto input[type=text]{font-size: 22px !important;}
    .transctns-grid .search-btn{margin-left: 12px !important;}
    .bill-grid .k-grid-header table{width: 1255px !important;}
    .bill-grid .k-grid-content table{width: 1254px !important;}
    .cust-input.cust-disable{width: 280px;}
}
@media (min-width: 1200px){
    .custom-font{font-size:28px !important;}
    .mega-menu{max-width: 1140px;  padding: 29px 0px 24px 60px;}
    .side-drawer .ant-drawer-body{padding: 0 20px 20px 20px;}
    .portfolio-list .mobile-list{height: 1087px;max-height: 1087px; overflow: auto;}
    .markets-panel .custom-table .ant-table-body{height: 900px !important;}
    .trade-legends{display: flex; justify-content: space-between;}
    .portfolio-list .ant-list-split .ant-list-item a + div{min-width: 170px;}
    .portfolio-list .ant-list-split .ant-list-item{display: flex;}
    .portfolio-list .ant-list-split ul li .custom-btn{min-width: 90px;}
    .coin-details.right{min-height: 705px;}
    .side-drawer.custom-drawer-width .ant-drawer-content-wrapper{width:865px !important;}
    .btn-container{padding-right: 20px;}

}
@media (min-width: 1400px){
    .search-bg.history-crypto-cards li.ant-list-item.cytpo-list-style, .search-bg.fait-card-transaction-h li.ant-list-item{width: 296px;}
    .register-blockwid{
        width: 900px !important;
        margin: 0 auto !important;}
    .custom-font{font-size:30px !important;}
    .mega-menu{max-width: 1320px;}
    .side-drawer .side-drawer-header{padding: 15px 0px 15px 0px;}
    .crypto-list-tabs .crypto-list{max-height: calc(100vh - 350px); overflow-y: auto; padding-right: 10px;}
    .sellcrypto-container{max-height: calc(100vh - 230px);}
    .suisfiat-container, .suisfiat-height{max-height: calc(100vh - 195px);}
    .dep-withdraw{max-height: calc(100vh - 260px);}
    .crypto-card .ant-card-body{padding: 16px 24px;height: 110px;}
    .crypto-percent{font-size: 26px;line-height: normal;}
    .coin{transform: scale(1);}
    .drawer-title{font-size: 34px !important;}
    .crypto-search.ant-input-search .ant-input-group input{height: 58px !important;}
    .cancel-btn{height: 57px !important;}
    .custon-btngroup{height: 57px !important;}
    .to-receive{margin-top: 0px;}
    .crypto-list .ant-list-item, .wallet-list .ant-list-item{padding:10px 8px 8px !important}
    .crypto-search.ant-input-search .ant-input-group .ant-input-group-addon{border-radius: 0 30px 30px 0;}
    .pay-list{padding: 16px 0;}
    .crypto-container {max-height: calc(100vh - 325px);}
    .custom-crypto-address .fs-12{font-size: 14px !important;}
    .addbook-height{max-height: calc(100vh - 112px);}
    .pop-btn-46{height:46px;}
    .minmax.custom-minmax{position: relative;right:-332px !important;top:-11px;width:100px;}
    .side-drawer.custom-drawer-width .ant-drawer-content-wrapper{width: 1094px !important;}
    .lg-fontsize{font-size: 14px !important;}
    .cases-grid-view{text-align: center;}
    .cases-grid-view .k-grid .k-grid-header table{width: 1254px !important;}
    .cases-grid-view .k-grid-container .k-grid-table{width: 1254px !important;}
    .transaction-grid .k-grid-header .k-grid-header-wrap table{width: 1874px !important;}
    .transaction-grid .k-grid-container .k-grid-content table{width: 1874px !important;}
}

@media (min-width: 1500){
    .main-container{margin: 0px auto;max-width:min(1460px, 100% - 40px)}
}
.currencyContains {display: inline;color: var(--textWhite30);}
.spinLoader {margin: 20px 0;margin-bottom: 20px;padding: 30px 50px;text-align: center;background: var(--bgWhiteSpin);border-radius: 4px;}
.inputSpinner{display: block;text-align: left;height: 30px}
.notkyc{top: 0; right: 0; bottom: 0; left: 0;display: flex; align-items: center;font-size: 36px;color: var(--textWhite);background-color: var(--bgDarkGrey);justify-content: center;margin-top: 150px;text-align: center;}
.crypto-address.custom-crypto-address .ant-typography-expand, .crypto-address .ant-typography-edit, .crypto-address .ant-typography-copy{font-size:18px;margin-left:0px;}
.share-adrs{display: flex;justify-content: center;padding: 0;border-radius: 10px;background-color: var(--bgDarkGrey);}
.share-adrs li{padding: 16px 20px 10px;}
.share-adrs li:hover{background-color: var(--bgGrey);}
.share-btn{background-color: transparent !important;color: var(--textWhite30) !important;border-color: var(--borderLight);}
.notice-nodata{display: flex; align-items: center;justify-content: center; height: 192px;}
.transaction-tabs .ant-tabs-nav::before{border-bottom: none;}
.transaction-tabs .ant-tabs-nav{margin-bottom: 15px;}
.transaction-tabs .ant-drawer-body{padding: 10px 24px;}
.transaction-tabs .ant-collapse-header{background: var(--bgDarkGrey) !important;color: var(--textWhite) !important;}
.transaction-tabs .ant-collapse{background: transparent;border: 1px solid var(--borderGrey);}
.transaction-tabs .ant-collapse-content{background: transparent;border: none;}
.transaction-tabs .ant-collapse > .ant-collapse-item{border-bottom: 1px solid var(--borderGrey);}
.transaction-tabs .ant-collapse-item .ant-collapse-arrow svg{transform: scale(1.3);}
.transaction-tabs .ant-collapse-item-active .ant-collapse-arrow svg{transform: rotate(90deg) scale(1.3) !important;}
.alltab-space .ant-table{margin: -16px -16px 0;}
.custom-search .anticon-search{display: none;}
.k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer {color: var(--textWhite) !important;background-color: transparent !important;}
.k-grid-content, .k-grid {background-color: transparent !important;color: var(--textWhite) !important;}
.k-grid-content{margin-right: -12px;}
.k-grid-header{padding-right: 0;}
.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover {background-color: var( --bgDarkGrey) !important;}
.k-pager-wrap {color: var(--textWhite);background-color: transparent;}
.change-space .ant-input-password-icon{padding: 10px 16px 10px 0 !important;cursor: pointer;}
.change-space .ant-input-password-icon svg{color: var(--textWhite)}
.hover-passlwngth {display: none}
.pass-onhover:hover + .hover-passlwngth {display: block;position: absolute;right: 0;background-color: #000;z-index: 999;padding: 10px;}
.pass-onhover:hover + .hover-passlwngth span {display: block;color: var(--textWhite);padding: 3px 2px 2px 8px;font-size: 12px;position: relative;}
.pass-onhover:hover + .hover-passlwngth span:first-child {padding: 0;margin-top: -5px;margin-left: -3px;}
.hover-passlwngth:after { content: ""; position: absolute; border: 12px solid transparent;  border-top-color: #000 !important;  border-bottom: 0;  width: 8px;  height: 8px;  left: 2px; transform: rotate( 175deg );  top: -7px; z-index: 99;}
.hover-passlwngth span:after {content: "";position: absolute;border: 6px solid transparent;border-top-color: #fff !important;border-left: 0; width: 4px; height: 4px;left: -7px;transform: rotate( 135deg ); top: 4px;z-index: 99;}
.hover-passlwngth span:first-child:after {display: none}
.pwd-popup.pop-cancel{vertical-align: middle;margin: 10px auto !important; display: flex;height: 36px !important;text-align: center !important;}
.pwd-popup.pop-cancel.ant-btn-block{width: auto !important;}
.colr-comn{color: var(--textPureWhite)!important}
.ant-custumcheck{display: flex;align-items: center;}
.ant-checkbox-inner{background-color: transparent !important;border: 0 !important;}
.customaudit-select .ant-select-single .ant-select-selector .ant-select-selection-search{left: 17px!important}
.customaudit-select .ant-form-item-label > label::after{display: none;}
.buy .ant-list-item-meta-avatar{background-color: var(--bgDarkGreen);}
.sell .ant-list-item-meta-avatar{background-color: var(--bgDarkRed);}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {-webkit-appearance: none;-moz-appearance: none;appearance: none;margin: 0;}
.c-notallowed{cursor:not-allowed !important;}
.cust-sumsub-font{width: 400px;}
.sumSub-confirm{background: var(--bgGrey);color: var(--black); padding: 50px 40px;width: 800px;margin: 80px auto;border-radius: 14px;}
.sumSub-confirm.cust-cord{background: var(--gridborder);}
.sumSub-error{color: var(--black); padding: 50px 40px 16px;height:225px;margin: 50px 0 100px 0;border-radius: 14px;}
.sumSub-review{font-size: 16px;font-weight: 700;line-height: 1.29;}
.sumsub-mb{margin-bottom: 16px;}
.sumsubactbtn{margin-left: 0px !important;margin-top: 16px;}
.pay-doc .file-label{white-space: nowrap;max-width: 250px !important;overflow: hidden; text-overflow: ellipsis; width: 200px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.cust-disable.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{color:#fafcfe7a;}
.page-title{font-size: 18px !important;color: var(--textWhite30);font-weight: 500;margin-bottom: 12px !important;}
.delete-disable[disabled]{cursor: not-allowed;}
.payments-modal .ant-modal-footer{border: none !important;}
.payments-modal .modal-para{margin-left: 26px !important;}
.excel-btn{background-color: var(--bgYellow); border-color: var(--bgYellow); border-radius: 40px;height: 42px; text-transform: uppercase;font-weight: 700; transition: 0.3s ease; color: rgba(0, 0, 0, 0.85);padding: 0 15px;}
.excel-btn:hover, .excel-btn:focus, .excel-btn:active{background-color: var(--bgDarkYellow);color: var(--textDark);border-color: var(--bgYellow);}
.pay-docs{white-space: nowrap;max-width: 250px !important;overflow: hidden; text-overflow: ellipsis; width: 65px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.pay-account{white-space: nowrap;max-width: 250px !important;overflow: hidden; text-overflow: ellipsis; width: 100px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.docdetails .docnames{font-weight: 700;color: var(--viewAll); line-height: 16px;max-width: 150px !important;}
.pay-docs{white-space: nowrap;overflow: hidden; text-overflow: ellipsis; width:auto;margin-right:12px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.portfolio-title{display: flex; justify-content: space-between;}
.ant-collapse.profile-collapse, .profile-collapse .ant-collapse-content{background: transparent !important;border: 0 !important;}
.drpdwn-list .ant-collapse > .ant-collapse-item{border-bottom: 0 !important;}
.ant-collapse-icon-position-right.profile-collapse > .ant-collapse-item > .ant-collapse-header{padding: 10px 0 10px 10px;color: var(--textWhite30);font-size: 14px;font-weight: 300;}

.doc-def{width: 460px;margin-left: -2px;}
.submission-sec{width: 550px;color: var(--bgWhite);margin: 0 auto;}

.custom-forminput .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{text-align: center;}
.custom-dropdown.btn-dropdown button{ background-color: var(--bgYellow) !important;  border-color: var(--bgYellow) !important;}
.custom-dropdown.ant-btn-group > .ant-btn:first-child:not(:last-child){border-top-left-radius: 40px; border-bottom-left-radius: 40px; padding-left: 50px;;padding-right: 50px;}
.custom-dropdown.ant-btn-group > .ant-btn:last-child:not(:first-child){ border-top-right-radius: 40px; border-bottom-right-radius: 40px; padding-right: 30px; border-left: 1px solid #333;}
.custom-dropdown .anticon.anticon-ellipsis{display: none;}
.user-list.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations{display: none;}
.basic-info .ant-table{background: transparent;}
.basic-info .ant-table-tbody > tr.ant-table-row:hover > td{background: var(--bgDarkGrey);}
.basic-info .ant-table-thead > tr > th{border-bottom: transparent;}
/* multi selection */
.ant-select-multiple .ant-select-selection-item-remove, .ant-select-multiple .ant-select-selection-item-content{color:var(--textWhite);}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){background-color: var(--profileMenu);color:#fff;}
.ant-select-dropdown{background-color: var(--bgGloom);}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){background-color: var(--bgDarkGrey);color:#fff;}
.ant-select-item{color:#ffff;}
.cust-input-light{background: transparent;border-radius: 30px;height: 50px;border: 1px solid #6e6e74;color:#6e6e74;text-align: center;font-size: 16px;}
.cust-input-light .ant-select-selection-item{ border: 1px solid #6e6e74 !important;}
.cust-input-light .ant-select-selection-item-content{color:#6e6e74 !important;}
.checkbox-red {border-color:var(--textError) !important;}
.check-red, .check-red .ant-checkbox{border-color:var(--textError) !important;}
.ant-select.cust-input-light.ant-select-multiple.ant-select-disabled.ant-select-show-search{padding:2px 0px;}
.cust-input-light.ant-select-disabled.ant-select-multiple .ant-select-selection-item{border-color: #787373;color: rgba(0, 0, 0, 0.25);}
.cust-input-light.ant-select-disabled.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content{color: rgba(255, 255, 255,0.25) !important}
.custom-notify.ant-select-multiple .ant-select-selection-placeholder{top:60%;}
.cust-input-light.ant-select-multiple .ant-select-selection-placeholder{top:70%;}
.cust-input.cust-text-area{padding:26px 16px 0px 16px!important;}
.bill-payment .pay-docs{width:100px;}
.enter-val.enter-vel-field{width:100%}
.k-animation-container.k-animation-container-relative.k-animation-container-shown{z-index: 9999;}
.toggle-space{position: relative;}
.toggle-space::before{content: '';position: absolute;width: 1px;height: 27px;right: -17px;background-color: #cecece; margin-left: 10px;}
.secureDropdown .ant-menu-root {background-color: var(--bgGloom);border-radius: 10px;min-width: 300px;max-width: 300px;padding: 6px 0;color: var(--textWhite);position: absolute;
    max-height: calc(100vh - 75px); overflow-y: auto;border: 0;}
.secureDropdown .ant-menu-root{top:27px !important}
.secureDropdown .ant-menu-root{min-width: 180px;max-width: 180px;}
.secureDropdown .ant-menu-title-content{line-height: 23px;}
.ant-result-title, .ant-result-subtitle{color: var(--textWhite);}
.crypto-card .ant-image-img{ margin-top:5px;margin-right: 0;border: 4px solid var(--coinBg);border-radius: 50%;}
.with-min{position: absolute;top: 52px;right: 46px;}
.with-max{position: absolute;top: 52px;right: 0;}
.crypto-list-custom li {color: #fff;}
.custom-crypto-btns .custom-bnt{background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2)); border-radius: 6px;border:1px solid #ccc;padding:8px 10px;}
.intertrans{display: flex;justify-content: center;align-items: center;height: 478px;}
.crypto-card .ant-card-body{width: 100%;}
.custom-spin .ant-spin-dot-spin{width: 2rem;height: 2rem;}
.custom-spin .ant-spin-dot-spin i{width: 16px;height: 16px;}
.ant-dropdown.secureDropdown{z-index: 995; width: 239px;}
.minmax.custom-minmax{position: relative;right:13px;top:-70px;text-align: right;}
.new-add .ant-btn:hover{border-color: transparent;outline: none !important;}
.markets-panel .custom-table .ant-table-body::-webkit-scrollbar-thumb{background-color: #899097 !important;}
.pay-grid .ant-table-cell-scrollbar{box-shadow: none;}
.pay-grid .ant-table-body{height: 450px;}
td.ant-table-column-sort{background: transparent;}

.custom-radio-btn.buysell-toggle{background-color: transparent;}
.custom-table.pay-grid.view .ant-table-tbody > tr.ant-table-placeholder:hover > td{background-color: transparent;}
.address-book-table .pay-grid tbody td{padding: 4px 8px 4px 8px;}
.side-drawer.custom-drawer-width .ant-drawer-title{color: #fff;}
.custom-radio-btn .ant-radio-button-wrapper{background: transparent;background-image: none;border-radius: 5px; margin-left: 5px;border: 1px solid #9b9bab;margin-right: 5px;color: var(--textWhite);width: auto;height: auto;display: inline-flex;align-items: center;}
.custom-radio-btn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{border: transparent;}
.custom-radio-btn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{border: transparent;}
.custom-radio-btn .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{border: transparent;}
.custom-radio-btn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{border: transparent;}
.custom-radio-btn .ant-radio-button-wrapper span{margin:auto;}
.custom-radio-btn .ant-radio-button-wrapper-checked{border:transparent;}
.cust-input.cust-text-area.address-book-cust{padding:20px 16px 15px 16px!important;}
.custom-radio-btn.buysell-toggle.crypto-toggle .ant-radio-button-checked{background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2));border-radius: 5px;}
.custom-radio-btn.buysell-toggle .ant-radio-button-wrapper-checked{color: #fff !important;;}
.ant-btn.ant-btn-block.profile-btn:focus, .ant-btn.ant-btn-block.profile-btn:active{border: 1px solid #ED4B9E !important;}
.fund-transfer-input .ant-input-group-addon:first-child{border-right: 1px solid #d9d9d9;cursor:not-allowed}
.fund-transfer-input .ant-input-group-addon{background-color: transparent;border: transparent;padding: 0 26px;}
.fund-transfer-input .ant-input-group .ant-input{border:transparent;}
.fund-transfer-input .ant-input:focus, .ant-input-focused{box-shadow:none;}
.fund-transfer-input .ant-input-group-addon{color:var(--textWhite);}
.small-text-align small{font-weight: 400; vertical-align: super;background-color: var(--bgDarkGrey);padding: 2px 6px;color: #fff;border-radius: 10px;}
.custom-radio-btn .ant-radio-button-wrapper.span-text span{line-height: 15px;margin: initial;}
.cust-fund{width: 88%;}
.cust-divide{margin: 0px !important; height: 2px; background-color: var(--bgDarkGrey);}
.create-new{text-decoration: underline !important;}
.cust-address{margin-bottom: 0px !important;}
.search-input .ant-input-wrapper.ant-input-group .ant-input{background-color: var(--bgWhite) !important; border-radius: 0px !important; height:34px !important; border-color: transparent !important; color: var(--bgBlack) !important;}
.search-input .ant-input-wrapper .ant-input-group-addon{background-color: var(--bgWhite) !important; border-radius: 0px !important; height:33px !important;}
.search-input .ant-input-wrapper.ant-input-group .ant-input:hover{border-color: transparent !important;}
.search-input .ant-input-wrapper.ant-input-group .ant-input:focus{box-shadow: none !important;}
.search-input .ant-input-wrapper .ant-input-group-addon .ant-input-search-button:focus,.search-input .ant-input-wrapper .ant-input-group-addon .ant-input-search-button:hover{z-index: 0 !important;}
.search-input .anticon.anticon-search{padding-right: 30px; }
.usr-add .ant-select-arrow{color: var(--bgGrey); }
.usr-add{position: relative; right: 32px; bottom: 6.7px;}
.custom-placeholder .cust-select .ant-select-selection-placeholder{color:#fff !important;}
.text-red-colr{color:#ff5e43 }
.input-label.otp-verify .ant-form-item-control{margin-top: 4px;}
.main-container .k-loading-color{background-color: transparent !important;}
.hy-align .ant-btn-loading::before{background-color: transparent !important;}
.btncolor{background:"gray"}
.cust-select-arrow .ant-select-single .ant-select-selector .ant-select-selection-item::after{margin-left: 8px;}
 .fund-transfer-input .tfunds-inputgroup .ant-input-group-addon{ padding: 0 10px 0 0 !important;}
 .plist-textwrap {width: 471px;word-wrap: break-word;}
 .trn-sumtext {width: 330px;word-wrap: break-word;}
 .send-crypto-sumry .ant-checkbox-wrapper.ant-custumcheck {position: relative;bottom: 10px !important;}
 .fiat-crypto-model .ant-moda{width: 520px;}
 .buy-usdt-btn .ant-btn .ant-btn-lg :focus{box-shadow : none !important;--antd-wave-shadow-color:none !important;}
 /* Standard */
 .k-grid-header .k-header > .k-link{font-size: 16px;font-weight: 500;}
 .k-grid td{font-size: 14px;font-weight: 400;}
 h1.sub-heading {text-transform: capitalize;padding-left:4px; padding-right:4px;font-size:18px;font-weight:500;margin-top: 36px;color:var(--textWhite);}
h1.heading{font-size: 24px;font-weight: 600;color:var(--textWhite);margin-bottom: 16px;padding-left:4px; padding-right:4px;}
.sidebar-push{padding:30px 16px 24px;}
.k-grid-header .k-header > .k-link{padding: 8px;margin: 0;}
.ant-btn{box-shadow: none;}
.custom-header span {padding: 6px 0px;}
.ant-menu-item:active, .ant-menu-submenu-title:active{background-color: transparent;}
.k-grid th{padding:0px 12px 0px 3px; height:42px;}
.cust-pop-up-btn .primary-btn.pop-cancel{border: transparent !important;}
.cust-pop-up-btn .primary-btn.pop-cancel:hover{border: 1px solid var(--textYellow) !important;}
.custom-back-btn .pop-cancel{border: transparent !important;}
.custom-back-btn .pop-cancel:hover{border: 1px solid #f2f2f2 !important;}
.cust-cypto-view,.cust-fiat-view{width: 900px;margin: auto;}
.loading-btn{margin: 0 auto;}
.new-add .loading-btn + button{position:absolute; right:142px !important;}
.bg-box{padding: 24px;border-radius: 0;}
.pop-btn.withdraw-popcancel{width: 150px; height: 46px;  border: transparent; color: var(--textYellow); background-color: transparent;}
.pop-btn.withdraw-popcancel:hover{border: 1px solid #f2f2f2 !important;}
.pop-btn.withdraw-popcancel span{color: var(--textYellow); font-weight: 400;}
.file-label.add-lbl{text-align: center;display: block;}
.gridlink-data{max-width:130px ;}
.custom-btn:hover span{color: white;}
.custom-crypto-btns .custom-bnt span{color: var(--textWhite); font-weight: 400;}
.pop-btn.cancel-btn{background: transparent; border: 1px solid transparent; border-radius: 40px; height: 46px !important; font-weight: 700 !important;}
.pop-btn.cancel-btn:hover{border: 1px solid var(--textYellow)!important;}
.pop-btn.cancel-btn span{color: var(--textYellow) !important;}
.crypto-pop .btn-width{width: 150px !important;height: 46px !important;}
.sc-error{font-weight: 400 !important;}
.crypto-blc-inpt{flex:1;}
.cust-tabs-fait .ant-tabs-nav-operations{display: none !important;}
.pop-btn.custom-send.error{margin-left: 0 !important;}
.error-img{margin-left: 50px; width: 300px;}
.error-content{margin-bottom: 40px !important;}
.error-title{margin-bottom:10px !important;}
.notify-viewbtn{margin-bottom: 10px;}
.uploaddoc-margin{margin: 12px 10px 0 0;}
.bill-pop .bill-cancel{border: transparent !important;}
.bill-pop .bill-cancel:hover{border: 1px solid var(--textYellow) !important;}
.carousel-card .carosel-content{color: var(--tablesub);font-weight: 400;font-size: 12px;}
.icon.md.To.Wallet{	background-position: -325px -101px;}
.icon.md.From.Wallet{	background-position: -325px -101px;}
.icon.md.To.Value{background-position: -694px -135px;}
.icon.md.From.Value{background-position: -694px -135px;}
.icon.md.Provider.Commission{background-position: -549px -101px;}
.icon.md.Network{background-position: -325px -101px;}
.icon.md.Wallet.Address{background-position: -325px -101px;}
.icon.md.State{background-position: -636px -135px;}
.icon.md.Bank.Account{background-position: -457px -70px;}
.icon.md.Wallet.Code{background-position: -325px -101px;}
.icon.md.Amount{background-position: -428px -70px;}
.icon.md.Coin{background-position: -325px -101px;}
.icon.md.Requested.Crypto{background-position: -428px -70px;}
.icon.md.Deposited.Coins{background-position: -428px -70px;}
.icon.md.Country.of.Residence{background-position: -436px -101px;}
.icon.md.Customer.ID{background-position: -126px -102px}
.dashb-btmtable table tbody .ant-table-placeholder{background: none;}
.pwdbtn-cancel.pop-btn.cancel-btn:hover{border: 1px solid var(--textYellow) !important;}
.selcerpto-list{padding-right: 10px;}
.wallet-info{padding: 16px 0;font-size: 16px;display: flex;justify-content: space-between;}
.wallet-info span{font-size: 16px;color: var(--textWhite30);font-weight: 600;}
.wallet-info>span:first-child{font-size: 14px;color: var(--textWhite50);font-weight: 200;}
.selectcustom-input .ant-form-item-label > label::after{content: none !important;}
.security-login{background: var(--drawerList);color: var(--black);padding: 50px 40px 16px;margin: 80px auto;border-radius: 14px;width: 500px;height: 350px;}
.security-text{font-size: 22px !important;font-weight: 700 !important;line-height: 1.29 !important;color: var(--white)!important;text-align: center;}
.primary-btn.pop-cancel:hover{border: 1px solid var(--textYellow) !important;}
.transaction_resp .cust-label .ant-select-selection-item{cursor: pointer !important;}
.pay-list.transaction-details h1{flex: 1; word-break: break-all;}
.ant-alert-info .ant-alert-icon{color: #ff4d4f;}
/* .ant-alert-info{background-color: #fff2f0; border: 1px solid #ffccc7;} */
.accordian.mb-togglespace .docfile{background-color:var(--drawerBG) !important;}
.docfile.address-upload{background-color: var(--uploadcard) !important;}
.statement-header{display: flex; justify-content: space-between; align-items: baseline;margin: 20px 0 20px 0;width: 100%;}
.header{font-weight: 500;margin-bottom: 0;}
.details-cust{display: grid;text-align: right;}
.statement-header ul{list-style: none !important;padding: 0;}
.statement-header ul li b,.statement-header p .generatedDate{display: inline-block;width: 100px;}
.logo{vertical-align: middle; height: 80px;}
.transaction-pdf-template tr:nth-child(even){background-color: rgb(235 235 235 / 85%)}

/* .statement-header.logo-content{align-items: flex-end;} */
.transaction-pdf-template thead{vertical-align: top;text-align: left;}
.transaction-pdf-template thead th:first-child,tbody tr td:first-child,.fee-discount-table tr td:first-child{padding-left: 10px; }
.transaction-pdf-template tbody tr td{padding-top:8px;padding-bottom:8px; }
.transaction-pdf-template .pdt-data tr td{word-break: break-all;}
.ant-dropdown-menu.history-excel{background-color: var(--profileMenu) !important; border-radius: 10px; min-width: 100px; max-width: 200px; padding: 24px 0;right: 25px;}
.export-pdf button{background-color: #3653E4;border-color: #3653E4;border-radius: 5px;height: 42px;color:#FFFFFF;font-weight: 700;transition: 0.3s ease;}
.export-pdf button:hover,.export-pdf button:active,.export-pdf button:focus{background-color:#7087FF;color:#FFFFFF;border-color:#7087FF;}
.secureDropdown.export-pdf{margin-bottom: 16px;}
.upload-disable{cursor: not-allowed;}
.file-label.batch-upload{width: 100%;}
.icon.md.close.batch-close{transform: scale(0.7);}
.gridLink.batch-filename{display: inline;}
.draft-filename{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.btn-space{margin-left: 8px;}
.webkit-color{-webkit-text-fill-color: #3653E4}
.address-clear.preview-content table tr td:first-child{white-space: nowrap;}
.file-label span.webkit-color{display: inline-block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.export-pdf .ant-dropdown-button button:first-child{cursor: default !important;}
.history-excel .drpdwn-list li a{padding: 10px 0;}
.input-label.selectcustom-inputdate-mobile{margin-bottom: 0 !important;}
/* .search-box > .search-btnexpand:hover, .search-box > .search-btnexpand:focus{border: 1px solid var(--subText);border-right: 0;border-radius: 10px 0px 0px 10px;} */
.cust-cancel-btn.send-crypto-btn{margin-top:28px;}
.cust-disabled.pop-btn.ant-btn[disabled]{background-color: rgb(112, 135, 255) !important;border-color: rgb(112, 135, 255) !important;}

.approve-email{font-size: 14px;font-weight: 400;}
.phone-flex{ display: flex;
    justify-content: space-between;
    align-items: center;}
.commision-fee-custtable .cust-tr-style > th{border-right: 1px solid var(--tableborder)}
.commision-fee-custtable .cust-tr-style > th:last-child{border-right:0}
.cust-send-amountfield.fund-transfer-input .tfunds-inputgroup .ant-select{width:auto;}
.accordian .ant-collapse-extra span{display: inline-block; color: var(--textPureWhite);font-size: 12px;padding: 2px 8px; border-radius: 4px; font-weight: 500;}
.reject-lbl{background-color: red !important;}
.subm-lbl{background-color: blue !important;}
.Requet-lbl{background-color: #c7c70d !important;}
.send-crypto-input.crypto-blc-inpt{position: relative;}
.cust-error-msg-align .ant-form-item-explain{position: absolute;top:90px;}
/* .history-crypto-cards{min-width: 786px;} */
/* .search-bg.fait-card-transaction-h .listitems-design{float: none;} */
/* .search-bg.fait-card-transaction-h .ant-list-item.cytpo-list-style:nth-child(even) {margin-right: 0;background: var(--faitGradeBlue)!important;} */
/* .search-bg.fait-card-transaction-h .ant-list-item.cytpo-list-style{background: var(--faitGradGreen) !important;} */
.trnsaction-mt-36{margin-top: 36px;}
.dashboard-kycbd p .add-custom{justify-content: start;}
.card-bg-style{background: var(--gridborder);padding:24px;border-radius: 12px;margin-bottom: 24px !important;}
.ant-menu-item-disabled .ant-menu-title-content .ant-menu-item{cursor:not-allowed;}